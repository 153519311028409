module.exports = {
  index: {
    circulation: 'BHB セキュリティと発展基金の流通量',
    hourEth: '今日の分配収益の累積換算',
    yesterdayEth: '昨日の分配収益の累積換算',
    yesterday: '昨日のマイニング収益',
    bhbTotal: 'BHB 総流通量',
    bhbSecond: 'BHB 二次市場の流通量',
    checkMineprinciple: 'マイニング原則の確認',
    checkFlowVolum: '流通量説明の確認',
    checkLockPosition: 'ロックポジションの確認',
    BHBregister: 'NTL アカウント登録',
    tibi: '引き出しの入金時間と制限',
    line_plane: 'BHB リストアップ計画、流通量、手数料還元のお知らせ',
    fenpeijizhi: 'NTL 収益分配メカニズムに関する説明',
    jiangli_jihua: '招待マイニング報酬プラン',
    friend_fanhuan: '友達を招待し、追加手数料還元を獲得',
    recommendInfo: 'より良い体験を得るためのアプリケーションアクセスの推奨',
    download: '今すぐ取得',
  },
  transpage: {
    gw: '公式ウェブサイト',
    h5: 'Web3 取引システム',
    down: '直接ダウンロード',
    down1: 'アプリストアからダウンロード',
    file: 'ファイル',
  },
  myfriends: {
    myhy: '私の友達',
    firsthy: 'ファーストレベルの友達',
    active: 'アクティブ',
    people: '人',
    unactive: '非アクティブ',
    sehy: 'セカンドレベルの友達',
    tx: 'プロフィール写真',
    nickname: 'ニックネーム',
    createtime: '登録日時',
    status: 'ステータス',
    action: '操作',
    charTime: '入金時間',
  },
  Launchpad: {
    focuses:
      'ハイクオリティなブロックチェーン新興プロジェクトを選定し、ユーザーが早期のデジタル通貨投資家になるのをサポートすることに焦点を当てています。',
    ckwdzc: 'マイアセットを表示',
    yssxm: '既存のプロジェクト',
    ggb: '犬のコインは異なる人にとって異なる意味を持っています。本質的には、犬のコインは人々に喜びをもたらす暗号通貨運動を偶発的に開始したものです！犬のコインは、コンピュータノードで構成されたネットワークを使用して記帳を行うオープンソースの分散型デジタル通貨です。高度に安全な分散型システムを実現しています。',
    LINK: 'ChainLinkはスマートコントラクトの新たな希望です。ChainLinkは2017年に設計され、その創設者はSteve Ellis、Ari Juels、Sergey Nazarovです。ChainLinkは分散型のオラクルトレードネットワークです。 ChainLinkはブロックチェーンスマートコントラクトを現実世界のデータ、イベント、支払いに接続します。',
    AVAX: 'Avalancheは、速度や分散を妨げずにスケーラビリティを向上させることを目指しています。その中核プラットフォームには、3つのブロックチェーンがあります：取引チェーン（Xチェーン）、スマートコントラクトチェーン（Cチェーン）、プラットフォームチェーン（Pチェーン）。Xチェーンは資産の作成と取引に使用され、Cチェーンはスマートコントラクトの作成に使用され、Pチェーンは検証者とサブネットの調整に使用されます。',
    SOL: 'SOLはSolanaのネイティブユーティリティトークンで、Solanaネットワークはその一部として通貨供給を縮小させています。送金やスマートコントラクトとのやり取り時に、ユーザーはSOLで手数料を支払う必要があります。SOLの保有者はネットワーク検証者になることもできます。イーサリアムと同様に、Solanaは開発者がスマートコントラクトを構築し、ブロックチェーン上でプロジェクトを作成できるようにサポートしています。',
    DOT: 'Polkadotは「全人類のためのオープンプロトコル」と称され、ブロックチェーン技術の次のステップを提供することを目指しています。Polkadotのコンセプトは、Ethereumの共同創設者であるGavin Wood博士によって最初に提案されました。開発チームはブロックチェーンのセキュリティ、スケーラビリティ、革新性に重点を置いて研究することを望んでおり、新しいアイデアとコンセプトをサポートし、相互運用性を確保する基盤を構築する必要があります。',
    ATMO: 'Cosmosは独立したブロックチェーンネットワークを結びつけることを目指しているプラットフォームです。ATOMはCosmosネットワークのネイティブトークンで、トランザクションの支払い、ガバナンストの投票、ネットワークを保護するステーキングに使用されます。',
    jr: '参加',
    jrnsdz:
      '革新的でユニークなブロックチェーンプロジェクトを立ち上げている場合、以下のリンクを使用して申し込むことができます。NTL Launchpad、あなたのブロックチェーンアセット発行プラットフォームです！',
    ljsq: '今すぐ申し込む',
    cjwt: 'よくある質問',
    rhcy: '参加方法',
    text1:
      'まず、NTLの個人アカウントを作成するか、既存のアカウントにログインする必要があります。アクティビティに参加するには、一定数のNTLをステーキングする必要があります。アカウントにログインしたら、「Launchpad」を見つけてアクティビティのホームページにアクセスし、情報を確認し、詳細情報を読むことができます。NTLをステーキングしたり解除したりする操作は、マウスまたはスクリーンを軽くタップするだけで簡単に行うことができます。',
    text2:
      'NTL Launchpadは現在、新しいトークンの採掘および割引セールの2つのアクティビティタイプを提供しています。新しいトークンの採掘モードでは、ユーザーはNTLをステーキングしてトークンの報酬を獲得することができます。各期のアクティビティには異なるステーキング期間と合計ステーキング制限があり、ステーキング量が多いほど報酬が多くなります。割引セールモードでは、「予約購読+くじ引き」の方法を使用してトークンを配布します。このプロセス全体において、公式にはアクティビティへの参加に使用できる代替通貨はNTLだけです。新しいトークンの採掘および割引セールアクティビティは、一部の制約のある国/地域のユーザーには適用されないことに注意が必要です。アクティビティへの参加資格については、NTLが発表したアクティビティ情報をご確認ください。',
    ynxhd: '利用可能なアクティビティタイプ',
    gfwz: '公式ウェブサイト',
    gw: 'ウェブサイト',
    bps: 'ホワイトペーパー',
    sjmt: 'ソーシャルメディア',
    jianjie: '紹介',

    ggbxq:
      '犬のコインは異なる人々にとって異なる意味を持っています。本質的に、犬のコインは偶然の出来事から始まり、人々に喜びをもたらす暗号通貨運動です。',
    ggbxq2:
      '犬のコインは、ブロックチェーン技術を使用したオープンソースの分散型デジタル通貨で、コンピュータノードからなるネットワークを使用して帳簿を高度に安全な分散システムで記録します。',
    ggbxq3:
      'さらに重要なことは、犬のコインが持つ精神です。犬のコインの宣言に記載されているように、それは驚くほど活気にあふれ、友好的な人々からなるコミュニティです。',
    ggbxq4:
      "犬のコインコミュニティは、お互いに助け合い、友好的で、暗号通貨を普及させ、資金調達をサポートし、楽しさを追求し、信念を築き上げ、馬鹿げたことを実現することを目指しています。犬のコインの非公式なスローガンは、これらの価値を素晴らしく要約しています: '毎日がポジティブ'。",
    ggbxq5:
      '犬のコインコミュニティは、犬のコインを伝統的な法定通貨の実行可能で合法的な代替手段に発展させることを期待しています。そのため、犬のコインエコシステムは、ダイナミックかつ有機的な方法で拡大し、人々に広く使用される通貨になるために努力しています。すべては人々のためです。同時に、犬のコインは初心を忘れず、最も楽しい暗号通貨であることを忘れません。',
    ggbxq6:
      '犬のコインの核心精神は実用的であり、優雅であり、温かく、信頼性があり、ここであなたに出会えて非常に嬉しいです。',

    LINKxq1:
      'ChainLinkは、スマートコントラクトの新たな希望です。2017年に設計され、その創設者はスティーブ・エリス（Steve Ellis）、アリ・ジュエルス（Ari Juels）、そしてセルゲイ・ナザロフ（Sergey Nazarov）です。ChainLinkは分散型オラクルトランザクションネットワークです。 ChainLinkはブロックチェーンスマートコントラクトを現実世界のデータ、イベント、および支払い活動に接続します。',
    LINKxq2:
      '取引の信頼性と安全性を確保するために、ChainLinkは合理的なソリューションを提供し、取引の安全性を向上させます。',
    LINKxq3:
      'ChainLinkプラットフォームは、トレーダーがスマートコントラクトアルゴリズムの潜在能力を最大限に活用し、大量の入出力を行うのに便益をもたらします。',
    LINKxq4:
      'ただし、このシステムの主要な特徴は実行の信頼性です。スマートコントラクトは現実世界の情報と連携する必要があるため、この種の契約はブロックチェーンシステム外のプロトコルを構築できます。 この過程で、スマートコントラクトはオラクルの使用を避けることはできません（オラクルはオンチェーンとオフチェーンのデータ間の仲介者として機能します）。',
    LINKxq5:
      '中央集権型オラクルを使用する際、ユーザーは信頼性が保証されていないため、セキュリティリスクに直面する可能性があります。 この状況がChainLinkが最も効果的に機能する場面です。',
    LINKxq6:
      'ChainLinkは、多くのソースからのデータを検証および調整し、ユーザーが不信頼なオラクルからの攻撃を回避するのに役立ちます。',
    LINKxq7:
      'さらに、ChainLinkは組み込み信用システムを備えており、ユーザーの取引に最適なオラクルを提供します。',
    LINKxq8: 'ChainLinkのネイティブトークンの名前は「LINK」です。 ',
    LINK: 'はERC-20トークンであり、追加のERC223「転送および呼び出し」機能（アドレス、uint256、バイト）を備えており、スマートコントラクトが1回のトランザクションでトークンを受け取りおよび処理できるようにします。',
    LINKxq9:
      'LINKはChainLinkプラットフォームのサービス手数料の支払いに使用されます。 ChainLinkノードオペレーターはLINKトークンを保持し、ネットワークに追加でき、ネットワークのステークを形成します。 この方法は評判契約（reputation contract）に影響を与えます：ステークが大きいほど、オペレーターは受けるリクエストの量が多くなります。',

    AVAX1:
      'Avalancheは、速度や分散性に影響を与えずにスケーラビリティを向上させることを目指しています。その中核プラットフォームには、トランザクションチェーン（Xチェーン）、スマートコントラクトチェーン（Cチェーン）、およびプラットフォームチェーン（Pチェーン）の3つのブロックチェーンがあります。Xチェーンは資産の作成と取引に使用され、Cチェーンはスマートコントラクトの作成に、Pチェーンはバリデータとサブネットの調整に使用されます。',
    AVAX2:
      'このプロトコルの最も重要な革新の1つはAvalanche共識です。この共識では、バリデータが繰り返しサブサンプリング投票を使用して、高速かつ負担に耐えうる合意を形成します。また、Avalancheはサブネットを使用した新しい横方向の拡張方法も採用しており、カスタマイズ可能で相互運用可能なブロックチェーンを作成できるようにしています。サブネットの数に制限はありません。',
    AVAX3:
      'Avalancheは、主に3つの問題を解決しています：スケーラビリティ、トランザクション手数料、および相互運用性です。',
    AVAX4: 'スケーラビリティと分散性の比較',
    AVAX5:
      '伝統的なブロックチェーンプロジェクトは長らく、スケーラビリティと分散性のバランスを模索し続けてきました。ネットワーク上のアクティビティが増加し続けると、すぐにネットワークが混雑するおそれがあります。ビットコイン（BTC）はその典型的な例であり、ネットワークが混雑した際には、ユーザーが一つのトランザクションを完了するのに数時間、場合によっては数週間もかかることがありました。',
    AVAX6:
      'この問題への対処策の一つは、ネットワークの中心化度を高め、一部の関係者に高い権限を与えてネットワーク活動を迅速に検証することです。しかし、ブロックチェーンの安全性にとって分散性は極めて重要です。新たなブロックチェーンは技術的な改善を通じてこの問題に取り組み、Avalancheも独自のソリューションを提供しています。詳細は以下で説明します。',
    AVAX7: '高額な手数料',
    AVAX8:
      'イーサリアムなどの大規模なブロックチェーンが直面するもう一つの一般的な問題はガス料金です。これはトラフィックの増加に応じて上昇する可能性があり、最終的にはこれらのブロックチェーンを使用するユーザーに対する抑制的な影響を及ぼします。しかし、競合他社のエコシステムはまだ成熟しておらず、例えばイーサリアムのような有力な競合者は出現していません。この結果、ネットワークの混雑と高額なトランザクション手数料が発生し続け、一部の時期にはイーサリアムネットワーク上の通常の一般トランザクションの手数料が10ドルを超えることもありました。複雑なスマートコントラクトが関与する場合、手数料は100ドル以上に上昇することもありました。',
    AVAX9: '互操作性',
    AVAX10:
      '不同项目和业务对区块链平台的需求不同。此前，区块链项目只能在以太坊、无法按需求调整的独立区块链或私链之间进行选择。在可定制化与多链协作之间寻找平衡点，一直是区块链行业的难题。Avalanche的解决方案是子网，它与主网拥有相同的安全性、速度与兼容性。',

    SOL1: 'SOLはSolanaのネイティブユーティリティトークンであり、Solanaネットワークでは通貨の収縮モデルの一部として焼却されます。トランザクションを実行したり、スマートコントラクトと対話する際に、ユーザーはSOLで手数料を支払う必要があります。SOLの保有者はネットワークのバリデータにもなることができます。Solanaは、Ethereumと同様に、開発者がスマートコントラクトを構築し、ブロックチェーンをベースにプロジェクトを作成することをサポートしています。',
    SOL2: 'SOLはSPLプロトコルを使用しており、SPLはSolanaブロックチェーンのトークン標準であり、EthereumのERC20に類似しています。SOLトークンには2つの主要な用途があります。',
    SOL3: '1. ネットワークやスマートコントラクトの使用に伴うトランザクション手数料の支払い。',
    SOL4: '2. プルーフオブステーク（PoS）共識メカニズムの一部として、ステーキングトークンとして機能する。',
    SOL5: 'Solana上に構築された分散型アプリケーション（DApp）は、SOLおよびSPL標準で構築された他のトークンの新たな用途を生み出しています。',
    SOL6: 'ただし、このシステムの主要な特徴は信頼性です。スマートコントラクトは現実世界の情報との連携が必要であり、そのため、スマートコントラクトはオラクルの使用を避けることはできません（オラクルはブロックチェーン上とオフチェーンのデータの仲介者として機能します）。',
    SOL7: '集中型のオラクルを使用する場合、ユーザーは信頼性が不確かであるため、セキュリティのリスクに直面する可能性があります。この状況がChainLinkの最適な機会です。',
    SOL8: 'ChainLinkは、さまざまなソースからのデータを検証および調整し、ユーザーが不正確なオラクルからの攻撃を回避できるように保護します。',
    SOL9: 'さらに、ChainLinkは埋め込み型の信頼性システムを備えており、ユーザーの取引に最適なオラクルを提供します。',
    SOL10:
      '2020年のメインネットのテスト版以降、Solanaエコシステムは急速に成長しています。2022年12月までに、Solanaを使用する21,255のGitHub開発者リポジトリがあり、800万人のアクティブユーザーをサポートしています。',
    SOL11:
      '伝統的な経済の巨大なプレイヤーがSolanaに参入を発表しています。Discord（ユーザーのプロファイルにSolanaウォレットをリンクすることができます）やASICS（Solana Pay支払いシステムを使用して限定版の靴デザインを購入できます）を含む企業がその一例です。Solanaは高速なトランザクション処理と高いスループットにより、多くのWeb3ユースケースの選択肢となっています。',

    DOT1: 'Polkadotは「全人類のためのオープンソースプロトコル」と称され、自身を「ブロックチェーン技術の次のステップ」と位置づけています。Polkadotの概念は、元Ethereum共同創設者であるGavin Wood博士によって最初に提案されました。開発チームは、ブロックチェーンのセキュリティ、拡張性、革新性に重点を置いて研究を行いました。そのために、新しいアイデアとコンセプトをサポートし、同時に相互運用性を確保するインフラストラクチャを構築する必要がありました。',
    DOT2: 'Polkadotエコシステム内の各ブロックチェーンはパラチェーン（平行ブロックチェーン）と呼ばれ、メインチェーンは「リレーチェーン」として知られています。これらのチェーンは常に情報を迅速に交換できるように設計されています。パラチェーンは、Ethereum 2.0プランで提案されている独立したシャーディングスキームに類似しています。',
    DOT3: '開発者、企業、個人は、暗号通貨や分散システムを作成するためのフレームワークであるSubstrateを使用して、パラチェーンをカスタマイズできます。カスタマイズされたブロックチェーンはPolkadotネットワークに接続され、他のパラチェーンと相互運用できます。',
    DOT4: '上記の設計により、クロスチェーンアプリケーション、製品、およびサービスの構築が容易になりました。これまで、大規模なクロスチェーンデータや資産の移動は困難でした。',
    DOT5: 'パラチェーンのデータセキュリティと検証は、ネットワークのバリデータによって行われ、わずかなバリデータでも複数のパラチェーンのセキュリティを確保します。バリデータはトランザクション情報が複数のパラチェーン間でスムーズに伝達されることを確保し、ネットワークの拡張性を向上させます。',
    DOT6: '開発者にとって、Polkadotエコシステムには多くの利点があります。現在のブロックチェーン技術にはいくつかの主要な問題があり、それには拡張性、カスタマイズ性、相互運用性、ネットワークガバナンス、およびアップグレード可能性が含まれます。',
    DOT7: '拡張性の観点から、Polkadotは多くの問題に対処しています。マルチチェーンネットワークとして、複数の独立したブロックチェーンを同時に処理できるようになり、ブロックチェーン技術分野での大きな進歩となります。並列処理は、グローバルなブロックチェーンの応用に道を開くでしょう。',
    DOT8: 'カスタムブロックチェーンを必要とするユーザーは、Polkadotの他の機能を探ることができます。現在、すべてのプロジェクトの要件を満たす「ワンサイズフィットオール」のブロックチェーンインフラストラクチャは存在しません。各プロジェクトには固有の要件があり、Polkadotは最適化されており、独立したブロックチェーンのカスタマイズをサポートします。Substrateのサポートを受けて、開発者はプロジェクト要件に応じて独立したブロックチェーンを効果的に調整できます。',
    DOT9: 'プロジェクトとアプリケーションをシームレスに統合し、データ共有を実現することは、インターオペラビリティを向上させるための重要な手段です。最終的に登場する製品やサービスはまだ不明ですが、潜在的なユースケースは多岐にわたります。Polkadotは新しい金融エコシステムを構築する能力を持ち、各パラチェーンは特定の機能を実現するだけで済みます。',
    DOT10:
      '各特定のパラチェーンは、それに関連するコミュニティによって独自の方法で独立して運用および管理できます。さらに、各コミュニティはPolkadotネットワーク全体のガバナンスに重要な影響を与えます。コミュニティからのフィードバックは、Polkadotプロジェクトの発展に貴重な意見を提供します。',
    DOT11:
      'さらに、Polkadotはパラチェーンのアップグレードをサポートし、そのプロセスは非常にシンプルです。ハードフォークによるコミュニティの分裂を引き起こす可能性を回避し、フリクションのない方法でアップグレードが行われます。',

    ATMO1:
      "Cosmosは、独立したブロックチェーンネットワークを接続することを目指すプラットフォームです。このプラットフォームは、異なるブロックチェーン間のデータ転送を促進し、いわゆる 'ブロックチェーンのインターネット' を推進します。ATOMはCosmosネットワークのネイティブトークンで、トランザクションの支払い、ガバナンスの投票、およびネットワークのセキュリティを保護するためのステーキングに使用されます。",
    ATMO2:
      'Cosmos自体はブロックチェーンではなく、アプリケーションや企業向けのカスタムブロックチェーン（ゾーンと呼ばれる）の基盤を提供します。各ブロックチェーンが連携と合意のコードをゼロから実装しなければならない場合、多くのブロックチェーンから成る世界を構築することは不可能です。そのため、Cosmosはこれらの機能を処理できるテンプレートソフトウェアCosmos SDKを提供しています。',
    ATMO3:
      "Cosmos SDKへの多年の開発作業により、ゾーンを起動することはスマートコントラクトをデプロイするのと同じくらい簡単になりました。ただし、このアプローチはCosmosに固有ではありません。他のアプリケーション固有のブロックチェーンアイデアと組み合わせたプロジェクトも、開発者に 'ボックス型ブロックチェーン' を提供しており、例としてPolkadotのSubstrateフレームワークが挙げられます。",
    ATMO4:
      'TendermintはCosmosネットワークの基礎となる共通アルゴリズムで、高性能なBFT SMR（State Machine Replication）プラットフォームとして設計されています。このプラットフォームはサービスを複製し、それらのサービスを決定論的でランダムでない状態マシンとしてシミュレートできるようにするものです。Tendermint Coreの作成は、特定のサーバー（またはステート）を複製し、これらのサーバーをCosmosネットワーク全体に分散させることで実現されます。このプロセスにより、Tendermint Coreのソフトウェアエンジニアは世界中のステートマシンでブロックチェーンを複製できます。',
    ATMO5:
      'Tendermintの重要な技術的能力により、開発者はゼロからすべてを構築する必要なく、独自のブロックチェーンプラットフォームを作成できます。これは非常に便利で、ユーザーはアプリケーションのロジックとトークン以外はほぼすべてが提供されており、CosmosネットワークにホストされるトークンにはKava（KAVA）、Terra（LUNA）、Band Protocol（BAND）、Aragon（ANT）、Akash Network（AKASH）などが含まれます。',
    ATMO6:
      'ATOMトークンはCosmos Hubがプルーフオブステークブロックチェーンプラットフォームであるため、Tendermint CoreのBFTコンセンサスメカニズムの機能で重要な役割を果たします。Cosmosは、100のバリデータノードネットワークに依存してコンセンサス、セキュリティ、および運用効率を維持します。このシステムを正常に機能させるために、ユーザーはATOMトークンをステーキングする必要があります。',
    ATMO7:
      'バリデータの役割は、完全なノードを実行し、ネットワークのルールを検証し、投票をネットワークにブロードキャストし、その後新しいブロックをブロックチェーンに提出することです。その結果、バリデータはATOMトークンのステーキングを通じて収益を得ます。',
  },
  AssetManagement: {
    Currency: '通貨',
    AssetsAccount: '資産口座',
    analyze: {
      Date: '日付',
      SelectDate: '日付を選択',
      Currency: '通貨',
      Balance: '残高',
      Today: '今日の収益/損失',
      CurrentAssets: '現在の資産',
      initialAssets: '今日の初期資産',
      netInflowAssets: '今日の純資産流入',
      MonthlyProfit: '今月の収益/損失',
      Accumulated: '今月1日00:00から今日00:00までの累積収益/損失',
      HistoricalPL: '歴史的な収益/損失',
      UpdatedAt: '更新日時',
      returnRate: '累積リターン率',
      CumulativeReturn: '累積リターン',
      InitialAssets: '初期資産',
      NetInflowAssets: '当日の純資産流入',
      netTransferOfAssets: '純入資産です',
      CumulativeProfitlos: '累積収益/損失',
      Endingassets: '期末資産',
      Initialassets: '初期資産',
      DailyEarnings: '日次収益',
      AssetsAt: '当日の終了時の資産',
      oftheDay: '当日の初期資産',
      distribution: '資産分布',
      AccountSumma: 'アカウントの各種資産の数量と割合、および現地通貨に換算した価値のまとめ',
      lzkyl: '累積収益率',
      lzbtcqs: '累積BTCトレンド',
      lzky: '累積収益/損失',
      mryk: '日次収益/損失',
      zcfb: '資産分布',
    },
    TotalAssets: {
      equity: '現在のエクイティは',
      Deposit: '仮想通貨をデポジット',
      TotalAssets: '総資産',
      Today: '本日の収益/損失',
      Day: '日',
      Month: 'ヶ月',
      HalfYear: '半年',
      classific: '資産分類',
      Spot: '現物',
      zd: '取引履歴',
      Fund: '資金動向',
      ViewAll: 'すべて',
      Recent: '最新記錄',
      Coin: '通貨',
      Depositamount: 'デポジット額',
      Time: '時間',
      NoData: 'データなし',
      SearchAssets: '資産の検索',
      hide0: 'ゼロの通貨を非表示',
    },
  },
  newmsg: {
    GeneralView: '全体ビュー',
    SafetyCenter: 'セキュリティセンター',
    setting: '設定',
    GeneralList: {
      nh: 'こんにちは',
      Hide: '個人情報を非表示',
      Show: '個人情報を表示',
      tradingFeeTier: '取引手数料階層',
      Email: 'メール',
      YouCurrently: '現在の資産はありません。入金して取引を始めましょう。',
      Invite: '友達を招待し、終身リベートのチャンスを享受できます。',
      Deposit: 'デポジット',
      Support: 'サポート: 仮想通貨間の転送',
      transfer: '転送',
      SupportsTransfers: '仮想通貨間の転送をサポート',
      Market: '市場',
      Announ: 'お知らせ',
      More: '詳細',
      Invitation: '招待',
      Partner: 'パートナープログラム',
      InviteFriends: '友達を招待し、最高で終身リベートを受けるチャンスがあります。',
      OpenTo: 'KOL向けで、プラットフォームのパートナーになるチャンスがあります。',
      InviteNow: '今すぐ招待',
      ApplyNow: '今すぐ申し込む',
    },
    CenterList: {
      SecurityCenter: 'セキュリティセンター',
      SecuritySettings: 'セキュリティ設定',
      Anonymous: '匿名ユーザーはメールアドレスをバインドしてアカウントを回復できます',
      NotBound: 'バインドされていません',
      AlreadyBound: 'バインド済み',
      LoginBassword: 'ログインパスワード',
      Device: 'デバイス管理',
      Authenticated: '認証済みデバイス',
      Recent: '最近のログイン時間',
      LoginLocation: 'ログイン場所',
      address: 'IPアドレス',
      Actions: 'アクション',
      CurrentDevice: '現在のデバイス',
      Recent10: '最新の10件のログイン記録',
      Location: '場所',
      AccountManagement: 'アカウント管理',
      DeactivateAccount: 'アカウントを無効にする',
      userTransaction: 'ユーザーの取引、出金などの確認には注意して設定し、記憶してください',
      LogOut: 'ログアウト',
      DoYouWant: '現在のデバイス情報を削除しますか？',
      AreYousure: 'このアカウントをログアウトしますか？',
      AreYousure2: 'この操作は不可逆になります!',
      his: '履歴',
      loc: '場所',
      IP: 'IP',
      datat: '日付と時間',
    },
    settingList: {
      PreferenceSettings: '設定の環境設定',
      Language: '言語',
      QuoteCurrency: '基軸通貨',
    },
  },
  common: {
    FindUs: '製品とサービスを発見',
    registeredAccount: 'NTLアカウントを登録',
    unlock: 'NTLをアンロック！匿名の力を体験',
    invitation: '友達をNTLに招待',
    closeTheDeal: '初めての取引を完了',
    sloganthree: 'NTLへようこそ！',
    slogantwo: 'NTLのユニークな宇宙を探索',
    slogantwo2: '透明なメカニズム、安全無縁',
    currencySelection: '通貨選択',
    chart: 'チャート',
    details: '詳細',
    entrust: '委託',
    login: 'ログイン',
    register: '登録',
    loginregister: 'ログイン/登録',
    signupnow: '今すぐ登録',
    indexplaceholde: 'メールアドレス',
    logout: 'ログアウト',
    tip: '重要な情報',
    logintip: '先にログインしてください',
    expect: 'お楽しみに！',
    delete: '削除してもよろしいですか？',
    nodata: 'データがありません',
    set: '設定',
    update: '更新',
    slogan: 'トップ50の暗号通貨取引所',
    subslogan: '国の機関が資産の安全性を保証',
    more: '詳細を表示',
    please: '先に',
    pleaseselect: '選択してください',
    searchplaceholder: '通貨名を入力してください',
    searchplaceholderswap: '契約名を入力して検索',
    loading: '読み込んでいます...',
    close: '閉じる',
    ok: '確定です',
    fillComplete: '情報を完全に入力してください',
    inputpleaseselect: '入力してください',
    showDetail: '詳細を表示',
    pleaseSelectTransferAccountFirst: '先に振り込みをお願いします。',
  },
  financeNav: {
    cjspan1:
      'NTL DAOは、カイマン財団に管理を委託してntlex.comを運営しており、このガバナンス構造は従来の暗号通貨取引所よりも優れており、公正な取引とユーザーアセットの安全性を効果的に保証しています。マネージメントチームのメンバーは世界中から集まり、中核メンバーはCoinbase、Binance、Morgan Stanley、Amazon、Googleなどの世界的に有名な金融およびテクノロジー企業での経験が豊富で、強力なグローバル戦略運営能力とリスク管理および管理の豊富な経験を持っています。',
    cjspan2:
      'ntlex.com（Nano Token Liquidity）は、分散型自治組織（DAO）ガバナンスに基づく国際的なデジタル資産デリバティブ取引所です。',
    cjspan3: 'NTLは以下のサービスを提供できます。',
    cjspan4:
      '・通貨間取引、Uベース契約取引、通貨ベース契約取引、オプション契約取引、スワップ契約取引など、さまざまな取引モード。',
    cjspan5:
      '・NTLプラットフォームで提供されるデポジットを利用して、手元の余剰資産を有価値にすることができます。',
    cjspan6: '・ユーザーのプライバシーを保護するために、匿名ログインをサポートしています。',
    cjspan7:
      'NTLのテクノロジーソリューションは、Amazon AWSやGoogle Cloudなどを基に構築されており、DDoS攻撃などを効果的に防ぐことができ、ダイナミックおよびスタティックデータの分離、ホットデータのキャッシュ、地理的な災害復旧、並列拡張などの機能をサポートしています。業界をリードするテクニカルアーキテクチャは、ユーザーの安全な取引を確保し、即時のブロックチェーン資産のアクセス機能、マルチ通貨アドレス共有技術、マルチ重要性確認などを備えています。また、ntlex.comは業界内で比較的低いブロックチェーン資産の取引手数料と引き出し手数料を提供しています。',
    Enhanced: 'ユーザーの取引体験向上とアセットの安全性保護',
    WhatIs: 'NTL DAOとは何ですか？',
    WhatServices: 'NTLはどのようなサービスを提供していますか？',
    IsNTL: 'NTLは信頼できるプラットフォームですか？',
    WatchTutorials: 'チュートリアルを視聴してデジタル通貨に関する知識を深める',
    BeginYour: '取引の旅を始めましょう',
    SignUp: '今すぐサインアップ',
    ExploreThe: 'NTLの宇宙を探索し、どこからでも取引できます',
    Deposit: 'デジタル通貨を入金する',
    wdzc: '私のアセット',
    zcgl: 'アセット管理',
    tzzx: 'お知らせセンター',
    cl: '閲覧',
    czszhb: 'デジタル通貨を入金',
    rmzc: '人気のあるアセット',
    HotRank: 'ホットランキング',
    NewCoinRank: '新しいコインランキング',
    PriceGainRank: '値上がりランキング',
    CoinBase: '通貨ベースおよびUベース契約で、セキュアな取引をサポート',
    Simplified: '簡単なオプション契約、デジタル資産デリバティブで多様な取引を実現',
    SpotContract: 'スポット契約',
    Experience: '刺激的な取引体験をお楽しみください',
    MakeMoney: '余剰資産を活用し、簡単に資産を増やす',
  },
  header: {
    Exploration: '探索',
    SecureAndStable: '安全で安定した取引環境',
    HowToGovern: 'ガバナンス方法',
    Alldecisions: 'すべての決定はトークン保有者によって行われます',
    UserCommunity: 'ユーザーコミュニティ',
    SharedGrowth: '共同成長',
    CryptoConvert: 'さらに多くのデジタルブラインドボックスを入手する',
    InviteMates: '友達を招待する',
    SiteUpdates: 'プラットフォームパートナーになる',
    PartnerProgram: 'パートナープログラム',
    NTL_SecureGuard: 'すべてのリワードを見る ',
    RewardHub: 'リワードハブ',
    potential: '潜在力発掘プロジェクトです',
    ExploreMore: 'もっと探す',
    NTL_Connect: 'NTLコミュニティに参加し、リアルタイムでインタラクト',
    CoinCommunity: 'Service',
    CryptoTradeInsights: '暗号通貨取引に関する包括的な知識を得る',
    HelpHub: 'ヘルプハブ',
    Aid: '支援',
    RookieHub: '初心者コミュニティ',
    ProjectProspect: '潜在的な新しいプロジェクトを見つける',
    AssetValor: 'アセットの価値を最大化する',
    EarningsEdge: 'アセットを活用して収益を得る',
    cunru: '入金',
    SpotGoods: '現物',
    AssetsOverview: '資産概要',
    UCon: 'Uベース契約',
    StableTrust: '安定した信頼性のスマート選択',
    CoinCon: 'コインベース契約',
    TrendMaster: '数字トレンドを安定的に捉え、賢明な投資',
    OptionC: 'オプション契約',
    EasyOptions: '簡単なオプション、数ステップで取引',
    QuickOptions: '秒契約',
    ManageMoneyAffairs: '投資',
    AssetAnalysis: '資産分析',
    InstaWave: '瞬間の市場変動',
    OrderCenter: '注文センター',
    gjhgpt1: '取引所',
    gjhgpt2: '市場',
    gjhgpt3: '仮想通貨の最新市況',
    gjhgpt4: '機会',
    gjhgpt5: '通貨ランキング、機会を発見',
    gjhgpt6: 'ワンクリックで取引完了',
    gjhgpt7: '多くの通貨をサポート、簡単に取引',
    index: 'ホーム',
    exchange: '通貨交換',
    trade: '取引',
    sq: 'コミュニティ',
    otc: '法定通貨OTC',
    kefu: 'ヘルプセンター',
    xiaoxi: 'お知らせ',
    login: 'ログイン',
    register: '登録',
    asset: '資産センター',
    otcOrder: '注文',
    ucenter: 'アカウントセンター',
    service: '公告',
    downloadapp: 'QRコードをスキャンしてアプリをダウンロード・インストール',
    appdownlaod: 'アプリのダウンロード',
    usercenter: 'ユーザーセンター',
    helpcenter: 'ヘルプセンター',
    aboutus: '会社について',
    partner: 'パートナーシップ',
    bzb: 'BZB',
    assetmanage: '資金管理',
    trademanage: '取引管理',
    activity: '最新のイベント',
    lab: '革新の研究所',
    ctc: '法定通貨C2C',
    labdetail: 'NTL研究所 ● 革新の成果を共有',
    invite: 'パートナーを招待',
    whitepaper: 'ホワイトペーパー',
    scandownload: 'QRコードをスキャンしてアプリをダウンロード',
    innovationmanage: '革新の管理',
    swap1: 'Uベース契約',
    swap2: 'コインベース契約',
    swap3: 'スプレッド契約',
    swap4: '秒契約',
    znum: '総数',
    cexchange: '変換',
    option: 'オプション契約',
    second: '秒契約',
    news: 'ニュース',
    swapExchange: '契約取引',
    finance: '金融サービス',
    fiatCurrency: '法定通貨取引',
  },
  sectionPage: {
    global_leader: '世界をリードする仮想通貨取引所',
    Professional: 'プロフェッショナルな取引、使いやすさ、デジタルトレードの理解を容易にします',
    ptaqTitle: 'セキュリティプラットフォーム',
    ptaqContent: 'ハッシュアルゴリズム暗号化を採用し、世界をリードするセキュリティ認証',
    ptslTitle: 'プラットフォームの実力',
    ptslContent: '世界最大のビットコイン取引プラットフォーム',
    newsTitle: '複数プラットフォームのアプリ',
    brandTitle: 'NTLについて',
    brandTitle1: '国際的なデジタル資産デリバティブ取引所',
    brandTitle2: 'ワンストップの暗号資産サービス',
    brandDetail: '誠実 | 公平 | 熱情 | オープン',
    brandDesc1:
      'チームメンバーは世界中から集まり、主要メンバーはMorgan Stanley、IBM、Google、Amazon、Microsoft、Tencent、Binanceなどの世界的な金融およびテクノロジー企業での経験を持つ、国際的なチームです。',
    brandDesc2:
      'チームメンバーは、国際的な仮想通貨取引所の設立および運営に参加しました。強力なグローバル戦略運営能力と豊富な金融リスク管理および経営経験を持っています。',
    brandDesc3:
      'NTLは、ユーザーの取引情報および資産を包括的に保護し、ユーザーの安全な取引を確実に保障する、信頼性のある国際的なブロックチェーン資産取引所を提供します。',
    brandDesc4:
      'NTLは、現物、永続契約、現物、オプション、ファイナンスおよび貸出など、ワンストップの暗号資産サービスを提供しており、多言語バージョン（英語、日本語、韓国語など）をサポートしています。',
    brandDesc5:
      'NTLの技術ソリューションはGoogle CloudおよびAmazon AWSをベースにしており、DDoS攻撃などを効果的に防止し、ダイナミックおよびスタティックデータの分離、ホットデータキャッシュ、サイト間冗長性、並列拡張などをサポートしています。ユーザーの安全な取引を保証する、業界をリードする技術アーキテクチャを持っています。',
    brandDesc6:
      'NTLは即時のブロックチェーン資産アクセス機能、多通貨アドレス共有技術、多層セキュリティ認証保護を提供し、業界内で低コストのブロックチェーン資産取引および引き出しを実現しています。',
    gettingstart: '初心者ガイド | 迅速な取引',
    officialstart: '初心者ガイド',
    oneminutebuy: 'スポット取引',
    oneminutebuytips: '仮想通貨と仮想通貨の交換取引、多くの主要なコインをサポートします。',
    baseknow: '永続契約',
    baseknowtips:
      'バイナリーオプション、コイン契約、2倍、最大100倍までのレバレッジ取引がサポートされており、ロングまたはショートの取引で収益を最大化できます。',
    baseexchange: 'オプション取引',
    baseexchangetips: 'デジタル資産デリバティブ取引で、多様な取引が可能です。',
    usersocial: '変換',
    usersocialtips: 'スポット取引、ワンクリック取引',
    mainboard: 'メインボード取引所',
    preview: '前のページ',
    nextpage: '次のページ',
    downloadwhite:
      'ホワイトペーパーをダウンロード（またはホワイトペーパー上で右クリック -> 名前を付けてリンク先を保存...）',
    showMore: 'もっと見る',
  },
  nav: {
    ptjy: 'プラットフォーム取引',
    ptaq: 'プラットフォームの安全性',
    ptsl: 'プラットフォームの実力',
    xwzx: '多端アプリ',
    fabu: '広告の掲載',
    shouye: 'ホーム',
    jiaoyi: '法定通貨取引',
    dingdan: '注文管理',
    zijin: '財務センター',
    yonghu: 'ユーザーセンター',
    bibi: 'コイン取引',
  },
  progress: {
    already: 'すでに採掘済み',
    total: '採掘可能な総供給量は60億BHB',
  },
  service: {
    USDT: 'USDT',
    BTC: 'BTC',
    ETH: 'ETH',
    CUSTOM: 'カスタム',
    COIN: 'コイン',
    favor: 'お気に入り',
    symbol: '取引ペア',
    NewPrice: '最新価格',
    ExchangeNum: '取引数量',
    FeePercent: 'ファンド料率',
    Change: '24時間の変動',
    OpenPrice: 'オープン価格',
    PriceTrend: '価格トレンド',
    Operate: '操作',
    Exchange: '取引へ進む',
    trading: '今すぐ取引',
    high: '24時間の最高価格',
    low: '24時間の最低価格',
    Mainstream: '主要コイン',
    HotList: 'ホットリスト',
    PriceIncrease: '値上がり率ランキング',
    SGDLeaderboard: '新着コインリスト',
    TurnoverList: '取引高ランキング',
  },
  exchange: {
    loginBuy: 'ログインして購入',
    loginOut: 'ログインして売却',
    coin: 'コイン',
    symbol: '取引ペア',
    lastprice: '最終価格',
    daychange: '24時間の変動',
    market: '市場',
    favorite: 'お気に入り',
    do_favorite: 'お気に入りに追加',
    cancel_favorite: 'お気に入りから削除',
    type: 'タイプ',
    dealamount: '取引金額',
    high: '高',
    low: '低',
    vol: '24時間取引量',
    buy: '購入',
    sell: '売却',
    limited_price: '指値',
    market_price: '市場',
    fees_rate: '手数料率',
    balance: '残高',
    or: 'または',
    starttrade: '取引を開始',
    canuse: '利用可能',
    recharge: '入金',
    buyprice: '購入価格',
    buynum: '購入数量',
    amount: '金額',
    totalAmount: '依頼総額です',
    buyin: '購入',
    disablebuyin: '成行注文は無効です',
    buytip: '市場で最適な価格で購入',
    sellprice: '売却価格',
    sellnum: '売却数量',
    sellout: '売却',
    disablesellout: '成行注文は無効です',
    selltip: '市場で最適な価格で売却',
    curdelegation: '未約定注文',
    hisdelegation: '注文履歴',
    realtransaction: 'リアルタイム取引',
    num: '数量',
    price: '価格',
    direction: '方向',
    time: '時間',
    stall: '階層',
    total: '累計',
    traded: '取引済',
    action: '操作',
    undo: '注文取り消し',
    delegationnum: '注文数量',
    done: '完了',
    status: 'ステータス',
    finished: '完了',
    canceled: 'キャンセル済',
    parted: '一部成立',
    tip: 'ヒント',
    buyamounttip: '購入数量を入力してください',
    buyamounttipwarning: '購入数量は最大で',
    success: '送信成功',
    pricetip: '取引金額を入力してください',
    pricetipwarning: '取引金額は最大で',
    sellamounttip: '売却数量を入力してください',
    sellamounttipwarning: '売却数量は最大で',
    sellpricetip: '売却価格を入力してください',
    sellpricetipwarning: '売却価格は最大で',
    withdrawalPrompt: 'キャンセルのヒントです',
    undotip: '注文をキャンセルしますか？',
    cancelsuccess: '注文がキャンセルされました！',
    marketprice: '成行',
    expand: {
      time: '時間',
      price: '価格',
      amount: '数量',
      fee: '手数料',
    },
    realtime: 'リアルタイム',
    kline: 'ローソク足チャート',
    depth: 'デプスチャート',
    publishstate0: '活動がもうすぐ開始します。お待ちください...',
    publishstate1: '活動が終了間近です。早く取引してください...',
    publishstate2: '活動の注文整理中です。お待ちください...',
    publishstate3: '注文整理が終了し、自由に取引できます...',
    publishdetail: '活動規則の詳細',
    publishprice: '発行価格',
    publishamount: '総量',
    publishcounttxt0: '活動開始まで残り',
    publishcounttxt1: '活動終了まで残り',
    publishcounttxt2: '清算終了まで残り',
    publishcounttxt3: '活動が終了しました',
    dateTimeDay: '日',
    dateTimeDay2: '日',
    dateTimeHour: '時間',
    dateTimeMinutes: '分',
    dateTimeSeconds: '秒',
    moredetail: '詳細を見る',
    coinSymbol: 'トレード通貨',
    cycle: 'サイクル',
    closeprice: 'クローズ価格',
    openprice: 'オープン価格',
    win: '成功',
    lose: '失敗',
    tied: '引き分け',
    earn: '利益',
    orderType: '包赔',
    orderTypeNo: 'いいえ',
    orderTypeYes: 'はい',
    purchasable: '購入可能',
    vendibility: '販売可能',
    swapassets: 'アカウントのエクイティ',
    Frozen: '凍結',
    toBeReleased: '解放待ち',
    sellMost: '{0}個まで売れます',
    lessThanMinimum: '利用可能残高は最小取引額未満です。',
    beAboutToStart: 'まもなく始まります',
    listingTime: '上場時間',
    countdown: 'カウントダウン',
    newCoin: '新しい通貨',
  },
  swap: {
    tradedTime: '取引時間',
    trade_tradedVolume: '取引ボリューム',
    trade_tradeFee: '取引手数料',
    contract: '契約',
    swapindex: 'スワップ',
    coin: 'コイン',
    symbol: 'トレードペア',
    volume: '取引ボリューム',
    lastprice: '最終価格',
    daychange: '24時間変動',
    market: 'マーケット',
    favorite: 'お気に入り',
    do_favorite: 'お気に入りに追加済み',
    cancel_favorite: 'お気に入りから削除',
    type: 'タイプ',
    dealamount: '取引金額',
    high: '高',
    low: '低',
    vol: '24時間ボリューム',
    buy: '購入',
    sell: '売却',
    limited_price: '指値',
    market_price: '成行注文',
    spot_price: 'ストップリミット',
    fees_rate: '手数料率',
    balance: '資産',
    or: 'または',
    starttrade: '取引開始',
    canuse: '利用可能',
    recharge: '入金',
    buyprice: '購入価格',
    buynum: '購入数量',
    amount: '取引額',
    buyin: '購入',
    disablebuyin: '成行注文不可',
    buytip: '市場で最良の価格で購入',
    sellprice: '売却価格',
    sellnum: '売却数量',
    sellout: '売却',
    disablesellout: '成行注文不可',
    selltip: '市場で最良の価格で売却',
    curdelegation: '未約定注文',
    hisdelegation: '注文履歷',
    TotalBill: '総売買',
    histrade: '取引詳細',
    realtransaction: 'リアルタイム取引',
    num: '数量',
    price: '価格',
    direction: '取引方向',
    time: '時間',
    stall: '階層',
    total: '累計',
    traded: '成立済み',
    action: '操作',
    undo: '注文取り消し',
    delegationnum: '注文数量',
    done: '完了',
    status: 'ステータス',
    finished: '完了',
    canceled: '取り消し',
    parted: '一部成立',
    tip: 'ヒント',
    buyamounttip: '購入数量を入力してください',
    buyamounttipwarning: '購入数量は上限を超えてはいけません',
    success: '成功',
    pricetip: '取引額を入力してください',
    pricetipwarning: '取引額は上限を超えてはいけません',
    sellamounttip: '売却数量を入力してください',
    sellamounttipwarning: '売却数量は上限を超えてはいけません',
    sellpricetip: '売却価格を入力してください',
    sellpricetipwarning: '売却価格は上限を超えてはいけません',
    undotip: '注文を取り消しますか？',
    cancelsuccess: '注文を取り消しました！',
    marketprice: '成行',
    expand: {
      time: '時間',
      price: '価格',
      amount: '数量',
      fee: '手数料',
    },
    realtime: 'リアルタイム',
    kline: 'ローソク足チャート',
    depth: 'デプスチャート',
    open: '新規建玉',
    close: '決済',
    latestdeal: '最新取引',
    myswapaccount: '私の契約アカウント',
    accountmode: 'アカウントモード',
    accountmargin: '契約倍数',
    accountquanyi: 'アカウント総資産',
    profitandloss: '未実現損益',
    principalAmount: '使用可能な証拠金',
    positionAmount: 'ポジション証拠金',
    frozenAmount: '凍結証拠金',
    principalRate: '証拠金利用率',
    zijinhuazhuan: '資金移動',
    zichanfenxi: '資産分析',
    up: 'ロング',
    down: 'ショート',
    canup: 'ロング建て可',
    candown: 'ショート建て可',
    cancloseup: 'ショートポジションクローズ可',
    canclosedown: 'ロングポジションクローズ可',
    openup: 'ロング建て',
    opendown: 'ショート建て',
    closeup: 'ロングクローズ',
    closedown: 'ショートクローズ',
    currentposition: '現在のポジション',
    assetsdetail: '資産詳細',
    marginMode1: '単一',
    marginMode2: 'クロス',
    modifyMarginModeTitle: 'アカウントモードの変更',
    modifyLeverage: '契約倍数の調整',
    moredetail: '詳細情報',
    shareNumber: '枚',
    entrustprice: '注文価格',
    triggleprice: 'トリガー価格',
    openvolume: '建てポジション数',
    closevolume: 'クローズポジション数',
    triggleplaceholder: 'デフォルトの注文価格は市場価格です',
    marketpriceplaceholder: '市場最良価格で取引',
    levmaxtip: '契約倍数は最大倍数を超えてはいけません',
    levmintip: '契約倍数は最小倍数より低くしてはいけません',
    marginModeSuccessTip: '契約アカウントモードが変更されました',
    noenoughbalance: 'アカウント証拠金が不足しています',
    pleaseinputopenvolume: '建てポジション数は0より大きい必要があります',
    pleaseinputcorrectopenvolume: '正しい建てポジション数を入力してください',
    pleaseinputrightentrustprice: '正しい注文価格を入力してください',
    noenoughposition: 'クローズポジション可能数が不足しています',
    pleaseinputclosevolume: 'クローズポジション数は0より大きい必要があります',
    pleaseinputcorrectclosevolume: '正しいクローズポジション数を入力してください',
    pos_Pl: '利益',
    pos_Ratio: '利益率',
    pos_pos: 'ポジション量',
    pos_canClose: 'クローズ可能量',
    pos_openPrice: '建てポジション平均価格',
    pos_qp: '強制平倉価格',
    pos_leverage: '契約倍数',
    pos_pattern: 'Pattern',
    pos_margin: '現在の証拠金',
    pos_mRatio: '証拠金率',
    pos_cmRatio: '維持証拠金率',
    pos_long: 'ロング',
    pos_short: 'ショート',
    ent_entrustType: '新規建玉/決済',
    ent_direction: '方向',
    ent_type: '注文の種類',
    ent_trigglePrice: 'トリガー価格',
    ent_entrustPrice: '注文価格',
    ent_tradedPrice: '成約価格',
    ent_margin: '証拠金',
    ent_volume: '注文数量',
    ent_status: '注文ステータス',
    ent_status1: '注文中',
    ent_status2: 'キャンセル済',
    ent_status3: '注文失敗',
    ent_status4: '注文成功',
    ent_statusblast: '強制平倉',
    ent_pl: '清算損益',
    ent_fee: '手数料',
    triggerpriceshouldbigger: 'トリガー価格は0より大きい必要があります',
    charts: 'チャート',
    currencyOverview: '通貨の概要',
    orderBook: 'オーダーブック',
    latestTrades: '最新トレード',
    marketBuy: '市場価格で購入',
    marketSell: '市場価格で売却',
    quantityInLots: '数量（枚）',
    inputQuantity: '数量を入力',
    SpotBill: '現物',
    UBill: 'Uベース契約',
    CoinBill: 'コインベース契約',
    SecondBill: '秒契約',
    FinancialBill: '金融',
    stopProfit: '止まります',
    stopLoss: '損切りです',
    orderForm: {
      graphicDescription: '図式で説明します。',
      case1: 'ケース1です:',
      l_text1:
        '指値依頼とは、ユーザーが依頼件数と受け入れ可能な最高買値または最低売値を設定し、市場価格がユーザーの予想通りであれば、指値の範囲内で最適な価格で成約します。',
      l_text2:
        '現在のBTC市場価格は53,000 USDTです。より安い52,900 USDTで購入したい場合、制限価格委託を選択して、購入価格52,900を設定することができます。註文後、価格が52,900 USDT以下に下落した場合、註文は自働的に成約されます。',
      m_text1:
        '時価委託とは、ユーザーが現在の市場で最適な価格で迅速に取引でき、すぐに取引が成立することです。',
      m_text2:
        '現在のBTC市場価格は53,000 USDTで、買いと売りの註文は53,000 USDT前後で成約されます。',
      ts_text1:
        'ストップロス委託は取引戦略で、トリガー価格と委託価格を設定することができます。市場価格がトリガー価格に達すると、システムは自働的に取引をトリガーします。',
      ts_text2:
        'あなたは、以下のパラメーターを記入することができますが、53,000 USDTの平均価格の註文を開いて、期待している51,000 USDTの市場では、フラットを販売するために損切りにする:',
      ts_text3:
        '売却時には、すぐに成約するためにトリガー価格を下回ることをお勧めし、またはデフォルトの時価を記入しません委託します。',
      ts_text4:
        '価格が51,000 USDTに下落した場合、50,000 USDT委託フラットでストップロスをトリガします。委託価格が相場であれば、相場で早く売り切ります。',
      ts_text5:
        'もしあなたはフラットに多くの利益を止めたいならば、53,000 USDTのトリガーより高い価格でフラットに多くの依頼を売ることを設定する必要があります。',
      cfjg: 'トリガー価格です:',
      wtjg: '依頼価格です:',
      wtjg2: '依頼価格です',
      wtjg3: '依頼価格です',
      // 全|逐仓弹窗
      cp_text1: '手付金モデルの調整は、現在の取引対象に対してのみ有効です。',
      cp_text2: '何が全在庫と逐倉保証金モードですか?',
      cp_text3: '証拠金モデルです:',
      cp_text4:
        'ポジションの手付金とトレーダーの口座残高が独立していることを指します。この手付金モデルでは、トレーダーはレバレッジを自由に決めることができます。ポジションが強制的にクローズされた場合、取引者が負担する最大の損失はポジション証拠金です。',
      cp_text5: '全倉保証金モデルです。',
      cp_text6:
        'NTLのデフォルト保証金モデルです。全ポジション証拠金モデルは、ポジションを維持するために、ポジション証拠金として対応する通貨のすべての利用可能な残高を使用して、強引分けを回避します。この手付金モードでは、資産純資産が手付金の需要を維持するのに十分でないとき、強制的なポジション解除がトリガーされます。ポジションが強制的にクローズされると、トレーダーはその通貨にあるすべての資産を失います。',
      warmReminder: '暖かいヒントです。',
      wr_text1:
        '発注を削除するには、発注の成約後に開始することを依頼する必要があります実際に存在していないストップロスストップ。註文の成約を依頼した後、デフォルトはあなたの実際の成約数量に従ってすべてのストップロスを掛けます。もしあなたが依頼を撤回する場合は、同時に無効になる予定のストップロスを設定します。',
      wr_text2:
        '市場の変動が激しい場合、ストップ・ロスが発生した時点で、実際の取引価格とその時点での価格が異なる場合があります。ストップ/損切り註文も成約できない、または完全成約できない可能性があります。',
    },
    orderConfirmation: 'ご註文確認です',
    tradeAfterLogin: '登録します',
    experienceBonus: '体験ボーナス',
    selection: '選択',
    trialWenxin_text1:
      '1.体験ボーナスは永続契約 - 成行注文にのみ適用され、有効期間内に使用する必要があります。',
    trialWenxin_text2:
      '2.取引時には体験ボーナスが優先されます。利益は引き出すことができ、体験ボーナスは自動的に破棄されます。損失は体験ボーナスから優先的に差し引かれ、体験ボーナスを超える場合は残高から差し引かれます。',
    trialWenxin_text3: '3.ポジションをクローズする際には体験ボーナスが優先されます。',
    zyj: '利益確定価格',
    zsj: '損切価格',
    leverage: 'てこ',
    marketIsFlat: '全ポジション決済',
    marketIsFlatTips: '市場価格で全ポジションを決済しますか？',
  },

  second: {
    balance: '残高',
    profitability: '収益率',
    opentime: '建玉時間',
    transactionMode: '取引モード',
    currentPosition: '現在のポジション',
    tiaojie: '市場調整手数料',
    countdown: 'カウントダウン',
    purchaseQuantity: '購入数量',
    quantity: '数量',
    SpotAccount: '現物',
    ULocalCurrencyAccount: 'Uベース契約',
    CurrencylocalCurrency: 'コインベース契約',
    secondContractAccount: '秒契約',
    actualPurchase: '実際の購入',
    fee: '手数料',
  },
  ctc: {
    title: 'ワンクリック売買 ● プラットフォームで保管 ● 安全で安心',
    desc: 'NTLプラットフォームによるデジタル資産の保管で、ユーザーの資産を安全に保護します',
    buyin: '買い入れる',
    sell: '売る',
    buyprice: '買値',
    sellprice: '売値',
    buynum: '買い入れ量',
    sellnum: '売出量',
    payamount: '決済総額',
    getamount: '受け取り総額',
    avabalance: '売却可能数量',
    time: '取引時間',
    tip: 'ヒント',
    notice: 'お知らせ',
    notice1:
      '1. 法定通貨取引エリアは、ユーザーとディーラーの間の資産取引であり、資金はプラットフォームを経由せず、プラットフォームは人民元のチャージ/送金を受け付けません。',
    notice2:
      '2. 法定通貨取引のディーラーはすべて実名認証を行い、取引保証金を提供し、代トークンはプラットフォームによって保管されます。安心して交換できます。',
    notice3:
      '3. ディーラーサービス時間は毎日9:00から21:00までで、受注後30分以内にディーリングを完了し、2時間で取引が完了しない場合は取引が無効になります。',
    notice4:
      '4. 法定通貨取引エリアは銀行カードをサポートしており、本人名義の認証済みアカウントを使用して送金する必要があります。',
    notice5:
      '5. 取引資金の安全を保障するために、法定通貨取引では実名認証を完了する必要があります。実名認証が完了したら、迅速に資金パスワードと受け取りアカウントを設定してください。さもないと、取引はできません。',
    payType: '支払い方法',
    receiveType: '受け取り方法',
    moneyTips: '上記の金額は参考用であり、実際の注文後の決済金額が優先されます',
    verifyset: '本人確認/資金パスワード',
    payset: '支払い/受け取り設定',
    trade: 'トレード',
    input50tips: '50から50000の間で数字を入力してください',
    bank: '銀行カード',
    alipay: 'アリペイ',
    wechatpay: 'ウィーチャット',
    payaccountset: '支払いアカウントの設定',
    useselfaccount: '本人の実名認証に合致する支払い/受け取りアカウントを使用してください',
    orderSn: '注文番号',
    tradetime: '注文時間',
    direction: '取引タイプ',
    amount: '数量（USDT）',
    money: '合計（CNY）',
    price: '価格（CNY）',
    status: 'ステータス',
    operate: '操作',
    moredetail: 'もっと売買の詳細',
  },
  cexchange: {
    use: '使用',
    consume: '交換',
    title: '高速交換',
    desc: 'ワンクリックで交換し、利益を享受',
    quantity: '交換数量',
    inputtips1: '数量を入力してください',
    maxtip: '最大',
    mintip: '最小',
    choosecurrency: '交換する通貨を選択',
    all: '全部',
    totalpay: '総交換金額',
    restricted: '今日の制限',
    currentprice: '両替価格',
    nextprice: '次期の交換価格',
    confirmpay: 'すぐに交換',
    assets: '残高：',
    loginFirst: '最初にログインしてください',
    success: 'おめでとうございます！交換に成功しました！',
    tip: 'ヒント',
    confirmmsg: '確認',
    quickExchange: '変換',
    zeroCommission: 'スリッページゼロ｜ワンクリック取引',
    records: '記録',
    max: '最大です',
    fee: '手数料です',
  },
  option: {
    currentorder: '現在の注文',
    historyorder: '注文履歴',
    title: 'オプション契約',
    buyupreward: '買い上げ総額',
    buydownreward: '買い下げ総額',
    quantity: 'ポジション量',
    myamount: '私のポジション',
    buyup: '上昇',
    buydown: '下落',
    history: '過去の結果',
    seriers: '第',
    period: '契約期間',
    currentoption: '現在の予測期間',
    openprice: 'オープニング価格',
    currentprice: '現在価格',
    openprice1: 'オープニング価格',
    closeprice: 'クローズ価格',
    opentime: 'オープニング時間',
    closetime: 'クローズ時間',
    progress: '予測の進行状況',
    loginFirst: '最初にログインしてください！',
    selectAmount: 'ベット数量を選択してください',
    balancenotenough: '利用可能な残高が不足しています！',
    betsuccess: 'おめでとうございます！ベットに成功しました！',
    noopening: 'オープニング契約はまだありません',
    col_createTime: '作成時間',
    col_direction: '予測の方向',
    col_result: '予測の結果',
    col_optionNo: '契約期間',
    col_betAmount: 'ポジション量',
    col_rewardAmount: '報酬数量',
    col_fee: 'オープニング手数料',
    col_winfee: '市場調整料',
    win: '成功',
    lose: '失敗',
    wait: '進行中',
    purchaseQuantity: '購入数量',
    avaliablebalance: '利用可能な残高',
    login: '最初にログインしてください',
    kline: 'リアルタイムチャート',
    countdown: 'カウントダウン',
    currentPeriod: '現在の期',
    trade: ' 取引',
    currentPrice: '現在の価格です',
  },
  otc: {
    payment_detail: '支払方法の詳細',
    bind_payment: '支払方法のバインディング',
    payment_placeholder: '支払方法を選択してください',
    payment: '支払方法',
    ad: '広告センター',
    buyin: '購入',
    sellout: '販売',
    merchant: '商人',
    applymerchant: '認定商人に申し込む',
    volume: '取引回数',
    paymethod: '支払方法',
    amount: '数量',
    limit: '制限',
    price: '単価',
    price_coin: '価格/コイン',
    operate: '操作',
    validate: 'まず実名認証を行ってください',
    sell: '販売',
    buy: '購入',
    transaction: '買い物取引',
    buttontxt: '商人になる',
    title: '法定通貨取引',
    desc: '便利で安全、迅速なデジタル通貨の購入と販売',
    title1: 'ダイナミックな調整',
    desc1: '市場価格にリアルタイムで調整されます',
    title2: '手数料ゼロ',
    desc2: 'ユーザーが見た価格に基づいて、プラットフォーム手数料は不要です',
    title3: '即時約定',
    desc3:
      'プラットフォームサービスプロバイダーを導入し、スマートマッチングと約定注文が行われ、合致待ちが不要です',
    title4: 'プラットフォーム保証',
    desc4:
      'プラットフォームの認証されたビジネスパートナーで安全に保護され、取引を保護するために24時間カスタマーサービスが提供されます',

    myad: {
      title: '私の広告',
      post: '広告を投稿',
      alert:
        '【注意】：最小取引額に残高と手数料を追加した金額が広告残高を超える場合、広告は自動的に非表示になります',
      no: '広告番号',
      type: '広告のタイプ',
      sell: '販売中',
      buy: '購入中',
      limit: '注文の制限額',
      remain: '残りの数量',
      coin: 'コイン',
      created: '作成日時',
      operate: '操作',
      errmsg: '広告を非表示にした後で編集できます',
      update: '修正',
      shelf: '掲載',
      dropoff: '非表示',
      delete: '削除',
    },
    myorder: '私の注文',
    chatline: {
      status_1: '購入者は支払っていません。購入者の支払いを待っています！',
      status_2: '購入者は支払いました。売り手の解放を待っています！',
      status_3: '注文は完了しました！',
      status_4: '注文は現在申し立て中です！',
      status_5: '注文はキャンセルされました！',
      loadmore: 'さらに読み込む',
      warning:
        '詐欺予防のお知らせ：最近、詐欺師が銀行振込情報と偽の送金証明書を使用して詐欺を行っている事例が多発しています。必ず自分の受け取り口座にログインして情報を確認してください。資金の安全を確保し、銀行口座が凍結されるリスクを回避しましょう！',
      contenttip: 'チャット内容を入力し、Enterキーを押して送信',
      contentmsg: 'メッセージを空にすることはできません',
    },
    chat: {
      seller: '売り手',
      buyer: '買い手',
      exchangeamount: '取引金額',
      operatetip: '操作のヒント',
      operatetip_1: '相手が指定したアカウントに支払いを完了し、このページでクリックして',
      finishpayment: '支払い完了',
      operatetip_1_1: '送金の際、取引番号をメモに記入してください',
      operatetip_1_2:
        '売り手が支払いを受け取ると、ウェブサイトで受領を確認し、システムが購入したデジタルアセットを自動的にアカウントに送信します。受け取りを確認してください',
      note: '注記',
      notetip:
        '他のチャットソフトウェアを使用して相手とコミュニケーションを取らないでください。ファイルや添付ファイルなど、相手からの受け取りを拒否してください。すべてのコミュニケーションは、このページのチャットウィンドウで行ってください',
      operatetip_2_1:
        '販売するデジタルアセットは、プラットフォームで凍結されています。相手の支払いを受け取ったことを確認した後、クリックして',
      operatetip_2_2:
        'トークンの放出を確認する前に、放出を要求する理由を信じないでください。お金を受け取った後にトークンを放出して、損害を避けましょう',
      operatetip_2_3:
        '振込の受領通知を受け取ったら、オンラインバンキングまたはモバイルバンキングにログインして入金が完了したことを確認してください。詐欺メッセージを受け取ってデジタルアセットを誤って放出しないようにしましょう！',
      confirmrelease: '放行を確認',
      paydigital: 'デジタルアセットを支払う',
      orderstatus: '注文状況',
      orderstatus_1: '支払い完了',
      orderstatus_2: '注文申し立て',
      orderstatus_3: '放行確認済み',
      orderstatus_4: '取引キャンセル',
      orderstatus_5: '注文申し立て',
      order: '注文',
      and: 'および',
      transition: 'の取引',
      transprice: '取引価格',
      transnum: '取引数量',
      transmoney: '取引金額',
      tip1: 'ユーザーは一時的に銀行口座番号を追加していません',
      tip2: 'ユーザーは一時的にAlipayアカウントを追加していません',
      tip3: 'ユーザーは一時的にWeChatアカウントを追加していません',
      zfb: 'アリペイ',
      wx: 'ウィーチャット',
      qrcode: 'QRコード',
      msg1: '支払いを完了しましたか？',
      msg2: '支払いは完了しません！注文をキャンセルしますか？',
      msg3: '【重要】：支払いは完了しません！注文をキャンセルしますか？',
      msg4: '支払い済み、コイン未受領',
      msg5: 'コインを支払ったがお金が未受領',
      tip: 'ヒント',
      comptype: '苦情の種類',
      compremark: '苦情の備考',
      willcomp: '苦情を提出する',
      msg6: 'コインを放行することを確認しますか？',
      result_1: '支払い待ち',
      result_2: '放行待ち',
      result_3: '完了',
      result_4: '申し立て中',
      result_5: 'キャンセル済み',
      msg7: '資金パスワード',
      msg7tip: '資金パスワードを入力してください',
    },
    checkuser: {
      emaildone: 'メール認証済み',
      emailundo: 'メール未認証',
      teldone: '電話番号認証済み',
      telundo: '電話番号未認証',
      idcarddone: '身分証明書認証済み',
      idcardundo: '身分証明書未認証',
      language: '言語',
      languagetext: '中国語',
      languagetext1: '英語',
      registtime: '登録時間',
      exchangetimes: '累計取引回数',
      exchangeinfo: 'の取引情報',
      tablabel1: 'オンライン売出',
      tablabel2: 'オンライン購入',
      col_symbol: '通貨',
      col_paymode: '支払い方法',
      col_num: '数量',
      col_price: '価格',
      col_created: '公開時間',
      col_operate: '操作',
      operatemsg: 'まず本名認証を行ってください',
      buyin: '買入',
      sellout: '売出',
    },
    tradecenter: {
      postad: '広告を投稿',
      exchange: 'GCC取引',
      buyin: '購入希望',
      sellout: '売却希望',
    },
    tradeinfo: {
      emaildone: 'メール認証済み',
      emailundo: 'メール未認証',
      teldone: '電話番号認証済み',
      telundo: '電話番号未認証',
      idcarddone: '身分証明書認証済み',
      idcardundo: '身分証明書未認証',
      exchangetimes: '取引回数',
      price: '価格',
      num: '数量',
      paymethod: '支払い方法',
      exchangelimitamount: '取引制限金額',
      location: '場所',
      location_text: '中国',
      exchangeperiod: '取引期限',
      minute: '分',
      amounttip: '金額を入力してください',
      numtip: '数量を入力してください',
      remarktip: 'あなたの要望を相手に伝えてください',
      remarktitle: '備考',
      exchangetitle: '取引に関する情報',
      exchange_tip1:
        '取引リクエストを送信すると、デジタル通貨はエスクローにロックされ、プラットフォームに保護されます。 もし売り手の場合、取引リクエストを送信した後、あなたは入金し、買い手が支払うのを待つことができます。 買い手は支払い期限内に支払います。 あなたは支払いを受け取った後、エスクロー内のデジタル通貨をリリースする必要があります。',
      exchange_tip2:
        '取引を行う前に、「プラットフォームのウェブサービス規約」やよくある質問、取引ガイドなどのヘルプドキュメントを確認してください。',
      exchange_tip3:
        '詐欺に注意してください！ 取引を開始する前に、ユーザーの受けた評価を確認し、新たに作成されたアカウントに特に注意を払ってください。',
      exchange_tip4:
        '四捨五入および価格の変動に注意してください。 これらは最終的なデジタル通貨の量に影響を与える可能性があります。 入力した固定金額が最終的な金額を決定し、デジタル通貨の金額はリクエストが発行された時点での為替レートで計算されます。',
      exchange_tip5:
        'エスクローサービスはオンライン取引の売り手と買い手の両方を保護します。 紛争が発生した場合、提供されたすべての情報を評価し、エスクロー内のデジタル通貨を合法的な所有者にリリースします。',
      warning1: '小数点以下2桁まで入力できます',
      warning2: '注文金額は',
      warning3: '小数点以下8桁まで入力できます',
      warning4: '注文数量は',
      confirmbuyin: '購入を確認',
      confirmsellout: '売却を確認',
      buyin: '購入',
      sellout: '売却',
      warning5: '指示に従って注文を記入してください',
    },
    publishad: {
      createad: '広告取引を作成する',
      msg1: '定期的に取引を行う場合、独自の取引広告を作成できます。たまに取引を行う場合、直接検索することをお勧めします。',
      msg2: '取引広告を作成することは',
      msg3: '無料です',
      msg4: '既存の広告を編集するには、',
      tradead: '取引広告',
      myad: 'マイ広告',
      iwant: '私は欲しい',
      sellonline: 'オンラインで販売',
      buyonline: 'オンラインで購入',
      exchangecoin: '取引通貨',
      country: '国',
      currency: '通貨',
      openfixedprice: '固定価格を有効にする',
      open: '有効',
      close: '無効',
      usetip: '有効にすると、取引価格は市場の変動に従わずに一定に保たれます。',
      premiseprice: 'プレミアム',
      premisepricetip: 'プレミアムを設定してください',
      fixedprice: '固定価格',
      fixedpricetip: '取引価格を入力してください',
      marketprice: '市場価格の参考価格',
      marketpricetip: 'プレミアムは、現在の市場価格から何パーセント高いかを指します。',
      exchangeprice: '取引価格',
      formual: '計算式',
      num: '数量',
      num_text1: '必要な数量を入力してください',
      num_text2: 'の数量',
      exchangeperiod: '取引期間',
      exchangeperiod_text1: '取引期間を入力してください',
      minute: '分',
      tip1: '購入者が取引を完了するまでにどれくらいの時間を許容しますか。整数で入力してください。',
      tip2: '【ヒント】個人センターに移動し、支払い方法をバインド/追加できます',
      tip3: '最小取引額を入力してください',
      tip4: '最大取引額を入力してください',
      tip5: '特別な要求、買い手への要件、オンライン時間などを備考欄に記入できます。',
      paymode: '支払い方法',
      minlimit: '最小取引額',
      maxlimit: '最大取引額',
      remark: '備考',
      openautoreply: '自動返信を有効にする',
      msg5: '有効にすると、ユーザーがこの広告を使用して取引を開始した場合、システムは選択した自動返信メッセージを相手に自動的に送信します。',
      autoreply: '自動返信',
      autoreplytip:
        '注文を受けた後、買い手に支払い方法や支払い口座などの情報を自動的に返信します。',
      fundpwd: '資金パスワード',
      fundpwdtip: '資金パスワードを入力してください',
      submit: '提出',
      warning1: '正確な数値を入力してください',
      warning2: 'プレミアム値は0から20までの範囲で設定してください',
      warning3: '最大取引額を入力してください！',
      warning4: '整数を入力してください',
      warning5: '最大取引額は最小取引額よりも大きくする必要があります！',
      warning6: '最大取引額は売却総額を超えてはいけません',
      warning7: '最小取引額を入力してください！',
      warning8: '最小取引額は100以上でなければなりません！',
      warning9: '最小取引額は最大取引額よりも小さくする必要があります',
      sellout: '売却',
      buyin: '購入',
      inputtip1: '通貨を入力してください',
      inputtip2: '正しいオプションを入力してください',
      inputtip3: 'プレミアムは0から20までの範囲で、0以外である必要があります',
      inputtip4: '正しい固定価格を入力してください',
      inputtip5: '正しい数値を入力し、最大取引数量が100通貨を超えてはいけません',
      inputtip6: '正しい取引期間を入力してください',
      inputtip7: '取引方法を選択してください',
      inputtip8: '資金パスワードを入力してください',
      zfb: 'アリペイ',
      wx: 'ウィーチャット',
      unionpay: '銀行カード',
      submit_failure: '送信に失敗しました！',
      submit_success: '送信成功',
      submittip1: '資金の安全のために、まず【本名認証】を完了してください。',
      submittip2: '資金の安全のために、まず【携帯電話のバインディング】を行ってください。',
      submittip3: '資金の安全のために、まず【ファンドパスワードの設定】を行ってください。',
      submittip4: '少なくとも1つの支払い方法をバインドしてください',
    },
    index: {
      title: '優れた広告のおすすめ',
      exchangetimes: '取引回数',
      exchangeprice: '取引価格',
      exchangelimit: '取引制限',
      paymode: '支払方法',
      buy: '購入',
      sell: '売却',
      viewmore: 'さらに表示',
      bot1: '安全性と信頼性',
      bot1_tip:
        '10年以上の金融リスク管理の経験を持つ\n専門チームによるカスタマイズ可能なセキュリティ戦略',
      bot2: '迅速で便利',
      bot2_tip:
        'ピアツーピアの自由なユーザー間取引モデルは、\nさまざまな資金調達方法をサポートします',
      bot3: '幅広い通貨',
      bot3_tip: '主要なデジタル資産取引通貨を厳選し、多くの\n資産取引体験に対応',
      ibuy: '購入希望',
      isell: '売却希望',
    },
  },
  activity: {
    all: 'すべて',
    prepare: '準備中',
    ongoing: '進行中',
    distributing: '配布中',
    completed: '完了',
    progress: '進捗',
    totalsupply: '活動総量',
    starttime: '開始時間',
    endtime: '終了時間',
    viewdetail: '詳細を表示',
    attend: '今すぐ参加',
    tokendistribute: 'トークン配布中',
    baseinfo: '基本情報',
    status: 'ステータス',
    activitytype: '認購タイプ',
    activitytype0: '不明',
    activitytype1: '初上場（ラッシュ購入）',
    activitytype2: '初上場（均等分割）',
    activitytype3: '保有者分配',
    activitytype4: '自由認購',
    activitytype5: 'クラウドマイニング認購',
    activitytype6: 'ロックリリース',
    ruledetail: 'タイプの説明',
    publishprice: '認購価格',
    activitycoin: 'アクティビティ通貨',
    acceptcoin: '受け入れ通貨',
    holdcoin: '保有要件通貨',
    limittimes: '個人制限購入回数',
    limitamount: '個人制限購入数量',
    unlimited: '無制限',
    alreadyamount: '購入済み数量',
    alreadyholdamount: '活動への総保有参加',
    myalreadyholdamount: '私の保有参加',
    leftamount: '残り数量',
    attention: 'ご注意',
    attentiontxt1:
      '1、【保有者分配】、【自由認購】、【クラウドマイニング認購】、【ロック】タイプの活動に参加すると、資産が凍結され、活動が終了すると解凍またはロックが解除されます',
    attentiontxt2:
      '2、【保有者分配】、【自由認購】、【クラウドマイニング認購】、【ロック】タイプの活動に参加した後、ユーザーは参加申請を取り消すことはできません',
    attentiontxt3:
      '3、プロジェクトの理由や他の不可抗力の要因で活動がキャンセルされた場合、凍結された資産は元の方法で返金されます',
    attentiontxt4: '* この活動の最終解釈権はNTL(ntlex.com)公式に帰属します',
    inputamount: '交換数量を入力',
    inputholdamount: 'ロック数量を入力',
    mybalance: '私の利用可能残高',
    holdtips:
      '【保有者分配】タイプの活動に参加するには、活動が終了するまでロックする必要があります',
    pleaseinputamount: '交換数量を入力してください',
    pleaseinputholdamount: 'ロック数量を入力してください',
    commitfailed: '参加申請が失敗しました！',
    minlimitamountfailed: '交換数量は最小交換数量未満にすることはできません！',
    maxlimitamountfailed: '交換数量は最大制限数量を超えることはできません！',
    limittimesfailed: 'あなたの参加回数が個人の最大制限回数を超えています！',
    balancenotenough: '残高が不足しています！',
    headertitledesc:
      '投資家、プロジェクトチーム、取引所などの多くの当事者が共同でプロジェクトを検証するオープンラボ',
    currentdivided: '現在の私の保有分は約',
    leveloneCount: '1次レベルの友達の数が必要です',
    submit: '提出',
    tipsmobile: '参加するにはPC版のページにアクセスしてください（ntlex.com）',
    tipsmobile1: '参加するにはPC版のページにアクセスしてください（ntlex.com）',
    intvalue: '整数を入力してください',
    inputminingamount: 'マイニングマシンの購入数量を入力',
    moreactivity: 'その他のアクティビティ',
    releaseType: 'リリースタイプ',
    releaseType0: '等額リリース',
    releaseType1: '比率リリース',
    lock: '期間ロック',
    releaseDetail: 'リリースの詳細',
    lockday: '日',
    lockweek: '週',
    lockmonth: '月',
    lockyear: '年',
    releasePercent: 'リリース割合',
    lockFee: 'ロック閾値',
    releaseTimes: 'リリース倍数',
    times: '倍',
  },

  invite: {
    inviteLoginName: 'パートナーにログイン',
    chengwei: '最高の終身リワードの機会を受け取る',
    pthhr: 'プラットフォームのパートナーになる',
    partnerText: '友達を招待し、プラットフォームの利益を享受する',
    myinvitelink: '私の招待リンク',
    copy: 'コピー',
    copyed: 'コピー済み',
    mylevelone: '私の一次友達',
    myleveltwo: '私の二次友達',
    mycommission: '手数料収入（USDT換算）',
    extrareward: '追加の報酬',
    partnerlevel: 'パートナーレベル',
    ruledetail: 'ルールの詳細',
    ruleprofile1: 'NTL(ntlex.com)の【パートナーシッププログラム】は、インターネット全体で',
    ruleprofile2: '最高のリワード率、',
    ruleprofile3: '最長のリワード期間（最高の終身リワード）',
    ruleprofile4:
      'を提供するプロモーション活動で、努力に見合ったプロモーターが真の意味でプラットフォームの「パートナー」として成長を共にする特典を提供します。詳細は以下の通りです：',
    ruletext1:
      '一、暗号資産の理念を普及させ、ユーザーベースを拡大するために、NTL(ntlex.com)は【パートナーシッププログラム】を導入し、このプログラムは長期間にわたり有効です。',
    ruletext2:
      '二、友達を招待すると、1次および2次の友達に分かれます。たとえば、AがBを招待する場合、BはAの1次の友達になり、BがCを招待した場合、CはBの1次の友達であり、同時にAの2次の友達です。',
    ruletext3:
      '三、友達を招待する場合、招待者が提供したリンクまたは招待者の招待コードを入力して登録する必要があります。登録したときに招待者の招待コードを入力しないと、1次の友達として招待者になれません。',
    ruletext4:
      '四、招待された人が招待者の招待コードで登録を完了すると、リワードがアクティブになります。',
    ruletext5:
      '五、リワード期間は招待された人が登録を完了してからのNか月であり、レベルに応じて異なるリワード率が適用されます。',
    ruletext6:
      '六、プロモーションリワードのアセットは、下位の友達の取引手数料で、リワードの通貨はUSDT、BTC、ETHなどの主要な基本通貨です。',
    ruletext7:
      '七、プロモーションリワード率およびリワード期間は、1次友達の数と正の相関関係にあり、友達を多く招待すればリワード率が高くなります。具体的なプロモーションリワード率は以下の通りです：',
    ruletext8:
      '八、プロモーションリワードのトップ10またはトップ100は、不定期にNTL(ntlex.com)から提供される追加の報酬を受け取ります。',
    ruletext9:
      '九、他のアクティビティと【パートナーシッププログラム】アクティビティが競合する場合、協議の後に一時的な変更を決定する場合があります。ご理解のほどお願い申し上げます。',
    ruletext10:
      '十、L4、L5、L6のパートナーは、年間リワード合計の5%、10%、15%の分配ボーナスを受けます。',
    ruletext11: '十一、このアクティビティの最終的な解釈権はすべてNTL(ntlex.com)に帰属します。',
    ruleexampletitle: '例：',
    ruleexamplecontent1:
      'ユーザーAが100人の1次友達を招待し、それぞれの1次友達が5人の2次友達を招待した場合、Aは100人の1次友達と500人の2次友達を持っています。平均して1人あたり1日3,000元の取引が行われていると仮定すると、Aの月間収入は約：(100*3000*0.001*30%  500*3000*0.001*10%) * 30 = 月間7,200ドルになります。',
    ruleexamplecontent2:
      'ユーザーAが1,000人の1次友達を招待し、それぞれの1次友達が5人の2次友達を招待した場合、Aは1,000人の1次友達と5,000人の2次友達を持っています。平均して1人あたり1日3,000元の取引が行われていると仮定すると、Aの月間収入は約：(1,000*3000*0.001*50%  5,000*3000*0.001*20%) * 30 = 月間135,000ドルになります。',
    lastupdate: '最終更新日',
    laststastic: '統計締め切り日',
    level: 'レベル',
    lv1: '',
    lv2: '',
    lv3: '',
    lv4: '',
    lv5: '',
    lv6: '',
    levelonecount: '1次友達数',
    levelonecommission: '1次友達リワード率 / リワード期間',
    leveltwocommission: '2次友達リワード率 / リワード期間',
    partnerdivide: 'パートナーのボーナス',
    day: '日',
    alllife: '終身リワード',
    totalcommissionrank: 'プロモーションリワード合計',
    rank: '順位',
    userid: 'ユーザーID',
    rankday: '(デイリーランキング)',
    rankweek: '(ウィークリーランキング)',
    rankmonth: '(マンスリーランキング)',
    rankall: '(総合ランキング)',
    promotioncount: 'プロモーション人数',
    commissionusdt: 'リワード（USDT換算）',
    ranktip1: '招待人数は1次友達数で、これはリワード合計金額の順位です。',
    ranktip2: '招待人数は1次友達数で、これは1次招待人数の順位です。',
    thanks: 'NTL(ntlex.com)と一緒に成長していただき、ありがとうございます！',
    headertip:
      '上記のリワードデータはリアルタイムでは更新されず、システムは24時間ごとに統計および更新します。',
    ptools: 'プロモーションツールボックス',
    pt_title: 'デジタル通貨ギフトカード',
    pt_desc:
      '兌換後自動的に下位友達になり、友達、家族、顧客、パートナーにプレゼントできます。パートナーシッププログラムのさらなるプロモーションをサポートします。',
    pt_more: 'もっとツールをお楽しみに',
    pt_tips:
      '創造的なアイデアがある場合は、promotion@NTL にメールを送信してください。受け入れられると報酬がもらえます！',
    pt_card_amount: 'カード金額',
    pt_card_deadline: '有効期限',
    pt_card_noend: '無期限',
    pt_card_btn: 'パートナーシッププログラムから30枚無料で入手',
    pt_card_btn1: 'パートナーシッププログラムから無料で',
    pt_card_btn2: '枚入手',
    pt_card_btn_login: 'ログイン後、30枚無料で入手',
    pt_card_rule: 'ルールの詳細',
    pt_card_summary:
      'デジタル通貨ギフトカードは、パートナーシッププログラムのプロモーターが下位友達をより効果的に招待できるように開発されたプロモーションツールの一つです。対象のユーザーがギフトカードを兌換すると、自動的にプロモーターの下位友達になります。',
    pt_card_rule1:
      '一、無料ギフトカードはユーザーごとに最大30枚までです。さらに多くのギフトカードを発行するには、ユーザーがカスタマイズ料金を支払う必要があり、カードの面額、ロゴ、説明などをカスタマイズできます。カスタマイズのご要望は、',
    pt_card_rule2:
      '二、無料ギフトカードは、ユーザーごとに1つだけ兌換できます。NTL(ntlex.com)が発行した無料ギフトカードは、ユーザーごとに1つだけ兌換でき、異なるユーザーから異なる兌換コードを取得しても、1つだけ兌換できます。自作のギフトカードはこの制限の対象外です。',
    pt_card_rule3:
      '三、ギフトカードを兌換する前に、友達としてリンクされていない場合（つまり、登録アカウント時に招待コードを入力しなかった場合）、自動的にカードを発行したユーザーの1次友達に関連付けられます。Aが招待コードを入力しないで登録した場合、Aは誰の1次または2次友達でもない。ただし、AがBが発行したギフトカードを兌換すると、AはBの1次友達に自動的になります。',
    pt_card_rule4:
      '四、ブラシングを防ぐために、ギフトカードの帳簿金額はリアルタイムで兌換されず、兌換条件（帳価が300USDTに達すること）が満たされるまで操作できません。',
    pt_card_rule5:
      '五、無料ギフトカードを取得する際に、アクティブ化条件を満たす必要があります。兌換時にアクティブ化条件を満たす必要はありません。これは、プロモーターがより迅速に下位友達を取得するのを助けるための措置です。',
    pt_card_rule6: '六、無料ギフトカードの最終的な解釈権はNTL(ntlex.com)に帰属します。',
    pt_card_day: '日',
    pt_card_title_tips: '2,000ドルのプロモーションサポートボーナス',
    pt_card_title_tips1: 'プロモーションサポートボーナスを受け取る',
    pt_card_receivew_success:
      'おめでとうございます！パートナーシッププログラムのプロモーションギフトカードを成功裁てました！【個人センター】->【クーポンセンター】でご確認いただけます。',
    usepromotion: 'プロモーション素材を使用',
    saveimage: '画像を保存',
    imagetips:
      '画像を正常にダウンロードできない場合は、スクリーンショットツールを使用して画像を保存してください！',
    pt_invite_title: '招待登録プロモーション画像',
    pt_invite_desc:
      '専用QRコードを使用して登録し、自動的に下位友達になります。各種グループやフォーラムでの転送に適しています。',
    colum_text0: 'ランク',
    colum_text1: 'メンバー',
    colum_text2: 'プロモーション人数',
    colum_text3: 'リワード（USDT換算）',
    colum_text4: '追加の報酬',
    context_title: 'パートナーシッププログラム専用の兌換コード',
    context_title1: 'もっと詳しく知るにはスキャン',
    context_title2: '私の招待コード',
    context_title3: '招待画像',
  },
  uc: {
    verify: '本人確認',
    retrieveprivate: {
      findText: 'アカウントの秘密鍵を取得',
      findText2: 'アカウントの秘密鍵を再作成',
      inputFindtext: '関連するメールアカウントを入力してください',
      continue: '続行',
    },
    login: {
      welcomelogintxt: 'NTLへようこそ',
      welcomelogintxt2: 'アカウントをまだお持ちでない場合、',
      welcomelogintxt3: 'こちらをクリックして',
      welcomelogintxt4: 'アカウントを登録',
      welcomelogintxt5: 'アカウントにログイン',
      welcomelogintxt6: '既にアカウントをお持ちの場合、',
      welcomelogintxt7: '新しい取引の旅を始める',
      phoneEmail: 'メールアカウントでログイン',
      anonymousLogin: '匿名ログイン',
      phoneLogin: 'Phone',
      inputPrivate: 'アカウントの秘密鍵を入力してください...',
      forgetPrivateTxt: 'アカウントの秘密鍵を忘れましたか？',
      noaccount: 'アカウントがありませんか？',
      register: '登録',
      login: 'ログイン',
      welcomelogin: 'ログインへようこそ',
      usertip: 'メールアドレスを入力してください',
      pwdtip: 'パスワードを入力してください',
      validatecodeload: '確認コードの読み込み中',
      validatemsg: '確認完了',
      forget: 'パスワードを忘れましたか？',
      loginvalidate: 'アカウントを入力してください',
      pwdvalidate1: 'パスワードを入力してください',
      pwdvalidate2: 'パスワードの長さは6文字未満にすることはできません',
      success: 'ログイン成功',
      goregister: '登録へ進む',
      getlostpwd: 'パスワードを忘れました',
      noid: 'アカウントがまだありません',
      keyErr: '入力された秘密鍵が間違っています',
      verify: '検証をお願いします',
      loginFailure: '登録無効になります',
      loginFailureDoc: 'ログイン状態が無効になりましたので、再度ログインしてください。',
    },
    forget: {
      title: 'パスワードのリセット',
      hasaccount: 'アカウントをお持ちですか？ ログインをクリックしてください',
      login: 'ログイン',
      sendcode: '確認コードを送信',
      newpwd: '新しいパスワードを入力してください',
      confirmpwd: 'もう一度パスワードを確認してください',
      save: '提出',
      pwdvalidate1: '確認用パスワードを入力してください',
      pwdvalidate2: 'パスワードが一致しません！',
      resettelpwd: '電話のパスワードをリセット',
      resetemailpwd: 'メールのパスワードをリセット',
      newpwdtip: '新しいパスワードを入力してください',
      pwdvalidate3: 'パスワードの長さは6文字未満にすることはできません',
      telno: '電話番号',
      smscode: '確認コードを入力してください',
      teltip: '電話番号を入力してください',
      smscodetip: 'SMS確認コードを入力してください',
      email: 'メール',
      password: 'パスワード',
      NoId: 'アカウントがまだありません',
      emailcode: 'メール確認コード',
      captchaPrompt: '確認コードを受け取らない場合は、メールボックス (スパム) で確認してください。',
      emailtip: 'メールアドレスを入力してください',
      emailcodetip: 'メール確認コードを入力してください',
      resetpwdsuccess: 'パスワードのリセットに成功しました',
      smswarn: 'SMSの受信に注意してください',
      bangding: 'QRコードをスキャンしてバインド',
      googleAuth: 'Google認証器',
      pleaseGoogleAuth: 'Google認証器をバインドしてください',
      bindGoogleAuth: 'Google認証器をバインド済み',
      jcbind: 'バインドを解除',
      GoogleCode: 'Google認証コード',
    },
    finance: {
      center: '財務センター',
      personalassets: '通貨アカウント',
      swapassets: '永続契約アカウント（USDTベース）',
      swapassets2: '永続契約アカウント（コインベース）',
      billdetail: '資産履歴',
      tradetail: '取引・マイニング',
      paydividends: '保有コインの分配',
      invitingmining: '招待マイニングボーナス',
      charge: '入金',
      pickup: '出金',
      money: {
        cointype: '通貨名',
        balance: '利用可能資産',
        frozen: '凍結資産',
        operate: '操作',
        charge: '入金',
        pickup: '出金',
        onkeytrans: 'ワンクリック転送',
        getaddress: 'アドレス取得',
        resetsuccess: '取得成功',
        match: 'マッチング',
        matchtip1: 'マッチングできるGCXの数量',
        matchtip2: 'マッチング数量を入力してください',
        matcherr1: '有効な数量を入力してください！',
        matcherr2: '最大マッチング数量を超えました！',
        matchsuccess: 'マッチング成功！',
        needreleased: '未解放資産',
        totalassets: '総資産（換算）：',
      },
      swap: {
        day1: '日次金利',
        day2: '年次金利',
        totalassets: 'アカウントエクイティ（換算）：',
        swap: ' 永続契約アカウント',
        swaptype: '契約アカウント',
        swapassets: 'アカウントエクイティ',
        avabalance: '利用可能証拠金',
        usebalance: '使用済証拠金',
        experienceBonus: '体験ボーナス',
        frozenbalance: '凍結証拠金',
        profitandloss: '未実現損益',
        transfor: '資金転送',
        inputtransferamount: '転送数量を入力',
        all: 'すべて転送',
        currencyaccount: '通貨アカウント',
        swapaccount: '永続契約アカウント',
        clickchange: '転送方向を切り替えるにはクリック',
        avaamount: '転送可能な金額',
        pleaseselectwallet: '最初に転送元/転送先アカウントを選択してください',
        pleaseinputamount: '転送数量を入力してください',
        oktransfer: '転送を確認',
        from: 'ですから',
        to: 'までです',
        available: '使えます',
        successfulTransfer: '回転成功です',
      },
      second: {
        secondaccount: '秒契約アカウント',
        swap: ' 秒契約アカウント',
      },
      finance: {
        transfer: '投資転入',
        account: '投資アカウント',
        balance: '残高',
        earn: '累計利子',
        stlTotalEarn: '未実現利子',
        rate: '利率',
        confirm: '転入を確認',
        transferAmount: '転入数量',
        all: 'すべて転入',
        coin: '通貨',
        open: 'ポジション記録',
        close: '清算記録',
        fail: '失敗',
        success: '操作成功です',
        updateTime: '更新時間',
        financialManagement: '投資管理',
        financialOrder: '投資注文',
      },
      record: {
        start_end: '開始-終了',
        to: 'から',
        code: 'コード',
        remark: '備考',
        operatetype: '操作タイプ',
        search: '検索',
        charge: '入金',
        pickup: '出金',
        pickupfee: '出金手数料',
        pickupCode: '出金コードで出金',
        pickupCodePay: '出金コードで入金',
        transaccount: '資金転送',
        exchange: '通貨取引',
        exchangefee: '通貨手数料',
        otcbuy: '法定通貨購入',
        otcsell: '法定通貨売却',
        activityaward: 'アクティビティ報酬',
        promotionaward: 'プロモーション報酬',
        promotionawardRebates: 'プロモーションキャッシュバック報酬',
        istantRedemptionRevenue: 'インスタントレッド入金',
        flashPayouts: 'インスタントレッド出金',
        instantRedemptionFees: 'インスタントレッド手数料',
        dividend: '分配',
        rebateincome: 'キャッシュバック収益',
        vote: '投票',
        handrecharge: '手動入金',
        match: 'マッチング',
        activitybuy: 'アクティビティ交換',
        ctcbuy: 'C2C購入',
        ctcsell: 'C2C売却',
        redout: 'レッドパケット発行',
        redin: 'レッドパケット受取',
        chargetime: '取引時間',
        type: 'タイプ',
        fee: '手数料',
        shouldfee: '支払手数料',
        discountfee: '手数料控除',
        realfee: '実支払手数料',
        symbol: '通貨',
        num: '数量',
        status: 'ステータス',
        finish: '完了',
        quickExchange: '変換',
        currentRate: '現在の交換レート',
        from: 'から',
        inputexchangeamount: 'インスタントレッド数量を入力',
        inputexchangepasswd: '取引パスワードを入力',
        chooseTransCoinUnit: '変換通貨を選択',
        inputTransAmount: '転送数量を入力',
        predictAmount: '予測可能な交換数量',
        qamount: 'インスタントレッド数量',
        qrate: '交換比率',
        qexamount: '交換数量',
        withdrawcodein: '出金コードで入金',
        withdrawcodeout: '出金コードで出金',
        contractfee: '永続契約手数料',
        contractprofit: '永続契約利益',
        expenditureofcapitalexpenses: '資金費用の支出',
        accesstofundingfees: '資金費用の獲得',
        optionscontractfails: 'オプション契約失敗',
        optionscontractfee: 'オプション契約手数料',
        optionscontractbonuses: 'オプション契約ボーナス',
        optionscontractcom: 'オプション契約市場調整手数料',
        contractloss: '永続契約損失',
        activityrevenue: 'アクティビティ収入',
        activityexpenditure: 'アクティビティ支出',
        redenvelopecollection: 'レッドパケット受取',
        optionfail: '予測契約失敗',
        optionfee: '予測契約手数料',
        optionreward: '予測契約ボーナス',
        secondfee: '秒契約手数料',
        secondcom: '秒契約市場調整手数料',
        secondfail: '秒契約失敗',
        secondreward: '秒契約ボーナス',
        financereward: '投資利息',
        other: 'その他',
        adjust: '調整する',
        tradingRewards: '交易獎勵',
        financialDefaultHandlingFee: '資産運用契約違反手数料',
        coinTradeFeeDiscount: '通貨間取引手数料控除',
        contracttradeFeeDiscount: '先物契約手数料控除',
        redEnvelopeReturn: 'レッドパケット返却',
        experienceUse: '契約体験ボーナスの使用',
        deduction: '契約体験ボーナスの控除',
        transfer: '振り替える',
        kolCommission: 'KOLのコミッション',
      },
      paydividende: {
        money_holding: '保有コインの分配の累積返還(ETH) : ',
        money_hold: '保有コインの分配待ちの累積(ETH) : ',
        paydividends: '保有コインの分配(ETH)',
        account_date: '到着日',
        datehodld: '保有日',
        start_end: '開始-終了',
        to: 'から',
        operatetype: '操作タイプ',
        search: '検索',
        charge: '入金',
        pickup: '出金',
        transaccount: '転送',
        exchange: '通貨取引',
        otcbuy: '法定通貨購入',
        otcsell: '法定通貨売却',
        activityaward: 'アクティビティ報酬',
        promotionaward: 'プロモーション報酬',
        dividend: '分配',
        vote: '投票',
        handrecharge: '手動入金',
        match: 'マッチング',
        chargetime: '取引時間',
        type: 'タイプ',
        fee: '手数料',
        symbol: '通貨',
        num: '数量',
        status: 'ステータス',
        finish: '完了',
      },
      trade: {
        accumulative_return: '累計マイニング(BHB) : ',
        accumulat_return: '未マイニング(BHB) : ',
        start_end: '開始-終了',
        account_date: '到着日',
        to: 'から',
        operatetype: '操作タイプ',
        search: '検索',
        charge: '入金',
        pickup: '出金',
        transaccount: '転送',
        exchange: '通貨取引',
        otcbuy: '法定通貨購入',
        otcsell: '法定通貨売却',
        activityaward: 'アクティビティ報酬',
        promotionaward: 'プロモーション報酬',
        dividend: '分配',
        vote: '投票',
        handrecharge: '手動入金',
        match: 'マッチング',
        chargetime: '注文生成時間',
        type: 'タイプ',
        fee: 'マイニング手数料返還(BHB)',
        symbol: '取引ペア',
        num: '数量',
        status: 'ステータス',
        finish: '完了',
        transactionTime: '取引時間',
        direction: '取引方向',
        price: '価格',
        entrustment: '委託数量',
        havedeal: '取引済み',
        poundageAmount: '手数料',
        exchangeOrderId: '注文ID',
        mineAmount: 'マイニング数量 (BHB)',
        buy: '購入',
        sell: '売却',
        limit: '指値',
        market: '成行',
        clear: '空にします',
        turnover: '取引金額',
      },
      inviting: {
        accumulative_return: '招待マイニング報酬の累積返還(BHB) : ',
        accumulat_return: '招待マイニング報酬待ちの累積(BHB) : ',
        start_end: '開始-終了',
        account_date: '到着日',
        to: 'から',
        operatetype: '操作タイプ',
        search: '検索',
        charge: '入金',
        pickup: '出金',
        transaccount: '転送',
        exchange: '通貨取引',
        otcbuy: '法定通貨購入',
        otcsell: '法定通貨売却',
        activityaward: 'アクティビティ報酬',
        promotionaward: 'プロモーション報酬',
        dividend: '分配',
        vote: '投票',
        handrecharge: '手動入金',
        match: 'マッチング',
        chargetime: '注文生成時間',
        type: 'タイプ',
        fee: 'マイニング手数料返還(BHB)',
        symbol: '通貨',
        num: '数量',
        status: 'ステータス',
        finish: '完了',
        refereename: '紹介者名',
        referetel: '紹介者電話番号',
        invitingawards: '招待マイニング報酬(BHB)',
        refere: '紹介者',
        refereinput: '名前/電話番号を入力してください',
      },
      recharge: {
        recharge: '入金',
        symbol: '通貨',
        address: '入金アドレス',
        copy: 'コピー',
        qrcode: 'QRコード',
        qrcodeaddress: '入金アドレスのQRコード',
        msg1: '上記のアドレスには通貨以外の資産を入金しないでください。それ以外の資産は復旧できません。',
        msg2: '上記のアドレスに入金すると、通常、ブロックチェーンメインネットワークの3回の確認が必要です。',
        msg3: '最小入金額：',
        msg3_1: '未満の入金は入金されません。',
        msg4: '入金アドレスは頻繁に変更されませんが、変更がある場合、ウェブサイトの公告またはメールで通知されます。',
        msg5: 'コンピュータおよびブラウザのセキュリティを確認し、情報が改ざんまたは漏洩しないようにしてください。',
        record: '入金記録',
        copysuccess: 'コピーに成功しました！',
        copyerr: 'コピーに失敗しました！手動でコピーしてください',
        time: '到着時間',
        amount: '入金額',
        gettingaddress: '入金アドレスの取得中、お待ちください...',
        getaddress: '入金アドレスの取得',
        memotips: '入金時にはMemoを入力してください。そうしないと資産が失われる可能性があります。',
        withdrawrecharge: '提供コード入金',
        coderechargetip: '提供コードを入力してください',
        rechargesuccess: 'おめでとう！提供コード入金が成功しました！',
        rechargeconfirm: '入金確認',
        status: 'ステータス',
        protocol: 'ネットワークプロトコル',
        status_0: '失敗',
        status_1: '未到着',
        status_2: '到着済み',
        protocolerr: 'プロトコルを選択してください',
        network: 'ネットワーク',
        notaddress:
          '入金アドレスをまだ申請していない場合は、ボタンをクリックして入金アドレスを取得してください',
        rechargeDisable: '入金機能は一時的に利用できません',
        nickname: 'ニックネーム入力お願いします。',
        phoneOrEmail: '正しい携帯番号またはメールアドレスをお願いします。',
      },
      withdraw: {
        pickup: '出金',
        network: '送金ネットワーク',
        withdrawDisable: '出金停止',
        addressmanager: '出金アドレス管理',
        withdrawbycode: 'コードで出金',
        withdrawcode: 'コード',
        symbol: '通貨',
        address: '出金アドレス',
        num: '出金数量',
        avabalance: '利用可能残高',
        msg1: '出金数量が',
        msg2: '以下の場合、自動的に処理されます。それ以上の場合、手動審査が必要です。',
        increase: '限度額増加',
        tip1: '最大',
        tip11: '桁の小数点以下を入力してください。最小値は',
        tip2: '最大値は',
        numtip1: '出金数量を入力してください',
        fee: '予測手数料',
        feeIns: '手数料は市場の変動に基づいて変動します。',
        range: '範囲',
        arriamount: '着金数量',
        msg3: '最小出金数量は',
        msg4: '通貨です',
        msg5: '資金の安全性を確保するため、アカウントのセキュリティ設定変更、パスワード変更、新しいアドレスを使用して出金する場合、出金は手動で審査されます。スタッフからの電話またはメールでのご連絡をお待ちください。',
        msg6: 'コンピュータとブラウザのセキュリティを確保し、情報の改ざんや漏洩を防止してください。',
        record: '出金記録',
        symboltip: '通貨を選択してください',
        addresstip: 'アドレスを入力してください',
        numtip2: '正しい出金数量を入力してください。最小値は',
        numtip3: '出金数量は手数料未満にすることはできません',
        amounttip: '出金数量を入力してください',
        newAmounttip: '正確なコインの数をお願いします。',
        numtip2_max: 'コインの正確な数量を記入してください,最大値です。',
        feetip1: '手数料の最小値は',
        feetip2: '最大値は',
        time: '出金時間',
        status: 'ステータス',
        turnDown: '却下します',
        inProcess: '処理中',
        inTransfer: '转账中',
        successful: '成功',
        failure: '失敗',
        reason: '理由',
        remark: '備考',
        add: '追加',
        addresslist: 'アドレスリスト',
        safevalidate: 'セキュリティ検証',
        telno: '電話番号',
        smscode: 'SMSコード',
        second: '秒',
        clickget: '取得',
        email: 'メール',
        emailcode: 'メールコード',
        save: '保存',
        delete: '削除',
        telerr: '電話番号が正しくありません',
        emailerr: 'メールアドレスが正しくありません',
        codeerr: 'コードが正しくありません',
        remarktip: '備考を入力してください',
        savemsg1: '保存に失敗しました！',
        savemsg2: '保存に成功しました！',
        operate: '操作',
        fundpwdtip: '資金パスワードを入力してください',
        click: 'クリック',
        filtrate: 'フィルタリング可能',
        txid: 'TxId',
        generateCode: 'コード生成',
        withdrawcodelist: 'コードリスト',
        withdrawcodestatus: 'ステータス',
        submit: '提出',
        generatecodesuccess: 'コード生成に成功しました',
        code_status0: '未使用',
        code_status3: '成功',
        protocol: 'ネットワークプロトコル',
        shenqing: '出金申請成功',
        fundsVerified: 'まだ暗証番号を設定していません!',
      },
      securityModal: {
        securityReminder: 'セキュリティリマインダー',
        toEAuthentication: '資金の安全を確保するために、',
        toEAuthentication2: 'に進み、メールアドレスとGoogle認証をバインドしてください。',
        setFundPassword: '資金パスワードの設定',
        goToSettings: '設定に移動',
        alreadySet: '設定済み',
        alreadyBound: 'バインド済み',
        bindEmail: 'メールアドレスのバインド',
        goToBind: 'バインドに移動',
        forTPassword:
          'アカウントのセキュリティを確保するため、パスワードを変更した後、出金機能が24時間無効になります。',
        youHHours:
          'ログイン/資金パスワードを変更しました。キャッシュアウト機能が無効になっており、{0}以降に利用可能になります。',
      },
    },
    member: {
      usercenter: 'ユーザーセンター',
      securitycenter: 'セキュリティセンター',
      securitysetting: 'セキュリティ設定',
      accountsetting: '受け取り方法',
      account: 'アカウント管理',
      assets: '資産管理',
      exchange: '取引管理',
      curdelegate: '未約定注文',
      hisdelegate: '注文履歷',
      otc: 'OTC管理',
    },
    order: {
      ordercenter: '注文センター',
      myorder: 'マイオーダー',
      myad: 'マイ広告',
    },
    regist: {
      anonymousLogin: '匿名登録',
      fillInvitationCode: '招待コードを記入',
      go_login: 'アカウントをお持ちの場合',
      go_login2: 'ログインへ',
      gologin: 'アカウントをお持ちの場合、今すぐログイン',
      SaveKey: '鍵を保存してください',
      zhanghaosiyao: 'アカウント秘密鍵',
      nimingtishiTxt:
        'アカウント秘密鍵を入力するだけでログインできます。アカウント秘密鍵はローカルでランダムに生成され、ユーザー自身で適切に保管する必要があります。紛失した場合、関連付けられたメールアドレスを使用して取り戻すことができます。それ以外の場合、取り戻すことはできません！',
      shuruningcheng: 'ニックネームを入力してください',
      logintishi:
        'ログイン時にはアカウント秘密鍵のみでログインできます。アカウント秘密鍵はローカルでランダムに生成され、ユーザー自身で適切に保管する必要があります。[OK]をクリックすると自動的にコピーされます。',
      hasaccount: 'アカウントをお持ちですか？ログインするにはクリックしてください。',
      login: 'ログイン',
      username: 'ユーザー名',
      country: '国',
      smscode: 'SMSコード',
      sendcode: 'コードを送信',
      resendcode: '再送信（',
      pwd: 'ログインパスワード',
      repwd: 'パスワードを確認',
      confrimpwd: 'パスワードを確認',
      agreement: '同意する',
      userprotocol: 'ユーザー規約',
      privacypolicy: 'プライバシーポリシー',
      regist: '登録',
      teltip: '携帯電話番号を入力してください',
      telerr: '携帯電話番号の形式が正しくありません。再入力してください',
      emailtip: 'メールアドレスを入力してください',
      emailerr: 'メールアドレスの形式が正しくありません。再入力してください',
      confirmpwdtip: '確認パスワードを入力してください',
      confirmpwderr: 'パスワードの確認入力が一致しません！',
      telregist: '携帯電話で登録',
      emailregist: 'メールで登録',
      usernametip: 'ユーザー名を入力してください',
      usernamemsg: 'ユーザー名は3文字未満または15文字を超えてはいけません',
      countrytip: '国を選択してください',
      smscodetip: 'SMSコードを入力してください',
      pwdtip: 'ログインパスワードを入力してください',
      pwdmsg: 'パスワードは6文字未満であってはいけません',
      telno: '携帯電話番号',
      email: 'メールアドレス',
      agreementtip: '同意するにはクリックしてください',
      reloadChoice: 'もう一度登録してください',
      modaltitle: '確認してください',
      promotion: '招待コード（任意）',
      china: '中国',
      us: 'アメリカ',
      japan: '日本',
      germany: 'ドイツ',
      uk: 'イギリス',
      india: 'インド',
      france: 'フランス',
      italy: 'イタリア',
      canada: 'カナダ',
      korea: '韓国',
      russia: 'ロシア',
      australia: 'オーストラリア',
      brazil: 'ブラジル',
      hk: '香港',
      macao: 'マカオ',
      taiwan: '台湾',
      spain: 'スペイン',
      mexico: 'メキシコ',
      indonesia: 'インドネシア',
      netherlands: 'オランダ',
      switzerland: 'スイス',
      saudiarabia: 'サウジアラビア',
      turkey: 'トルコ',
      iran: 'イラン',
      poland: 'ポーランド',
      sweden: 'スウェーデン',
      belgium: 'ベルギー',
      thailand: 'タイ',
      nigeria: 'ナイジェリア',
      austria: 'オーストリア',
      ireland: 'アイルランド',
      israel: 'イスラエル',
      norway: 'ノルウェー',
      argentina: 'アルゼンチン',
      philippines: 'フィリピン',
      arab: 'アラブ',
      egypt: 'エジプト',
      denmark: 'デンマーク',
      malaysia: 'マレーシア',
      singapore: 'シンガポール',
      vietnam: 'ベトナム',
      bangladesh: 'バングラデシュ',
      southafrica: '南アフリカ',
      chile: 'チリ',
      finland: 'フィンランド',
      colombia: 'コロンビア',
      romania: 'ルーマニア',
      portugal: 'ポルトガル',
      newzealand: 'ニュージーランド',
      greece: 'ギリシャ',
      qatar: 'カタール',
      belarus: 'ベラルーシ',
    },
    safe: {
      safelevel_low: 'セキュリティレベル: 低',
      safelevel_high: 'セキュリティレベル: 高',
      safelevel_medium: 'セキュリティレベル: 中',
      nickname: 'ニックネーム',
      bind: 'バインド',
      binded: 'バインド済み',
      binding: '審査中',
      binderr: '失敗',
      bindretry: 'リトライ',
      verifypass: '認証済み',
      verified: '実名認証',
      notverified: '未認証',
      verifiedtip:
        'アカウントの安全性を確保するために、実名認証を完了してから取引を行ってください！',
      realname: '本名',
      idcard: '身分証明書番号',
      upload: 'アップロードをクリック',
      upload_positive: '身分証明書正面',
      upload_negative: '身分証明書裏面',
      upload_hand: '身分証明書を持ち、個人の署名写真',
      save: '保存',
      reset: 'リセット',
      email: 'メール',
      bindemail: 'メールをバインド',
      loginpwd: 'ログインパスワード',
      emailcode: 'メール確認コード',
      clickget: 'クリックして取得',
      second: '秒',
      phone: '電話',
      bindphone: '電話をバインド',
      phonecode: '電話確認コード',
      logintip: 'プラットフォームログイン時に使用',
      edit: '編集',
      oldprivateKey: 'プライベートキー',
      oldpwd: '元のログインパスワード',
      newpwd: '新しいログインパスワード',
      confirmnewpwd: '新しいパスワードを確認',
      fundpwd: '資金パスワード',
      fundtip: 'アカウントの資金が変動する際に、資金パスワードを確認する必要があります',
      set: '設定',
      confirmpwd: 'パスワードを確認',
      oldfundpwd: '元の資金パスワード',
      newfundpwd: '新しい資金パスワード',
      newpwdmsg1: '6文字以上の新しいログインパスワードを入力してください',
      newpwdmsg2: '新しいログインパスワードが一致しません',
      pwdmsg1: '6文字以上のパスワードを入力してください',
      pwdmsg2: 'パスワードが一致しません',
      emailtip: 'メールアドレスを入力してください',
      privateKeytip: 'プライベートキーを入力してください',
      codetip: '確認コードを入力してください',
      telnotip: '電話番号を入力してください',
      oldpwdtip: '元のパスワードを入力してください',
      realnametip: '本名を入力してください',
      idcardtip: '身分証明書番号を入力してください',
      bindphonetip: 'まず電話をバインドしてください！',
      resetfundpwd: '資金パスワードをリセット',
      upload_positivetip: '身分証明書正面写真をアップロードしてください',
      upload_negativetip: '身分証明書裏面写真をアップロードしてください',
      upload_handtip: '身分証明書を持っている写真をアップロードしてください',
      save_success: '保存に成功しました！',
      save_failure: '保存に失敗しました！',
      idcard_verifymsg1:
        '1. JPG、PNG形式をサポートし、ファイルサイズは8MB以下である必要があります。証明書の情報ははっきり見え、修正や隠蔽は許されず、証明書番号と名前が読み取れる必要があります。',
      idcard_verifymsg2:
        '2. 写真は無帽で、メイクはお勧めしない。証明書を持っている人物の顔立ちははっきりと見え、手が露出している必要があります。',
      idcard_verifymsg3:
        "3. 証明書と手書きテキスト（名前、申請日）を同時に持っている必要があります。テキストは '私はこのサイトでのすべての行動が私自身によって行われ、関連するリスクを認識し、このアカウントのすべての法的後果を受け入れる用意があります。' と記載されている必要があります。",
    },
    account: {
      successDelete: '削除に成功',
      pagetitle: '実名アカウトをバインド',
      pagetip: 'お支払い方法を設定してください。必ずご自身の実名アカウトを使用してください。',
      backcardno: '銀行カード番号',
      backcardtip: '個人の銀行カード情報はバインドされていません',
      modify: '変更',
      bind: 'バインド',
      name: '名前',
      bankaccount: '銀行名',
      bankbranch: '支店名',
      bankno: '銀行カード番号',
      confirmbankno: 'カード番号を確認',
      fundpwd: '資金パスワード',
      save: '保存',
      zfbaccount: 'アリペイアカウント',
      zfbaccounttip: '個人のアリペイアカウントはバインドされていません',
      wxaccount: 'WeChatアカウント',
      wxaccounttip: '個人のWeChatアカウントはバインドされていません',
      banknomsg1: '正しい銀行カード番号を入力してください',
      banknomsg2: '2回入力した銀行カード番号が一致しません！',
      verifiedmsg: 'まず実名認証を行ってください',
      bankaccountmsg: '銀行名を選択してください',
      bankbranchmsg: '支店名を入力してください',
      banknomsg: '正しい銀行カード番号を入力してください',
      fundpwdmsg1: '正しい資金パスワードを入力してください',
      fundpwdmsg2: 'パスワードは6文字未満であってはいけません',
      zfbaccountmsg: 'アリペイアカウントを入力してください',
      wxaccountmsg: 'WeChatアカウントを入力してください',
      save_success: '保存に成功しました！',
      save_failure: '保存に失敗しました！',
      imgtip: 'お支払いコードをアップロードしてください',
    },
    otcorder: {
      unpaid: '未払い',
      paided: '支払済み',
      finished: '完了',
      canceled: 'キャンセル済み',
      appealing: '申し立て中',
      searchtip: '注文番号を入力して検索を開始',
      orderno: '注文番号',
      created: '作成日',
      symbol: '取引通貨',
      type: '取引タイプ',
      type_sell: '売却',
      type_buy: '購入',
      tradename: '取引相手',
      amount: '数量',
      money: '金額',
      fee: '手数料',
    },
    identity: {
      certified: '認証済み',
      placeholder: 'キャンセル理由を記入してください',
      apply: 'ビジネスパートナーとして申し込む',
      become: 'NTL認定ビジネスパートナーになり、さらなる取引特典を楽しむ',
      zhusnhu: 'ビジネスパートナー専用の広告スペースを持ち、成功率を高める',
      tijiaoziliao: 'ビジネスパートナー認定資料を提出',
      place: '用意したビジネスパートナー認定資料をアップロードし、提出してください',
      tijiao: 'ビジネスパートナー認定申請が提出されました',
      tijiaosuc: 'おめでとうございます！ビジネスパートナー認定審査が承認されました',
      tijiaofail: '申し訳ありませんが、ビジネスパートナー認定審査は承認されませんでした',
      zhuxiaotijiao: 'ビジネスパートナーの解除申請が提出されました',
      shenhefail: 'ビジネスパートナーの解除申請審査は承認されませんでした',
      shenhesuc: 'ビジネスパートナーの解除申請審査が承認されました',
      shangjiazhuxiao: 'ビジネスパートナーの解除',
      shenheshibai: '審査失敗',
      shenagain: '再審査',
      sheqinggain: '再申請',
      reason: '理由',
      shenqingtuibao: '保険金の申請',
      getquan: '以下の権限を取得します',
      read: '次を読んで同意しました',
      lijishenqing: 'すぐに申請',
      tips: 'ヒント',
      wufachexiao:
        'ビジネスパートナーの解除操作を行っています。申請操作を確認して提出した後、取り消すことはできなくなります。',
      suredo: 'この操作を実行しますか？',
      shuzizichan: 'デジタルアセット取引証明書',
      gerenzichan: '個人デジタルアセット証明書',
      second: {
        line: 'ビジネスパートナーになる手順',
        step1: 'ステップ1：指示に従ってビジネスパートナー認定資料を用意',
        step1c1: '次の認定資料を用意してください：',
        step1c2:
          '電話番号、WeChat ID、QQ番号、個人デジタルアセット証明書（画像）、デジタルアセット取引証明書（画像）',
        step2: 'ステップ2：申し込みを提出',
        step2c:
          '提出が必要なビジネスパートナー認定審査資料を完成させ、提出をクリックして審査を申請してください。',
        step3: 'ステップ3：審査資料の審査',
        stepc:
          'ビジネスパートナー認定審査資料は提出後、3-5営業日以内に審査されます。審査が承認されたら、法定通貨取引エリアで広告を掲載できるようになります。',
        agree: '次に同意する',
        agreec: 'ビジネスパートナー保証金として',
      },
      yuanyin: '理由',
      tijiaoshenqing: '申請を提出',
      bizhong: '保証金の通貨',
      shuliang: '保証金の量',
      chosen: '通貨の選択',
      seat: '専用の広告枠',
      service: '1対1のサービス',
      lowfee: 'より低い手数料',
      phone: '携帯電話',
      balance: '個人資産状況',
      cardphoto: 'IDカード表裏の写真',
      wx: 'WeChat',
      exchange: 'オフサイトデジタルアセット取引を行ったことがありますか？',
      handphoto: 'ユーザーがIDカードを手に持っている写真',
      qq: 'QQ',
      ploy: '適切なリスク管理戦略を持っていますか？',
      agreement: '認定ビジネスパートナー契約書',
      he: 'および',
      applyfor: '申し込みを確認',
      sendcode: '確認コードを送信',
      confirm: '確認',
      prepare: '資料の準備',
      review: '提出審査',
      result: '結果待ち',
      passed: '審査合格',
      approve: '認定ビジネスパートナー契約書に同意してください',
      emailtip1: '次の情報をメールで送信してください：',
      emailtip2: 'できるだけ早く審査を行います。',
    },

    extension: {
      title1: 'プロモーションリンク',
      title2: '友達を紹介',
      title3: '私のコミッション',
      linkdesc:
        '以下のURLは、外部でプロモーションを行うためのものです。友達、QQ、WeChat、Weibo、ブログ、フォーラム、または自分のウェブサイトを通じてプロモーションできます。このURLを経由してサイトにアクセスし、登録した人はすべてあなたのユーザーとなり、これらのユーザーがサイトで取引を行った場合、あなたはコミッションを獲得できます。詳細なプロモーションの状況は、アクセス履歴で確認できます。',
      linktitle: 'あなたのプロモーションリンク',
      copy: 'コピー',
      copy_msg1: 'コピーに成功しました！',
      copy_msg2: 'コピーに失敗しました。手動でコピーしてください。',
      username: 'ユーザー名',
      currrecharge: '現在の取引',
      userlevel: '推奨レベル',
      createdtime: '登録日時',
      currcommission: '現在のコミッション',
      managerfee: '管理手数料',
      yuan: '元',
      symbol: '通貨',
      amount: '金額',
      remark: '備考',
      amounttime: '支払日時',
    },
    promotion: {
      title: 'プロモーション',
      subtitle1: 'プロモーションカード',
      subtitle2: '交換履歴',
      subtitle3: '招待状',
      card_column0: '交換コード',
      card_column1: 'カード名',
      card_column2: '表面通貨',
      card_column3: '表面金額',
      card_column4: '有効期間',
      card_column5: '総数',
      card_column6: '交換済',
      card_column7: '取得日時',
      card_column8: '期限切れ日時',
      my_column0: '招待ユーザー',
      my_column1: '登録日時',
      my_column2: '本名認証ステータス',
      gopromotion: 'プロモーション資材',
      inputcardno: '交換コードを入力してください',
      exchange: '今すぐ交換',
      exchangesuccess: '交換成功！資産管理で残高を確認してください！',
      exchangewithcode: '交換コードで交換',
    },
    activity: {
      all: 'すべて',
      navTitle: '新規管理',
      subNavTitle1: '私の参加',
      subNavTitle2: '私のマイニング',
      subNavTitle3: '私のロック',
      column1: '名前',
      column2: 'タイプ',
      column3: '申し込み数量',
      column4: '参加通貨',
      column5: '申し込み単位',
      column6: '現在のステータス',
      column7: '売買額',
      column8: '作成日時',
      mColumn1: 'マイニング',
      mColumn2: '名前',
      mColumn3: '通貨',
      mColumn4: 'サイクル',
      mColumn5: '日数',
      mColumn6: '掘削済み',
      mColumn7: 'ステータス',
      mColumn8: '基本生産能力',
      mColumn9: '現在の生産能力',
      mColumn10: '総生産',
      mColumn11: '終了日',
      mColumn12: '作成日',
      lColumn1: 'ロック',
      lColumn2: '名前',
      lColumn3: '通貨',
      lColumn4: 'サイクル',
      lColumn5: '日数',
      lColumn6: '解放済み',
      lColumn7: 'ステータス',
      lColumn8: '基本解放',
      lColumn9: '現在の解放',
      lColumn10: '総放出',
      lColumn11: '終了日',
      lColumn12: '作成日',
      lColumn12: '创建日期',
    },
    mining: {
      title: '私のマイニングリスト',
      empty: 'マイニングなし',
      day: '日',
      week: '週',
      month: '月',
      year: '年',
      invitetip1:
        'このマイニング生産能力は友達を招待することで向上し、友達1人を招待するごとにマイニングマシンを交換し、生産能力が向上します',
      invitetip2: '、最大生産能力向上率は',
      miningUnit: '生産通貨',
      miningPeriod: '生産サイクル',
      miningDays: 'マイニング期間',
      miningedDays: 'マイニング済み期間',
      miningDaysProfit: '基本生産能力',
      miningCurrentDaysProfit: '実際の生産能力',
      status0: '展開待ち',
      status1: '展開済み',
      status2: '終了済み',
    },
    locked: {
      title: '私のロックリスト',
      empty: 'ロックなし',
      day: '日',
      week: '週',
      month: '月',
      year: '年',
      invitetip1:
        'このロック生産能力は友達を招待することで向上し、友達1人を招待するごとにロックに参加し、生産能力が向上します',
      invitetip2: '、最大生産能力向上率は',
      miningUnit: '解放通貨',
      miningPeriod: '解放サイクル',
      miningDays: '総解放サイクル',
      miningedDays: '解放済みサイクル',
      miningDaysProfit: '基本解放',
      miningCurrentDaysProfit: '実際の解放',
      status0: '解放待ち',
      status1: '解放中',
      status2: '終了済み',
      totallocked: '総ロック',
    },
    api: {
      apiAdmin: 'API管理',
      createApi: '新しいAPIキーの作成',
      mark: 'APIメモ',
      bindAddress: '承認IP（承認されたIPアドレスの有効期限は90日です）',
      hint: 'ヒント',
      part1:
        '強力なAPIを提供しており、APIを使用して市場データのクエリや自動取引などのサービスを利用できます。詳細については',
      apiDoc: 'APIドキュメント',
      howUse: 'をご覧ください。',
      part2: 'ユーザーごとに最大5つのAPIキーを作成できます',
      part3:
        'APIキーを漏洩しないようにしてください。セキュリティ上の理由から、APIキーをIPにバインドすることをお勧めし、各APIキーに最大5つのIPをバインドできます。単一のアドレスは直接入力し、複数のIPアドレスは半角コンマで区切って入力してください。例：192.168.1.1,192.168.1.2,192.168.1.3。',
      myApiKey: 'マイAPIキー',
      ipAddress: 'IPアドレス',
      createTime: '作成日時',
      endTime: '最終計算日時',
      accessKey: 'アクセスキー',
      bindIpAddress: 'バインドIPアドレス',
      ioDays: '有効期限残り（日）',
      operation: '操作',
      create: '作成',
      edit: '編集',
      delete: '削除',
      sureDelete: '削除を確認しますか',
      tips: 'キーは新規作成時のみ表示されます。必ず保存してください。',
      copy: 'コピー',
      access: 'キー',
      okText: '確認',
      cancel: 'キャンセル',
    },
  },
  cms: {
    NewExclusive: '新規専用',
    Newtip: 'NTLアカウントに登録し、友達を招待すると、終身リベートのチャンスがあります',
    noticecenter: 'お知らせセンター',
    newshelp: '初心者ヘルプ',
    appdownload: 'アプリのダウンロード',
    onlineservice: 'オンラインサポート',
    faq: 'よくある質問',
    notice: 'お知らせ',
    NTLnotice: 'NTLお知らせ',
    info: 'NTLの最新情報をいつでもどこでも取得',
    servicecenter: 'カスタマーサポートセンター',
    about: '約',
    joinus: '私たちに参加',
    aboutus: '私たちについて',
    exchangerule: '取引ルール',
    useprotocol: '利用規約',
    feenote: '料金について',
    merchantprocotol: 'ビジネス契約',
    features: 'プラットフォームの特徴',
    contactus: 'お問い合わせ',
    noticelist: 'お知らせリスト',
    nextnotice: '次の記事',
    prevnotice: '前の記事',
    notexist: 'アクセスしようとしたお知らせは存在しないか削除されています。',
    otherhelp: 'このセクション内の記事',
    scanforshare: 'スキャンして共有',
    download: '今すぐダウンロード',
    downloadslogan: 'NTL APP - グローバルデジタル資産取引プラットフォーム',
    pinned: '屋根をつけます',
    searchArticle: '記事を検索します',
  },
  description: {
    title1: '金融の安全性',
    title2: '高速入出金',
    title3: 'グローバルサービス',
    title4: 'プロジェクトの厳選',
    message1:
      '包括的な金融リスク管理システムと盗難防止システム、コールドウォレット、マルチシグシステムにより資産の安全性を確保',
    message2:
      '入金と出金は最短3分で完了し、24時間オンライン審査により、顧客が最適な投資機会を逃さないよう保護',
    message3:
      'グローバルビジネスサービスネットワークが提供され、顧客がグローバルな暗号資産への投資と世界中のユーザーとの取引をサポート',
    message4: '優れた暗号プロジェクトを厳選し、80%以上の高リスクプロジェクトをフィルタリング',
    message5:
      'NTL(ntlex.com)国際デジタル資産取引プラットフォームのモバイルクライアント、モバイル取引体験に特化しています',
    scanqrcode: 'QRコードをスキャンしてアプリをダウンロード',
    aboutdesc1:
      'NTL(ntlex.com)は、ケイマン諸島に登録され、香港に本拠を置く、技術的に先進的なデジタル資産取引プラットフォームです。 NTL(ntlex.com)の中核メンバーは、トップのインターネットおよび金融企業出身で、ほとんどのメンバーはビットコインとブロックチェーンの熱心な信者であり、ブロックチェーンは伝統的な独占的金融システムを変え、より民主的で自律的な社会構造を構築すると信じています。',
    aboutdesc2:
      'NTL(ntlex.com)プラットフォームは、プロの金融レベルの取引アーキテクチャを持ち、独自に開発した高並列インメモリマッチング取引エンジンを持っており、コールドウォレットの充実、マルチシグ、高防御のDDOS攻撃システムなどの取引アーキテクチャを採用し、顧客の資産の安全性を確保しています。',
    aboutdesc3:
      '顧客が最適な投資機会を逃さないようにするため、カスタマーサポート部門ではフラットな管理を採用し、迅速なサービス対応を実現しました。資産カスタマーサポートマネージャーは年中無休でオンラインで、入金および出金などのサービスを提供し、お客様の25分以内に資産を入金および出金することを保証します。',
    aboutdesc4:
      'NTL(ntlex.com)は厳格に優れたプロジェクトを選択し、安全で安定した資産管理サービスを提供しています。NTL(ntlex.com)は「誠実、公正、情熱、オープン」の理念に従い、お客様に安全で信頼性のある、効率的で友好的な最高の取引所を提供するために最善を尽くしています。',
    aboutdesc5:
      'デジタル資産は現在は小さなコミュニティでのみ利用可能ですが、私たちはこの個々の人が完全にコントロールできる資産が将来的に大流行すると信じています。一緒に取り組んで、期待しましょう！',
    aboutdesc6:
      'ntlex.com (Nano Token Liquidity) は、DAO（分散型自治組織）ガバナンスをベースにした国際的なデジタル資産デリバティブ取引所で、グローバルユーザーに安全で安定した、便利で信頼性のあるブロックチェーン資産取引サービスを提供することを目的としています。',
    aboutdesc7:
      'NTL DAO はケイマン財団に管理される ntlex.com を認可し、このガバナンス構造は従来の暗号通貨取引所よりも優れており、取引所の公正性およびユーザー資産の安全性を有効に保証しています。管理チームのメンバーは世界中から集まり、コインベース、バイナンス、モルガンスタンリー、アマゾン、グーグルなどの有名な金融およびテクノロジー企業での経験が豊富で、強力なグローバル戦略的運営能力と豊富な金融リスク管理および運営経験を持っています。',
    aboutdesc8:
      'ntlex.com は通貨の交換、パーペチュアル契約、現物、オプション、NFT、資産運用、貸し出しなど、すべての暗号資産サービスを提供し、中文、英語、日本語、韓国語など多言語バージョンをサポートしています。テクニカルソリューションは Amazon AWS および Google Cloud などをベースにしており、DDOSなどの攻撃を効果的に防ぎ、ダイナミックおよびスタティックデータの分離、ホットデータキャッシュ、サイト冗長、平行スケーリングなどの機能をサポートしています。先進的な技術アーキテクチャにより、安全な取引が保証され、即時のブロックチェーン資産アクセス機能、多通貨アドレス共有技術、多層のセキュリティ検証保護を備えています。同時に、ntlex.com は業界内で比較的低いブロックチェーン資産取引手数料および引き出し手数料を提供しています。',
    aboutdesc9:
      '信頼性のあるDAOガバナンス取引所として、ntlex.com はユーザーの取引情報のセキュリティと資産の安全性を総合的に保護し、卓越した取引体験を提供することに専念しています。',
    support: '技術サポート',
    report: '苦情と提案',
    service: 'カスタマーサービス',
    apply: '上場申請',
    hhjh: 'パートナーシッププログラム',
    coop: 'ビジネス提携',
    community: 'NTLコミュニティ',
    wechat: 'WeChat',
    addwechat: 'WeChat友達として「bmate601」を追加し、WeChat福祉コミュニティに参加',
    weibo: 'Weibo',
    twitter: 'Twitter',
    biyong: '币用',
    telegram: 'Telegram',
    medium: 'Medium',
    reddit: 'Reddit',
  },
  feereturn: {
    ruletitle: '返还规则',
    rulecontent:
      'ホワイトペーパーには、BHBの割り当て比率に関する詳細な説明があります。51％のBHBは「トレードマイニング」を通じてトレーダーに段階的に戻されます。 51％のBHBが完全に戻されると、「マイニング」は自動的に終了します。',
    recordtitle: '返还记录',
    recordcontent:
      '毎日（UTC+8、以下同様）前日のユーザーによって発生したトランザクション手数料は、100％がBHBに変換され、ユーザーに返還されます。変換価格は前日のBHBの平均価格（平均価格の計算方法は、総取引額/総取引量です）に従います。返還は、毎日午前11時に前日の取引手数料に変換されたBHBの返還を開始します。',
  },
  finance: {
    saveCoins: '存币生息',
    info: 'シンプルで効率的なコイン収益',
    whySaveCoins: '存币生息とは何ですか？',
    order: 'マイオーダー',
    current: '当座',
    periodical: '定期',
    save: '預金',
    introduce:
      '存币生息は、NTLが提供する金融製品の1つです。余剰資産を活用してコインを稼ぐのを支援します。存币生息には「活期」と「定期」の2つの種類があります。活期のユーザーは元本と利息をいつでも引き出すことができますが、定期のユーザーは預金時に選択した期間に従って計算され、早期に引き出すと手数料が発生します。',
    ok: '確認',
    curDayRate: '日次利率',
    curYearRate: '年次利率',
    term: '年率と期間',
    day: '日',
    quantity: '預金数量',
    inputQuantity: '数量を入力してください...',
    all: 'すべて',
    available: '利用可能',
    reminder: '注意:',
    reminderCont1: '定期オーダーを早期に終了する場合、手数料が発生します。',
    reminderCont2:
      '満期後、システムは自動的にポジションをクローズし、元本と利息はコイン取引アカウントに預けられます。',
    cancle: 'キャンセル',
    PositionRecords: 'ポジション記録',
    ClosingRecord: 'ポジションクローズ記録',
    ClosingPosition: 'ポジションクローズ',
    title1: '通貨',
    title2: '数量',
    title3: '累積利息',
    title4: '作成時間',
    title5: '期限',
    title6: '最後の決済時間',
    title7: '終了時間',
    title8: '操作',
    title9: '参考年利回り',
    title10: '参考日利回り',
    title11: '投資済み',
    tip1: 'このオーダーを終了してもよろしいですか？',
    tip2: '定期オーダーはまだ終了していないため、早期に終了するには {0}% の手数料が発生します。このオーダーを終了してもよろしいですか？',
    depositQuantity: '入金数の入力をお願いします。',
    cannot: '預け入れ可能な量以上にしてはいけません',
    aheadOfSchedule: '棚上げします',
    expire: '満期平積みです。',
  },
  Partnership: {
    PartnershipPlan: '計画を組みます',
    becomePartner: 'プラットフォームのパートナーになります',
    info: 'プロモーションNTL、最高の終身返金の機会を受け取り、友達を招待し、プラットフォームの特典を受け取ります。',
    moreService: 'もっと専属のサービスを楽しんでください',
    title1: '1on1の専属サービス',
    title2: 'リアルタイムデータ追跡',
    title3: 'Win-Winのパートナーシップ',
    introduce1:
      '専属の運営スタッフとカスタマーサービス、24時間オンラインで使用またはプロモーションプロセス中の問題を解決します。',
    introduce2:
      '利益状況をリアルタイムで表示する、可視化データダッシュボード。プロモーション状況が明確に理解できます。',
    introduce3: 'ファンと一緒に友達を招待し、自分自身のチームを開発し、管理します。',
    invite: 'ユーザーを招待し、各ユーザーが50 USDT（1人当たり）取引します。',
    tip: '毎月のコミッション',
    NTL: 'NTLの利点',
    NTLTitle1: '最高の終身リベートを受けることができます',
    NTLTitle2: '世界をリードする取引プラットフォーム',
    NTLIntroduce1:
      'NTLプラットフォームをどのようにプロモートするかに関しては、ソーシャルメディア、コミュニティなど、どのメディアを使用しても問題ありません。紹介したアクティブなトレーダー1人当たりの収入が増加するたびに、終身リベートの機会があります。',
    NTLIntroduce2:
      'NTLは国際的なデジタル資産デリバティブ取引所で、分散型自治組織（DAO）に基づいており、ユーザーに安全で安定した、便利で公正で信頼性のあるブロックチェーン資産取引サービスを提供しています。',
    methods: 'NTLでどれだけのコミッションを獲得できますか？',
    method1: '方法1：フォームを記入し、NTL公式の審査後、専門チームがお客様に連絡します。',
    method2: '方法2：10人の有効な新しいユーザーを招待します。',
    stepBtn1: 'フォームの申し込み',
    stepBtn2: 'Telegramの申し込み',
    stepBtn3: '今すぐ招待',
    rule: '規則の詳細を確認>',
    KOL: 'KOLがNTLに参加して新しい宇宙を開きます',
    KOLText1: '国際的なパートナーネットワーク',
    KOLText2: 'さまざまな国に分散',
    KOLText3: '最高月額収益(USDT)',
    view: 'コミッション収益ランキングを表示',
    join: '今すぐ参加',
    earn: 'どれだけ稼げますか？',
    promoteNTL: 'NTLをプロモート',
    becomingPartner: 'NTLのパートナーになる',
    earnRebates: '高額のリベートを獲得',
    application: '申し込み',
    submit: '提出',
    name: '名前',
    email: 'メール',
    emailCode: 'メール認証コード',
    contact: 'あなたの連絡先（ソーシャルメディアのアカウントまたはメール）',
    fans: 'あなたのコミュニティ/ソーシャルメディア/コミュニティのフォロワー/メンバー/ユーザーは何人ですか？',
    community:
      'あなたのコミュニティ/ソーシャルメディア/コミュニティ（メンテナンスするコミュニティ/ソーシャルメディア/コミュニティを表示できます。複数の場合は「;」で区切って入力してください。）',
    inputName: '名前を入力してください...',
    inputemail: 'メールアドレスを入力してください...',
    inputemailCode: 'メール認証コードを入力してください...',
    inputcontact: 'ソーシャルメディアのアカウントまたはメールを入力してください...',
    inputfans: 'フォロワー数を入力してください...',
    inputcommunity: 'コミュニティ/ソーシャルメディア/コミュニティを入力してください...',
    getCode: '認証コードを取得',
    share:
      '招待リンクまたは招待コードを友達に共有するか、ソーシャルメディアなどの他のチャネルで宣伝します。',
    trades: '招待したユーザーが取引すると、手数料からコミッション収益を取得できます。',

    congratulations: 'おめでとうございます',
    becomingAPlatformPartner: 'プラットフォームのパートナーになりました',
    myRebates: '私のリベート',
    spotRebatePercentage: 'スポットリベート率',
    futuresRebatePercentage: '先物リベート率',
    accumulatedRebates: '積算リベート（USDT）',
    yesterdaysRebates: '昨日のリベート',
    kolAndMemberInformation: 'KOLと会員情報',
    directKol: '直属KOL',
    subordinateKol: '下属KOL',
    directMember: '直属メンバー',
    subordinateKolMember: '下属KOLメンバー',
    directRechargeMember: '直接リチャージメンバー',
    subordinateKolRechargeMember: '下属KOLリチャージメンバー',
    totalMembers: '総メンバー数',
    totalRechargeMembers: '総リチャージメンバー数',
    newDirectMembersToday: '本日の新規直属メンバー',
    newSubordinateKolToday: '本日の新規下属KOL',
    forMoreInformationPleaseLogInToTheBackendToView:
      '詳細については、バックエンドにログインして表示してください。',
  },
  rule: {
    Breadcrumb1: 'ヘルプセンター',
    Breadcrumb2: 'よくある質問',
    Breadcrumb3: 'パートナーシッププラン',
    Breadcrumb4: '記事',
    rule: 'パートナーシッププラン規則',
    time: '2023年2月19日に公開、2023年7月19日に更新',
  },
  helpCenter: {
    uzxsq: 'NTLコミュニティ',
    yhzc: 'ユーザーサポート',
    help: 'ヘルプセンター',
    helpCenter: 'NTLヘルプセンター',
    card1: 'ログインパスワードの変更',
    card2: '資金パスワードの変更',
    card3: 'メールアドレスのバインディング',
    hotProblem: '人気の質問',
    novice: '初心者向けガイド',
    more: 'もっと見る',
    commonProblem: 'よくある質問',
    safe: 'アカウントセキュリティ',
    article: '記事',
    searchResults: '検索結果',
    toSubmite: '提出へ',
    tip: '製品の意見やバグの提出を歓迎します',
    search: '検索',
    published: '公開日',
    updated: '更新日',
    inputProblem: '質問を入力',
    account: '口座管理です',
    community: '初心者コミュニティです',
    articleTime: '{0}に公開、{1}に更新',
    toMyworkorder: '私の依頼書です',
    googleVerification: 'グーグル検証です',
    setAFundPassword: '資金パスワードを設定します',
    forgotTheFundPassword: '資金コードを忘れました',
    nonsupport: '匿名アカウントは修正をサポートしません。',
  },
  feedBack: {
    topic: 'Everyone is a Product Manager',
    info: '製品の使用中に意見やバグを提出できます',
    feedBack: 'フィードバック',
    email: 'メール（任意）',
    submit: '提出',
    error: '提出エラー',
    photo: '画像またはビデオ',
    title: 'タイトル',
    inputTitle: 'タイトルを入力してください...',
    inputContent: '内容を入力してください...',
    look: '表示＃ヒョウジ＃',
  },
  detail: {
    createTime: '作成時間',
  },
  MyWorkOrder: {
    customerService: 'カスタマーサービス製造オーダ',
    info: '未解決および過去にコミットされた問題の表示',
    toBeProcessed: '待处理',
    processing: '处理中',
    completed: '終わりました',
  },
  community: {
    joinCommunity: 'NTLコミュニティに参加',
    info: '最新のデジタル資産投資戦略、ブロックチェーンの知識、業界トレンドを知り、NTLカスタマーサポートとオンラインでコミュニケーションを取ります',
    english: '英語',
    chinese: '中国語',
  },
  noviceCommunity: {
    noviceCommunity: '初心者コミュニティ',
    topic: '仮想通貨を楽しむ',
    info: '初心者向けガイド、トレードの上達、操作ガイドはすべてNTL初心者アカデミーで提供します',
    recharge: 'USDTのリチャージ',
    secondUnderstanding: '仮想通貨トレードの基本',
    UContract: 'U標準契約',
    toRecharge: 'リチャージへ',
    toTransaction: 'トレードへ',
    topicClass: 'トピック授業',
    more: 'さらに見る',
    blockchain: 'ブロックチェーン用語集',
    unresolved: 'まだ解決していない質問ですか？',
    toHelpCenter: 'ヘルプセンターへ',
    contactService: 'NTLカスタマーサポートへ',
    noviceStrategy: '初心者向け戦略',
    advancedTrading: '高度なトレード',
  },
  bzb_description: {
    title1: '配当権益',
    title2: '手数料割引',
    title3: '優先購読',
    title4: '投票権',
    title5: '第一情報権',
    title6: '優先上場',
    title7: '意思決定共同体',
    title8: 'その他',
    desc1: '手数料分配、収益分配、上場料分配、株主分配など',
    desc2: 'トレード手数料の最大80%割引およびリワード、詳細は決定中',
    desc3: 'IEO初回発行およびBootプロモーショントークンの優先購読権利',
    desc4: 'NTLコミュニティの投票イベントへの参加、上場などに関するものを含む',
    desc5: '一定数の保有量でSVIPグループへのアクセス、有名トレーダーとの直接の接触',
    desc6: 'スーパーノードに限定、上場推薦資格を保有',
    desc7: 'スーパーノードに限定、重要な意思決定権を保有',
    desc8: 'その他の権益、お楽しみに',
  },
  footer: {
    wechatkefu: 'WeChatサポート',
    notice: 'プラットフォームのお知らせ',
    changjian: 'よくある質問',
    shiyong: '利用規約',
    feilv: '手数料説明',
    lianxi: 'お問い合わせメール',
    biyong: '币用',
    gsmc: 'グローバルトップクラスのデジタル資産取引所',
    bah: '上海ICP备13026899号-3',
    gsjj: '会社について',
    gywm: '当社について',
    jrwm: '採用情報',
    lxwm: 'お問い合わせ',
    yqlj: 'パートナーリンク',
    bzzx: 'ヘルプセンター',
    xsrm: '初心者向けガイド',
    czzn: 'リチャージガイド',
    jyzn: 'トレードガイド',
    ptsm: 'プラットフォーム説明',
    fysm: 'トレード規則',
    fltk: '料金説明',
    wechat: 'WeChatサポート',
    xszn: '初心者向けガイド',
    cjwt: 'よくある質問',
    mztk: '免責事項',
    ystk: 'プライバシーポリシー',
    tkxy: '利用規約',
    gybzb: 'BZBについて',
    kfyx: 'カスタマーサポートメール',
    swhz: 'ビジネスパートナーシップ',
    sbsq: '上場申請',
    bzzl: 'コイン情報',
    tsjb: '苦情報告',
    apidoc: 'APIインタフェース',
    zwkf: '利用できない',
    fwtk: 'サービス規約',
    yszc: 'プライバシーポリシー',
    mzsm: '免責免責事項',
    lzjb: '倫理的な報告',
    zfqq: '法執行の要求',
    cp: '製品',
    fw: 'サービス',
    secondFuture: '秒契約',
  },
  plate: {
    title: 'プラットフォーム収益分配プラン（BHBホルダーの権益）',
    content1:
      '白書に記載されているように、NTLはプラットフォーム収益の80％（税金控除後）をBHBホルダーに分配し、20％の収益をプラットフォームの開発および運営のサポートに充てます。',
    content2:
      '収益分配は日ごとのサイクルで行われ、2018年6月6日が最初の分配日です。それ以降の毎日、前日に蓄積されたすべての未分配収益が、BHBホルダーに比例して一括して分配されます。',
    content3: '（注：1. ここでのBHBは、リリース済み/流通可能なBHBを指します。詳細は【',
    content3_1: 'BHB流通量/収益分配比率に関する説明',
    content3_2:
      'を参照してください。2. 毎時（整時）スナップショットおよび計算が行われ、収益分配は毎日1回行われます。）。',
    hourFenpei: '今日の分配収益合計',
    hourTotal: '今日のプラットフォーム総収益合計',
    xiangqing: '今日の収益分配詳細',
    yesterdaytit: '昨日の分配収益合計',
    yesterdaytotal: '昨日のプラットフォーム総収益合計',
    yesterdayxiangqing: '昨日の収益分配詳細',
  },
  rewardCenter: {
    rewardCenter: '報酬センター',
    aChanceToWinMorePrizes: 'さらに多くの報酬を獲得できるチャンス',
    activity: 'アクティビティ',
    singleDeposit: '単一の入金',
    singleDepositDescription1: '初めての単一入金が',
    singleDepositDescription2: 'ドルに達した場合、デジタルブラインドボックスが提供されます',
    myRewards: '私の報酬',
    learnMoreAboutRewards: '報酬ルールについて詳しく知る',
    digitalCurrency: 'デジタル通貨',
    digitalCurrencyType: 'デジタル通貨',
    financialExperienceGold: '体験ボーナスで取引',
    notIssued: '未発行',
    issued: '発行済み',
    noAvailableRewards:
      '現在、使用可能な報酬はありません。友達を招待してデジタルブラインドボックスを獲得できます',
    topUpNow: '今すぐチャージ',
    commissionCard: 'リベートカード',
    dikouquan1: 'コイン取引手数料割引券です',
    dikouquan2: '契約取引手数料クーポン',
    perpetualContractExperience: '永続契約',
    leverageLimit: 'テコ {0}{1}x 利用可能です',
    unlimitedLeverage: '無制限です',
    deductible: '相殺しました',
    useNow: '使用する',
    expired: '期限切れ',
    haveBeenUsed: '使用済みです',
    noDataAvailable: 'データがありません',
    inviteFriends: '友達を招待',
    commonProblems: 'よくある質問',
    whatIsRebateCardAndHowToUseIt: 'リベートカードとは？どのように使用するのか？',
    rebateCardUsageDescription:
      'リベートカードは有効期間内に取引手数料を差し引くために使用できます。リベートカードを取得したらすぐに有効になります。取引手数料が発生した翌日に、リベートカードは対応する金額を差し引き、残りのUSDTを資産口座に返金します。',
    whereToViewDigitalCurrencies: 'デジタル通貨はどこで見られますか？',
    digitalCurrenciesViewingDescription:
      'デジタル通貨を受け取ったら、それらは直接現物アカウントに送金され、資産>現物アカウントで確認できます。デジタル通貨には有効期限はありません。',
    rewardRules: '報酬ルール',
    text1: 'NTLユーザーの初回入金報酬に関する説明',
    text2: '2023年2月19日に公開、2023年7月19日に更新',
    text3: '100％の初心者報酬：',
    text4:
      '1、新規ユーザーが初めて入金を完了すると、報酬が提供されます（ページに表示されている報酬の形式と数量に基づく；デジタルブラインドボックス報酬はBTC、ETH、NTLCのいずれかのトークンまたはリベートカードです）。',
    text5: '2、入金方法には以下が含まれます：',
    text6:
      '（1）オンチェーン送金：他の取引所や個人ウォレットから個人のNTLアカウントにコインを入金し、単一の注文が35ドルに達する。',
    text7: '3、受け取ったNTLプラットフォームトークン報酬は凍結状態です。',
    text8:
      'アンロックルール：友達を合約または現物取引を行うように勧め、招待された友達が合約取引を完了した後、取引ユーザーと上位招待者のNTLプラットフォームトークンがアンロックされます。アンロック数量=取引から生じた手数料/NTLプラットフォームトークン価格。',
    text9: 'デジタルブラインドボックスについて',
    text10: '1、デジタルブラインドボックスは期限切れになりますか？',
    text11:
      'はい、デジタルブラインドボックスを受け取った後、30日以内に引き換えなければ期限切れになります。例：3月1日00:00にブラインドボックスを受け取った場合、ブラインドボックスは3月30日23:59に期限切れになります。期限切れ後は引き換えることができません。',
    text12: '2、何個のブラインドボックスを入手できますか？',
    text13: '$50を初めてチャージすると、1つのブラインドボックスを入手できます。',
    text14:
      'より多くのブラインドボックス入手方法については、アクティビティのお知らせをご覧ください。',
    text15: '3、ブラインドボックスにはどの種類の報酬が含まれていますか？',
    text16:
      'ブラインドボックスの報酬には、最大でXXXXドル相当のデジタル通貨、BTC、ETH、NTLCのいずれかのトークン、または手数料割引クーポンが含まれます。報酬タイプは変更されることがあります。',
    text17: '4、ブラインドボックスの中のデジタル通貨報酬をどのように確認できますか？',
    text18:
      'ブラインドボックスを受け取った後、NTL App>ユーザーセンター>報酬センターにアクセスしてデジタル通貨報酬を確認できます。',
    text19: 'アクティビティ条件',
    text20: '1.条件を満たすユーザーは1つの報酬を受け取ることができます。',
    text21:
      '2.サブアカウントはデジタルブラインドボックスアクティビティに参加する資格または報酬を受け取る資格を持っていません。',
    text22:
      '3.報酬の受け取りと使用に関連する税金、手数料、および追加費用（接続、インストール、および/またはサービス料金を含む）は、報酬を受け取るユーザーが負担します。',
    text23:
      '4.NTLアカウントを登録した後、すべての要件を満たし、NTL Appに30日以内にログインすると、報酬は即座に発行されます。',
    text24:
      '5. NTLのリスク管理システムがアカウントで不正行為を検出した場合、初めてのコインチャージを完了した操作は無効とみなされます。 NTLは不正行為のあるアカウントをブロック、一時停止、または終了する権利を保持します。',
    text25:
      '6.NTLは、いつでも本アクティビティの参加者をキャンセルする権利、および本アクティビティを変更または修正する権利を保持します。',
    text26:
      '7.本文書の翻訳バージョンと英語バージョンとの間に相違がある場合、英語バージョンが優先されます。',
    text27:
      '8.NTLは、本アクティビティ条件を解釈し、いつでも本アクティビティ条件を調整または変更する権利を保持します。',
  },
  inviteNew: {
    winUpTo: '最大で獲得',
    OfCryptoBlindBox: 'の暗号通貨ブラインドボックス',
    inviteFriendsAndGetCryptoBlindBox:
      '友達を招待し、あなたと友達の両方が暗号通貨ブラインドボックスを手に入れます',
    inviteLink: '招待リンク',
    link: 'リンクします',
    inviteCode: '招待コード',
    howToGetCryptoBlindBox: '暗号盲目ボックスを入手する方法',
    inviteFriends: '友達を招待',
    friendsNeedToDownloadAppAndRegisterWithInviterCode:
      '友達はアプリをダウンロードし、招待者のコードを使用してntlex.comアカウントに登録する必要があります',
    friendsLoginToApp: '友達がアプリにログイン',
    bothPartiesUnlockBlindBoxUponFriendsLogin:
      '友達がアプリにログインしたら、双方がブラインドボックスを解除できます',
    receiveBlindBoxRewards: 'ブラインドボックスの報酬を受け取る',
    openNTLComAppInviteFriendsCheckReceivedBlindBoxes:
      'ntlex.comアプリを開いて友達を招待し、受け取ったブラインドボックスを確認します',
    invitationRules: '招待のルール',
    inviteYourFriendsToJoinNTLComAndGetUpTo10000USDOfCryptoBlindBox:
      '1. あなたの友達をntlex.comに招待し、最大10000USD相当の暗号通貨ブラインドボックスを手に入れます。',
    step1EnterInviteFriendsPageShareInviteLinkOrCode:
      'ステップ1：「友達を招待」ページに入り、友達に招待リンクまたは招待コードを共有します。',
    step2FriendSuccessfullyRegistersAndBothGetBlindBoxWithin30Days:
      'ステップ2：友達が成功裏に登録したら、30日以内にntlex.comアプリにログインし、あなたと友達の両方がブラインドボックスを1つずつ手に入れます。',
    step3FriendDeposits200USDInCryptoWithin30DaysBothGetBlindBox:
      'ステップ3：友達登録してから30日以内に、1回に$35以上のデジタルマネーをチャージすると、あなたと友達はブラインドボックスを1つずつ手に入れます。',
    eachBlindBoxContainsUpTo10000USDOfCryptoAndMore:
      '2. 各ブラインドボックスには最大10000ドル相当の暗号通貨、BTC、ETH、NTLCなどの暗号通貨とリベートカードが含まれます。暗号通貨報酬はブラインドボックスを開いてから1時間以内にあなたの資金口座に入金され、報酬を確認できます。',
    noLimitOnBlindBoxQuantityInviteMoreFriendsForMoreBlindBoxes:
      '3. ブラインドボックスの数に上限はありません。友達をさらに招待して、ブラインドボックスをさらに手に入れましょう！',
    subAccountsCannotParticipateInThisPromotion:
      '4. サブアカウントはこのプロモーションに参加できません。',
    moreDetailedRulesAvailableAt: '詳細なルールはこちらでご覧いただけます：',
    uzxInviteFriendsRulesExplanation: '《NTL招待友達のルールの説明》',
    inviteFriendsAndGetCommission: '友達を招待してコミッションを受け取る',
    recommend1NewUserToRegisterAndGetCommissionRewards:
      '1人の新しいユーザーをNTLに登録し、招待者はNTLでの現物または契約取引中に招待者からのコミッションを受け取ることができます。被招待者も自身の取引中にキャッシュバック報酬を受け取ることができます。',
    setFriendCommissionPercentageInApp: 'アプリ内で友達のコミッション率を設定できます。',
    uzxCommissionRulesExplanation: '《NTLコミッションルールの説明》',
    promotionTools: 'プロモーションツール',
    invitePromotionalGraphics: '招待のプロモーショングラフィック',
    promotionalMaterials: 'プロモーション資材',
    exclusiveQRCodeForAutomaticRegistration:
      '自動登録用の専用QRコード。QRコードを使用して自動的に下位の友達になり、さまざまなグループやフォーラムで転送できます。',
    inviteFriendsRecord: '友達の招待履歴',
    copy: 'コピー',
    acquired: '取得済みです',
    toBeAcquired: '待ちます',
    invitePromoImage: '招待プロモーション画像',
    scanToLearnMore: '詳細を確認するためにスキャン',
    myInvitationCode: '私の招待コード：',
    downloadImageUsingScreenshotTool:
      '画像を正常にダウンロードできない場合、スクリーンショットツールを使用して保存してください',
    save: '保存',
    helpCenter: 'ヘルプセンター',
    faq: 'よくある質問',
    inviteFriendsAndPartnershipProgram: '友達を招待してパートナーシッププログラム',
    articles: '記事',
    text_1: 'NTL招待友達のルールの説明',
    text_2: '2023年2月19日に公開され、2023年7月19日に更新されました',
    text_3: '1. 活動規則',
    text_4:
      '1. ntlex.comに友達を招待し、最大10000USD相当の暗号通貨ブラインドボックスを手に入れます。',
    text_5: 'ステップ1：「友達を招待」ページに入り、友達に招待リンクまたは招待コードを共有します。',
    text_6:
      'ステップ2：友達が30日以内にntlex.com APPにログインすると、あなたと友達がそれぞれ1つのブラインドボックスを手に入れます。',
    text_7:
      'ステップ3：友達が30日以内に一度に35ドル以上の暗号通貨をチャージすると、あなたと友達がそれぞれもう1つのブラインドボックスを手に入れます。',
    text_8:
      '暗号通貨をチャージ：他の取引所から、個人ウォレットからntlex.comアカウントに1回の取引で35ドル以上をチャージします。内部振込、紅包、プロモーションリワード、法定通貨の購入などで得た暗号通貨はチャージに含まれません。注意：友達は30日以内にアプリにログインし、1回の入金が35ドル以上を完了しない限り、ブラインドボックスを手に入れることはできません。',
    text_9:
      '2. 各ブラインドボックスには最大10000ドル相当の暗号通貨、BTC、ETH、LTC、DOT、BNBなどの暗号通貨とキャッシュバックカードが含まれます。暗号通貨報酬はブラインドボックスを開いてから1時間以内にあなたの資金口座に入金され、報酬を確認できます。',
    text_10:
      '3. ブラインドボックスの数に上限はありません。友達をさらに招待すると、ブラインドボックスがさらに手に入ります。友達をたくさん招待しましょう！',
    text_11: '4. サブアカウントはこの活動に参加できません',
    text_12: '2. よくある質問',
    text_13: '1. ブラインドボックスが期限切れになりますか？',
    text_14:
      'ブラインドボックスの有効期間は取得時点から開始し、30日間の有効期限があります。たとえば、1月1日の午前0時にブラインドボックスを取得した場合、期限は1月30日の午後11時59分までです。期限切れのブラインドボックスは開けませんので、有効期間内に開けてください。',
    text_15: '2. 驚きのブラインドボックスとは何ですか？',
    text_16:
      '招待された友達がチャージ要件を満たすと、驚きのブラインドボックスを受け取ることができ、報酬金額が2倍になります。',
    text_17: '3. ブラインドボックスの数はどこで確認できますか？',
    text_18:
      '「アプリ - 友達を招待 - 私の招待記録」を通じて、取得したブラインドボックスの合計数と有効期限内で開けられるブラインドボックスの合計数を確認できます。',
    text_19: '4. どれだけ多くのブラインドボックスを取得できますか？',
    text_20:
      'ブラインドボックスの数に上限はありません。友達をさらに招待すれば、さらに多くのブラインドボックスを取得できます。友達をたくさん招待しましょう！',
    text_21: '5. 取得報酬はどこで確認できますか？',
    text_22:
      'ブラインドボックスが解除された後、報酬は「友達を招待のホームページ - 私の報酬」ページで確認できます。',
    text_23: '6. ブラインドボックスを開けた後、どのような報酬を受け取れますか？',
    text_24:
      '各ブラインドボックスには最大10000ドル相当の暗号通貨、BTC、ETH、NTLCなどの暗号通貨とリベートカードが含まれます。ntlex.comは報酬を定期的に更新し、実際のブラインドボックスの報酬に基づいてください。',
    text_25: '7. リベートカードとは何ですか？どのように使用しますか？',
    text_26:
      'リベートカードは有効期間内に手数料を差し引いて取引手数料を支払うために使用できます。リベートカードを受け取った後、取引手数料が発生した翌日にリベートカードから対応額を差し引いて残りのUSDTを資金口座に返金します。使い切るまで使用できます。',
    text_27: '3. 特別な注意事項',
    text_28:
      '1. このプロモーションからブラインドボックス報酬を受け取るには、友達を招待することができます。他のプロモーションを通じて友達を招待してもブラインドボックス報酬は受け取れません。',
    text_29: '2. サブアカウントは招待および報酬の対象外です。',
    text_30:
      '3. ntlex.comはユーザーの招待行動を厳格に審査し、アカウントまたはデバイスに不正な操作が疑われる場合、リスク管理戦略がトリガーされ、報酬の資格を失う可能性があります。重大な不正行為はアカウントの凍結につながる可能性があります。',
    text_31: '4. ntlex.comはプロモーション形式、プロモーション報酬を調整する権利を留保します',
    text2_0: 'NTLコミッションルールの説明',
    text2_1: '2023年2月19日に公開され、2023年7月19日に更新されました',
    text2_2: '一、招待者のリワード：',
    text2_3:
      '新規ユーザー1人がNTLに登録成功するごとに、招待者はNTLでの現物取引または契約取引時に発生するコミッションリワードを受け取ります。同時に、招待者も自身の取引時にキャッシュバックリワードを受け取ることができます。',
    text2_4: '二、招待規則の詳細：',
    text2_5:
      '仲間を招待して登録させるための招待リンクを使用した場合、友達があなたの推薦リンク経由で登録すると、友達はあなたの設定した取引手数料割引を受けることができます',

    text2_6: '現物取引:',
    text2_7:
      '1.招待者のNTLのロック数量が100未満の場合、基本の返金率は50%です。招待者は招待された友達と0〜50%をシェアすることができます。',
    text2_8:
      '2.招待者のNTLのロック数量が100から500の間の場合、基本の返金率は70%に増加します。招待者は招待された友達と0〜70%をシェアすることができます。',
    text2_8_new:
      '3.招待者のNTLのロック数量が500を超える場合、基本の返金率はさらに80%に増加します。招待者は招待された友達と0〜80%をシェアすることができます。',
    text2_9: '先物取引:',
    text2_10:
      '1.招待者のNTLのロック数量が100未満の場合、基本の返金率は50%です。招待者は招待された友達と0〜50%をシェアすることができます。',
    text2_11:
      '2.招待者のNTLのロック数量が100から500の間の場合、基本の返金率は70%に増加します。招待者は招待された友達と0〜70%をシェアすることができます。',
    text2_11_new1:
      '3.招待者のNTLのロック数量が500を超える場合、基本の返金率はさらに80%に増加します。招待者は招待された友達と0〜80%をシェアすることができます。',
    text2_11_new2:
      'ユーザーがより高い返金基準を受け取りたい場合は、NTLプラットフォームのパートナーになることができます。',

    text2_12: '三、コミッションルール説明：',
    text2_13:
      '1.招待されたユーザーは、専用の招待リンク（QRコード、招待ID）を使用して登録し、NTLの現物取引または契約取引を行う必要があり、その際に招待者は取引手数料からコミッションを獲得できます。アカウントIDを直接入力して登録した場合、コミッションまたはキャッシュバックを受けることはできません。',
    text2_14:
      '2.コミッションまたはキャッシュバックは、実際の取引時に発生した手数料通貨で支払われ、毎日（GMT+8）0〜2時に精算と支払いが行われ、総コミッションはUに換算されます。契約取引の後、報酬は現物アカウントに戻ります。；',
    text2_15:
      '3.毎日24時間以内にランダムにNTLC数量をスナップショットして保有ポジションの総額を統計し、NTLC数量は1日の平均数量（前日の終了時と当日の終了時の平均）です。',
    text2_16:
      '4.ベースコミッション率が上昇または下降する場合、比率は翌日に適用され、ベース比率はNTLC保有量に基づいて設定されます。',
    text2_17:
      '5.最終的な解釈権はプラットフォームにあり、システムの予測不可能なバグや規則の欠陥を利用した違反行為は無効と見なされ、プラットフォームは追求権を行使する権利を有します。',
    text2_18: '6.招待されたユーザーの比率',
    text2_19:
      'リスク管理に関する説明： 悪意のある取引量操作、プラットフォームの在庫の大口取引などの行動は、プラットフォームのリスク管理を引き起こし、プラットフォームは違法なユーザーのコミッションまたはキャッシュバックを取り消す権利を有します。',
    transactionType: '取引タイプ',
    inviterUzxcAmount: '招待者のNTL保有数量',
    inviterBaseCommissionRate: '邀请人基础返佣比例',
    inviterReceivedCommissionRate: '招待者の手数料還元率',
    inviteeCashbackRate: '招待された人の手数料還元率',
    spot: '現物',
    contract: '契約',
    accountNumber: 'アカウントです',
    registrationBonus: '登録報酬です',
    depositBonus: 'ご褒美です。',
    custom: 'カスタム',
    notLoggedIn: '未登録です',
    tipText:
      'あなたはプラットフォームのパートナーになりました。より多くの情報は「パートナーシッププログラム」でご確認ください。',
    view: '閲覧する',
  },
  summarize: {
    coinSummary: '通貨概要',
    collapse: '収納',
    expand: '展開',
    officialLinks: '公式リンク',
    officialWebsite: '公式ウェブサイト',
    github: 'GitHub',
    blockExplorer: 'ブロックエクスプローラ',
    socialMedia: 'ソーシャルメディア',
    popularityRank: '人気ランキング',
    marketValueRank: '時価総額ランキング',
    marketValue: '時価総額',
    circulatingSupply: '流通供給',
    addToWatchlistRatio: 'ウォッチリストへの追加比率',
    historicalHighPrice: '歴史的な最高価格',
    historicalHighDate: '歴史的な最高値日',
    historicalLowPrice: '歴史的な最安値',
    historicalLowDate: '歴史的な最低価格日',
    firstIssuanceDate: '初発行日',
    ownedPublicChain: 'オス鎖です',
    initialIssuePrice: '最初の売り出し価格です',
    maximumSupplyMarketValue: '最大供給時価総額です',
    flowRate: '流通率です',
    tokenDistribution: 'トークン分布',
    maxSupply: '最大供給量',
    powMining: 'PoW マイニング',
  },
  NTLDao: {
    welcomeNTL: 'NTL OpenGovへようこそ',
    info: 'ntlex.comはDAOによって駆動されるグローバルな仮想通貨取引所で、安全で安定した取引環境を提供することに専念しています。DAOを通じて、中央集権的な取引所の潜在的なリスクを排除し、ユーザーの信頼性と透明性を向上させることを目指しています。',
    joinWhitelist: 'さんか',
    limitedTimeFree: '期間限定で無料のエアドロップガバナンストークンを受け取る',
    equity: 'NTLガバナンストークンの持ち株分',
    proposalRights: '提案権',
    proposalRightsInfo:
      'ガバナンストークン保有者は、取引所およびエコシステムの開発に関する提案を出す権利を持っています。これには新しい機能の提案、ユーザーエクスペリエンスの向上の提案、市場の拡大の提案などが含まれます。',
    VotingRights: '投票権',
    VotingRightsInfo:
      'ガバナンストークン保有者は投票に参加する権利を持ちます。彼らは取引所の運営やエコシステムの発展に関する重要な事項について投票できます。提案の受け入れまたは拒否、スーパーレプの選挙などが該当します。',
    VIP: '取引VIP権',
    VIPInfo:
      '一定数のガバナンストークンを保有するユーザーは取引VIP権を取得し、低手数料、高い手数料割戻しなどの一連の取引特典を受けることができます。',
    DividendRights: '取引所プロジェクトの配当権',
    DividendRightsInfo:
      'ガバナンストークン保有者は、取引所の利益の配当権を持ちます。これは、取引所の成功から利益を共有することを意味し、プロジェクトの積極的な支援を奨励します。',
    circuitDiagram: 'NTLガバナンストークンロードマップ',
    GovernanceTokens:
      '1年目で95％以上のロック率、2年目で90％のロック率まで、ガバナンストークンの価格が安定して成長することを保証します。',
    SecurityEmpowerment: 'セキュリティの強化',
    smartContracts: 'スマートコントラクト',
    smartContractsInfo:
      'メンバーは公式の契約に拘束されず、共通の目標とネットワークインセンティブによって結ばれます。これらの規則は透明で、オープンソースのソフトウェアに組み込まれています。',
    Timelocks: 'タイムロック',
    TimelocksInfo:
      '資金は特定の時点まで移動できなくなるように設定され、ユーザーに対する追加のセキュリティを提供し、不正なアクティビティに対応するための手段となります。',
    MultiSig: 'マルチシグ（Multi-Sig）ウォレット',
    MultiSigInfo:
      '分散型自治組織（DAO）は取引所の意思決定に使用され、資金管理を含む。DAOメンバーは通常、取引所のユーザーであり、取引所の運営に関する意思決定を行うために投票できます。',
    ProjectRoadmap: 'プロジェクトロードマップ',
    NTLExchange: 'ntlex.com取引所は誰が所有していますか？',
    SharedOwnership: 'NTL DAOメンバーが共同所有！',
    NTLExchangeInfo:
      'Uzxのトークン保有者（Uzx DAOメンバー）は、投票権、提案権、配当権を持っています。ntl dao意思決定委員会は、提案の受け入れや審査を決定するために投票することができます。各メンバーには1票の資格があり、重要な決定には3分の2の投票が必要であり、日常のガバナンス決定には半数以上の投票が必要です。',
    NTLOpenGov: 'NTL OpenGov',
    introduce: '紹介',
    introduceInfo: '分散型、コミュニティガバナンス',
    vote: '投票とガバナンス',
    voteTitle: 'すべての決定はNTL保有者によって行われます',
    voteInfo:
      '私たちは取引所のガバナンスメカニズムとしてDAO（分散型自治組織）を採用します。これは、すべてのトークン保有者が取引所の運営と意思決定に平等な権限を持つことを意味し、公正でオープンで透明なガバナンス環境を確保します。',
    decisionSystem: '意思決定システム',
    decisionSystemInfo:
      '取引所の運営と発展を効果的に管理し導くために、取引所DAO意思決定委員会を構成する20のスーパーレプと7つのジェネシスメンバーから成る委員会を設立します。',
    RepresentativeTerm: '代表任期',
    RepresentativeTermInfo:
      '各スーパーレプの任期は1年に設定されます。スーパーレプの任期が終了すると、コミュニティのNTLトークン保有者は新しいスーパーレプを選出する機会を持ちます。この仕組みは、委員会メンバーの継続的な流動性と民主性を確保します。',
    CandidateRequirements: '候補要件',
    CandidateRequirementsInfo:
      'スーパーレプになる候補者の最低保有トークンロック要件は500万枚のNTLトークン未満に設定されません。これにより、スーパーレプが取引所の長期的な成功に共感するための十分な利益を持っていることが確保されます。',
    ProposalSystem: '提案システム',
    ProposalSystemInfo:
      '私たちはDAOのメンバーが積極的に取引所の発展に関する提案を出すことを奨励しています。これらの提案には新機能の追加、ユーザーエクスペリエンスの向上、市場の拡大、セキュリティの向上など、さまざまな側面が含まれることがあります。',
    construct: '建設を開始',
    becomeMember: '私たちのコミュニティの一員になる',
    becomeMemberInfo: '優れた開発者と連絡を取り、最も活発なコミュニティに参加します',
    whitePaper: 'NTL DAOホワイトペーパー',
    whitePaperInfo: 'NTL分散型ガバナンスを共同創造',
    download: 'ダウンロード',
    modalTitle: '情報を入力し、エアドロップに参加し、DAOの一員になる',
    name: '名前（オプション）',
    email: 'メールアドレス',
    wallet: 'ウォレット(NTLプラットフォーム内のNTLチャージアドレスをお願いします)',
    inputname: '名前を入力してください...',
    inputemail: 'メールアドレスを入力してください...',
    inputwallet: 'NTLプラットフォーム内のNTLチャージアドレスをお願いします',
    days: '日',
    hours: '時間',
    minutes: '分',
    seconds: '秒',
    // "tip1": "アクティブなアドレスのみサポートされます（アドレスがPolygonチェーンまたはEthereumチェーンでトランザクション（送金または受信）を行ったことがあるアドレス、例：UsdtまたはEth、Maticなどを受け取ったアドレス）。",
    tip: 'NTLはあなたのプライバシーを保護し尊重することを約束します。あなたの個人情報はアカウント管理およびリクエストした製品とサービスを提供するためにのみ使用されます。下の「送信」をクリックすると、NTLが提出された個人情報を保存および処理することに同意したものと見なされます。',
    stepTitle1: '50億のガバナンストークンを発行',
    stepTitle2: '4億のトークンをジェネシスメンバーに提供',
    stepTitle3: '1億のトークンをスーパーレプに提供',
    stepTitle4: '1億のトークンの取引報酬',
    stepTitle5: '取引所運用に3000万のトークンを保有',
    stepTitle6: 'NTLの成長基金が2000万のトークンを保有',
    stepInfo1:
      'NTL DAOはNTLと呼ばれるガバナンストークンを発行し、合計50億枚のトークンを発行します。初期価格は0.0416USDTです。',
    stepInfo2:
      '4億枚のNTLトークンは7人のジェネシスメンバーに分配され、それぞれ2,000万枚ずつ配布され、発行後に2年間ロックされます。',
    stepInfo3:
      '2億1000万枚のNTLトークンは、3か月ごとに2,100万枚ずつスーパーレプの2人に分配され、発行後1年間ロックされます。',
    stepInfo4:
      'ネットワイドな報酬総額は1億枚のNTLガバナンストークンで、取引所でのユーザーの日次手数料に対する1％の割合で同期的に発行されます。ユーザーが受け取る報酬トークンは即時に取引できます。',
    stepInfo5:
      '取引所の運用基金アカウントは3,000万枚のNTLトークンを保有し、取引所の初期の日常運用費用、市場活動、優れた才能への報酬などに使用されます。市場活動関係者に支給される報酬にはロック要件はありません。',
    stepInfo6:
      'NTL DAOの成長基金は2,000万枚のNTLトークンを保有し、NTL.networkの技術開発およびコミュニティの構築に使用されます。',
    NTLToken: 'NTLトークン',
    NTLTokenInfo:
      'NTLガバナンストークンはプラットフォームのデジタル通貨だけでなく、ガバナンスの中核トークンでもあります。これにより、世界中の知恵を結集し、取引所の中核競争力を高めることができます。',
    objectTitle1: 'インフラストラクチャの構築',
    objectTitle2: 'プラットフォームの立ち上げと拡張',
    objectTitle3: '持続的な改善と発展',
    objectTime1: '(2023年初 - 2023年末)',
    objectTime2: '(2024年 - 2025年)',
    objectTime3: '(2026年以降)',
    objectInfo1: 'プラットフォームの構築',
    objectInfo2: 'トークンの発行',
    objectInfo3: 'セキュリティ審査',
    objectInfo4: 'コミュニティの対話と透明性',
    objectInfo5: '取引所の上場',
    objectInfo6: 'クロスチェーンブリッジ',
    objectInfo7: 'コミュニティの構築',
    objectInfo8: '技術のアップグレード',
    objectInfo9: 'グローバル展開',
    objectInfo10: 'NTL.networkパブリックチェーンの構築',
    objectInfo11: 'コミュニティガバナンス',
    emailCode: 'メールボックス認証コード',
    inputemailCode: 'メールボックス認証コードを入力してください',
    getCode: '認証コードの取得',
    reGetCode: '再送信',
    certificate1: 'ケイマン諸島の',
    certificate2: '会社登記証明書',
  },
  tradingDrawer: {
    tradeMarketInfo: '取引/市場情報',
    orderCenter: '注文センター',
    tutorials: 'チュートリアル',
    beginnerTutorials: '初心者向けチュートリアル',
    layout: 'レイアウト',
    optionContractBilling: 'オプション契約請求',
    coinBasedContractBilling: 'コインベース契約請求',
    perpetualContractBilling: '秒契約請求',
    usdtBasedContractBilling: 'Uベース契約請求',
    spotBilling: '現物取引請求',
    openOrders: '未約定注文',
    historicalOrders: '注文履歷',
    chart: 'チャート',
    orderTableLatestTrades: '注文テーブル、最新取引',
    market: '市場',
    orderAssets: '注文、資産',
    positionOrdersAssets: 'ポジション、注文、資産',
    quickBuySell: '高速売買',
    pastResultsRealTimeQuotes: '過去の結果、リアルタイムの引用',
    current: '現在',
    trade: '取引',
    history: '履歴',
  },
  price: {
    USDT_M: 'Uベース契約',
    COIN_M: 'コインベース契約',
    secondFuture: '秒契約',
    options: 'オプション契約',
    '24hVolume': '24時間の取引量',
    '24hTurnover': '24時間の売上高',
    MarketCap: '時価総額',
    NTLRanking: 'NTLランキング',
    Overview: '概要',
    Details: '詳細',
    MainstreamCoins: '主要コイン',
    PopularList: '人気リスト',
    ChangeRanking: '変動ランキング',
    NewCoinsList: '新規コインリスト',
    TradingVolumeRanking: '取引量ランキング',
    More: 'もっと見る',
    addOptional: 'ウォッチリストに追加',
    NTLCurrencyQuotation: 'NTL通貨の市況',
    understand: '最新のデジタル通貨の価格、変動、時価総額、取引量などを理解する。',
  },
  transitionpage: {
    transtr:
      'アップグレードおよびメンテナンス中です。PC版をご利用いただくか、後で再試行してください。',
    open: '続行',
    cancel: '戻る',
  },
  aboutUs: {
    paragraph1:
      'ntlex.com（Nano Token Liquidity）は、分散型自治組織（DAO）ガバナンスに基づく国際的なデジタルアセットデリバティブ取引所で、世界中のユーザーに安全で安定した、便利で公平で信頼性のあるブロックチェーン資産取引サービスを提供することを目的としています。',
    paragraph2:
      'NTL DAOは、ケイマン財団に管理を委任し、このガバナンス構造は従来の仮想通貨取引所よりも優れており、取引所の公正性とユーザーアセットの安全性を効果的に保証しています。管理チームのメンバーは世界各国と地域から集まり、主要メンバーはCoinbase、Binance、Morgan Stanley、Amazon、Googleなどの世界的に有名な金融およびテクノロジー企業での経験を持ち、強力なグローバル戦略運営能力と豊富な金融リスク管理および経営経験を有しています。',
    paragraph3:
      'ntlex.comは、トークン取引、永続契約、現物取引、オプション、NFT、資産管理と貸出など、暗号資産のワンストップサービスを提供し、中国語、英語、日本語、韓国語など、多言語に対応しています。テクノロジーソリューションは、Amazon AWSおよびGoogle Cloudなどをベースにしており、DDOSなどの攻撃を効果的に防ぎ、動的および静的データの分離、ホットデータキャッシュ、災害復旧の確立、パラレル拡張などをサポートしています。業界トップのテクノロジーアーキテクチャがユーザーの安全な取引を保証し、即時のブロックチェーンアセットへのアクセス、多通貨アドレス共有テクノロジー、多重セキュリティ認証が備わっています。また、ntlex.comは業界内で低いブロックチェーンアセット取引手数料と引き出し手数料を提供しています。    ',
    paragraph4:
      '信頼できるDAOガバナンス取引所として、ntlex.comはユーザーの取引情報の安全性とアセットの安全性を包括的に保護し、優れた取引体験を提供することに専念しています。',
    paragraph5:
      '1.デジタルアセットには重大なリスクが関連しており、市場取引には多くの不確実要因が存在します。また、デジタル通貨の総市場価値が一般的に低く、価格は操縦者の制御や世界中の政府の政策の影響を受けて大幅な変動を示すことがあります。価格の変動により、大きな利益または損失が発生する可能性があります。任意のデジタルアセットは大幅な変動を経験し、無価値になる可能性があります。',
    paragraph6:
      '2.各国の法律、規制、規範文書の制定または変更により、デジタルアセット取引がいつでも一時停止または禁止される可能性があります。NTLサービスを使用する際には、現地の法律、規制、政策文書を考慮し、リスクを評価する必要があります。',
    paragraph7:
      '3.ユーザーはデジタルアセット取引に参加する際に、自身の財務状況を考慮し、デジタルアセット投資の価値とリスクを慎重に評価し、リスクを自己制御し、投資全体を失う可能性のある経済的リスクを負う必要があります。',
    email: 'メールアドレス',
    service: 'カスタマーサービス',
    support: 'テクニカルサポート',
    application: '上場申請',
    suggestions: '苦情と提案',
    Cooperation: 'ビジネスコラボレーション',
    media: 'ソーシャルメディア',
    aboutUs: '私たちについて',
    ContactUs: 'お問い合わせ',
  },
  aboutNTL: {
    service: 'サービス規約',
    Privacy: 'プライバシーポリシー',
    Disclaimer: '免責事項',
    report: '告発通知',
    Guidelines: '執行要請ガイド',
    report1:
      '「当社グループ各実体は、誠実さと倫理的な最良の実践と原則（賄賂防止および反汚職コンプライアンス基準を含む）に基づいて業務を遂行しており、賄賂および汚職に対してゼロトレランスの方針を採用しています。',
    report2:
      '当社グループの従業員、顧問、関連者、またはその関連者と主張されたいかなる人物が、お客様から賄賂を要求したり、そのような行動を疑っている場合、ただちに当社グループに通報し、関連情報をすべて CS@ntlex.com に送信してください。',
    report3:
      '当社グループは、情報提供者と提供された情報を厳密に機密に保持し、次のような多重の保護を提供します：',
    report4:
      '1.お客様が当社グループの従業員またはその関連者、またはそのような人物の関連者に不当な利益や好都合を提供したかどうかに関係なく、お客様が情報を自発的に提供し、状況を説明する場合、当社グループは実際の状況に応じて判断し、お客様とのビジネス協力を続行することができます。お客様が上記の規定に違反した場合、当社グループは追及しないことがあります。    ',
    report5:
      '2.当社に提供された関連の商業賄賂行為に関する情報が確認されると、当社グループは情報提供者に対して報奨金を提供します。」 ',
    Guidelines1: '最新の更新日：2023年09月01日',
    Guidelines2: '政府機関および執行機関専用：',
    Guidelines3: '1.イントロダクション',
    Guidelines4:
      '弊社の利用規約に記載されている通り、ntlex.comのサービスは以下の主体によって提供されています：',
    Guidelines5:
      'NTLEX.COM（以下、「会社」と称します）は、開曼諸島法に基づき設立された開曼諸島に登録された企業で、当社はWebサイトhttp://ntlex.com（以下、「本ウェブサイト」または「ウェブサイト」と称します）を運営しており、このウェブサイトはユーザーにデジタルアセット取引と関連サービス（以下、「サービス」と称します）を提供するプラットフォームです。この契約を簡単に表現するために、「私たち」または「当社」の言葉を使用して、会社とウェブサイトを本契約で結合させています。',
    Guidelines6:
      '必要な場合、私たちは権限を持つ執行機関の要請に応じます。私たちは関連するサービス規約、プライバシーポリシー、適用法令に基づいて、各ケースごとに要請を検討し、協力します。本執行要請ガイドでは、権限を持つ執行機関が当社と連絡を取り、お客様の情報を要求し、またはNTLアカウントを凍結する方法について説明しています。国際的な執行に関与する場合、国際的な執行機関は情報を開示するために公式な司法相互協力協定の要請を提供する必要があります。',
    Guidelines7: '2.執行要請',
    Guidelines8:
      '私たちは、すべての司法管轄区からの執行要請に対応する責任があります。 執行機関である場合、執行に関連するすべての要請について、次の電子メールアドレスにご連絡いただくことをお勧めします：CS@ntlex.com',
    Guidelines9: '3.必要な情報',
    Guidelines10:
      '要請を処理できるようにするために、執行機関は、政府のドメインを含む公式の電子メールアドレスからメールを送信し、少なくとも次の情報を提供する必要があります：',
    Guidelines11: '執行機関の正式名称',
    Guidelines12: '承認された文書の執行要請、裁判所の命令、または召喚状',
    Guidelines13: 'お客様の公式な連絡先情報（電子メールアドレス、電話番号）',
    Guidelines14:
      '必要であれば、次の文書を提供していただければ、要請の処理を迅速化できるかもしれません：(i) 情報提供者が情報要請を行う権限の証明（認可証明など）、および彼らの職務を示す執行機関の証明（ii） 執行機関の正体を明らかにする身分証明書（ID番号のあるID写真および内部ID番号のある内部IDカード写真）の証明書。執行機関は、私たちが要請を適切に処理できるように、要請内容を明確に説明し、要請の適切な処理を可能にする十分な詳細情報を提供することが求められます。',
    Guidelines15: '4.緊急執行要請',
    Guidelines16:
      '非常に緊急の要請、たとえば死亡または重大な身体的危険がある場合は、電子メールの件名に緊急要請と明示してください。',
    Disclaimer1: 'リスク注意事項',
    Disclaimer2:
      '1.デジタル資産には重大なリスクが伴います。市場取引は不確実性の要因が多く、また、デジタル通貨の総市場価値が一般的に少ないため、価格は操縦者の制御や各国政府の政策に影響を受けて大幅な変動をすることがあります。価格の変動により、大きな利益または損失が生じる可能性があります。どんなデジタル資産も大幅な変動が発生し、時にはまったく価値を持たなくなることさえあります。',
    Disclaimer3:
      '2.各国の法律、規制、規範文書の策定または変更により、デジタル資産の取引が中断されたり、禁止される可能性があります。NTLサービスを利用する際は、現地の法律や規制、政策文書を評価し、リスクを自己判断してください。',
    Disclaimer4:
      '3.デジタル資産取引に参加する際は、自身の財務状況に基づいて、デジタル資産の投資価値とリスクを注意深く評価し、取引リスクを自己管理し、投資全体の経済的リスクを負担する必要があります。',
    Disclaimer5: '法的免責事項',
    Disclaimer6: '一．権利の帰属',
    Disclaimer7:
      'NTL内のすべての製品、技術、ソフトウェア、プログラム、データ、およびその他の情報（テキスト、アイコン、画像、写真、音声、ビデオ、図表、色の組み合わせ、レイアウトデザインなどを含む）は、NTLサービスプロバイダーおよび/または関連会社の所有物であり、著作権、商標権、特許権、営業秘密、その他の関連権利を含むすべての権利を保有しています。NTLサービスプロバイダーおよび/または関連会社の許可なしに、機械、スパイダーなどのプログラムや装置を使用して、NTL内のコンテンツを監視、コピー、配布、表示、ミラーリング、アップロード、ダウンロードなどの方法で無断で使用することはできません。NTLのテキスト、ロゴ、その他の商標、ロゴ、製品名、サービス名は、NTLサービスプロバイダーおよび/または関連会社の商標であり、広告、表示などの使用が必要な場合は、事前にNTLサービスプロバイダーおよび/または関連会社から書面での許可を取得する必要があります。',
    Disclaimer8: '二．責任の制限',
    Disclaimer9:
      'NTLはデジタル資産と派生商品の取引を行うためのオンライン取引プラットフォームを提供する「ネットワークサービスプロバイダー」として、ウェブサイトプラットフォーム上の情報とサービスがユーザーの要求を充分に満たすことを保証しません。NTLが転載する作品（ユーザーコメントを含む）は、さらに多くの情報を提供するために掲載されており、その内容を支持しているわけではなく、その内容が真実であることを証明しているわけではありません。',
    Disclaimer10:
      '1.ユーザーは理解し、同意します。NTLのサービスは、現在の技術と条件に基づいて提供されています。NTLは、ユーザーに対し、サービスの連続性と安全性を確保し、最善の努力を払います。しかし、NTLは法的、技術的、およびその他のリスク、不可抗力、ウイルス、トロイの木馬、ハッカー攻撃、システムの不安定、第三者サービスの欠陥、政府の行動など、予測および予防ができないリスクに随時さらされており、これらの理由によるサービスの中断、データの損失、およびその他の損害やリスクが発生する可能性があります。以下の状況では、NTLは正常に機能しないため、ユーザーはいかなる損害賠償責任も免れることに同意します。これらの状況には、以下が含まれますが、これに限定されません：',
    Disclaimer11: '1）. NTLプラットフォームの公告によるシステムの停止およびメンテナンス期間。',
    Disclaimer12: '2）. 電信機器の故障によりデータ転送ができない場合。',
    Disclaimer13:
      '3）. 台風、地震、津波、洪水、停電、戦争、テロ攻撃などの不可抗力の要因により、NTLプラットフォームのシステムが正常に動作できない場合。',
    Disclaimer14:
      '4）. ハッカー攻撃、コンピューターウイルスの侵入または発生、電信部門の技術調整または故障、ウェブサイトのアップグレード、銀行に関する問題、政府による一時的なクローズなどにより、通常のネットワーク運用に影響を及ぼし、サービスが中断または遅延した場合。',
    Disclaimer15: '5）. 既存の技術リソースでは予測または解決できない技術の問題による損失。',
    Disclaimer16: '6）. 第三者の過失または遅延により、ユーザーまたは他の第三者に損害を与える場合。',
    Disclaimer17:
      'システムの障害、ネットワークの問題、DDosなどのハッカー攻撃などの予期しない要因により、異常な取引、市場の中断、およびその他の異常な状況が発生する可能性がある場合、NTLは実際の状況に基づいて異常な取引結果をキャンセルし、特定の期間のすべての取引をロールバックする権利を有します。    ',
    Disclaimer18:
      '2.ユーザーは、NTLサービスを使用する際に取引のリスクを十分に理解し、以下の事項についていかなる場合にもNTLに対するいかなる損害賠償責任も免除することに同意します：',
    Disclaimer19: '1）. 収益の損失。',
    Disclaimer20: '2）. 取引の利益または契約の損失。',
    Disclaimer21: '3）. ビジネスの中断。',
    Disclaimer22: '4）. 期待される通貨の節約の損失。',
    Disclaimer23: '5）. 情報の損失。',
    Disclaimer24: '6）. 機会、信用、または評判の損失。',
    Disclaimer25: '7）. データの損傷または損失。',
    Disclaimer26: '8）. 代替製品またはサービスの購入コスト。',
    Disclaimer27: '9）. デジタルアセットの消滅または市場からの撤退。',
  },
  // 交易费率
  TransactionRate: {
    TransactionRate: '取引手数料',
    VIPgrade: 'VIP レベル:',
    holdingAmount: '昨日の平均 NTL 保有量',
    turnover: '30日間の取引高',
    info: 'NTL は保有量と取引量に基づいて異なるレベルに分かれています。異なるレベルは翌日の取引手数料を決定します。 ',
    transaction: '取引',
    withdrawal: '入金と出金 ',
    title1: 'レベル',
    title2: '平均 NTL 保有量 ',
    title3: '30日間の取引高',
    title4: 'Maker/Taker',
    title5: '手数料割引',
    title6: 'フルネーム ',
    title7: 'メインネット ',
    title8: '最小引き出し数量',
    title9: '入金手数料',
    title10: '引き出し手数料',
    searchCoin: '通貨の検索',
    searchLevel: '検索レベル',
    free: '無料',
    noFind: '見つかりませんでした',
  },
  // 模拟交易
  mockTrading: {
    demoTrading: '仮想取引',
    demoTradText: '安全な取引、リスクゼロ',
    realTransaction: '実際のトランザクション',
    exitSimulation: '模擬取引からは退出されました',
  },
  // uzxLab
  uzxLab: {
    topText:
      'NTL LABは、巨大な潜在力を持つブロックチェーンプロジェクトの発掘と投資に取り組んでいます。初期ファンド規模は500万ドルで、ブロックチェーン分野の高品質なイノベーションの発見と支援に焦点を当てています。',
    aboutUs: '私たちについてです',
    meText1:
      'ファンドは主にブロックチェーン領域のプロジェクトに投資し、基礎となるインフラ、Layer2ソリューション、分散型金融（DeFi）、Web 3.0、非同質トークン（NFT）、およびメタバースなどの領域に焦点を当てています。NTL LABはこれらの領域でプロジェクトの発掘と資金調達に焦点を当てることを目指しています。',
    meText2:
      'NTL LABは、ブロックチェーンセクターで最も先端の技術革新プロジェクトの探索と投資に専念しており、世界のブロックチェーン基盤技術の発展を促進することを目指しています。私たちは夢と実行力に満ちたブロックチェーン起業家と共に成長し、未来の可能性を共同で探求します。同時に、グローバルなリソースと豊富な業界経験を惜しみなく共有し、このダイナミックな分野で起業家が成功する手助けをします。',
    direction: '投資方針',
    dcTitle1: '基盤構築カテゴリ',
    dcText1:
      'Layer2ソリューション、技術的なパフォーマンス向上、効率向上、およびスケーラビリティの問題の解決',
    dcTitle2: '取引および金融プロジェクト',
    dcText2: 'デリバティブ/Dex/レンディング/合成資産/オラクル/保険',
    dcTitle3: '人気のあるパブリックブロックチェーンエコシステムプロジェクト',
    dcText3:
      'Polkadotエコシステム/Solanaエコシステム/Nearエコシステム/Polygonエコシステム/Avalancheエコシステム',
    dcTitle4: 'アプリケーションカテゴリ',
    dcText4: 'ブロックチェーンブラウザ/ウォレット/NFT/GameFi/SocialFi',
    dcTitle5: 'Web3.0ツールカテゴリ',
    dcText5: 'データプロトコル/技術サービスプロバイダ/プラグイン/プライバシー保護',
    support: 'プロジェクトへのサポート',
    haiwai: '海外リソース',
    haiwaiText:
      '我々は起業家に対して、中国本土、シリコンバレー、日本、韓国、東南アジアなど、主要な市場を網羅した広範な世界リソースへのアクセスを提供しています。これらの地域のメディアやKOL（Key Opinion Leaders）と密接なグローバルリソースネットワークを構築し、起業家に対して多角的なサポートを提供しています。製品やサービスの確認または改善、価値ある戦略的なパートナーシップの構築、潜在的な顧客層との連携、投資家の引き寄せ、潜在的な買収先の見つけ方、またはビジネスアドバイザーとの関与など、様々な面で鍵となるサポートとガイダンスを提供できます。我々の目標は、起業家が世界中で視野を広げ、ビジネス目標を達成するお手伝いをすることです。',
    shengtai: 'エコロジカルリソース',
    shengtaiText:
      '私たちは、深く関与し、投資しているプロジェクトに対して取引プラットフォームがエコロジカルなリソースサポートを提供することを推進しています。これらのプロジェクトは関連するプラットフォームに優先的に推薦されます。一旦承認されると、取引所が提供する各種の製品サービス、宣伝リソース、ユーザートラフィック、およびブランドサポートを無料で享受する機会があります。各プロジェクトの独自の特性に基づいて詳細なプロモーションプランを立て、プラットフォーム上での初期段階からプロジェクトの優位性を包括的に展示します。これによりプラットフォームユーザーの惹起だけでなく、世界中の広範なブロックチェーン業界のユーザーグループを対象とします。',
    fazhan: '発展の提案',
    fazhanText:
      '私たちの使命は明確でシンプルです：起業家が真に強力な企業を築く手助けをすることです。私たちにとって、起業家の成功は私たちの成功です。相互の信頼が成功の礎であることを深く理解しています。したがって、私たちは仕事の中で常に誠実かつ透明な原則を持ち、起業家と共に成功に歩んでいくことを約束します。',
    lianxi: '連絡してきます',
  },
  official: {
    officialChannel: '公式の認証チャネル。',
    officialChannel2: '非公式の認証チャネル。',
    ocDoc:
      '資産の損失を招くフィッシングやオンライン詐欺を避けるために、このページでメールアドレス、またはX、TelegramなどのソーシャルアカウントがNTLの公式認証チャネルであるかどうかを確認できます。 ',
    ocPlaceholder: '確認のために正しい情報と形式を入力してください。',
    ocCheck: '検証',
    notPass: 'あなたが問い合わせた【{0}】は、NTLの公式チャネルではありません。',
    tips: '入力した情報とフォーマットが正しいかどうかを確認してください： ',
    tips2: 'Telegram/X：@xxxxx（@記号付き） ',
    tips3: 'Telegramグループ：https://xxxxx（ウェブサイトリンク）',
    doc: '*アカウント情報やパスワードを他人に開示しないでください。',
    doc2: '*詐欺に注意し、慎重に操作してください。 ',
  },
  country: {
    cou1: `アメリカ`,
    cou2: `日本`,
    cou3: `ドイツ`,
    cou4: `イギリス`,
    cou5: `インド`,
    cou6: `フランス`,
    cou7: `イタリア`,
    cou8: `カナダ`,
    cou9: `韓国`,
    cou10: `ロシア`,
    cou11: `オーストラリア`,
    cou12: `ブラジル`,
    cou13: `香港`,
    cou14: `マカオ`,
    cou15: `台湾`,
    cou16: `スペイン`,
    cou17: `メキシコ`,
    cou18: `インドネシア`,
    cou19: `オランダ`,
    cou20: `スイス`,
    cou21: `サウジアラビア`,
    cou22: `トルコ`,
    cou23: `イラン`,
    cou24: `ポーランド`,
    cou25: `スウェーデン`,
    cou26: `ベルギー`,
    cou27: `タイ`,
    cou28: `ナイジェリア`,
    cou29: `オーストリア`,
    cou30: `アイルランド`,
    cou31: `イスラエル`,
    cou32: `ノルウェー`,
    cou33: `アルゼンチン`,
    cou34: `フィリピン`,
    ou35: `アラブ`,
    cou36: `エジプト`,
    cou37: `デンマーク`,
    cou38: `マレーシア`,
    cou39: `シンガポール`,
    cou40: `ベトナム`,
    cou41: `バングラデシュ`,
    cou42: `南アフリカ`,
    cou43: `チリ`,
    cou44: `フィンランド`,
    cou45: `コロンビア`,
    cou46: `ルーマニア`,
    cou47: `ポルトガル`,
    cou48: `ニュージーランド`,
    cou49: `ギリシャ`,
    cou50: `カタール`,
    cou51: `ベラルーシ`,
  },

  authentication_title: '認証',
  authentication_has: '認証済み',
  authentication_no: '未認証',
  authentication_real_name: '本名',
  authentication_real_name_placeholder: '本名を入力してください',
  authentication_real_name_error: '正しい本名を入力してください',
  authentication_real_type: '書類の種類',
  authentication_real_card: '書類番号',
  authentication_real_card_placeholder: '書類番号を入力してください',
  authentication_real_card_error: '正しい書類番号を入力してください',
  authentication_card_image: '書類の写真',
  authentication_front_image: '書類の表面',
  authentication_reverse_image: '書類の裏面',
  authentication_hand_image: '手持ち書類の写真',
  authentication_card_image_error: '正しい書類の写真をアップロードしてください',
  authentication_submit: '送信',
  authentication_real_type_0: '身分証明書',
  authentication_real_type_1: 'パスポート',
  authentication_real_type_2: '運転免許証',

  bind_phone: '電話番号のバインディング',
  bind_phone_tip: '電話番号を入力してください',
  bind_phone_no: 'バインドされていません',
  bind_phone_yes: 'バインドされました',
  bind_phone_number_error: '電話番号を入力してください',
  bind_phone_code_error: '電話の確認コードを入力してください',
  bind_phone_pwd: 'ログインパスワード',
  bind_phone_pwd_tip: 'ログインパスワードを入力してください',
};
