module.exports = {
  index: {
    circulation: 'BHB 安全与发展基金流通量',
    hourEth: '今日待分配收入累计折合',
    yesterdayEth: '昨日分配收入累计折合',
    yesterday: '昨日挖矿产出',
    bhbTotal: 'BHB 总流通量',
    bhbSecond: 'BHB二级市场流通量',
    checkMineprinciple: '查看挖矿原理',
    checkFlowVolum: '检查流通量说明',
    checkLockPosition: '查看锁仓情况',
    BHBregister: 'NTL账户注册',
    tibi: '提币到账时间及限额',
    line_plane: 'BHB上线计划、流通量及手续费返还公告',
    fenpeijizhi: '关于NTL收入分配机制的说明',
    jiangli_jihua: '邀请挖矿奖励计划',
    friend_fanhuan: '邀请好友、赚取额外手续费返还',
    NTL: 'NTLex.com',
    recommendInfo: '推荐使用应用程序访问，获得更好的体验',
    download: '即刻获取',
  },
  transpage: {
    gw: '官网',
    h5: 'Web3 交易系统',
    down: '直接下载',
    down1: '应用商店下载',
    file: '文件',
  },
  myfriends: {
    myhy: '我的好友',
    firsthy: '一级好友',
    active: '已激活',
    people: '人',
    unactive: '未激活',
    sehy: '二级好友',
    tx: '头像',
    nickname: '昵称',
    createtime: '注册时间',
    status: '状态',
    action: '操作',
    charTime: '充币时间',
  },
  Launchpad: {
    focuses: '专注于挑选高质量区块链新兴项目，其目标是协助用户成为早期数字货币投资者',
    ckwdzc: '查看我的资产',
    yssxm: '已上线项目',
    ggb: '狗狗币对不同人而言意味着不同的含义。本质来讲，狗狗币是一场偶然发起的给人们带来快乐的加密货币运动！狗狗币是一种运用区块链技术的开源分布式数字货币 , 通过运用由计算机节点组成的网络进行记账实现了一个高度安全的去中心化的系统。',
    LINK: 'ChainLink是智能合约 （Smart Contract）新的希望。ChainLink于2017年设计，其创造者是史蒂夫·埃利斯（Steve Ellis）、阿里·尤尔斯（Ari Juels）和谢尔盖·纳扎罗夫（Sergey Nazarov）。ChainLink是预言机的去中心化交易网络。 ChainLink将区块链智能合约连接到现实世界的数据、活动和支付行为。',
    AVAX: 'Avalanche致力于在不影响速度或去中心化的前提下提升可扩展性。其核心平台有三条区块链：交易链（X链）、合约链（C链）及平台链（P链）。X链用于创建和交易资产，C链用于创建智能合约，P链用于协调验证者和子网。',
    SOL: 'SOL是Solana的原生效用代币，Solana网络将其作为通货紧缩模式的一部分进行销毁。在转账或与智能合约交互时，用户需要以SOL支付手续费。SOL持有者也可以成为网络验证者。与以太坊相同，Solana支持开发者构建智能合约并基于区块链创建项目。',
    DOT: 'Polkadot被称为“为全人类打造的开源协议”，自称是“区块链技术发展的下一站”。Polkadot概念最初由以太坊联合创始人Gavin Wood博士提出。开发团队希望重点研究区块链的安全性、可扩展性与创新性。为此，必须创建一套支持新的想法与理念，同时保障互操作性的基础架构。',
    ATMO: 'Cosmos 是一个致力于将独立区块链网络连接在一起的平台。该平台促进了不同区块链之间的数据传输，以促进所谓的“区块链互联网”。ATOM 是 Cosmos 网络的原生代币，用于交易支付、治理投票和抵押以保护网络。',
    jr: '加入',
    jrnsdz:
      '假如您所打造的区块链项目充满创新和独特潜能，您可以通过以下链接提交申请。NTL Launchpad，你的区块链资产发行平台！',
    ljsq: '立即申请',
    cjwt: '常见问题',
    rhcy: '我如何参与',
    text1:
      '首先，您需要创建NTL个人账户或登录已有账户；您需要持有一定数量的NTL进行质押后即可参与活动。在您登录账号后，可以在平台内找到【Launchpad】并进入活动主页，进行信息查看与详情阅读，仅需轻点鼠标/屏幕，即可完成持有NTL的质押或解押操作。',
    text2:
      'NTL Launchpad目前提供两种活动类型：新币挖矿和打折销售。新币挖矿模式允许用户通过质押NTL来获得项目发行的代币奖励，每一期的活动都有不同的质押周期和总质押限制，质押的数量越多，获得的代币奖励也就越多。打折销售模式采用了“预约认购+抽签”的方式来分发代币。整个流程包括持仓统计、预约认购、奖励计算以及奖励分发等步骤。值得注意的是，在这个过程中，NTL是唯一被官方接受用于参与活动认购的代币。需要强调的是，新币挖矿和打折销售活动并不适用于部分受限制的国家/地区用户，具体能够参与活动的用户请以NTL发布的活动信息为准。',
    ynxhd: '有哪些活动类型?',
    gfwz: '官方网站',
    gw: '官网',
    bps: '白皮书',
    sjmt: '社交媒体',
    jianjie: '简介',

    ggbxq:
      '狗狗币对不同人而言意味着不同的含义。本质来讲，狗狗币是一场偶然发起的给人们带来快乐的加密货币运动！',
    ggbxq2:
      '狗狗币是一种运用区块链技术的开源分布式数字货币 , 通过运用由计算机节点组成的网络进行记账实现了一个高度安全的去中心化的系统。',
    ggbxq3:
      '更重要的，狗狗币所蕴含的精神，如狗狗币宣言中所言，是一个由你我一样友善的人组成的令人惊叹、充满活力的社区！',
    ggbxq4:
      '狗狗币社区致力于互帮互助、友善、普及加密货币、寻求筹款支持、有趣、形成信仰以及将荒谬变成现实。狗狗币的非官方口号很好地概括了这些：每日正气。',
    ggbxq5:
      '狗狗币社区期盼着狗狗币能发展成为传统法定货币的可行且合法的替代者。为此，狗狗币生态将以动态和有机的方式不断扩展，致力于成为被人们广泛使用的货币，一切都是为了人民。同时，狗狗币也将牢记自己的初心，做最有趣的加密货币！',
    ggbxq6: '狗狗币的核心精神是实用、优雅、温暖和值得信赖，非常开心能在这里与你相遇。',

    LINKxq1:
      'ChainLink是智能合约 （Smart Contract）新的希望。ChainLink于2017年设计，其创造者是史蒂夫·埃利斯（Steve Ellis）、阿里·尤尔斯（Ari Juels）和谢尔盖·纳扎罗夫（Sergey Nazarov）。ChainLink是预言机的去中心化交易网络。 ChainLink将区块链智能合约连接到现实世界的数据、活动和支付行为。',
    LINKxq2: '为了保证交易可靠性及安全性，ChainLink提供合理的解决方案，从而提高交易的安全程度。',
    LINKxq3:
      'ChainLink平台让其交易商充分发挥智能合约演算法的潜力，并方便用来进行大量多次的输入和输出。',
    LINKxq4:
      '不过，该系统的主要特点是运行的可靠性。 智能合约需求与现实世界信息的连接，因此这种合约可以建立区块链系统以外的协议。 在这种过程中， 智能合约无法避免预言机的使用（预言机作为链上和链下数据之间的中介者）。',
    LINKxq5:
      '在使用集中式预言机时，用户可能会遇到安全风险，因为集中式预言机缺乏可靠性的保证。 这种情况是ChainLink发挥作用的最好机会。',
    LINKxq6:
      'ChainLink可以对来自许多来源的数据进行验证和协调，以保护用户避免不可信任预言机的攻击。',
    LINKxq7:
      '除此之外，ChainLink还具有嵌入式信誉的系统，该系统可以为用户的交易提供最合适的预言机。',
    LINKxq8:
      'ChainLink的原生代币名称是“ LINK”。“ LINK”是一种ERC-20代币，具有附加的ERC223«转移和呼叫»的功能（地址、uint256、字节），这种功能让智能合约在一次交易中进行代币的接收和处理。',
    LINKxq9:
      'LINK用于支付ChainLink平台服务的手续费。 ChainLink节点运营商可以将持有的LINK代币加入该网络，从而形成网络的股票。 这种方法对名誉性质合约有影响（ reputation contract）：股票数越大，运营商接收的请求量就越多。',

    AVAX1:
      'Avalanche致力于在不影响速度或去中心化的前提下提升可扩展性。其核心平台有三条区块链：交易链（X链）、合约链（C链）及平台链（P链）。X链用于创建和交易资产，C链用于创建智能合约，P链用于协调验证者和子网。',
    AVAX2:
      '该协议最重要的突破之一是Avalanche共识，验证者使用重复的子采样投票方式，来达成快速且能否负担得起的共识。Avalanche还使用子网作为横向扩展的新方法，允许创建可定制、可互操作的区块链。可能的子网数量不受限制。',
    AVAX3: 'Avalanche主要解决三大问题：可扩展性、交易手续费以及互操作性。',
    AVAX4: '可扩展性与去中心化水平对比',
    AVAX5:
      '长期以来，传统区块链项目一直在苦苦寻求可扩展性与去中心化的平衡点。如果网络上的活动不断增加，可能很快就会拥堵。比特币(BTC)就是一项典型示例：在网络拥塞时期，用户完成一笔交易需要耗费数小时、甚至数周时间。',
    AVAX6:
      '解决这项问题的一种方法是提高网络的中心化水平，赋予一部分人更高的权限来验证网络活动，实现更快的速度。然而，去中心化对于区块链安全性至关重要。新区块链尝试通过技术改进解决问题，Avalanche也给出了独特的方案，具体内容将在下文介绍。',
    AVAX7: '高额费用',
    AVAX8:
      '以太坊等大型区块链的另一个常见问题是燃料费，可能会随流量的推高而增加。这最终将打击用户使用这些区块链的积极性，但竞争对手的生态系统也仍不成熟。例如，以太坊的热度不减，并且强有力的竞争对手一直未出现，这就导致了拥塞的网络以及高昂的交易费。在某段时间，以太坊网络普通单笔转账的手续费超过10美元。若涉及复杂的智能合约，费用甚至可能超过100美元。',
    AVAX9: '互操作性',
    AVAX10:
      '不同项目和业务对区块链平台的需求不同。此前，区块链项目只能在以太坊、无法按需求调整的独立区块链或私链之间进行选择。在可定制化与多链协作之间寻找平衡点，一直是区块链行业的难题。Avalanche的解决方案是子网，它与主网拥有相同的安全性、速度与兼容性。',

    SOL1: 'SOL是Solana的原生效用代币，Solana网络将其作为通货紧缩模式的一部分进行销毁。在转账或与智能合约交互时，用户需要以SOL支付手续费。SOL持有者也可以成为网络验证者。与以太坊相同，Solana支持开发者构建智能合约并基于区块链创建项目。',
    SOL2: 'SOL使用SPL协议；SPL是Solana区块链的代币标准，类似于以太坊中的ERC20。SOL代币有两大主要用例：',
    SOL3: '1.支付使用网络或智能合约时产生的交易费用。',
    SOL4: '作为权益证明共识机制的一部分，充当质押代币。',
    SOL5: '基于Solana构建的去中心化应用程序(DApp)创建了SOL和使用SPL标准构建的其他代币的新用例。',
    SOL6: '不过，该系统的主要特点是运行的可靠性。 智能合约需求与现实世界信息的连接，因此这种合约可以建立区块链系统以外的协议。 在这种过程中， 智能合约无法避免预言机的使用（预言机作为链上和链下数据之间的中介者）。',
    SOL7: '在使用集中式预言机时，用户可能会遇到安全风险，因为集中式预言机缺乏可靠性的保证。 这种情况是ChainLink发挥作用的最好机会。',
    SOL8: 'ChainLink可以对来自许多来源的数据进行验证和协调，以保护用户避免不可信任预言机的攻击。',
    SOL9: '除此之外，ChainLink还具有嵌入式信誉的系统，该系统可以为用户的交易提供最合适的预言机。',
    SOL10:
      '自2020年推出主网测试版以来，Solana生态系统得到了巨大的发展。截止到2022年12月，有21,255个Github开发人员存储库使用Solana，服务800万活跃用户。',
    SOL11:
      '传统经济巨头纷纷宣布入局Solana。其中包括Discord（用户个人主页可链接Solana钱包）和ASICS（用户可使用Solana Pay支付系统购买限量版鞋子设计）。Solana凭借快速交易和高吞吐量已成为多个Web3用例的首选网络。',

    DOT1: 'Polkadot被称为“为全人类打造的开源协议”，自称是“区块链技术发展的下一站”。Polkadot概念最初由以太坊联合创始人Gavin Wood博士提出。开发团队希望重点研究区块链的安全性、可扩展性与创新性。为此，必须创建一套支持新的想法与理念，同时保障互操作性的基础架构。',
    DOT2: 'Polkadot生态系统内的单条区块链称为平行链（平行区块链），主链称为“中继链”，二者始终可以方便快捷地交换信息。平行链类似于以太坊2.0计划实施方案中的独立分片。',
    DOT3: '开发者、企业或个人均可通过Substrate——创建加密货币和去中心化系统的框架来定制平行链。定制化区块链与Polkadot网络相连后，即可与其他平行链互操作。',
    DOT4: '通过上述设计方案，构建跨链应用程序、产品及服务变得更加简单。在此之前，大规模跨链转移数据或资产均难以实现。',
    DOT5: '平行链的数据安全与验证工作由网络验证者完成，一小部分验证者即可保障多条平行链的安全。验证者也负责保障交易信息可在多条平行链间顺畅传递，提高网络的可扩展性。',
    DOT6: '对于开发者而言，Polkadot生态系统具有诸多优势。当前区块链的局限性产生了一些核心问题，其中包括：可扩展性、定制化、互操作性、网络治理与可升级性。',
    DOT7: '在可扩展性方面，Polkadot解决了诸多问题。作为一种多链网络，它支持多条独立区块链并行传输，扫清了区块链技术领域的一大障碍。并行处理是区块链领域的重大突破，它将为区块链的全球应用铺平道路。',
    DOT8: '需要定制区块链的用户可以深入挖掘Polkadot的其他功能。目前，满足所有项目需求的“万能”区块链基础架构并不存在。每个项目均有独特需求，而Polkadot经过优化设计，支持独立区块链实现个性化功能。在Substrate的支持下，开发者可根据项目需求有效调整其独立区块链。',
    DOT9: '保证项目与应用程序无缝衔接，实现数据共享是提升互操作性的重要手段。虽然尚不清楚最终问世的会是哪些产品与服务，但潜在用例的确很多。Polkadot有能力打造一套全新的金融生态系统，每条平行链只需实现某种特定功能。',
    DOT10:
      '特定平行链可由相关社区按照专属方式独立运营管理。此外，每个社区都是整个Polkadot网络的组成部分，对Polkadot未来的治理方式产生重大影响。从社区搜集的反馈将为Polkadot项目的发展提供宝贵意见。',
    DOT11:
      '此外，Polkadot支持平行链升级，过程极其简单。原生链避开了可能导致社区分裂的硬分叉，而是通过无摩擦方式顺利升级。',

    ATMO1:
      'Cosmos 是一个致力于将独立区块链网络连接在一起的平台。该平台促进了不同区块链之间的数据传输，以促进所谓的“区块链互联网”。ATOM 是 Cosmos 网络的原生代币，用于交易支付、治理投票和抵押以保护网络。',
    ATMO2:
      'Cosmos 自身并不是区块链，而是设计应用与企业的专用区块链 （称为 Zone） 的基础设施。如果每条区块链都必须从零开始实现所有联网和共识代码，要构建由许多区块链组成世界将是不现实的，因此 Cosmos 提供了可处理这些功能的模板软件 Cosmos SDK。',
    ATMO3:
      'Cosmos 对 SDK 的多年开发工作，已经使得启动 Zone 就像部署智能合约一样容易。但是这种方法并不是 Cosmos 独有的，其他结合应用专属区块链想法的项目也为开发者提供了 「盒装区块链」 ，例如，Polkadot 的 Substrate 框架，类似于 Cosmos 的 SDK。',
    ATMO4:
      'Tendermint 是 Cosmos 网络底层的共识算法，它被构建为一个高性能的 BFT SMR（状态机复制）平台，可以复制服务，然后可以将这些服务模拟为确定性的、非随机的状态机。创建 Tendermint Core 是为了复制专门的服务器（或状态）。这是通过一台特殊的机器来实现的，该机器复制服务器并将它们传播到全球 Cosmos 网络。这个过程允许来自 Tendermint Core 的软件工程师在世界各地的状态机上复制区块链。',
    ATMO5:
      'Tendermint 的重要技术能力允许开发人员创建自己的区块链平台，而无需从头开始构建一切。这很有用途，因为它允许用户创建他们想要的任何类型的区块链系统，几乎所有的东西都已经为他们完成，除了他们的应用程序逻辑和代币。托管在 Cosmos 网络上的代币包括：Kava (KAVA)、Terra (LUNA)、Band Protocol (BAND)、Aragon (ANT) 和 Akash Network (AKASH)。',
    ATMO6:
      'ATOM代币在 Tendermint Core BFT 共识机制的功能中也发挥着关键作用，因为 Cosmos Hub 是一个权益证明区块链平台。Cosmos 依靠 100 个验证者节点网络来维持共识、安全性和运营效率。为了使该系统正常运行，用户必须抵押他们的 ATOM 币。',
    ATMO7:
      '验证器的作用是运行一个完整的节点，它验证网络规则并向网络广播投票，随后将新块提交到区块链。反过来，验证者根据作为抵押品抵押的 ATOM 代币的数量以 ATOM 的形式获得收入。',
  },
  AssetManagement: {
    Currency: '存币',
    AssetsAccount: '资产账户',
    analyze: {
      Date: '日期',
      SelectDate: '选择日期',
      Currency: '货币',
      Balance: '余额',
      Today: '今日盈亏',
      CurrentAssets: '当前资产',
      initialAssets: '今日初始资产',
      netInflowAssets: '今日净划入资产',
      MonthlyProfit: '本月盈亏',
      Accumulated: '本月第一个自然日00:00至今日00:00的累计盈亏',
      HistoricalPL: '历史盈亏',
      UpdatedAt: '更新于',
      returnRate: '累计收益率',
      CumulativeReturn: '累计收益',
      InitialAssets: '期初资产',
      NetInflowAssets: '当日净划入资产',
      netTransferOfAssets: '净划入资产',
      CumulativeProfitlos: '累计收益',
      Endingassets: '期末资产',
      Initialassets: '期初资产',
      DailyEarnings: '每日收益',
      AssetsAt: '当日结束时资产',
      oftheDay: '当日初始资产',
      distribution: '资产分布',
      AccountSumma: '账户汇总各类资产的数量和占比，及其折合本地货币的价值',
      lzkyl: '累计盈亏率',
      lzbtcqs: '累计BTC趋势',
      lzky: '累计盈亏',
      mryk: '每日盈亏',
      zcfb: '资产分布',
    },
    TotalAssets: {
      equity: '您的权益目前为 ',
      Deposit: '充值数字货币',
      TotalAssets: '总资产',
      Today: '今日盈亏',
      Day: '天',
      Month: '个月',
      HalfYear: '半年',
      classific: '资产分类',
      Spot: '现货',
      zd: '账单',
      Fund: '资金动态',
      ViewAll: '查看全部',
      Recent: '最近充提币记录',
      Coin: '币种',
      Depositamount: '充值数量',
      Time: '时间',
      NoData: '暂无数据',
      SearchAssets: '搜索资产',
      hide0: '隐藏为0的币种',
    },
  },
  newmsg: {
    GeneralView: '总览',
    SafetyCenter: '安全中心',
    setting: '偏好设置',
    GeneralList: {
      nh: '您好',
      Hide: '隐藏个人信息',
      Show: '显示个人信息',
      tradingFeeTier: '手续费等级',
      Email: '邮箱',
      YouCurrently: '您当前还没有资产，入金开启交易之旅',
      Invite: '邀请好友，有机会享受终身返佣',
      Deposit: '充币',
      Support: '支持数字货币之间的划转',
      transfer: '划转',
      SupportsTransfers: '支持数字货币之间的划转',
      Market: '行情',
      Announ: '公告',
      More: '更多',
      Invitation: '邀请有礼',
      Partner: '合伙计划 ',
      InviteFriends: '邀请好友，最高有机会享受终身返佣',
      OpenTo: '面向KOL，有机会成为平台合伙人',
      InviteNow: '立即邀请',
      ApplyNow: '立即申请',
    },
    CenterList: {
      SecurityCenter: '安全中心',
      SecuritySettings: '安全设置',
      Anonymous: '匿名用户可通过绑定邮箱找回账号',
      NotBound: '未绑定',
      AlreadyBound: '已绑定',
      LoginBassword: '登录密码',
      Device: '设备管理',
      Authenticated: '已认证设备',
      Recent: '最近登录时间',
      LoginLocation: '登录所在地',
      address: 'IP地址',
      Actions: '操作',
      CurrentDevice: '当前设备',
      Recent10: '最近十条登录记录',
      Location: '所在地',
      AccountManagement: '账号管理',
      DeactivateAccount: '注销账户',
      userTransaction: '用户交易、提现等验证，请谨慎设置并牢记',
      LogOut: '注销',
      DoYouWant: '是否删除当前设备信息?',
      AreYousure: '您确定要注销此账号吗?',
      AreYousure2: '该操作将不可逆！',
      his: '历史记录',
      loc: '地点',
      IP: 'IP',
      datat: '日期和时间',
    },
    settingList: {
      PreferenceSettings: '偏好设置',
      Language: '语言',
      QuoteCurrency: '计价货币',
    },
  },
  common: {
    FindUs: '发现我们的产品和服务',
    registeredAccount: '注册NTL账户',
    unlock: '解锁NTL！体验匿名的力量',
    invitation: '邀请好友加入NTL',
    closeTheDeal: '完成首次交易',
    sloganthree: '欢迎来到NTL!',
    slogantwo: '探索NTL独特宇宙',
    slogantwo2: '机制透明，安全无忧',
    currencySelection: '表格',
    chart: '图表',
    details: '详情',
    entrust: '委托',
    login: '登录',
    register: '注册',
    loginregister: '登录/注册',
    signupnow: '注册',
    indexplaceholde: '邮箱',
    logout: '退出登录',
    tip: '温馨提示',
    logintip: '请先登录',
    expect: '敬请期待!',
    delete: '确定删除吗?',
    nodata: '没有记录',
    set: '设置',
    update: '修改',
    slogan: '全球前50的数字货币交易所',
    subslogan: '国家机构担保资产安全',
    more: '查看更多',
    please: '请先',
    pleaseselect: '请选择',
    searchplaceholder: '请输入币种名称',
    searchplaceholderswap: '输入合约名称搜索',
    loading: '加载中...',
    close: '关闭',
    ok: '确定',
    fillComplete: '请填写完整的信息',
    inputpleaseselect: '请输入',
    showDetail: '查看详情',
    pleaseSelectTransferAccountFirst: '请先选择转入账户',
  },
  financeNav: {
    cjspan1:
      'NTL DAO 通过授权开曼基金会管理 NTLex.com，该治理结构比传统加密货币交易所更具优势，有效保证了交易所的公平公正及用户资产安全。管理团队成员来自全球多个国家和地区，核心骨干曾就职于Coinbase、Binance、MorganStanley、Amazon、Google等全球知名金融及科技企业，具有强大的全球战略运营能力和丰富的金融风险控制和管理经验。',
    cjspan2:
      'NTLex.com (Nano Token Liquidity) 是一家基于分散式自治组织（DAO）治理的国际化数字资产衍生品交易所。',
    cjspan3: 'NTL可以为您提供',
    cjspan4: '·多种交易模式，如币币交易、U本位合约交易、币本位合约交易、期权合约交易、秒合约交易。',
    cjspan5: '·通过NTL平台推出的存币生息，让手中的闲置资产变得有价值。',
    cjspan6: '·支持用户匿名登录，保护用户的隐私。',
    cjspan7:
      'NTL的技术方案基于 Amazon AWS 与 Google Cloud 等，能有效防止 DDOS 等攻击，支持动态和静态数据分离，热点数据缓存，异地容灾，平行扩展等功能。全球领先的技术架构保障了用户安全交易，并且拥有即时区块链资产存取功能，多币种地址共享技术，多重安全验证保护。同时，NTLex.com 拥有业内较低的区块链资产交易费用和提取费用。',
    Enhanced: '提升用户交易体验，保护用户资产安全',
    WhatIs: '什么是NTL DAO？',
    WhatServices: 'NTL提供哪些服务？',
    IsNTL: 'NTL值得用户信赖吗？',
    WatchTutorials: '观看教程，掌握更多数字货币知识',
    BeginYour: '开启你的交易之旅',
    SignUp: '立即注册',
    ExploreThe: '玩转NTL宇宙 交易随时随地',
    Deposit: '充值数字货币',
    wdzc: '我的资产',
    zcgl: '资产管理',
    tzzx: '通知中心',
    cl: '查看',
    czszhb: '充值数字货币',
    rmzc: '热门资产',
    HotRank: '热门榜',
    NewCoinRank: '新币榜',
    PriceGainRank: '涨幅榜',
    CoinBase: '币本位、U本位合约，安全交易保障。',
    Simplified: '简易化期权合约,数字资产衍生品，实现交易多元化',
    SpotContract: '秒合约',
    Experience: '体验刺激的交易时刻',
    MakeMoney: '利用闲置资产，轻松赚币',
  },
  header: {
    Exploration: '探索',
    SecureAndStable: '安全稳定交易环境',
    HowToGovern: '如何治理',
    Alldecisions: '所有决定均有代币持有者做出',
    UserCommunity: '用户社区',
    SharedGrowth: '共享成长',
    CryptoConvert: '获取佣金',
    InviteMates: '邀请好友',
    SiteUpdates: '成为平台合伙人',
    PartnerProgram: '合伙计划',
    NTL_SecureGuard: '查看全部奖励',
    RewardHub: '奖励中心',
    potential: '发掘潜力项目',
    ExploreMore: '更多',
    NTL_Connect: '加入NTL社群，实时互动',
    CoinCommunity: '客服',
    CryptoTradeInsights: '全面了解数字货币交易',
    HelpHub: '帮助中心',
    Aid: '帮助',
    RookieHub: '新手社区',
    ProjectProspect: '发现潜力新项目',
    AssetValor: '用闲置资产创造价值',
    EarningsEdge: '存币生息',
    cunru: '存入',
    SpotGoods: '现货',
    AssetsOverview: '资产总览',
    UCon: 'U本位合约',
    StableTrust: '稳定信任的智选',
    CoinCon: '币本位合约',
    TrendMaster: '稳抓数字趋势，智慧投资',
    OptionC: '期权合约',
    EasyOptions: '简易期权，交易只需几步',
    QuickOptions: '秒合约',
    ManageMoneyAffairs: '理财',
    AssetAnalysis: '资产分析',
    InstaWave: '秒市场波动',
    OrderCenter: '订单中心',
    gjhgpt1: '行情',
    gjhgpt2: '市场',
    gjhgpt3: '数字货币最新行情',
    gjhgpt4: '机会',
    gjhgpt5: '币种榜单，发觉机会',
    gjhgpt6: '一键完成交易',
    gjhgpt7: '支持多币种，轻松完成交易',
    index: '首页',
    exchange: '币币',
    trade: '交易',
    sq: '社群',
    otc: '法币OTC',
    kefu: '帮助中心',
    xiaoxi: '消息',
    login: '登录',
    register: '注册',
    asset: '财务中心',
    otcOrder: '订单',
    ucenter: '账户中心',
    service: '公告',
    downloadapp: '扫码进行下载安装',
    appdownlaod: 'APP下载',
    usercenter: '个人中心',
    helpcenter: '帮助中心',
    aboutus: '关于',
    partner: '合伙人',
    bzb: 'BZB',
    assetmanage: '资金管理',
    trademanage: '交易管理',
    activity: '最新活动',
    lab: '创新实验室',
    ctc: '法币C2C',
    labdetail: 'NTL实验室  ●  共享创新成果',
    invite: '推广合伙人',
    whitepaper: '白皮书',
    scandownload: '扫一扫下载APP',
    innovationmanage: '创新管理',
    swap1: 'U本位合约',
    swap2: '币本位合约',
    swap3: '差价合约',
    swap4: '秒合约',
    znum: '总数量',
    cexchange: '闪兑',
    option: '期权合约',
    second: '秒合约',
    news: '资讯',
    swapExchange: '合约交易',
    finance: '金融业务',
    fiatCurrency: '法币交易',
  },
  sectionPage: {
    global_leader: '全球领先数字货币交易所',
    Professional: '专业交易，操作简单，让你轻松了解数字交易',
    ptaqTitle: '平台安全',
    ptaqContent: '采用哈希算法加密，全球领先的安全认证',
    ptslTitle: '平台实力',
    ptslContent: '全球最大的比特币交易平台',
    newsTitle: '多终端APP',
    brandTitle: '关于NTL',
    brandTitle1: '国际化数字资产衍生品交易所',
    brandTitle2: '一站式加密资产服务',
    brandDetail: '诚实 | 公平 | 热情 | 开放',
    brandDesc1:
      '团队成员来自全球多个国家和地区，核心骨干成员曾就职于摩根士丹利、IBM、Google、Amazon、微软、腾讯、币安等全球知名金融及科技企业。',
    brandDesc2:
      '团队成员参与过多家国际性数字货币交易所的组建和运营工作。拥有强大的全球战略运营能力，以及丰富的金融风险控制和管理经验。',
    brandDesc3:
      'NTL 致力于做一家值得用户信赖的安全、稳定、便捷、公平、有信誉的区块链资产国际化交易所，全面保障用户交易信息安全和资产安全。',
    brandDesc4:
      'NTL 目前拥有币币、永续合约、现货、期权、理财借贷等一站式加密资产服务，支持中/英/日/韩/等多种语言版本。',
    brandDesc5:
      'NTL的技术方案基于Google Cloud与Amazon AWS ,有效防止DDOS等攻击，支持动态和静态数据分离；支持热点数据缓存，支持异地容灾，支持平行扩展，全球领先的技术架构保障了用户安全交易。',
    brandDesc6:
      'NTL拥有即时区块链资产存取功能，多币种地址共享技术，多重安全验证保护，同时拥有业内较低的区块链资产交易费用和提取费用。',
    gettingstart: '新手入门 | 极速买币',
    officialstart: '新手导航',
    oneminutebuy: '币币交易',
    oneminutebuytips: '虚拟币和虚拟币之间的兑换交易，支持多种热门币种。',
    baseknow: '永续合约',
    baseknowtips:
      'U本位、币本位永续合约支持双向持仓，最高可100倍杠杆，您可以通过做多做空放大收益。',
    baseexchange: '期权交易',
    baseexchangetips: '数字资产衍生品，实现交易多元化。',
    usersocial: '闪兑',
    usersocialtips: '币币闪兑，一键完成交易',
    mainboard: '主板交易区',
    preview: '上一页',
    nextpage: '下一页',
    downloadwhite: '下载白皮书(或在白皮书上鼠标右键 -> "另存为...")',
    showMore: '查看更多',
  },
  nav: {
    ptjy: '平台交易',
    ptaq: '平台安全',
    ptsl: '平台实力',
    xwzx: '多终端APP',
    fabu: '发布广告',
    shouye: '首页',
    jiaoyi: '法币交易',
    dingdan: '订单管理',
    zijin: '财务中心',
    yonghu: '用户中心',
    bibi: '币币交易',
  },
  progress: {
    already: '已挖矿',
    total: '可挖矿总量60亿 BHB',
  },
  service: {
    USDT: 'USDT',
    BTC: 'BTC',
    ETH: 'ETH',
    CUSTOM: '自选',
    COIN: '币种',
    favor: '收藏',
    symbol: '交易对',
    NewPrice: '最新价',
    ExchangeNum: '交易数量',
    FeePercent: '资金费率',
    Change: '24h涨跌',
    OpenPrice: '开盘价',
    PriceTrend: '价格趋势',
    Operate: '操作',
    Exchange: '去交易',
    trading: '立即交易',
    high: '24h最高价',
    low: '24h最低价',
    Mainstream: '主流币',
    HotList: '热门榜',
    PriceIncrease: '涨幅榜',
    SGDLeaderboard: '新币榜',
    TurnoverList: '成交额榜',
  },
  exchange: {
    loginBuy: '登录后买入',
    loginOut: '登录后卖出',
    coin: '币种',
    symbol: '交易对',
    lastprice: '最新价',
    daychange: '24h涨跌',
    market: '市场',
    favorite: '收藏',
    do_favorite: '已收藏',
    cancel_favorite: '取消收藏',
    type: '类型',
    dealamount: '成交金额',
    high: '高',
    low: '低',
    vol: '24H量',
    buy: '买入',
    sell: '卖出',
    limited_price: '限价',
    market_price: '市价',
    fees_rate: '费率',
    balance: '资产',
    or: '或',
    starttrade: '开始交易',
    canuse: '可用',
    recharge: '充币',
    buyprice: '买入价',
    buynum: '买入量',
    amount: '金额',
    totalAmount: '委托总额',
    buyin: '买入',
    disablebuyin: '禁止市价买入',
    buytip: '以市场上最优价格买入',
    sellprice: '卖出价',
    sellnum: '卖出量',
    sellout: '卖出',
    disablesellout: '禁止市价卖出',
    selltip: '以市场上最优价格卖出',
    curdelegation: '当前委托',
    hisdelegation: '委托历史',
    realtransaction: '实时成交',
    num: '数量',
    price: '价格',
    direction: '方向',
    time: '时间',
    stall: '档位',
    total: '累计挂单',
    traded: '已成交',
    action: '操作',
    undo: '撤单',
    delegationnum: '委托量',
    done: '已成交',
    status: '状态',
    finished: '已完成',
    canceled: '已取消',
    parted: '部分成交',
    tip: '提示',
    buyamounttip: '请输入买入量',
    buyamounttipwarning: '买入数量不能高于',
    success: '提交成功',
    pricetip: '请输入交易额',
    pricetipwarning: '交易额不能高于',
    sellamounttip: '请输入卖出量',
    sellamounttipwarning: '卖出数量不能高于',
    sellpricetip: '请输入卖出价格',
    sellpricetipwarning: '卖出价格不能高于',
    withdrawalPrompt: '撤单提醒',
    undotip: '是否确认撤单？',
    cancelsuccess: '撤单成功！',
    marketprice: '市价',
    expand: {
      time: '时间',
      price: '价格',
      amount: '数量',
      fee: '手续费',
    },
    realtime: '分时',
    kline: 'K线图',
    depth: '深度图',
    publishstate0: '活动即将开始，请稍候...',
    publishstate1: '活动即将结束，请尽快交易...',
    publishstate2: '活动订单盘整中，请稍候...',
    publishstate3: '盘整结束，请自由交易...',
    publishdetail: '活动规则详情',
    publishprice: '发行价',
    publishamount: '总量',
    publishcounttxt0: '距活动开始还剩',
    publishcounttxt1: '距离活动结束还剩',
    publishcounttxt2: '距离清盘结束还剩',
    publishcounttxt3: '活动已结束',
    dateTimeDay: '天',
    dateTimeDay2: '天',
    dateTimeHour: '时',
    dateTimeMinutes: '分',
    dateTimeSeconds: '秒',
    moredetail: '更多详情',
    coinSymbol: '交易币种',
    cycle: '周期',
    closeprice: '平仓价格',
    openprice: '开仓价格',
    win: '成功',
    lose: '失败',
    tied: '平局',
    earn: '盈利',
    orderType: '包赔',
    orderTypeNo: '否',
    orderTypeYes: '是',
    purchasable: '可买',
    vendibility: '可卖',
    swapassets: '账户权益',
    Frozen: '冻结',
    toBeReleased: '待释放',
    sellMost: '最多能卖{0}个',
    lessThanMinimum: '可用余额小于最小交易额',
    beAboutToStart: '即将开始',
    listingTime: '上线时间',
    countdown: '开盘倒计时',
    newCoin: '新币',
  },
  swap: {
    tradedTime: '成交时间',
    trade_tradedVolume: '成交量',
    trade_tradeFee: '成交手续费',
    contract: '合约',
    swapindex: '永续',
    coin: '币种',
    symbol: '交易对',
    volume: '成交量',
    lastprice: '最新价',
    daychange: '24H涨跌',
    market: '市场',
    favorite: '收藏',
    do_favorite: '已收藏',
    cancel_favorite: '取消收藏',
    type: '类型',
    dealamount: '成交金额',
    high: '高',
    low: '低',
    vol: '24H量',
    buy: '买入',
    sell: '卖出',
    limited_price: '限价委托',
    market_price: '市价委托',
    spot_price: '止盈止损',
    fees_rate: '费率',
    balance: '资产',
    or: '或',
    starttrade: '开始交易',
    canuse: '可用',
    recharge: '充币',
    buyprice: '买入价',
    buynum: '买入量',
    amount: '交易额',
    buyin: '买入',
    disablebuyin: '禁止市价买入',
    buytip: '以市场上最优价格买入',
    sellprice: '卖出价',
    sellnum: '卖出量',
    sellout: '卖出',
    disablesellout: '禁止市价卖出',
    selltip: '以市场上最优价格卖出',
    curdelegation: '当前委托',
    hisdelegation: '历史委托',
    TotalBill: '总账单',
    histrade: '成交明细',
    realtransaction: '实时成交',
    num: '数量',
    price: '价格',
    direction: '方向',
    time: '时间',
    stall: '档位',
    total: '累计',
    traded: '已成交',
    action: '操作',
    undo: '撤单',
    delegationnum: '委托量',
    done: '已成交',
    status: '状态',
    finished: '已完成',
    canceled: '已取消',
    parted: '部分成交',
    tip: '提示',
    buyamounttip: '请输入买入量',
    buyamounttipwarning: '买入数量不能高于',
    success: '提交成功',
    pricetip: '请输入交易额',
    pricetipwarning: '交易额不能高于',
    sellamounttip: '请输入卖出量',
    sellamounttipwarning: '卖出数量不能高于',
    sellpricetip: '请输入卖出价格',
    sellpricetipwarning: '卖出价格不能高于',
    undotip: '是否确认撤单？',
    cancelsuccess: '撤单成功！',
    marketprice: '市价',
    expand: {
      time: '时间',
      price: '价格',
      amount: '数量',
      fee: '手续费',
    },
    realtime: '分时',
    kline: 'K线图',
    depth: '深度图',
    open: '开仓',
    close: '平仓',
    latestdeal: '最新交易',
    myswapaccount: '我的合约账户',
    accountmode: '账户模式',
    accountmargin: '合约倍数',
    accountquanyi: '账户总权益',
    profitandloss: '未实现盈亏',
    principalAmount: '可用保证金',
    positionAmount: '仓位保证金',
    frozenAmount: '冻结保证金',
    principalRate: '资金利用率',
    zijinhuazhuan: '资金划转',
    zichanfenxi: '资产分析',
    up: ' 多',
    down: ' 空',
    canup: '可开多',
    candown: '可开空',
    cancloseup: '空仓可平',
    canclosedown: '多仓可平',
    openup: '买入开多',
    opendown: '卖出开空',
    closeup: '买入平空',
    closedown: '卖出平多',
    currentposition: '当前持仓',
    assetsdetail: '资金明细',
    marginMode1: '逐仓',
    marginMode2: '全仓',
    modifyMarginModeTitle: '修改账户模式',
    modifyLeverage: '调整合约倍数',
    moredetail: '更多详情',
    shareNumber: '张',
    entrustprice: '委托价',
    triggleprice: '触发价',
    openvolume: '开仓数量',
    closevolume: '平仓数量',
    triggleplaceholder: '默认委托价为市价',
    marketpriceplaceholder: '以市场最优价成交',
    levmaxtip: '合约倍数不能高于最高倍数',
    levmintip: '合约倍数不能低于最低倍数',
    marginModeSuccessTip: '合约账户模式修改成功',
    noenoughbalance: '账户保证金不足',
    pleaseinputopenvolume: '开仓数量必须大于0',
    pleaseinputcorrectopenvolume: '请输入正确的开仓数量',
    pleaseinputrightentrustprice: '请输入正确的委托价格',
    noenoughposition: '可平仓位不足',
    pleaseinputclosevolume: '平仓数量必须大于0',
    pleaseinputcorrectclosevolume: '请输入正确的平仓数量',
    pos_Pl: '收益',
    pos_Ratio: '收益率',
    pos_pos: '持仓量',
    pos_canClose: '可平量',
    pos_openPrice: '开仓均价',
    pos_qp: '强平价',
    pos_leverage: '合约倍数',
    pos_pattern: '合约类型',
    pos_margin: '当前保证金',
    pos_mRatio: '保证金率',
    pos_cmRatio: '维持保证金率',
    pos_long: '多头',
    pos_short: '空头',
    ent_entrustType: '开仓/平仓',
    ent_direction: '方向',
    ent_type: '委托类型',
    ent_trigglePrice: '触发价',
    ent_entrustPrice: '委托价',
    ent_tradedPrice: '成交价',
    ent_margin: '保证金',
    ent_volume: '委托数量',
    ent_status: '委托状态',
    ent_status1: '委托中',
    ent_status2: '已撤销',
    ent_status3: '委托失败',
    ent_status4: '委托成功',
    ent_statusblast: '爆仓',
    ent_pl: '结算盈亏',
    ent_fee: '手续费',
    triggerpriceshouldbigger: '触发价必须大于0',
    charts: '图表',
    currencyOverview: '币种概括',
    orderBook: '订单表',
    latestTrades: '最新成交',
    marketBuy: '市价买入',
    marketSell: '市价卖出',
    quantityInLots: '数量（张）',
    inputQuantity: '输入数量',
    SpotBill: '现货账单',
    UBill: 'U本位合约账单',
    CoinBill: '币本位合约账单',
    SecondBill: '秒合约账单',
    FinancialBill: '金融账单',
    stopProfit: '止盈',
    stopLoss: '止损',
    orderForm: {
      graphicDescription: '图示说明',
      case1: '案例1：',
      l_text1:
        '限价委托是指用户设置委托数量以及可接受的最高买价或最低卖价，当市场价格符合用户预期时，系统会以限价范围内的最优价格进行成交。',
      l_text2:
        '当前BTC市场价格为53,000 USDT，如果您想要在更便宜的52,900 USDT买入，可选择限价委托，设定买入价格52,900，下单后，当价格下跌到小于等于52,900 USDT时委托单会自动成交。',
      m_text1: '市价委托是指用户可以当前市场上以最优价格进行快速交易，并立即成交。',
      m_text2: '当前BTC市场价格为53,000 USDT，买入和卖出的单将在53,000 USDT左右成交。',
      ts_text1:
        '止盈止损委托是一种交易策略，您可以预先设置触发价格和委托价格，在市场价格达到触发价时，系统将自动触发交易。',
      ts_text2:
        '假设您有开仓均价53,000 USDT的订单，期望在市场下跌到51,000 USDT时卖出平多来止损，则可以按照以下参数填写：',
      ts_text3: '卖出时，建议低于触发价格，以便立即成交，或不填写默认市价委托。',
      ts_text4:
        '价格下跌至51,000 USDT，则触发止损，以50,000 USDT委托平多。若委托价为市价，则以市价快速卖出平仓。',
      ts_text5: '如果您想平多止盈，则应在高于53,000 USDT的触发价位预设卖出平多的委托。',
      cfjg: '触发价格：',
      wtjg: '委托价格：',
      wtjg2: '委托价格',
      wtjg3: '委托价格',
      // 全|逐仓弹窗
      cp_text1: '调整保证金模式仅对当前交易标的生效',
      cp_text2: '什么是全仓和逐仓保证金模式？',
      cp_text3: '逐仓保证金模式：',
      cp_text4:
        '指的是仓位的保证金与交易者的账户余额是独立分开的。在这种保证金模式下，交易者可以自由决定所用杠杆倍数。如果仓位被强制平仓，交易者所需承担的最大损失即仓位保证金。',
      cp_text5: '全仓保证金模式：',
      cp_text6:
        '是 NTL 的默认保证金模式。全仓保证金模式指的是使用相对应币种里所有的可用余额作为仓位保证金来维持仓位，避免强平。在这种保证金模式下，当资产净值不足以满足维持保证金需求时，强制平仓将被触发。如果仓位被强制平仓，交易者将损失所对应币种里所有的资产。',
      warmReminder: '温馨提示',
      wr_text1:
        '删委托单止盈止损并非实际存在的止盈止损单，需委托成交后才可发起。下单委托成交后，默认将按照您的实际成交数量全部挂止盈止损单。如果您撤销挂单委托，预设止盈止损单将同时失效。',
      wr_text2:
        '若市场波动剧烈，触发止盈/止损时，实际成交价可能与时刻的价格存在差异。止盈/止损订单也存在无法成交或无法完全成交的可能。',
    },
    orderConfirmation: '下单确认',
    tradeAfterLogin: '登录后交易',
    experienceBonus: '体验金',
    selection: '选择体验金',
    trialWenxin_text1: '1.体验金仅用于永续合约-市价委托，且须在有效期内使用。',
    trialWenxin_text2:
      '2.交易时优先使用体验金。盈利部分可提现，体验金将自动销毁；亏损部分优先扣除体验金，若超出体验金将扣除余额。',
    trialWenxin_text3: '3.平仓时优先平仓体验金。',
    zyj: '止盈价',
    zsj: '止损价',
    leverage: '杠杆',
    marketIsFlat: '市价全平',
    marketIsFlatTips: '您确定按市价全部平仓吗？',
  },
  second: {
    balance: '余额',
    profitability: '盈利率',
    opentime: '开仓时间',
    transactionMode: '交易模式',
    currentPosition: '当前委托',
    tiaojie: '市场调节费',
    countdown: '倒计时',
    purchaseQuantity: '购买数量',
    quantity: '数量',
    SpotAccount: '现货账户',
    ULocalCurrencyAccount: 'U本位合约',
    CurrencylocalCurrency: '币本位合约',
    secondContractAccount: '秒合约账户',
    actualPurchase: '实际购买',
    fee: '手续费',
  },
  ctc: {
    title: '一键买卖 ● 平台托管 ● 安全放心',
    desc: '由NTL平台托管数字资产，保障用户资产安全',
    buyin: '买入',
    sell: '卖出',
    buyprice: '买入价',
    sellprice: '卖出价',
    buynum: '买入量',
    sellnum: '卖出量',
    payamount: '结算总额',
    getamount: '结算总额',
    avabalance: '可卖数量',
    time: '交易时间',
    tip: '提示',
    notice: '温馨提示',
    notice1:
      '1. 法币交易区是用户与承兑商之间资产交易，资金不经转平台，平台也不接受人民币充值/汇款；',
    notice2:
      '2. 法币交易承兑商均通过实名认证，提供交易保证金，并且代币均由平台托管，您可以放心兑换；',
    notice3: '3. 承兑商服务时间为每天09:00-21:00，接单后30分钟内完成承兑，2小时未完成交易作废；',
    notice4: '4. 法币交易区支持银行卡，必须使用本人已实名认证的账户进行转账；',
    notice5:
      '5. 为保障交易资金安全，法币交易要求用户通过实名认证，请在通过实名认证后，及时设置资金密码与收款账户，否则无法交易；',
    payType: '付款方式',
    receiveType: '收款方式',
    moneyTips: '以上金额仅供参考，请以下单后的实际结算金额为准',
    verifyset: '实名认证/资金密码',
    payset: '付款/收款设置',
    trade: '交易',
    input50tips: '请输入50~50000之间的数字',
    bank: '银行卡',
    alipay: '支付宝',
    wechatpay: '微信',
    payaccountset: '付款账户设置',
    useselfaccount: '请务必使用与您本人实名认证相符的收款/付款账户',
    orderSn: '订单号',
    tradetime: '下单时间',
    direction: '交易类型',
    amount: '数量(USDT)',
    money: '总额(CNY)',
    price: '单价(CNY)',
    status: '状态',
    operate: '操作',
    moredetail: '更多买卖须知',
  },
  cexchange: {
    use: '消耗',
    consume: '兑换',
    title: '快速兑换',
    desc: '一键兑换,坐享收益',
    quantity: '兑换数量',
    inputtips1: '请输入数量',
    maxtip: '最多兑换',
    mintip: '最少兑换',
    choosecurrency: '选择兑换币种',
    all: '全部',
    totalpay: '总兑换金额',
    restricted: '今日限量',
    currentprice: '兑换价格',
    nextprice: '下期兑换价格',
    confirmpay: '立即兑换',
    assets: '余额：',
    loginFirst: '请先登录',
    success: '恭喜你！兑换成功！',
    tip: '提示',
    confirmmsg: '确认',
    quickExchange: '闪兑',
    zeroCommission: '无滑点丨一键交易',
    records: '记录',
    max: '最大',
    fee: '手续费',
  },
  option: {
    currentorder: '当前订单',
    historyorder: '历史订单',
    title: '期权合约',
    buyupreward: '买涨总额',
    buydownreward: '买跌总额',
    quantity: '开仓金额',
    myamount: '我的开仓',
    buyup: '涨',
    buydown: '跌',
    history: '往期结果',
    seriers: '第',
    period: '期',
    currentoption: '本期预测周期',
    openprice: '开盘价',
    currentprice: '当前价',
    openprice1: '开盘价格',
    closeprice: '收盘价格',
    opentime: '开盘时间',
    closetime: '收盘时间',
    progress: '预测进度',
    loginFirst: '请先登录!',
    selectAmount: '请选择投注数量',
    balancenotenough: '可用余额不足!',
    betsuccess: '恭喜你！投注成功！',
    noopening: '暂无待期权合约',
    col_createTime: '创建时间',
    col_direction: '预测方向',
    col_result: '预测结果',
    col_optionNo: '合约期数',
    col_betAmount: '开仓金额',
    col_rewardAmount: '奖金数量',
    col_fee: '开仓手续费',
    col_winfee: '市场调节费',
    win: '成功',
    lose: '失败',
    wait: '进行中',
    purchaseQuantity: '购买数量',
    avaliablebalance: '可用余额',
    login: '请先登录',
    kline: '实时行情',
    countdown: '倒计时',
    currentPeriod: '当期',
    trade: ' 交易',
    currentPrice: '当前价格',
  },
  otc: {
    payment_detail: '收款方式详情',
    bind_payment: '绑定收款方式',
    payment_placeholder: '请选择收款方式',
    payment: '收款方式',
    ad: '广告中心',
    buyin: '买入',
    sellout: '卖出',
    merchant: '商家',
    applymerchant: '申请为认证商家',
    volume: '交易笔数',
    paymethod: '付款方式',
    amount: '数量',
    limit: '限额',
    price: '单价',
    price_coin: '价格/币',
    operate: '操作',
    validate: '请先进行实名认证',
    sell: '卖出',
    buy: '买入',
    transaction: '买卖交易',
    buttontxt: '成为商家',
    title: '法币交易',
    desc: '便捷、安全、快速买卖数字货币',
    title1: '动态调整',
    desc1: '根据市场价格实时波动',
    title2: '免手续费',
    desc2: '用户所见即所得，买卖价格外，无需任何平台手续费',
    title3: '即时成交',
    desc3: '引入平台服务商家，智能匹配，成交订单，无须等待撮合',
    title4: '平台担保',
    desc4: '平台认证商家，安全有保障，24小时客服为交易保驾护航',
    myad: {
      title: '我的广告',
      post: '发布广告',
      alert: '【温馨提示】：当广告最小交易额所购买数量加上手续费大于广告剩余数量，该广告自动下架',
      no: '广告编号',
      type: '广告类型',
      sell: '在线出售',
      buy: '在线购买',
      limit: '订单限额',
      remain: '剩余数量',
      coin: '币种',
      created: '创建时间',
      operate: '操作',
      errmsg: '广告下架后才可编辑修改',
      update: '修改',
      shelf: '上架',
      dropoff: '下架',
      delete: '删除',
    },
    myorder: '我的订单',
    chatline: {
      status_1: '买家未付款，等待买家付款！',
      status_2: '买家已付款，等待卖家放行！',
      status_3: '订单已完成交易！',
      status_4: '订单正在申诉中！',
      status_5: '订单已取消！',
      loadmore: '加载更多',
      warning:
        '防诈骗提示：近期，屡有诈骗份子利用银行转账汇款信息和假汇款凭据进行诈骗，请一定登陆自己的收款账号核实。保证汇入资金的安全，避免银行卡被冻结的风险！',
      contenttip: '请输入聊天内容 回车键发送',
      contentmsg: '消息不能为空',
    },
    chat: {
      seller: '卖家',
      buyer: '买家',
      exchangeamount: '交易金额',
      operatetip: '操作提示',
      operatetip_1: '请在规定的时限内按照对方给出的账号完成支付，并在本页面点击',
      finishpayment: '付款完成',
      operatetip_1_1: '转账时请在留言中备注好交易单号',
      operatetip_1_2:
        '卖方收到款项后会在网站确认收款，系统会自动将您所购买的数字资产发放至您的账户，请注意查收',
      note: '注意',
      notetip:
        '请不要使用其他聊天软件与对方沟通，更不要接受对方向您发送的任何文件、邮箱附件等，所有沟通环节请都在本页面的聊天窗口完成',
      operatetip_2_1: '您所出售的数字资产，已交由平台托管冻结。请在确定收到对方付款后，点击',
      operatetip_2_2: '请不要相信任何催促放币的理由，确认收到款项后再释放数字资产，避免造成损失！',
      operatetip_2_3:
        '在收到账短信后，请务必登录网上银行或手机银行确认款项是否入账，避免因收到诈骗短信错误释放数字资产！',
      confirmrelease: '确认放行',
      paydigital: '支付数字资产',
      orderstatus: '订单状态',
      orderstatus_1: '付款完成',
      orderstatus_2: '订单申诉',
      orderstatus_3: '确认放行',
      orderstatus_4: '取消交易',
      orderstatus_5: '订单申诉',
      order: '订单',
      and: '与',
      transition: '的交易',
      transprice: '交易价格',
      transnum: '交易数量',
      transmoney: '交易金额',
      tip1: '用户暂时未添加银行卡卡号',
      tip2: '用户暂时未添加支付宝账号',
      tip3: '用户暂时未添加微信账号',
      zfb: '支付宝',
      wx: '微信',
      qrcode: '二维码',
      msg1: '您确定已经付款完成吗',
      msg2: '已付款项并不退还！您确定取消订单吗？',
      msg3: '【重要】：已付款项并不退还！您确定取消订单吗？',
      msg4: '已付款，未收到币',
      msg5: '已付币，未收到款',
      tip: '提示',
      comptype: '投诉类型',
      compremark: '投诉备注',
      willcomp: '我要投诉',
      msg6: '是否确认放币？',
      result_1: '等待付款',
      result_2: '等待放行',
      result_3: '已完成',
      result_4: '申诉中',
      result_5: '已取消',
      msg7: '资金密码',
      msg7tip: '请填写资金密码',
    },
    checkuser: {
      emaildone: '邮件已认证',
      emailundo: '邮件未认证',
      teldone: '手机号码已认证',
      telundo: '手机号码未认证',
      idcarddone: '身份证已认证',
      idcardundo: '身份证未认证',
      language: '语言',
      languagetext: '中文',
      languagetext1: '英文',
      registtime: '注册时间',
      exchangetimes: '累计交易次数',
      exchangeinfo: '的交易信息',
      tablabel1: '在线出售',
      tablabel2: '在线购买',
      col_symbol: '币种',
      col_paymode: '付款方式',
      col_num: '数量',
      col_price: '价格',
      col_created: '发布时间',
      col_operate: '操作',
      operatemsg: '请先进行实名认证',
      buyin: '买入',
      sellout: '卖出',
    },
    tradecenter: {
      postad: '发布广告',
      exchange: 'GCC交易',
      buyin: '我要买入',
      sellout: '我要卖出',
    },
    tradeinfo: {
      emaildone: '邮件已认证',
      emailundo: '邮件未认证',
      teldone: '手机号码已认证',
      telundo: '手机号码未认证',
      idcarddone: '身份证已认证',
      idcardundo: '身份证未认证',
      exchangetimes: '交易次数',
      price: '价格',
      num: '数量',
      paymethod: '付款方式',
      exchangelimitamount: '交易限额',
      location: '所在地',
      location_text: '中国',
      exchangeperiod: '交易期限',
      minute: '分钟',
      amounttip: '请输入金额',
      numtip: '请输入数量',
      remarktip: '告诉他您的要求',
      remarktitle: '备注信息',
      exchangetitle: '交易须知',
      exchange_tip1:
        '在您发起交易请求后，数字货币被锁定在托管中，受到平台保护。 如果您是卖家，发起交易请求后，您可通过充值并等待买家付款。买家在付款时限内进行付款。您在收到付款后应放行处于托管中的数字货币。',
      exchange_tip2: '交易前请阅读《平台网络服务条款》以及常见问题、交易指南等帮助文档。',
      exchange_tip3: '当心骗子！交易前请检查该用户收到的评价，并对新近创建的账户多加留意。',
      exchange_tip4:
        '请注意，四舍五入和价格的波动可能会影响最终成交的数字货币数额。您输入的固定数额决定最后数额，数字货币金额将在请求发布的同一时间由即时的汇率算出。',
      exchange_tip5:
        '托管服务保护网上交易的买卖双方。在发生争议的情况下，我们将评估所提供的所有信息，并将托管的数字货币放行给其合法所有者。',
      warning1: '最多输入2位小数',
      warning2: '下单金额为',
      warning3: '最多输入8位小数',
      warning4: '下单数量为',
      confirmbuyin: '确认买入',
      confirmsellout: '确认卖出',
      buyin: '买入',
      sellout: '卖出',
      warning5: '请按要求填写订单',
    },
    publishad: {
      createad: '创建一个广告交易',
      msg1: '如果您经常进行交易，您可以创建自己的交易广告。如果您只是偶尔交易，我们建议您直接搜索',
      msg2: '创建一则交易广告是',
      msg3: '免费的',
      msg4: '若您想直接编辑已创建的广告，请查看',
      tradead: '交易广告',
      myad: '我的广告',
      iwant: '我想要',
      sellonline: '在线出售',
      buyonline: '在线购买',
      exchangecoin: '交易币种',
      country: '国家',
      currency: '货币',
      openfixedprice: '开启固定价格',
      open: '开启',
      close: '关闭',
      usetip: '启用后，您的币价不会随市场波动，价格不变。',
      premiseprice: '溢价',
      premisepricetip: '请设置您的溢价',
      fixedprice: '固定价格',
      fixedpricetip: '请输入您的交易价格',
      marketprice: '市场参考价格',
      marketpricetip: '溢价是指高于当前市场价格多少百分比进行',
      exchangeprice: '交易价格',
      formual: '计价公式',
      num: '数量',
      num_text1: '请输入您要',
      num_text2: '的数量',
      exchangeperiod: '交易期限',
      exchangeperiod_text1: '请输入您的交易期限',
      minute: '分钟',
      tip1: '可接受买方在多少时间内交易，请输入整数',
      tip2: '【提示】可前往个人中心绑定/增加支付方式',
      tip3: '请输入您的最小交易额',
      tip4: '请输入您的最大交易额',
      tip5: '可以内备注信息里填写您的特殊要求，例如：对买方的要求，在线时间等。',
      paymode: '付款方式',
      minlimit: '最小交易额',
      maxlimit: '最大交易额',
      remark: '备注信息',
      openautoreply: '开启自动回复',
      msg5: '启用后，用户通过此广告向您发起交易时，系统自动将您选择的自动回复用语发送给对方。',
      autoreply: '自动回复',
      autoreplytip: '在接收订单后，自动回复给买家的信息，例如：收款方式、收款账号等。',
      fundpwd: '资金密码',
      fundpwdtip: '请输入您的资金密码',
      submit: '提交',
      warning1: '请输入正确数字',
      warning2: '溢价值为0-20',
      warning3: '请输入您的最大交易额！',
      warning4: '请输入整数',
      warning5: '最大交易额必须大于最小交易额！',
      warning6: '最大交易额不能超过您的卖出总金额',
      warning7: '请输入您的最小交易额！',
      warning8: '最小交易额必须大于等于100！',
      warning9: '最小交易额必须小于最大交易额',
      sellout: '卖出',
      buyin: '买入',
      inputtip1: '请输入币种',
      inputtip2: '请输入正确选项',
      inputtip3: '溢价值为0-20，且不能为0',
      inputtip4: '请输入正确固定价格',
      inputtip5: '请输入正确数字，并且最大交易数量不超过100币',
      inputtip6: '请输入正确交易限期',
      inputtip7: '请选择交易方式',
      inputtip8: '请输入资金密码',
      zfb: '支付宝',
      wx: '微信',
      unionpay: '银行卡',
      submit_failure: '提交失败!',
      submit_success: '',
      submittip1: '为了您的资金安全，请先进行【实名认证】！',
      submittip2: '为了您的资金安全，请先进行【手机绑定】！',
      submittip3: '为了您的资金安全，请先设置【资金密码】！',
      submittip4: '请至少绑定一种支付方式',
    },
    index: {
      title: '优质广告推荐',
      exchangetimes: '交易次数',
      exchangeprice: '交易价格',
      exchangelimit: '交易限额',
      paymode: '付款方式',
      buy: '购买',
      sell: '卖出',
      viewmore: '查看更多',
      bot1: '安全可靠',
      bot1_tip: '超过10年金融风控经验团队全方为\n可定制的安全策略体系',
      bot2: '快速便捷',
      bot2_tip: '点对点的用户自由交易模式支持多\n种资金渠道的兑换方式',
      bot3: '币种全面',
      bot3_tip: '甄选主流数字资产交易币种满足多\n方面的多资产交易体验',
      ibuy: '我要买入',
      isell: '我要卖出',
    },
  },
  activity: {
    all: '全部',
    prepare: '即将开始',
    ongoing: '进行中',
    distributing: '派发中',
    completed: '已完成',
    progress: '进度',
    totalsupply: '活动总量',
    starttime: '开始时间',
    endtime: '结束时间',
    viewdetail: '查看详情',
    attend: '立即参与',
    tokendistribute: 'Token派发中',
    baseinfo: '基本信息',
    status: '状态',
    activitytype: '认购类型',
    activitytype0: '未知',
    activitytype1: '首次上线(抢购)',
    activitytype2: '首次上线(平分)',
    activitytype3: '持仓瓜分',
    activitytype4: '自由认购',
    activitytype5: '云矿机认购',
    activitytype6: '锁仓释放',
    ruledetail: '类型说明',
    publishprice: '认购价格',
    activitycoin: '活动币种',
    acceptcoin: '接受币种',
    holdcoin: '持仓要求币种',
    limittimes: '个人限购次数',
    limitamount: '个人限购数量',
    unlimited: '不限',
    alreadyamount: '已购数量',
    alreadyholdamount: '活动参与总持仓',
    myalreadyholdamount: '我的参与持仓',
    leftamount: '剩余数量',
    attention: '注意',
    attentiontxt1:
      '1、提交参与【持仓瓜分】、【自由认购】、【云矿机认购】、【锁仓】类型活动后将冻结资产，活动结束后解冻或解锁释放',
    attentiontxt2:
      '2、参与【持仓瓜分】、【自由认购】、【云矿机认购】、【锁仓】类型活动后，用户不得撤销参与申请',
    attentiontxt3: '3、如因项目方及其他不可抗力因素取消活动，将原路退回冻结资产',
    attentiontxt4: '* 本活动最终解释权归NTL(WWW.NTLEX.COM)官方所有',
    inputamount: '输入兑换数量',
    inputholdamount: '输入锁仓数量',
    mybalance: '我的可用余额',
    holdtips: '参与【持仓瓜分】类型活动需锁仓至活动结束',
    pleaseinputamount: '请输入兑换数量',
    pleaseinputholdamount: '请输入锁仓数量',
    commitfailed: '提交参与失败！',
    minlimitamountfailed: '兑换数量不能小于最低起兑量！',
    maxlimitamountfailed: '兑换数量不能大于最高限购量！',
    limittimesfailed: '您参与的次数超过个人最高限购次数！',
    balancenotenough: '余额不足！',
    headertitledesc: '投资者、项目方、交易所等多方共赢的开放实验室',
    currentdivided: '当前我的持仓可瓜分约',
    leveloneCount: '需要一级好友数',
    submit: '提交',
    tipsmobile: '请进入电脑端页面参与（WWW.NTLEX.COM）',
    tipsmobile1: '请在电脑端打开（WWW.NTLEX.COM）并进入创新实验区参与',
    intvalue: '请输入整数',
    inputminingamount: '输入购买矿机数',
    moreactivity: '更多其他活动',
    releaseType: '释放类型',
    releaseType0: '等额释放',
    releaseType1: '等比释放',
    lock: ' 期释放',
    releaseDetail: '释放详情',
    lockday: ' 日',
    lockweek: ' 周',
    lockmonth: ' 月',
    lockyear: ' 年',
    releasePercent: '释放比例',
    lockFee: '锁仓门槛',
    releaseTimes: '释放倍数',
    times: '倍',
  },
  invite: {
    inviteLoginName: '登录成为合伙人',
    chengwei: '领取最高终身返佣机会',
    pthhr: '成为平台合伙人',
    partnerText: '邀请好友注册，享受平台红利',
    myinvitelink: '我的邀请链接',
    copy: '复制',
    copyed: '已复制',
    mylevelone: '我的一级好友',
    myleveltwo: '我的二级好友',
    mycommission: '佣金收入(折合USDT)',
    extrareward: '额外奖励',
    partnerlevel: '合伙级别',
    ruledetail: '规则详情',
    ruleprofile1: 'NTL(WWW.NTLEX.COM)的【推广合伙人】是全网',
    ruleprofile2: '返佣比例最高、',
    ruleprofile3: '返佣时长最长(最高终身返佣)',
    ruleprofile4:
      '的推广活动，让付出相应努力的推广者能够成为真正意义上的平台“合伙人”，享受与NTL(WWW.NTLEX.COM)交易平台共同成长的红利！具体细则如下：',
    ruletext1:
      '一、为布道数字资产理念与扩大用户规模，NTL(WWW.NTLEX.COM)推出【推广合伙人】计划，该活动长期有效。',
    ruletext2:
      '二、邀请好友分为两级，如A邀请B，则B是A的一级好友，若B又邀请了C，则C属于B的一级好友，同时也属于A的二级好友。',
    ruletext3:
      '三、推广好友注册时，需通过邀请者提供的链接或手动输入邀请者邀请码方可成为邀请者的一级好友。',
    ruletext4: '四、被邀请者通过邀请者邀请码完成注册，返佣激励即生效。',
    ruletext5: '五、返佣时间为从被邀请者完成注册开始的N个月，根据等级不同，享受的返佣比例不同。',
    ruletext6:
      '六、推广返佣资产为下级好友币币交易手续费，返佣币为三大市场基础币，即：USDT、BTC、ETH。',
    ruletext7:
      '七、推广返佣比例、返佣时长与推广一级好友人数呈正相关，推广人数越多，可享返佣比例越高。具体推广返佣比例如下：',
    ruletext8:
      '八、推广返佣排行榜TOP10或TOP100不定期会获得由NTL(WWW.NTLEX.COM)官方发放的额外奖励。',
    ruletext9:
      '九、如其他活动与【推广合伙人】活动冲突，则经协商后决定是否临时变更，望诸合伙人谅解。',
    ruletext10: '十、L4、L5、L6级合伙人分别享受每年返佣总额的5%、10%、15%的分红奖励。',
    ruletext11: '十一、本活动最终解释权归NTL(WWW.NTLEX.COM)所有。',
    ruleexampletitle: '举例说明：',
    ruleexamplecontent1:
      '若用户小严邀请了100名一级好友，TA的一级好友分别邀请了5名二级好友，此时小严就拥有100名一级好友，500名二级好友。如果平均每人每天交易3000元，那么小严月收入约：(100*3000*0.001*30%  500*3000*0.001*10%) * 30 = 7200/月。',
    ruleexamplecontent2:
      '若用户小严邀请了1000名一级好友，TA的一级好友分别邀请了5名二级好友，此时小严就拥有1000名一级好友，5000名二级好友。如果平均每人每天交易3000元，那么小严月收入约：(1000*3000*0.001*50%  5000*3000*0.001*20%) * 30 = 135000/月。',
    lastupdate: '最后更新于',
    laststastic: '统计截止时间',
    level: '级别',
    lv1: '',
    lv2: '',
    lv3: '',
    lv4: '',
    lv5: '',
    lv6: '',
    levelonecount: '一级好友数',
    levelonecommission: '一级好友返佣比例 / 返佣时间',
    leveltwocommission: '二级好友返佣比例 / 返佣时间',
    partnerdivide: '合伙人分红',
    day: '天',
    alllife: '终身返佣',
    totalcommissionrank: '推广返佣总额',
    rank: '排名',
    userid: '用户ID',
    rankday: '(日榜)',
    rankweek: '(周榜)',
    rankmonth: '(月榜)',
    rankall: '(总榜)',
    promotioncount: '推广人数',
    commissionusdt: '返佣(折合USDT)',
    ranktip1: '邀请人数为一级好友数，此榜为返佣总金额排名',
    ranktip2: '邀请人数为一级好友数，此榜为一级邀请人数排名',
    thanks: '感谢您陪着NTL(WWW.NTLEX.COM)一起成长！',
    headertip: '以上佣金数据非实时更新，系统每24H统计并更新一次。',
    ptools: '推广工具箱',
    pt_title: '数字货币礼品卡',
    pt_desc: '兑换后自动成为下级好友，送朋友、送亲人、送客户、送伙伴。助力推广合伙人更好推广。',
    pt_more: '更多工具，敬请期待',
    pt_tips: '如果您有好的创意，请发送邮件至promotion@NTL，采纳后有奖励哦！',
    pt_card_amount: '卡面金额',
    pt_card_deadline: '有效期间',
    pt_card_noend: '无限期',
    pt_card_btn: '推广合伙人免费领30张',
    pt_card_btn1: '推广合伙人免费领',
    pt_card_btn2: '张',
    pt_card_btn_login: '登录后免费领取30张',
    pt_card_rule: '规则详情',
    pt_card_summary:
      '数字货币礼品卡是为了让推广合伙人能够更好地邀请下线好友而开发的推广工具之一，推广对象用户兑换礼品卡时，会自动成为推广者的下线好友。',
    pt_card_rule1:
      '一、免费礼品卡每个用户限领30张。如需发放更多礼品卡，则需要用户付费定制，可定制卡面金额、LOGO、说明文等。定制需求请发送邮件至',
    pt_card_rule2:
      '二、免费礼品卡每个用户限兑1份。由NTL(WWW.NTLEX.COM)官方发放的免费礼品卡，每个用户限兑一份，即使通过不同用户领取到不同兑换码的免费礼品卡，也只能兑换1份。自定义制作礼品卡不受此限制。',
    pt_card_rule3:
      '三、用户兑换礼品卡前，如不存在“邀请者”（即注册账号时未输入过邀请码），则自动关联为发卡者的一级好友。如A注册账号时未输入邀请码，此时A不属于任何人的一级或者二级好友，但当A通过B发放的礼品卡兑换后，那么A自动成为B的一级好友。',
    pt_card_rule4:
      '四、为防止刷客，礼品卡账面金额并非实时兑付，领取后需要达到激活条件（充值币价值达到300USDT）才可操作。',
    pt_card_rule5:
      '五、领取免费礼品卡时需要达到激活条件，兑换时无需完成达到激活条件即可领取，此举是为了方便推广者更快获取下级好友。',
    pt_card_rule6: '六、免费礼品卡最终解释权归WWW.NTLEX.COM所有。',
    pt_card_day: '天',
    pt_card_title_tips: '领2000元推广助力金',
    pt_card_title_tips1: '领',
    pt_card_title_tips2: '推广助力金',
    pt_card_receivew_success:
      '恭喜！成功领取合伙人推广礼品卡！请到【个人中心】->【卡券中心】查看！',
    usepromotion: '使用推广素材',
    saveimage: '保存图片',
    imagetips: '如无法正常下载图片，请使用截图工具截图保存！',
    pt_invite_title: '邀请注册宣传图',
    pt_invite_desc: '专属二维码，通过二维码注册自动成为下级好友，适合在各种群或论坛转发。',
    colum_text0: '排名',
    colum_text1: '会员',
    colum_text2: '推广人数',
    colum_text3: '返佣(折合USDT)',
    colum_text4: '额外奖励',
    context_title: '推广合伙人专属兑换码',
    context_title1: '扫一扫了解更多',
    context_title2: '我的邀请码',
    context_title3: '邀请图片',
  },
  uc: {
    verify: '实名认证',
    retrieveprivate: {
      findText: '找回账号私钥',
      findText2: '已重新创建账号私钥',
      inputFindtext: '输入该账号绑定的邮箱',
      continue: '继续',
    },
    login: {
      welcomelogintxt: '欢迎来到NTL',
      welcomelogintxt2: '如果你还没有账号',
      welcomelogintxt3: '你可以点击此处',
      welcomelogintxt4: '注册账号',
      welcomelogintxt5: '登录账号',
      welcomelogintxt6: '如果你已有账号',
      welcomelogintxt7: '开启全新交易之旅',
      phoneEmail: '邮箱登录',
      phoneLogin: '手机登录',
      anonymousLogin: '匿名登录',
      inputPrivate: '请输入账号私钥...',
      forgetPrivateTxt: '忘记账号私钥?',
      noaccount: '没有账号？',
      register: '注册',
      login: '登录',
      welcomelogin: '欢迎登录',
      usertip: '请输入邮箱',
      pwdtip: '请输入密码',
      validatecodeload: '正在加载验证码',
      validatemsg: '完成验证',
      forget: '忘记密码?',
      loginvalidate: '请输入账号',
      pwdvalidate1: '请输入密码',
      pwdvalidate2: '密码长度不能少于6位',
      success: '登录成功',
      goregister: '去注册',
      getlostpwd: '忘记密码',
      noid: '还没有账号',
      keyErr: '输入的私钥有误',
      verify: '请完成验证',
      loginFailure: '登录失效',
      loginFailureDoc: '登录状态已失效，请重新登录。',
    },
    forget: {
      title: '重置密码',
      hasaccount: '已有账号？请点击登录',
      login: '登录',
      sendcode: '发送验证码',
      newpwd: '请输入新密码',
      confirmpwd: '请再次确认密码',
      save: '提交',
      pwdvalidate1: '请输入确认密码',
      pwdvalidate2: '两次密码输入不一致!',
      resettelpwd: '重置手机密码',
      resetemailpwd: '重置邮箱密码',
      newpwdtip: '请输入新密码',
      pwdvalidate3: '密码长度不能少于6位',
      telno: '手机号',
      smscode: '请输入验证码',
      teltip: '请输入手机号',
      smscodetip: '请输入短信验证码',
      email: '邮箱',
      password: '密码',
      NoId: '还没有账号',
      emailcode: '邮箱验证码',
      captchaPrompt: '若未收到验证码，可前往短信/邮箱中查看',
      emailtip: '请输入邮箱',
      emailcodetip: '请输入邮箱验证码',
      resetpwdsuccess: '重置密码成功',
      smswarn: '请注意查收短信',
      bangding: '扫码绑定',
      googleAuth: 'Google验证器',
      pleaseGoogleAuth: '请绑定Google验证器',
      bindGoogleAuth: '已绑定Google验证器',
      jcbind: '解除绑定',
      GoogleCode: '谷歌验证码',
    },
    finance: {
      center: '财务中心',
      personalassets: '币币账户',
      swapassets: '永续账户(u本位)',
      swapassets2: '永续账户(币本位)',
      billdetail: '资产流水',
      tradetail: '交易挖矿',
      paydividends: '持币分红',
      invitingmining: '邀请挖矿奖励',
      charge: '充币',
      pickup: '提币',
      money: {
        cointype: '币种名称',
        balance: '可用资产',
        frozen: '冻结资产',
        operate: '操作',
        charge: '充币',
        pickup: '提币',
        onkeytrans: '一键转',
        getaddress: '获取地址',
        resetsuccess: '获取成功',
        match: '配对',
        matchtip1: '可配对GCX的数量',
        matchtip2: '请输入配对数量',
        matcherr1: '请输入有效的数量!',
        matcherr2: '超出最大配对数量!',
        matchsuccess: '配对成功!',
        needreleased: '待释放资产',
        totalassets: '总资产折合：',
      },
      swap: {
        day1: '日利率为',
        day2: '年利率为',
        totalassets: '账户权益折合：',
        swap: ' 永续账户',
        swaptype: '合约账户',
        swapassets: '账户权益',
        avabalance: '可用保证金',
        usebalance: '已用保证金',
        experienceBonus: '已用体验金',
        frozenbalance: '冻结保证金',
        profitandloss: '未实现盈亏',
        transfor: '资金划转',
        inputtransferamount: '划转数量',
        all: '全部划转',
        currencyaccount: '币币账户',
        swapaccount: '永续合约账户',
        clickchange: '点击切换划转方向',
        avaamount: '可转额度',
        pleaseselectwallet: '请先选择转入/转出账户',
        pleaseinputamount: '请输入划转数量',
        oktransfer: '确认划转',
        from: '从',
        to: '到',
        available: '可用',
        successfulTransfer: '划转成功',
      },
      second: {
        secondaccount: '秒合约账户',
        swap: ' 秒合约账户',
      },
      finance: {
        transfer: '理财转入',
        account: '理财账户',
        balance: '余额',
        earn: '累计利息',
        stlTotalEarn: '未平利息',
        rate: '利率',
        confirm: '确认转入',
        transferAmount: '转入数量',
        all: '全部转入',
        coin: '币种',
        open: '持仓记录',
        close: '平仓记录',
        fail: '操作失败',
        success: '操作成功',
        updateTime: '更新时间',
        financialManagement: '理财管理',
        financialOrder: '理财订单',
      },
      record: {
        start_end: '起止时间',
        to: '至',
        code: '编码',
        remark: '备注',
        operatetype: '操作类型',
        search: '搜索',
        charge: '充值',
        handrecharge: '人工充值',
        pickup: '提现',
        pickupfee: '提现手续费',
        pickupCode: '提现码提现',
        pickupCodePay: '提现码充值',
        activityaward: '活动奖励',
        promotionawardRebates: '推广返佣奖励',
        istantRedemptionRevenue: '闪兑收入',
        flashPayouts: '闪兑支出',
        instantRedemptionFees: '闪兑手续费',
        exchange: '币币交易',
        exchangefee: '币币手续费',
        contractfee: '永续合约手续费',
        contractprofit: '永续合约盈利',
        contractloss: '永续合约亏损',
        expenditureofcapitalexpenses: '支出资金费用',
        accesstofundingfees: '获得资金费用',
        optionscontractfails: '期权合约失败',
        optionscontractfee: '期权合约手续费',
        optionscontractbonuses: '期权合约奖金',
        optionscontractcom: '期权合约市场调节费',
        transaccount: '转账',
        otcbuy: '法币买入',
        otcsell: '法币卖出',
        promotionaward: '推广奖励',
        dividend: '分红',
        rebateincome: '返佣收入',
        vote: '投票',
        match: '配对',
        activitybuy: '活动兑换',
        ctcbuy: 'C2C买入',
        ctcsell: 'C2C卖出',
        redout: '发红包',
        redin: '领红包',
        chargetime: '交易时间',
        type: '类型',
        fee: '手续费',
        shouldfee: '应付手续费',
        discountfee: '抵扣手续费',
        realfee: '实付手续费',
        symbol: '币种',
        num: '数量',
        status: '状态',
        finish: '已完成',
        quickExchange: '闪兑',
        currentRate: '当前兑换比率',
        from: '从',
        inputexchangeamount: '输入闪兑数量',
        inputexchangepasswd: '输入交易密码',
        chooseTransCoinUnit: '选择转化币种',
        inputTransAmount: '输入转化数量',
        predictAmount: '预计可兑数量',
        qamount: '闪兑数量',
        qrate: '兑换比例',
        qexamount: '兑换数量',
        withdrawcodein: '提币码充币',
        withdrawcodeout: '提币码提币',
        activityrevenue: '活动收入',
        activityexpenditure: '活动支出',
        redenvelopecollection: '红包领取',
        optionfail: '预测合约失败',
        optionfee: '预测合约手续费',
        optionreward: '预测合约奖金',
        secondfee: '秒合约手续费',
        secondcom: '秒合约市场调节费',
        secondfail: '秒合约失败',
        secondreward: '秒合约奖金',
        financereward: '理财利息',
        other: '其他',
        adjust: '调整',
        tradingRewards: '交易奖励',
        financialDefaultHandlingFee: '理财违约手续费',
        coinTradeFeeDiscount: '币币手续费抵扣',
        contracttradeFeeDiscount: '合约手续费抵扣',
        redEnvelopeReturn: '红包退回',
        experienceUse: '合约体验金使用',
        deduction: '合约体验金抵扣',
        transfer: '划转',
        kolCommission: 'KOL返佣入账',
      },
      paydividende: {
        money_holding: '持币分红已返还累计(ETH) : ',
        money_hold: '持币分红待返还累计(ETH) : ',
        paydividends: '持币分红(ETH)',
        account_date: '到帐时间',
        datehodld: '持币日期',
        start_end: '起止时间',
        to: '至',
        operatetype: '操作类型',
        search: '搜索',
        charge: '充值',
        pickup: '提现',
        transaccount: '转账',
        exchange: '币币交易',
        otcbuy: '法币买入',
        otcsell: '法币卖出',
        activityaward: '活动奖励',
        promotionaward: '推广奖励',
        dividend: '分红',
        vote: '投票',
        handrecharge: '人工充值',
        match: '配对',
        chargetime: '交易时间',
        type: '类型',
        fee: '手续费',
        symbol: '币种',
        num: '数量',
        status: '状态',
        finish: '已完成',
      },
      trade: {
        accumulative_return: '累计挖矿(BHB) : ',
        accumulat_return: '待挖矿(BHB) : ',
        start_end: '起止时间',
        account_date: '到帐时间',
        to: '至',
        operatetype: '操作类型',
        search: '搜索',
        charge: '充值',
        pickup: '提现',
        transaccount: '转账',
        exchange: '币币交易',
        otcbuy: '法币买入',
        otcsell: '法币卖出',
        activityaward: '活动奖励',
        promotionaward: '推广奖励',
        dividend: '分红',
        vote: '投票',
        handrecharge: '人工充值',
        match: '配对',
        chargetime: '订单生成时间',
        type: '类型',
        fee: '挖矿手续费返还(BHB)',
        symbol: '交易对',
        num: '数量',
        status: '状态',
        finish: '已完成',
        transactionTime: '交易时间',
        direction: '交易方向',
        price: '价格',
        entrustment: '委托量',
        havedeal: '已成交',
        poundageAmount: '手续费',
        exchangeOrderId: '订单ID',
        mineAmount: '挖币数量 (BHB)',
        buy: '买入',
        sell: '卖出',
        limit: '限价',
        market: '市价',
        clear: '清空',
        turnover: '成交金额',
      },
      inviting: {
        accumulative_return: '邀请挖矿奖励已返还累计(BHB) : ',
        accumulat_return: '邀请挖矿奖励待返还累计(BHB) : ',
        start_end: '起止时间',
        account_date: '到帐时间',
        to: '至',
        operatetype: '操作类型',
        search: '搜索',
        charge: '充值',
        pickup: '提现',
        transaccount: '转账',
        exchange: '币币交易',
        otcbuy: '法币买入',
        otcsell: '法币卖出',
        activityaward: '活动奖励',
        promotionaward: '推广奖励',
        dividend: '分红',
        vote: '投票',
        handrecharge: '人工充值',
        match: '配对',
        chargetime: '订单生成时间',
        type: '类型',
        fee: '挖矿手续费返还(BHB)',
        symbol: '币种',
        num: '数量',
        status: '状态',
        finish: '已完成',
        refereename: '被推荐人姓名',
        referetel: '被推荐人手机号',
        invitingawards: '邀请挖矿奖励(BHB)',
        refere: '被推荐人',
        refereinput: '请输入姓名/手机号',
      },
      recharge: {
        recharge: '充币',
        symbol: '币种',
        address: '充币地址',
        copy: '复制',
        qrcode: '二维码',
        qrcodeaddress: '充值地址二维码',
        msg1: '请勿向上述地址充值任何非币种资产，否则资产将不可找回。',
        msg2: '您充值至上述地址后，需要整个区块链网络节点的确认，一般区块链主网3次网络确认后到账。',
        msg3: '最小充值金额：',
        msg3_1: '，小于最小金额的充值将不会上账。',
        msg4: '您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您。',
        msg5: '请务必确认电脑及浏览器安全，防止信息被篡改或泄露。',
        record: '充值记录',
        copysuccess: '复制成功！',
        copyerr: '复制失败！请手动复制',
        time: '到账时间',
        amount: '数量',
        gettingaddress: '获取充值地址中，请稍候...',
        getaddress: '获取充值地址',
        memotips: '充值时请按要求输入此Memo，否则会丢失资产。',
        withdrawrecharge: '提币码充值',
        coderechargetip: '请输入提币码',
        rechargesuccess: '恭喜！提币码充值成功！',
        rechargeconfirm: '充值确认',
        status: '状态',
        protocol: '网络协议',
        status_0: '失败',
        status_1: '未到账',
        status_2: '已到账',
        protocolerr: '请选择协议',
        network: '网络',
        notaddress: '您未申请过充值地址，请点击按钮获取充值地址',
        rechargeDisable: '充值功能暂时不可用',
        nickname: '请输入昵称',
        phoneOrEmail: '请填写正确的手机号或者邮箱号',
      },
      withdraw: {
        pickup: '提币',
        network: '转账网络',
        withdrawDisable: '暂停提币',
        addressmanager: '提币地址管理',
        withdrawbycode: '提币码提币',
        withdrawcode: '提币码',
        symbol: '币种',
        address: '提币地址',
        num: '提币数量',
        avabalance: '可用余额',
        msg1: '提币数量低于',
        msg2: '时自动到账，否则需要人工审核',
        increase: '提升额度',
        tip1: '最多输入',
        tip11: '位小数,最小值为',
        tip2: '最大值为',
        fee: '预估手续费',
        feeIns: '手续费根据市场行情波动变化',
        range: '范围',
        arriamount: '到账数量',
        msg3: '最小提币数量为',
        msg4: '币',
        msg5: '为保障资金安全，当您账户安全策略变更、密码修改、使用新地址提币，我们会对提币进行人工审核，请耐心等待工作人员电话或邮件联系',
        msg6: '请务必确认电脑及浏览器安全，防止信息被篡改或泄露。',
        record: '提现记录',
        symboltip: '请选择币种',
        addresstip: '请填入地址',
        amounttip: '请输入提币数量',
        newAmounttip: '请填写正确的提币数量',
        numtip1: '输入提币数量',
        numtip2_max: '请填写正确提币数量，最大值为',
        numtip2: '请填写正确提币数量，最小值为',
        numtip3: '提币数量不得小于手续费',
        feetip1: '手续费最小值为',
        feetip2: '最大值为',
        time: '提现时间',
        status: '状态',
        turnDown: '驳回',
        inProcess: '处理中',
        inTransfer: '转账中',
        successful: '成功',
        failure: '失败',
        reason: '原因',
        remark: '备注',
        add: '添加',
        addresslist: '地址列表',
        safevalidate: '安全验证',
        telno: '手机号码',
        smscode: '手机验证码',
        second: '秒',
        clickget: '点击获取',
        email: '邮箱',
        emailcode: '邮箱验证码',
        save: '保 存',
        delete: '删除',
        telerr: '手机号不正确',
        emailerr: '邮箱不正确',
        codeerr: '验证码不正确',
        remarktip: '请输入备注',
        savemsg1: '保存失败!',
        savemsg2: '保存成功!',
        operate: '操作',
        fundpwdtip: '请输入资金密码',
        click: '点击',
        filtrate: '可筛选',
        txid: 'TxId',
        generateCode: '生成提币码',
        withdrawcodelist: '提币码列表',
        withdrawcodestatus: '状态',
        submit: '提交',
        generatecodesuccess: '提现码生成成功',
        code_status0: '未使用',
        code_status3: '成功',
        protocol: '网络协议',
        shenqing: '提现申请成功',
        fundsVerified: '您还未设置资金密码！',
      },
      securityModal: {
        securityReminder: '安全提示',
        toEAuthentication: '为保证资金安全，请前往',
        toEAuthentication2: '绑定邮箱和谷歌验证。',
        setFundPassword: '设置资金密码',
        goToSettings: '去设置',
        alreadySet: '已设置',
        alreadyBound: '已绑定',
        bindEmail: '绑定邮箱',
        goToBind: '去绑定',
        forTPassword: '为确保您的账户安全，在您更改密码后，提现功能将被禁用24小时。',
        youHHours: '您修改了登录/资金密码，提现功能已被禁用，将在{0}后可用。',
      },
    },
    member: {
      usercenter: '个人中心',
      securitycenter: '安全中心',
      securitysetting: '安全设置',
      accountsetting: '收款方式',
      account: '账户管理',
      assets: '资金管理',
      exchange: '交易管理',
      curdelegate: '当前委托',
      hisdelegate: '历史委托',
      otc: 'OTC管理',
    },
    order: {
      ordercenter: '订单中心',
      myorder: '我的订单',
      myad: '我的广告',
    },
    regist: {
      anonymousLogin: '匿名注册',
      fillInvitationCode: '填写邀请码',
      go_login: '已有账号',
      go_login2: '去登录',
      gologin: '已有账号，立即登录',
      SaveKey: '请保存密钥',
      zhanghaosiyao: '账号私钥',
      nimingtishiTxt:
        '输入账号私钥即可登录账号，账号私钥由本地随机创建，需您本人妥善保管，若丢失可通过该账号绑定的邮箱找回，否则将无法找回！',
      shuruningcheng: '请输入您的昵称',
      logintishi:
        '登录时仅需账号私钥即可登录账号，账号私钥由本地随机创建，需您本人妥善保管，点击确定可自动复制',
      hasaccount: '已有账号？请点击登录',
      login: '登录',
      username: '用户名',
      country: '国家',
      smscode: '短信验证码',
      sendcode: '发送验证码',
      resendcode: '重新发送(',
      pwd: '登录密码',
      repwd: '确认密码',
      confrimpwd: '确认密码',
      agreement: '接受',
      userprotocol: '用户协议',
      privacypolicy: '隐私政策',
      regist: '注册',
      teltip: '请输入手机号',
      telerr: '手机号码格式不正确,请重新输入',
      emailtip: '请输入邮箱',
      emailerr: '邮箱格式不正确,请重新输入',
      confirmpwdtip: '请输入确认密码',
      confirmpwderr: '两次密码输入不一致!',
      telregist: '手机注册',
      emailregist: '邮箱注册',
      usernametip: '请输入用户名',
      usernamemsg: '用户名长度不能少于3位,多于15位',
      countrytip: '请选择国家',
      smscodetip: '请输入短信验证码',
      pwdtip: '请输入登录密码',
      pwdmsg: '密码长度不能少于6位',
      telno: '手机号码',
      email: '邮箱地址',
      agreementtip: '请点击同意',
      reloadChoice: '请重新注册',
      modaltitle: '请校验',
      promotion: '邀请码',
      china: '中国',
      us: '美国',
      japan: '日本',
      germany: '德国',
      uk: '英国',
      india: '印度',
      france: '法国',
      italy: '意大利',
      canada: '加拿大',
      korea: '韩国',
      russia: '俄罗斯',
      australia: '澳大利亚',
      brazil: '巴西',
      hk: '中国香港',
      macao: '中国澳门',
      taiwan: '中国台湾',
      spain: '西班牙',
      mexico: '墨西哥',
      indonesia: '印度尼西亚',
      netherlands: '荷兰',
      switzerland: '瑞士',
      saudiarabia: '沙特阿拉伯',
      turkey: '土耳其',
      iran: '伊朗',
      poland: '波兰',
      sweden: '瑞典',
      belgium: '比利时',
      thailand: '泰国',
      nigeria: '尼日利亚',
      austria: '奥地利',
      ireland: '爱尔兰',
      israel: '以色列',
      norway: '挪威',
      argentina: '阿根廷',
      philippines: '菲律宾',
      arab: '阿拉伯',
      egypt: '埃及',
      denmark: '丹麦',
      malaysia: '马来西亚',
      singapore: '新加坡',
      vietnam: '越南',
      bangladesh: '孟加拉国',
      southafrica: '南非',
      chile: '智利',
      finland: '芬兰',
      colombia: '哥伦比亚',
      romania: '罗马尼亚',
      portugal: '葡萄牙',
      newzealand: '新西兰',
      greece: '希腊',
      qatar: '卡塔尔',
      belarus: '白俄罗斯',
    },
    safe: {
      safelevel_low: '安全等级：低',
      safelevel_high: '安全等级：高',
      safelevel_medium: '安全等级：中',
      nickname: '昵称',
      bind: '绑定',
      binded: '已绑定',
      binding: '审核中',
      binderr: '失败',
      bindretry: '重试',
      verifypass: '已认证',
      verified: '实名认证',
      notverified: '未认证',
      verifiedtip: '为保障您的账户安全，请完成实名认证后方可交易操作！',
      realname: '真实姓名',
      idcard: '身份证号',
      upload: '点击上传',
      upload_positive: '身份证正面照',
      upload_negative: '身份证反面照',
      upload_hand: '手持身份证与个人签名照',
      save: '保 存',
      reset: '重置',
      email: '邮箱',
      bindemail: '绑定邮箱',
      loginpwd: '登录密码',
      emailcode: '邮箱验证码',
      clickget: '点击获取',
      second: '秒',
      phone: '手机',
      bindphone: '绑定手机',
      phonecode: '手机验证码',
      logintip: '登录平台时使用',
      edit: '修改',
      oldprivateKey: '私有密钥',
      oldpwd: '原登录密码',
      newpwd: '新登录密码',
      confirmnewpwd: '确认新密码',
      fundpwd: '资金密码',
      fundtip: '账户资金变动时，需先验证资金密码',
      set: '设置',
      confirmpwd: '确认密码',
      oldfundpwd: '原资金密码',
      newfundpwd: '新资金密码',
      newpwdmsg1: '请输入不小于6位新登录密码',
      newpwdmsg2: '新登录密码不一致',
      pwdmsg1: '请输入不小于6位密码',
      pwdmsg2: '密码不一致',
      emailtip: '请输入邮箱号',
      privateKeytip: '请输入私钥',
      codetip: '请输入验证码',
      telnotip: '请输入手机号',
      oldpwdtip: '请输入原密码',
      realnametip: '请输入真实姓名',
      idcardtip: '请输入身份证号码',
      bindphonetip: '请先绑定手机!',
      resetfundpwd: '重置资金密码',
      upload_positivetip: '请上传身份证正面照',
      upload_negativetip: '请上传身份证反面照',
      upload_handtip: '请上传手持身份证照',
      save_success: '保存成功!',
      save_failure: '保存失败!',
      idcard_verifymsg1:
        '1、支持JPG、PNG格式，文件小于8M；证件上的信息，清晰可见，不允许任何修改和遮挡，必须能看清证件号和姓名。',
      idcard_verifymsg2: '2、照片需免冠，建议未化妆，手持证件人的五官清晰可鉴，完整露出手臂。',
      idcard_verifymsg3:
        "3、需要同时手持证件和手写文案（姓名申请日期）'我在本站的所有行为均为本人操作，并已知悉相关风险，愿意承担本账户的一切法律后果。'",
    },
    account: {
      successDelete: '删除成功',
      pagetitle: '绑定实名帐号',
      pagetip: '请设置您的收款方式，请务必使用本人的实名账号',
      backcardno: '银行卡账号',
      backcardtip: '个人银行卡信息未绑定',
      modify: '修改',
      bind: '绑定',
      name: '姓 名',
      bankaccount: '开户银行',
      bankbranch: '开户支行',
      bankno: '银行卡号',
      confirmbankno: '确认卡号',
      fundpwd: '资金密码',
      save: '保 存',
      zfbaccount: '支付宝账号',
      zfbaccounttip: '个人支付宝账户未绑定',
      wxaccount: '微信账号',
      wxaccounttip: '个人微信账户未绑定',
      banknomsg1: '请输入正确银行卡号',
      banknomsg2: '两次输入的银行卡号不一致!',
      verifiedmsg: '请先进行实名认证',
      bankaccountmsg: '请选择开户银行',
      bankbranchmsg: '请输入开户支行',
      banknomsg: '请输入正确的银行卡号',
      fundpwdmsg1: '请输入正确的资金密码',
      fundpwdmsg2: '密码不得少于6个字符',
      zfbaccountmsg: '请输入支付宝账号',
      wxaccountmsg: '请输入微信账号',
      save_success: '保存成功!',
      save_failure: '保存失败!',
      imgtip: '请上传您的收款码',
    },
    otcorder: {
      unpaid: '未付款',
      paided: '已付款',
      finished: '已完成',
      canceled: '已取消',
      appealing: '申诉中',
      searchtip: '输入订单编号开始搜索',
      orderno: '订单号',
      created: '创建时间',
      symbol: '交易币种',
      type: '交易类型',
      type_sell: '卖出',
      type_buy: '买入',
      tradename: '交易对象',
      amount: '数量',
      money: '金额',
      fee: '手续费',
    },
    identity: {
      certified: '已认证',
      placeholder: '请填写取消原因',
      apply: '申请成为商家',
      become: '成为NTL认证商家， 享更多交易特权',
      zhusnhu: '商家享有专属广告展位，增加交易成功率',
      tijiaoziliao: '提交商家认证资料',
      place: '请您将准备好的商家认证资料上传至平台并提交',
      tijiao: '您的商家认证审核已提交',
      tijiaosuc: '恭喜！您的商家认证审核已通过',
      tijiaofail: '抱歉！您的商家认证审核未通过',
      zhuxiaotijiao: '您的商家注销申请已提交',
      shenhefail: '您的商家注销申请审核未通过',
      shenhesuc: '您的商家注销申请审核已通过',
      shangjiazhuxiao: '商家注销',
      shenheshibai: '审核失败',
      shenagain: '重新审核',
      sheqinggain: '重新申请',
      reason: '原因',
      shenqingtuibao: '申请退保',
      getquan: '您获得以下权限',
      read: '我已阅读并同意',
      lijishenqing: '立即申请',
      tips: '提示',
      wufachexiao: '您正在进行商家注销操作，确认提交申请操作后，将无法撤销。',
      suredo: '是否确认执行此操作？',
      shuzizichan: '数字资产交易证明',
      gerenzichan: '个人数字资产证明',
      second: {
        line: '如何申请成为商家?',
        step1: '第一步：按要求准备商家申请资料',
        step1c1: '准备如下申请资料：',
        step1c2: '手机号、微信号、QQ号、个人数字资产证明(图片)、数字资产交易证明(图片)',
        step2: '第二步：提交申请',
        step2c: '完成需要填写和上传的商家认证审核资料，点击提交审核。',
        step3: '第三步：资料审核',
        stepc:
          '我们将在3-5个工作日内对您的商家申请资料进行审核，请随时关注审核状态，可在提交页面查看。审核通过后，您即可在法币交易区发布广告。',
        agree: '同意冻结',
        agreec: '作为商家保证金',
        shenqingchngweishangjia: '申请成为商家',
      },
      yuanyin: '原因',
      tijiaoshenqing: '提交申请',
      bizhong: '保证金币种',
      shuliang: '保证金数量',
      chosen: '选择币种',
      seat: '专属展位',
      service: '一对一服务',
      lowfee: '更低手续费',
      phone: '手机',
      balance: '个人资产情况',
      cardphoto: '身份证正反面照片',
      wx: '微信',
      exchange: '是否从事过数字资产的场外交易',
      handphoto: '用户手持身份证照片',
      qq: 'QQ',
      ploy: '是否有相应的风控策略',
      agreement: '《认证商家协议》',
      he: '和',
      applyfor: '确认申请',
      sendcode: '发送验证码',
      confirm: '确定',
      prepare: '准备资料',
      review: '提交审核',
      result: '等待结果',
      passed: '审核通过',
      approve: '请同意认证商家协议',
      emailtip1: '请将如下材料用邮件发送至',
      emailtip2: '我们将尽快对您的申请进行审核。',
    },
    extension: {
      title1: '推广链接',
      title2: '推广好友',
      title3: '我的佣金',
      linkdesc:
        '以下网址是您对外界进行推广的地址，您可以通过朋友、QQ、微信、微博、博客、论坛或者自己的网站进行推广，所有通过该地址访问过来的人，注册后就都属于您的用户，而当这些用户在本站提交策略时，您就可以赚取佣金了，详细的推广情况可到访问记录里查看。',
      linktitle: '您的推广链接',
      copy: '复制',
      copy_msg1: '复制成功！',
      copy_msg2: '复制失败！请手动复制',
      username: '用户名',
      currrecharge: '当前交易',
      userlevel: '推荐级别',
      createdtime: '注册时间',
      currcommission: '当前佣金',
      managerfee: '管理费',
      yuan: '元',
      symbol: '币种',
      amount: '金额',
      remark: '备注',
      amounttime: '发放时间',
    },
    promotion: {
      title: '我的推广',
      subtitle1: '我的推广卡',
      subtitle2: '兑换记录',
      subtitle3: '我的邀请',
      card_column0: '兑换码',
      card_column1: '卡名',
      card_column2: '卡面币种',
      card_column3: '卡面金额',
      card_column4: '有效期',
      card_column5: '总数',
      card_column6: '已兑',
      card_column7: '领取时间',
      card_column8: '过期时间',
      my_column0: '邀请用户',
      my_column1: '注册时间',
      my_column2: '实名状态',
      gopromotion: '推广素材',
      inputcardno: '请输入兑换码',
      exchange: '立即兑换',
      exchangesuccess: '兑换成功！请到资产管理查看余额！',
      exchangewithcode: '兑换码兑换',
    },
    activity: {
      all: '全部',
      navTitle: '创新管理',
      subNavTitle1: '我参与的',
      subNavTitle2: '我的矿机',
      subNavTitle3: '我的锁仓',
      column1: '名称',
      column2: '类型',
      column3: '认购数量',
      column4: '参与币种',
      column5: '认购单位',
      column6: '当前状态',
      column7: '成交额',
      column8: '创建时间',
      mColumn1: '矿机',
      mColumn2: '名称',
      mColumn3: '币种',
      mColumn4: '周期',
      mColumn5: '天数',
      mColumn6: '已挖',
      mColumn7: '状态',
      mColumn8: '基础产能',
      mColumn9: '当前产能',
      mColumn10: '总产出',
      mColumn11: '结束日期',
      mColumn12: '创建日期',
      lColumn1: '锁仓',
      lColumn2: '名称',
      lColumn3: '币种',
      lColumn4: '周期',
      lColumn5: '天数',
      lColumn6: '已释放',
      lColumn7: '状态',
      lColumn8: '基础产能',
      lColumn9: '当前产能',
      lColumn10: '总产出',
      lColumn11: '结束日期',
      lColumn12: '创建日期',
    },
    mining: {
      title: '我的矿机列表',
      empty: '暂无矿机',
      day: '天',
      week: '周',
      month: '月',
      year: '年',
      invitetip1: '本矿机产能可通过邀请好友获得提升，每邀请一人兑换矿机，产能提升',
      invitetip2: '%，产能提升上限',
      miningUnit: '产出币种',
      miningPeriod: '产出周期',
      miningDays: '挖矿时间',
      miningedDays: '已挖时间',
      miningDaysProfit: '基础产能',
      miningCurrentDaysProfit: '实际产能',
      status0: '待部署',
      status1: '已部署',
      status2: '已结束',
    },
    locked: {
      title: '我的锁仓列表',
      empty: '暂无锁仓',
      day: '天',
      week: '周',
      month: '月',
      year: '年',
      invitetip1: '本锁仓产能可通过邀请好友获得提升，每邀请一人参与锁仓，产能提升',
      invitetip2: '%，产能提升上限',
      miningUnit: '释放币种',
      miningPeriod: '释放周期',
      miningDays: '总释放周期',
      miningedDays: '已释放周期',
      miningDaysProfit: '基础释放',
      miningCurrentDaysProfit: '实际释放',
      status0: '待释放',
      status1: '释放中',
      status2: '已结束',
      totallocked: '总锁仓',
    },
    api: {
      apiAdmin: 'API管理',
      createApi: '创建新API Key',
      mark: 'API备注',
      bindAddress: '授权IP（授权IP地址的密钥有效期为90天）',
      hint: '提示',
      part1: '我们为您提供了强大的API,您可以通过API使用行情查询、自动交易等服务。通过',
      apiDoc: 'API文档',
      howUse: '查看如何使用。',
      part2: '每个用户最多创建5组API Key',
      part3:
        '请不要泄露您的API Key，以免造成资产损失。 出于安全考虑，建议为API Key绑定IP，每个API Key最多绑定5个Ip。单个地址直接填写，多个IP地址用半角逗号分隔，如：192.168.1.1,192.168.1.2,192.168.1.3。',
      myApiKey: '我的API Key',
      ipAddress: 'ip地址',
      createTime: '创建时间',
      endTime: '最后结算时间',
      accessKey: '访问密钥',
      bindIpAddress: '绑定IP地址',
      ioDays: '剩余有效期（天）',
      operation: '操作',
      create: '创建',
      edit: '编辑',
      delete: '删除',
      sureDelete: '确认删除吗',
      tips: '密钥只在新增时展示，请及时保存',
      copy: '复制',
      access: '密钥',
      okText: '确认',
      cancel: '取消',
    },
  },
  cms: {
    NewExclusive: '新人专享',
    Newtip: '注册NTL账户，邀请好友，有机会享受终身返佣',
    noticecenter: '公告中心',
    newshelp: '新手帮助',
    appdownload: 'APP下载',
    onlineservice: '在线客服',
    faq: '常见问题',
    notice: '公告',
    NTLnotice: 'NTL公告',
    info: '随时随地获取NTL第一手消息',
    servicecenter: '客服中心',
    about: '关于',
    joinus: '加入我们',
    aboutus: '关于我们',
    exchangerule: '交易规则',
    useprotocol: '使用协议',
    feenote: '资费说明',
    merchantprocotol: '商家协议',
    features: '平台特色',
    contactus: '联系我们',
    noticelist: '公告列表',
    nextnotice: '下一篇',
    prevnotice: '上一篇',
    notexist: '您访问的公告不存在或已被删除！',
    otherhelp: '此组别内的文章',
    scanforshare: '扫一扫分享',
    download: '立即下载',
    downloadslogan: 'NTLAPP - 全球数字资产交易平台',
    pinned: '置顶',
    searchArticle: '搜索文章',
  },
  description: {
    title1: '金融安全',
    title2: '极速充提',
    title3: '全球服务',
    title4: '严选项目',
    message1: '全方位金融风控系统和防盗系统，冷热钱包、多签系统保证资金安全',
    message2: '充值提现最快3分钟完成，24H人工在线审核，保护客户不错过最佳投资机会',
    message3: '全球业务服务网络覆盖，助您投资全球加密资产，与全球用户交易',
    message4: '严格选择优质加密项目，为您过滤80%极高风险项目',
    message5: 'NTL(WWW.NTLEX.COM)国际数字加密资产交易平台手机客户端，专注掌上交易体验',
    scanqrcode: '扫描二维码，下载APP',
    aboutdesc1:
      'NTL(WWW.NTLEX.COM)是技术全球领先的数字资产交易平台，注册于开曼群岛，核心运营团队位于香港。NTL(WWW.NTLEX.COM)核心成员来自于顶级互联网和金融公司，大部分成员是深度比特币和区块链信仰者，我们深信区块链将改变传统垄断性金融体系，会建立一个更加民主、自治的社会结构。',
    aboutdesc2:
      'NTL(WWW.NTLEX.COM)平台拥有专业金融级别的交易架构，拥有自主研发的高并发内存撮合交易引擎，平台采用全冷充值钱包系统+多重签名+高防护DDOS攻击系统等交易架构，保证客户资产安全。',
    aboutdesc3:
      'NTL(WWW.NTLEX.COM)为了让客户更好的把握投资机会，客服部门内部采用扁平化管理，建立了极速服务响应机制，资产客服经理常年7*24H在线，为客户提供资产充值提现等服务，保障客户在25分钟内完成资产充提。',
    aboutdesc4:
      'NTL(WWW.NTLEX.COM)严格筛选优质项目并提供安全稳定的资产托管服务。NTL(WWW.NTLEX.COM)秉承着“诚实、公正、热情、开放”的理念，竭力为用户打造安全、可靠、高效和友好的极致交易所。',
    aboutdesc5:
      '尽管数字资产现在只在一个小小的圈子里，但是我们相信，这种每个人能够完全掌握的资产在未来一定能够流行起来，让我们一起努力并期待着！',
    aboutdesc6:
      'NTLex.com (Nano Token Liquidity) 是一家基于分散式自治组织（DAO）治理的国际化数字资产衍生品交易所，旨在为全球用户提供安全、稳定、便捷、公平、有信誉的区块链资产交易服务。',
    aboutdesc7:
      'NTL DAO 通过授权开曼基金会管理 NTLex.com，该治理结构比传统加密货币交易所更具优势，有效保证了交易所的公平公正及用户资产安全。管理团队成员来自全球多个国家和地区，核心骨干曾就职于Coinbase、Binance、MorganStanley、Amazon、Google等全球知名金融及科技企业，具有强大的全球战略运营能力和丰富的金融风险控制和管理经验。',
    aboutdesc8:
      'NTLex.com 提供币币、永续合约、现货、期权、NFT、理财借贷等一站式加密资产服务，并支持中、英、日、韩等多种语言版本。其技术方案基于 Amazon AWS 与 Google Cloud 等，能有效防止 DDOS 等攻击，支持动态和静态数据分离，热点数据缓存，异地容灾，平行扩展等功能。全球领先的技术架构保障了用户安全交易，并且拥有即时区块链资产存取功能，多币种地址共享技术，多重安全验证保护。同时，NTLex.com 拥有业内较低的区块链资产交易费用和提取费用。',
    aboutdesc9:
      '作为一家值得用户信赖的DAO治理交易所，NTLex.com 致力于全面保障用户交易信息安全和资产安全，为用户提供卓越的交易体验。',
    support: '技术支持',
    report: '投诉建议',
    service: '客户服务',
    apply: '上币申请',
    hhjh: '合伙计划',
    coop: '商务合作',
    community: 'NTL社区',
    wechat: '微信',
    addwechat: '添加 “bmate601” 为微信好友，进入微信福利社群',
    weibo: '微博',
    twitter: '推特',
    biyong: '币用',
    telegram: '电报',
    medium: 'Medium',
    reddit: 'Reddit',
  },
  feereturn: {
    ruletitle: '返还规则',
    rulecontent:
      '白皮书里已经对BHB的分配比例有详细的说明。51%比例的BHB通过“交易即挖矿”的方式逐步回馈给交易用户。一旦51%的BHB全部回馈完成，“挖矿”即自动终止。',
    recordtitle: '返还记录',
    recordcontent:
      '每日（UTC+8，以下同）都会将前一日的用户所产生交易手续费，100%折算成BHB返还给用户，折算价格按前一交易日BHB的均价（均价计算方式为总成交金额/总成交量）。我们将于每日上午11点，开始发放前一日交易手续费折合BHB的返还。',
  },
  finance: {
    saveCoins: '存币生息',
    info: '简单 高效 轻松赚币',
    whySaveCoins: '什么是存币生息？',
    order: '我的订单',
    current: '活期',
    periodical: '定期',
    save: '存入',
    introduce:
      '存币生息是NTL打造的一款金融产品。可以帮助用户利用闲置资产赚币。存币生息分为活期和定期。活期用户可以随时提取本金和利息，定期用户会按照存入时选择的时间进行结算，若提前提取会收取一定手续费。',
    ok: '确定',
    curDayRate: '日利率',
    curYearRate: '年利率',
    term: '年化率及期限',
    day: '天',
    quantity: '存入数量',
    inputQuantity: '请输入数量...',
    all: '全部',
    available: '可用',
    reminder: '温馨提示：',
    reminderCont1: '若提前结束定期订单，需支付',
    reminderCont2: '手续费。到期后系统将自动平仓，本金及利息将存入您的币币账户。',
    cancle: '取消',
    PositionRecords: '持仓记录',
    ClosingRecord: '平仓记录',
    ClosingPosition: '平仓',
    title1: '币种',
    title2: '数量',
    title3: '累计利息',
    title4: '创建时间',
    title5: '期限',
    title6: '最后结算时间',
    title7: '结束时间',
    title8: '操作',
    title9: '参考年化',
    title10: '参考日化',
    title11: '已投入',
    title12: '预期利息',
    title13: '到期时间',
    title14: '利率',
    title15: '实际利息',
    title16: '期限',
    title17: '平仓时间',
    title18: '类型',
    title19: '手续费',
    tip1: '确定结束此订单吗?',
    tip2: '您的定期订单还未到结束时间，提前结束需支付{0}%手续费，确定结束此订单吗？',
    depositQuantity: '请输入存入数量',
    cannot: '存入数量不能大于可用数量',
    aheadOfSchedule: '提前平仓',
    expire: '到期平仓',
  },
  Partnership: {
    PartnershipPlan: '合伙计划',
    becomePartner: '成为平台合伙人',
    info: '推广NTL，领取最高终身返佣机会 邀请好友注册，享受平台红利',
    moreService: '享受更多专属服务',
    title1: '一对一专属服务',
    title2: '实时数据追踪',
    title3: '合作共赢',
    introduce1: '专属运营和客服，24小时在线解决您在使用或推广过程中的问题。',
    introduce2: '实时展示收益情况，可视化数据看板。让您对推广情况认知清晰。',
    introduce3: '与粉丝共同邀请好友，发展并管理属于自己的团队。',
    invite: '邀请用户且每个用户交易50 USDT(人)',
    tip: '可获佣金/月',
    NTL: 'NTL优势',
    NTLTitle1: '最高可获得终身返佣',
    NTLTitle2: '全球领先交易平台',
    NTLIntroduce1:
      '你可以在社媒、社群等任何形式的媒介推广NTL平台，每一位你推荐的活跃交易者带给我们的收入，您将有机会获得终身返佣！',
    NTLIntroduce2:
      'NTL是一家基于分散式自治组织（DAO）治理的国际化数字资产衍生品交易所，为全球用户提供安全、稳定、便捷、公平、有信誉的区块链资产交易服务。',
    methods: '如何在NTL赚取佣金',
    method1: '方式一：填写表格，待NTL官方审查后，会有专业团队联系您。',
    method2: '方式二：邀请10名有效新用户。',
    stepBtn1: '表单申请',
    stepBtn2: 'Telegram申请',
    stepBtn3: '立即邀请',
    rule: '查看规则详情>',
    KOL: 'KOL加盟NTL 开启新宇宙',
    KOLText1: '全球化合伙人网络',
    KOLText2: '分布各个国家',
    KOLText3: '每月最高收益(USDT)',
    view: '查看佣金收益榜单',
    join: '立即加入',
    earn: '能赚多少',
    promoteNTL: '推广NTL',
    becomingPartner: '成为NTL合伙人',
    earnRebates: '赚取高额返佣',
    application: '申请',
    submit: '提交',
    name: '姓名',
    email: '邮箱',
    emailCode: '邮箱验证码',
    contact: '您的联系方式(社交账号或邮箱)',
    fans: '您的社群/社媒/社区有多少粉丝/成员/用户',
    community: '您的社群/社媒/社区(可查看您维护的社群/社媒/社区，若多个可用“；”隔开)',
    inputName: '请输入姓名....',
    inputemail: '请输入邮箱...',
    inputemailCode: '请输入邮箱验证码...',
    inputcontact: '请输入社交账号或邮箱...',
    inputfans: '请输入粉丝数量...',
    inputcommunity: '请输入社群/社媒/社区...',
    getCode: '获取验证码',
    share: '将邀请链接或邀请码分享给你的好友，或在社交媒体等其他渠道宣传。',
    trades: '当你邀请的用户交易后，你可从手续费中获取返佣收益。',

    congratulations: '恭喜您！',
    becomingAPlatformPartner: '成为平台合伙人',
    myRebates: '我的返佣',
    spotRebatePercentage: '币币返佣比例',
    futuresRebatePercentage: '合约返佣比例',
    accumulatedRebates: '累计返佣(USDT)',
    yesterdaysRebates: '昨日返佣',
    kolAndMemberInformation: 'KOL和会员信息',
    directKol: '直属KOL数',
    subordinateKol: '下属KOL数',
    directMember: '直属会员数',
    subordinateKolMember: '下属KOL会员数',
    directRechargeMember: '直属充值会员数',
    subordinateKolRechargeMember: '下属KOL充值会员数',
    totalMembers: '会员总数',
    totalRechargeMembers: '充值会员总数',
    newDirectMembersToday: '当天直属会员新增',
    newSubordinateKolToday: '当天下属KOL会员新增',
    forMoreInformationPleaseLogInToTheBackendToView: '更多信息请登录后台查看',
  },
  rule: {
    Breadcrumb1: '帮助中心',
    Breadcrumb2: '常见问题',
    Breadcrumb3: '合伙计划',
    Breadcrumb4: '文章',
    rule: '合伙计划规则',
    time: '发布于2023年2月19日丨更新于2023年7月19日',
  },
  helpCenter: {
    uzxsq: 'NTL社群',
    yhzc: '用户支持',
    help: '帮助中心',
    helpCenter: 'NTL帮助中心',
    card1: '修改登录密码',
    card2: '修改资金密码',
    card3: '绑定邮箱',
    hotProblem: '热门问题',
    novice: '新手必读',
    more: '更多',
    commonProblem: '常见问题',
    safe: '账户安全',
    article: '文章',
    searchResults: '搜索结果',
    toSubmite: '去提交',
    tip: '欢迎提交产品意见和Bug',
    search: '搜索',
    published: '发布于',
    updated: '更新于',
    inputProblem: '请输入问题',
    account: '账户管理',
    community: '新手社区',
    articleTime: '发布于{0} | 更新于{1}',
    toMyworkorder: '我的工单',
    googleVerification: '谷歌验证',
    setAFundPassword: '设置资金密码',
    forgotTheFundPassword: '忘记资金密码',
    nonsupport: '匿名账户不支持修改',
  },
  feedBack: {
    topic: '人人都是产品经理',
    topic2: '反馈意见',
    info: '可提出您使用过程中的产品意见和Bug',
    feedBack: '反馈意见',
    email: '邮箱(选填)',
    submit: '提交',
    error: '提交失败',
    photo: '图片或视频',
    title: '标题',
    inputTitle: '请输入标题...',
    inputContent: '请输入内容...',
    look: '查看',
  },
  // 反馈详情
  detail: {
    createTime: '创建时间',
  },
  MyWorkOrder: {
    customerService: '客服工单',
    info: '查看未解决及过去提交的问题',
    toBeProcessed: '待处理',
    processing: '处理中',
    completed: '已结束',
  },
  community: {
    joinCommunity: '加入NTL社群',
    info: '了解数字资产投资策略、区块链知识和行业趋势的最新资讯，与NTL客服在线交流。',
    english: '英文',
    chinese: '中文',
  },
  noviceCommunity: {
    noviceCommunity: '新手社区',
    topic: '玩转数字货币',
    info: '新手攻略、交易进阶、操作指南就看NTL新手学院',
    recharge: '充值USDT',
    secondUnderstanding: '秒懂币币交易',
    UContract: 'U本位合约',
    toRecharge: '去充值',
    toTransaction: '去交易',
    topicClass: '主题课堂',
    more: '查看更多',
    blockchain: '区块链词典',
    unresolved: '还未解决您的问题?',
    toHelpCenter: '去帮助中心',
    contactService: '联系NTL客服',
    noviceStrategy: '新手攻略',
    advancedTrading: '交易进阶',
    unresolved1: '还未解决？',
    join: '让您快速入门',
  },
  bzb_description: {
    title1: '分红权益',
    title2: '手续费折扣',
    title3: '优先认购',
    title4: '投票权',
    title5: '第一知情权',
    title6: '优先上币',
    title7: '共商决策',
    title8: '更多',
    desc1: '手续费分红、收益分红、上币分红、股权分红等',
    desc2: '交易手续费最高80%折扣返还奖励，细则待定',
    desc3: 'IEO首发及Boot促销Token优先认购权利',
    desc4: '参与NTL社区投票活动，包括但不限于上币、回购等',
    desc5: '持仓量达一定数量进SVIP群，跟大V零距离接触',
    desc6: '仅限超级节点，拥有推荐上币资格',
    desc7: '仅限超级节点，拥有共商重大决策权利',
    desc8: '更多权益，敬请期待',
  },
  footer: {
    wechatkefu: '微信客服',
    notice: '平台公告',
    changjian: '常见问题',
    shiyong: '使用协议',
    feilv: '费率说明',
    lianxi: '联系邮箱',
    biyong: '币用',
    gsmc: '全球优质数字资产交易所',
    bah: '沪ICP备13026899号-3',
    gsjj: '关于',
    gywm: '关于我们',
    jrwm: '加入我们',
    lxwm: '联系我们',
    yqlj: '友情链接',
    bzzx: '帮助中心',
    xsrm: '新手教程',
    czzn: '充值指南',
    jyzn: '交易指南',
    ptsm: '平台说明',
    fysm: '交易规则',
    fltk: '资费说明',
    wechat: '微信客服',
    xszn: '新手指南',
    cjwt: '常见问题',
    mztk: '免责条款',
    ystk: '隐私条款',
    tkxy: '条款协议',
    gybzb: '关于BZB',
    kfyx: '客服邮箱',
    swhz: '商务合作',
    sbsq: '上币申请',
    bzzl: '币种资料',
    tsjb: '投诉举报',
    apidoc: 'API接口',
    zwkf: '暂未开放',
    fwtk: '服务条款',
    yszc: '隐私政策',
    mzsm: '免责声明',
    lzjb: '廉政举报',
    zfqq: '执法请求',
    cp: '产品',
    fw: '服务',
    secondFuture: '秒合约',
  },
  plate: {
    title: '平台收入分配方案 (BHB 持有者权益)',
    content1:
      '正如白皮书所描述的，NTL会拿出平台的80% (扣除税费后) 的收入分配给NTL持有者，20%的收入用来支持平台的研发及运营。',
    content2:
      '收入分配以日为一个分配周期，2018年6月6日为首个分配日。之后的每一天，会将前一天累积的所有待分配收入，一次性的按比例分给BHB持有者',
    content3: '（注：1.此处的BHB仅指已经释放的/可流通的BHB，具体请参见【',
    content3_1: '关于BHB流通量/参与收入分配比例的说明',
    content3_2: '2.每小时（整点）快照并计算一次，收入分配执行为每天一次）。',
    hourFenpei: '今日分配收入折合',
    hourTotal: '今日平台总收入折合',
    xiangqing: '今日收入分配详情',
    yesterdaytit: '昨日天分配收入折合',
    yesterdaytotal: '昨日平台总收入折合',
    yesterdayxiangqing: '昨日收入分配详情',
  },
  rewardCenter: {
    rewardCenter: '奖励中心',
    aChanceToWinMorePrizes: '有机会赢得更多奖励',
    activity: '活动',
    singleDeposit: '单笔入金',
    singleDepositDescription1: '首次单笔充值满',
    singleDepositDescription2: '$，即可获得数字盲盒',
    myRewards: '我的奖励',
    learnMoreAboutRewards: '了解更多奖励规则',
    digitalCurrency: '数字货币',
    digitalCurrencyType: '数字货币',
    financialExperienceGold: '交易体验金',
    notIssued: '未发放',
    issued: '已发放',
    noAvailableRewards: '您暂时没有可用的奖励，邀请好友可获得盲盒',
    topUpNow: '去充值',
    commissionCard: '返佣卡',
    dikouquan1: '币币交易手续费抵扣券',
    dikouquan2: '合约交易手续费抵扣券',
    perpetualContractExperience: '永续合约体验金',
    leverageLimit: '杠杆 {0}{1}x 可用',
    unlimitedLeverage: '无限制',
    deductible: '已抵扣',
    useNow: '去使用',
    expired: '已失效',
    haveBeenUsed: '已使用',
    noDataAvailable: '暂无数据',
    inviteFriends: '邀请好友',
    commonProblems: '常见问题',
    whatIsRebateCardAndHowToUseIt: '什么是返佣卡？如何使用？',
    rebateCardUsageDescription:
      '返佣卡在有效期内可用于抵扣交易手续费，获取返佣卡后立即生效。在您产生交易手续费的次日，返佣卡将扣除相应额度并返还剩余的USDT至您的资金账户中，用完为止。',
    whereToViewDigitalCurrencies: '数字货币在哪查看？',
    digitalCurrenciesViewingDescription:
      '数字货币领取后，会直接发放至您的现货账户，可在“资产-现货账户”中查看。数字货币无到期时间。',
    rewardRules: '奖励规则',
    text1: 'NTL用户首次入金奖励说明',
    text2: '发布于2023年2月19日丨更新于2023年7月19日',
    text3: '100%新手奖励：',
    text4:
      '1、新用户完成首次入金可获得奖励（获取奖励形式及数量以页面展示为准；其中盲盒奖励为BTC，ETH，NTLC其中的任意一种代币,或返佣卡)；',
    text5: '2、入金方式包括：',
    text6: '（1）链上转账：从其他交易所或个人钱包向个人NTL账户充币，单笔订单金额达 35$；',
    text7: '3、获得的NTL平台币奖励为冻结状态',
    text8:
      '解锁规则：推荐好友进行合约或者现货交易，被邀请好友完成合约交易后，交易用户与上级邀请人即可解锁NTL平台币。解锁数量=交易产生的手续费/NTL平台币价格；',
    text9: '关于盲盒',
    text10: '1、盲盒是否会过期？',
    text11:
      '是，用户必须在收到盲盒后 30 天内领取，否则将过期。例如：如果您在 3 月 1 日 00:00 收到一个盲盒，则该盲盒将于 3 月 30 日 23:59 过期。过期后您将无法领取。',
    text12: '2、我可以获得多少个盲盒？',
    text13: '当您首次充币达 $50 时，即可获得 1 个盲盒。',
    text14: '更多盲盒获取方式，请关注我们的活动公告。',
    text15: '3、盲盒中有哪些类型的奖励？',
    text16:
      '盲盒中的奖励最高可达 $XXXX 等值数字货币，BTC，ETH，NTLC其中的任意一种代币,或手续费抵扣券。奖励类型可能会不时发生变化。',
    text17: '4、我如何查看盲盒中的数字货币奖励？',
    text18: '领取盲盒后，您可以前往NTL App > 用户中心> 奖励中心查看数字货币奖励。',
    text19: '活动条款',
    text20: '1.每位符合条件的用户限领一份奖励。',
    text21: '2.子账户不具备参与盲盒活动或领取任何奖励的资格。',
    text22:
      '3.与接受和使用奖励相关的任何及所有适用税费以及所有手续费和额外开支（包括连接、安装和/或服务费用）将由接受该奖励的用户承担。',
    text23: '4.当您满足所有要求并在注册NTL账户后 30 天内登录NTL App，奖励将立即发放。',
    text24:
      '5.如果NTL风控系统检测到您的账户存在作弊行为，则您为完成首次充币而执行的任何操作均将视为无效。NTL将阻止、暂停或终止任何存在作弊行为的账户。',
    text25: '6.NTL保留取消任何参与者参与本次活动和/或取消本次活动的权利，恕不另行通知。',
    text26: '7.如果本文件的翻译版本与英文版本之间存在差异，请以英文版本为准。',
    text27:
      '8.NTL保留以其认为合适的方式解释本活动条款的权利，并保留随时调整或修改本活动条款的权利。',
  },
  inviteNew: {
    winUpTo: '赢取高达',
    OfCryptoBlindBox: '数字货币盲盒',
    inviteFriendsAndGetCryptoBlindBox: '邀请好友赚佣金',
    inviteLink: '邀请链接',
    link: '链接',
    inviteCode: '邀请码',
    howToGetCryptoBlindBox: '如何领取数字盲盒',
    inviteFriends: '邀请好友',
    friendsNeedToDownloadAppAndRegisterWithInviterCode:
      '好友需下载APP并通过邀请人的邀请码注册NTLex.com账户',
    friendsLoginToApp: '好友登录App',
    bothPartiesUnlockBlindBoxUponFriendsLogin: '好友登录APP后，双方均可解锁盲盒',
    receiveBlindBoxRewards: '领取盲盒奖励',
    openNTLComAppInviteFriendsCheckReceivedBlindBoxes:
      '打开NTLex.com APP端-邀请好友，在盲盒领取记录中查看已领取盲盒',
    invitationRules: '邀请规则',
    inviteYourFriendsToJoinNTLComAndGetUpTo10000USDOfCryptoBlindBox:
      '1. 邀请您的好友加入NTLex.com，您可获得最高价值10000USD的数字货币盲盒。',
    step1EnterInviteFriendsPageShareInviteLinkOrCode:
      '第一步：进入“邀请好友”页面，将您的邀请链接或邀请码分享给好友。',
    step2FriendSuccessfullyRegistersAndBothGetBlindBoxWithin30Days:
      '第二步：好友成功注册后，在注册30天内登录NTLex.com APP，您和好友各获得一个盲盒。',
    step3FriendDeposits200USDInCryptoWithin30DaysBothGetBlindBox:
      '第三步：好友在注册30天内，单次充值大于等于$35的数字货币，您和好友可再各得一个盲盒。',
    eachBlindBoxContainsUpTo10000USDOfCryptoAndMore:
      '2.每个盲盒包含最高价值10000USD的数字货币，盲盒中包含BTC，ETH，NTLC等数字货币和返佣卡。数字货币奖励将在开启盲盒1小时内发放至您的资金账户中，您可前往“资产”查收您的奖励。',
    noLimitOnBlindBoxQuantityInviteMoreFriendsForMoreBlindBoxes:
      '3. 盲盒数量不设上限，邀请的好友越多，您获得盲盒越多，快去邀请更多的好友吧！',
    subAccountsCannotParticipateInThisPromotion: '4. 子账户不可参与该活动。',
    moreDetailedRulesAvailableAt: '更多详细规则请查看',
    uzxInviteFriendsRulesExplanation: '《NTL邀请好友规则说明》',
    inviteFriendsAndGetCommission: '邀请好友获得返佣',
    recommend1NewUserToRegisterAndGetCommissionRewards:
      '每推荐1个新用户成功注册NTL，邀请人即可享受被邀请人通过NTL在进行现货或合约交易时的返佣奖励，同时被邀请人也可享受自身交易时的返现奖励。',
    setFriendCommissionPercentageInApp: '可在App中设置好友返佣比例。',
    uzxCommissionRulesExplanation: '《NTL返佣规则说明》',
    promotionTools: '推广工具',
    invitePromotionalGraphics: '邀请宣传图',
    promotionalMaterials: '宣传素材',
    exclusiveQRCodeForAutomaticRegistration:
      '专属二维码，通过二维码注册自动成为下级好友，适合在各种群或论坛转发。',
    inviteFriendsRecord: '邀请好友记录',
    copy: '复制',
    acquired: '已获取',
    toBeAcquired: '待获取',
    invitePromoImage: '邀请宣传图',
    scanToLearnMore: '扫一扫了解更多',
    myInvitationCode: '我的邀请码：',
    downloadImageUsingScreenshotTool: '如无法正常下载图片，请使用截图工具保存',
    save: '保存',
    helpCenter: '帮助中心',
    faq: '常见问题',
    inviteFriendsAndPartnershipProgram: '邀请好友与合伙计划',
    articles: '文章',
    text_1: 'NTL邀请好友规则说明',
    text_2: '发布于2023年2月19日丨更新于2023年7月19日',
    text_3: '一、活动规则',
    text_4: '1. 邀请您的好友加入NTLex.com，您可获得最高价值10000USD的数字货币盲盒。',
    text_5: '第一步：进入“邀请好友”页面，将您的邀请链接或邀请码分享给好友。',
    text_6: '第二步：好友成功注册后，在注册 30 天内登录NTLex.com APP，您和好友各获得一个盲盒。',
    text_7: '第三步：好友在注册 30 天内，单次充值大于等于35$的数字货币，您和好友可再各得一个盲盒。',
    text_8:
      '充值数字货币：从其他交易所、个人钱包向NTLex.com账户单笔充值金额大于等于35$元。通过内部转账、红包、活动奖励、法币购买等方式获得的数字货币均不属于充值。注意：好友必须完成入金和登录NTLex.comAPP后方可获得盲盒。如果您的受邀好友注册30天内未登录APP，即使完成单笔入金大于等于35$，您与好友也无法获得盲盒。',
    text_9:
      '2. 每个盲盒包含最高价值10000USD的数字货币，盲盒中包含BTC，ETH，LTC，DOT，BNB等数字货币和返佣卡。数字货币奖励将在开启盲盒1小时内发放至您的资金账户中，您可前往“资产”查收您的奖励。',
    text_10: '3. 盲盒数量不设上限，邀请的好友越多，您获得盲盒越多，快去邀请更多的好友吧！',
    text_11: '4. 子账户不可参与该活动',
    text_12: '二、常见问题',
    text_13: '1. 盲盒不开启会过期吗？',
    text_14:
      '盲盒的有效期从获得时刻开始计算，30天有效期。例如1月1日上午00:00获得盲盒，过期时间为1月30日下午23:59。过期的盲盒无法开启，请在有效期内打开盲盒。',
    text_15: '2. 什么是惊喜盲盒？',
    text_16: '您邀请的好友入金达标后，你可获得惊喜盲盒，惊喜盲盒的奖励金额翻倍。',
    text_17: '3. 哪里可以看到我的盲盒数量？',
    text_18:
      '您可以通过“APP-邀请好友-我的邀请记录”查询您已获得的盲盒总数以及未过期且可打开的盲盒总数。',
    text_19: '4. 我最多可以获得多少盲盒？',
    text_20: '盲盒数量不设上限，邀请的好友越多，您获得盲盒越多，快去邀请更多的好友吧！',
    text_21: '5. 如何查看已获得的奖励？',
    text_22: '盲盒解锁后，奖励可前往“邀请好友主页-我的奖励”页面查看。',
    text_23: '6. 开启盲盒后，我能获得什么奖励？',
    text_24:
      '每个盲盒包含最高价值10000$的数字货币，盲盒中包含BTC，ETH，NTLC等数字货币和返佣卡。NTLex.com将不定期更新奖励，请以实际开启盲盒的奖励为准。',
    text_25: '7. 什么是返佣卡？如何使用？',
    text_26:
      '返佣卡在有效期内可用于抵扣交易手续费，获取返佣卡后立即生效。在您产生交易手续费的次日，返佣卡将扣除相应额度并返还剩余的USDT至您的资金账户中，用完为止。',
    text_27: '三、特别说明',
    text_28: '1. 您仅可通过邀请好友得盲盒活动获得盲盒奖励，通过其他活动邀请好友无法获得盲盒奖励。',
    text_29: '2. 子账户不可参与邀请及获得奖励。',
    text_30:
      '3. NTLex.com将严格审核用户的邀请行为，账户或设备存在疑似作弊行为将触发风控策略失去奖励资格，严重作弊行为可能导致您的账户被冻结。',
    text_31: '4. NTLex.com保留调整活动形式、活动奖励',
    text2_0: 'NTL返佣规则说明',
    text2_1: '发布于2023年2月19日丨更新于2023年7月19日',
    text2_2: '一、邀请人获得什么奖励？',
    text2_3:
      '每推荐1个新用户成功注册NTL，邀请人即可享受被邀请人通过NTL在进行现货或合约交易时的返佣奖励，同时被邀请人也可享受自身交易时的返现奖励。',
    text2_4: '二、邀请规则明细：',
    text2_5:
      '通过邀请链接邀请好友注册，当好友通过您推荐的链接注册时，即可获得您设定的交易费用折扣。',

    text2_6: '现货：',
    text2_7:
      '1、如果邀请人的NTL持仓锁仓总量小于100，则基础返佣率为50%，邀请人可以选择与受邀好友分享其中的0-50%；',
    text2_8:
      '2、如果邀请人的NTL持仓锁仓总量100到500，基础返佣率将增至70%，邀请人可选择与受邀好友分享其中的0-70%；',
    text2_8_new:
      '3、如果邀请人的NTL持仓锁仓总量大于500，基础返佣率将增至80%，邀请人可选择与受邀好友分享其中的0-80%。',
    text2_9: '合约：',
    text2_10:
      '1、如果邀请人的NTL持仓锁仓总量小于100，则基础返佣率为50%，邀请人可以选择与受邀好友分享其中的0-50%；',
    text2_11:
      '2、如果邀请人的NTL持仓锁仓总量100到500，基础返佣率将增至70%，邀请人可选择与受邀好友分享其中的0-70%；',
    text2_11_new1:
      '3、如果邀请人的NTL持仓锁仓总量大于500，基础返佣率将增至80%，邀请人可选择与受邀好友分享其中的0-80%。',
    text2_11_new2: '如用户想拿到更高返佣标准，可以申请成为NTL平台合伙人。',

    text2_12: '三、返佣规则说明：',
    text2_13:
      '1、被邀请人必须使用邀请人的专属推荐链接（二维码，推荐ID）注册成功后，被邀请人在NTL的现货或合约市场进行交易，邀请人即可从其交易费用中赚取推荐佣金。若注册时直接输入邀请人帐户ID，将无法享受返佣返现；',
    text2_14:
      '2、返佣返现将以实际交易时所收取的手续费币种，每日（GMT+8）0~2点结算发放，总佣金显示折合为U。合约交易后，奖励返还至现货帐户；',
    text2_15:
      '3、每日24小时内随机快照NTLC数量进行持仓总量统计，NTLC量为每日平均量（上一日期末和当日期末平均）；',
    text2_16: '4、当基础返佣比例进行升/降时，比例次日生效，基础比例根据NTLC持仓量确定；',
    text2_17:
      '5、最终解释权归平台所有，利用系统不可预见的Bug或规则漏洞进行的违规操作将被视为无效，平台有权进行追责。',
    text2_18: '6、被邀请人比例',
    text2_19:
      '风控说明：对于恶意刷量、拉平台存量大客户等行为将触发平台风控，平台将有权取消违规用户的返佣及返现。',
    transactionType: '交易类型',
    inviterUzxcAmount: '邀请人持NTLC数量',
    inviterBaseCommissionRate: '邀请人基础返佣比例',
    inviterReceivedCommissionRate: '邀请人返佣比例',
    inviteeCashbackRate: '被邀请人返佣比例',
    spot: '现货',
    contract: '合约',
    accountNumber: '账号',
    registrationBonus: '注册奖励',
    depositBonus: '入金奖励',
    custom: '自定义',
    notLoggedIn: '未登录',
    tipText: '您已成为平台合伙人，可在“合伙计划”中查看更多内容。',
    view: '查看',
  },
  summarize: {
    coinSummary: '币种概括',
    collapse: '收起',
    expand: '展开',
    officialLinks: '官方链接',
    officialWebsite: '官网',
    github: 'Github',
    blockExplorer: '区块浏览器',
    socialMedia: '社交媒体',
    popularityRank: '热度排行',
    marketValueRank: '市值排行排行',
    marketValue: '市值',
    circulatingSupply: '流通量',
    addToWatchlistRatio: '添加自选比例',
    historicalHighPrice: '历史最高价',
    historicalHighDate: '历史最高价日期',
    historicalLowPrice: '历史最低点',
    historicalLowDate: '历史最低点日期',
    firstIssuanceDate: '首次发行时间',
    ownedPublicChain: '所属公链',
    ownedPublicChain: '首次发行价',
    maximumSupplyMarketValue: '最大供应市值',
    flowRate: '流通率',
    tokenDistribution: '代币分配',
    maxSupply: '最大供应量',
    powMining: 'PoW 挖矿',
  },
  NTLDao: {
    welcomeNTL: '欢迎来到 NTL OpenGov',
    info: 'NTLex.com是一家由DAO驱动的全球加密货币交易所，致力于提供安全稳定的交易环境。通过DAO，我们的目标是消除中心化交易所的潜在风险，提高用户信任度和透明度。',
    joinWhitelist: '参加',
    limitedTimeFree: '限时免费领取空投治理代币',
    equity: 'NTL 治理代币持有人部分权益',
    proposalRights: '提案权',
    proposalRightsInfo:
      '治理代币持有人有权提出关于交易所和生态系统发展的提案。这可以包 括新功能的建议、改进用户体验的建议、扩展市场的建议等。',
    VotingRights: '投票权',
    VotingRightsInfo:
      '治理代币持有人将有权参与投票。他们可以投票决定关于交易所运营和 生态系统发展的重要事项，例如提案的接受或拒绝、超级代表的选举等。',
    VIP: '交易 VIP 权',
    VIPInfo:
      '持有一定数量的治理代币的用户将获得交易 VIP 权，享受一系列交易优惠， 如低手续费、高佣金奖励等。',
    DividendRights: '交易所项目分红权',
    DividendRightsInfo:
      '治理代币持有人享有交易所利润的分红权。这意味着他们可以从交易所的成功中分享利润，激励他们积极支持项目的发展。',
    circuitDiagram: 'NTL 治理代币路线图',
    GovernanceTokens:
      '治理代币在第一年内高达95%以上锁仓率，在第二年内预计高达90%锁仓率，这将保证治理代币价格随交易所发展稳步增长。',
    SecurityEmpowerment: '安全赋能',
    smartContracts: '智能合约',
    smartContractsInfo:
      '成员不受正式合同的约束，而是通过共同的目标和网络激励相互连接，这些规则是透明的，嵌入在开源软件中。',
    Timelocks: '时间锁（Timelocks)',
    TimelocksInfo:
      '资金可以被设定在某个时间点之前无法移动，为用户提供了额外的安全保障，以便对可疑活动做出反应。',
    MultiSig: '多重签名（Multi-Sig）钱包',
    MultiSigInfo:
      '去中心化自治组织(DAO)将用于交易所的决策，包括资金管理。DAO 成员通常是交易所的用户，他们可以投票决定交易所的经营决策。',
    ProjectRoadmap: '项目路线图',
    NTLExchange: '谁拥有NTLex.com交易所？',
    SharedOwnership: 'NTL DAO成员共同拥有！',
    NTLExchangeInfo:
      'Uzx的代币持有者（Uzx dao成员）拥有投票权，提案权，分红权。ntl dao决策委员会可以投票决定是否接受提案，审核提案。每个成员具有一票资格，重大决策需要三分之二票数通过，日常治理决策需要超过半数的票数通过。',
    NTLOpenGov: 'NTL OpenGov',
    introduce: '介绍',
    introduceInfo: '去中心化，社区治理',
    vote: '投票和治理',
    voteTitle: '所有决策均由NTL持有者制定',
    voteInfo:
      '我们将采用 DAO（去中心化自治组织）作为交易所的治理机制。这意味着所有代币持有者将有平等的权力参与交易所的运营和决策，确保了公平、开放和透明的治理环境。',
    decisionSystem: '决策系统',
    decisionSystemInfo:
      '为了有效管理和指导交易所的运营和发展，我们将设立一个由 20 个超级代表和 7 个创世成员组成的交易所 DAO 决策委员会。',
    RepresentativeTerm: '代表任期',
    RepresentativeTermInfo:
      '每位超级代表的任期将设定为一年。一旦超级代表的任期结束，社区的NTL 代币持有者将有机会投票选举新的超级代表。这种机制确保了决策委员会成员的持续流动性和民主性。',
    CandidateRequirements: '候选要求',
    CandidateRequirementsInfo:
      '成为超级代表的候选人，最低持币锁仓要求将不得低于 500 万枚 NTL 代币。这一要求旨在确保超级代表拥有足够的权益，并对交易所的长期成功有着共同的信念。',
    ProposalSystem: '提案系统',
    ProposalSystemInfo:
      '我们鼓励 DAO 的成员积极参与提出有关交易所发展的提案。这些提案可以涵盖各种方面，包括但不限于增加新功能、改进用户体验、扩展市场、提高安全性等。',
    construct: '开始建设',
    becomeMember: '成为我们社区的一员',
    becomeMemberInfo: '与优秀的开发者联系并加入最活跃的社区',
    whitePaper: 'NTL DAO 白皮书',
    whitePaperInfo: '共创 NTL 去中心化治理',
    download: '下载',
    modalTitle: '填写信息，参加空投，成为dao的一员',
    name: '名字(选填)',
    email: '邮箱',
    wallet: '钱包(请提供NTL平台内NTL的充值地址)',
    inputname: '请输入名字...',
    inputemail: '请输入邮箱...',
    inputwallet: '请提供NTL平台内NTL的充值地址...',
    days: '天',
    hours: '时',
    minutes: '分',
    seconds: '秒',
    // "tip1": "仅支持活跃地址(地址在polygon 链或以太链上有过交易，比如转帐或接收过Usdt或Eth，Matic等)",
    tip: 'NTL 致力于保护和尊重您的隐私，我们只会使用您的个人信息来管理您的帐户并提供您向我们请求的产品和服务。我们会不时就单击下面的“提交”，即表示您同意 NTL存储和处理上面提交的个人信息，以便为您提供所请求的内容。',
    stepTitle1: '发行5亿枚治理代币',
    stepTitle2: '4亿枚代币给创世纪成员',
    stepTitle3: '1亿枚代币给超级代表',
    stepTitle4: '交易奖励1亿枚代币',
    stepTitle5: '交易所营运持有3000万枚代币',
    stepTitle6: 'NTL发展基金持有2000万枚代币',
    stepInfo1: 'NTL DAO 将发行名为 NTL 的治理代币，总量限制为 5 亿枚，初始价格0.0416USDT。',
    stepInfo2: '1.4 亿枚 NTL 代币将分配给 7 个创世成员，每名成员 2000 万枚，发行后币锁仓2年。',
    stepInfo3:
      '2.1 亿枚 NTL 代币将按照每三个月释放 2100 万枚 NTL 代币给 2 个超级代表，释放后币锁仓1年，直至释放完毕。',
    stepInfo4:
      '全网奖励总额为 1 亿枚 NTL 治理代币，根据每日用户在交易所产生的手续费的1%比例同步释放。用户获得的奖励代币可以即时买卖。',
    stepInfo5:
      '交易所营运基金账户将持有 3000 万枚 NTL 代币，用于交易所前期的日常运营开支，包括市场活动和奖励优秀人才等。市场活动人员获得的奖励无锁仓要求，随时可以买卖。',
    stepInfo6: 'NTL DAO 的发展基金将持有 2000 万枚 NTL 代币，用于NTL.network 技术研发和社区建设。',
    NTLToken: 'NTL 代币',
    NTLTokenInfo:
      'NTL治理代币不仅是平台的数字货币，还是治理的核心代币。它将决定交易所的发展方向与未来，是汇集全球智慧，提升交易所核心竞争力的至胜法宝。',
    objectTitle1: '构建基础设施',
    objectTitle2: '平台推出和扩展',
    objectTitle3: '持续改进和发展',
    objectTime1: '(2023 年初 - 2023 年底)',
    objectTime2: '(2024 年 - 2025年)',
    objectTime3: '(2026年以后)',
    objectInfo1: '平台搭建',
    objectInfo2: '代币发行',
    objectInfo3: '安全审计',
    objectInfo4: '社区互动和透明度',
    objectInfo5: '交易所上线',
    objectInfo6: '跨链桥接',
    objectInfo7: '社区建设',
    objectInfo8: '技术升级',
    objectInfo9: '全球扩张',
    objectInfo10: '建设 NTL.network 公链',
    objectInfo11: '社区治理',
    emailCode: '邮箱验证码',
    inputemailCode: '请输入邮箱验证码',
    getCode: '获取验证码',
    reGetCode: '秒后重新发送',
    certificate1: '开曼群岛',
    certificate2: '公司注册证书',
  },
  tradingDrawer: {
    tradeMarketInfo: '交易/市场信息',
    orderCenter: '订单中心',
    tutorials: '教程',
    beginnerTutorials: '新手教程',
    layout: '布局',
    optionContractBilling: '期权合约账单',
    coinBasedContractBilling: '币本位合约账单',
    perpetualContractBilling: '秒合约账单',
    usdtBasedContractBilling: 'U本位合约账单',
    spotBilling: '现货账单',
    openOrders: '当前委托',
    historicalOrders: '历史委托',
    chart: '图表',
    orderTableLatestTrades: '订单表、最新成交',
    market: '市场',
    orderAssets: '委托、资产',
    positionOrdersAssets: '仓位、委托、资产',
    quickBuySell: '快捷买卖',
    pastResultsRealTimeQuotes: '往期结果、实时行情',
    current: '当期',
    trade: '交易',
    history: '历史',
  },
  price: {
    USDT_M: 'U本位合约',
    COIN_M: '币本位合约',
    secondFuture: '秒合约',
    options: '期权合约',
    '24hVolume': '24h交易量',
    '24hTurnover': '24h交易额',
    MarketCap: '市值',
    NTLRanking: 'NTL排行榜',
    Overview: '概览',
    Details: '详情',
    MainstreamCoins: '主流币',
    PopularList: '热门榜',
    ChangeRanking: '涨幅榜',
    NewCoinsList: '新币榜',
    TradingVolumeRanking: '成交额榜',
    More: '更多',
    addOptional: '添加自选',
    NTLCurrencyQuotation: 'NTL币种行情',
    understand: '了解数字货币最新价格、涨跌、市值、交易量等信息。',
  },
  transitionpage: {
    transtr: '升级维护中，请用PC访问电脑版。',
    open: '继续打开',
    cancel: '返回',
  },
  aboutUs: {
    paragraph1:
      'NTLex.com (Nano Token Liquidity) 是一家基于分散式自治组织（DAO）治理的国际化数字资产衍生品交易所，旨在为全球用户提供安全、稳定、便捷、公平、有信誉的区块链资产交易服务。',
    paragraph2:
      'NTL DAO 通过授权开曼基金会管理NTLex.com，该治理结构比传统加密货币交易所更具优势，有效保证了交易所的公平公正及用户资产安全。管理团队成员来自全球多个国家和地区，核心骨干曾就职于Coinbase、Binance、MorganStanley、Amazon、Google等全球知名金融及科技企业，具有强大的全球战略运营能力和丰富的金融风险控制和管理经验。',
    paragraph3:
      'NTLex.com 提供币币、永续合约、现货、期权、NFT、理财借贷等一站式加密资产服务，并支持中、英、日、韩等多种语言版本。其技术方案基于 Amazon AWS 与 Google Cloud 等，能有效防止 DDOS等攻击，支持动态和静态数据分离，热点数据缓存，异地容灾，平行扩展等功能。全球领先的技术架构保障了用户安全交易，并且拥有即时区块链资产存取功能，多币种地址共享技术，多重安全验证保护。同时，NTLex.com拥有业内较低的区块链资产交易费用和提取费用。',
    paragraph4:
      '作为一家值得用户信赖的DAO治理交易所，NTLex.com 致力于全面保障用户交易信息安全和资产安全，为用户提供卓越的交易体验。',
    paragraph5:
      '1.数字资产涉及重大风险，市场交易不确定性因素较多。同时由于数字货币总市值普遍偏少，价格易受到庄家控制以及全球各政府政策的影响而出现大幅波动。由于价格波动，您可能会有大额的盈利或亏损。任何数字资产都可能发生大幅度波动，甚至可能变得毫无价值。',
    paragraph6:
      '2.因各国法律、法规和规范性文件的制定或者修改，数字资产交易随时可能被暂停、或被禁止，用户使用NTL服务时请自行根据当地法律法规、政策性文件评估风险。',
    paragraph7:
      '3.用户参与数字资产交易，应当根据自己财务状况，仔细考虑、评估数字资产投资价值和投资风险，自行控制交易风险，并承担可能损失全部投资的经济风险。',
    email: '邮箱地址',
    service: '客户服务',
    support: '技术支持',
    application: '上币申请',
    suggestions: '投诉建议',
    Cooperation: '商务合作',
    media: '社交媒体',
    aboutUs: '关于我们',
    ContactUs: '联系我们',
  },
  aboutNTL: {
    service: '服务条款',
    Privacy: '隐私政策',
    Disclaimer: '免责声明',
    report: '廉政举报须知',
    Guidelines: '执法请求指南',
    report1:
      '集团公司各实体均致力于诚信且基于道德的最佳实践和原则（包括反贿赂和反腐败合规标准）开展业务。集团对贿赂和腐败零容忍度。',
    report2:
      '如您知悉或怀疑集团公司的任何员工或顾问或相关人士或其关联人，或声称其为集团公司员工或顾问的任何人士有向您索取贿赂的行为，请立即通知集团公司并将全部相关详情发送至邮箱 CS@NTLex.com',
    report3:
      '集团公司会对所有信息提供者及其提供的全部信息严格保密，并采取如下措施为您提供多重保障：',
    report4:
      '1.无论您主动还是被动向集团公司任何员工或其相关人士或该等人士的关联人提供不正当利益或好处，如在集团公司通过其他途径获悉该等信息之前，您主动向集团公司提供有效信息并说明情况，集团公司可以自行决定根据实际情形进行考量，继续与您进行商业合作。集团公司可以对您违反任何上述规定不予追究。',
    report5:
      '2.对于向集团公司提供的相关商业贿赂行为的信息一经查实，集团公司应根据商业贿赂行为的影响程度给予信息提供者酬金。',
    Guidelines1: '最新更新时间：2023年09月01日',
    Guidelines2: '仅适用于政府和执法机构：',
    Guidelines3: '1. 简介',
    Guidelines4: '正如我们的服务条款所披露的，NTLex.com 的服务由以下主体提供：',
    Guidelines5:
      'NTLEX.COM.（以下称 “公司”）是一家根据开曼群岛法律在开曼群岛注册成立的公司，该公司运营网站http://ntlex.com（以下称“本网站”或“网站”），该网站是一个专门供用户进行数字资产交易和提供相关服务（以下称“该服务”或“服务”）的平台。为了本协议表述之方便，公司和该网站在本协议中合称使用“我们”或“我们的”。',
    Guidelines6:
      '当需要时，我们会针对能提供授权证明的授权执法官员的请求做出回应。我们将根据相关服务条款、我们的隐私政策声明和任何适用的法律法规，逐案审核每个案例并进行合作。本执法请求指南介绍了授权的执法官员如何与我们联系，并请求客户信息和/或冻结用户的NTL账户。请注意，我们只会回复授权执法官员发给我们的请求。 如果涉及国际执法（如适用），国际执法机关需要提供正式的司法互助协定请求，以便于我们提供披露信息。',
    Guidelines7: '2. 执法请求',
    Guidelines8:
      '我们负责根据我们的服务条款、我们的隐私政策声明和任何适用的法律法规处理来自所有司法管辖区的执法请求。如果您是授权执法官员，对于所有与执法相关的请求，请联系以下电子邮件地址：CS@NTLex.com',
    Guidelines9: '3. 所需信息',
    Guidelines10:
      '为使我们可以处理您的请求，执法官员应使用含有政府域名的官方电子邮件地址发送电子邮件给我们，并在请求中至少提供如下信息：',
    Guidelines11: '执法机关的全称；',
    Guidelines12: '盖章的书面执法请求、法庭令或传票；以及',
    Guidelines13: '您的官方联系信息（电子邮件地址，电话号码）。',
    Guidelines14:
      '如果您能同时提供下列文件，您的请求可能会被加快处理：(i) 该执法官员被授权要求信息的证明（如授权证明）及其在执法机构的在职证明， (ii) 执法机构官员的身份证明（例如带有证件编号的证件照片及，带有内部证件号码的内部身份证件照片）的证明文件。执法官员应清楚地说明要求我们协助的内容，并提供足够的详细信息，以便我们可以妥善处理请求。',
    Guidelines15: '4. 执法紧急请求',
    Guidelines16:
      '对于非常紧急的请求，例如当某人存在死亡或严重人身危险时，请在电子邮件的主题行中说明紧急请求。',
    Disclaimer1: '风险提示',
    Disclaimer2:
      '1. 数字资产涉及重大风险，市场交易不确定性因素较多。同时由于数字货币总市值普遍偏少，价格易受到庄家控制以及全球各政府政策的影响而出现大幅波动。由于价格波动，您可能会有大额的盈利或亏损。任何数字资产都可能发生大幅度波动，甚至可能变得毫无价值。',
    Disclaimer3:
      '2. 因各国法律、法规和规范性文件的制定或者修改，数字资产交易随时可能被暂停、或被禁止，用户使用NTL服务时请自行根据当地法律法规、政策性文件评估风险。',
    Disclaimer4:
      '3. 用户参与数字资产交易，应当根据自己财务状况，仔细考虑、评估数字资产投资价值和投资风险，自行控制交易风险，并承担可能损失全部投资的经济风险。',
    Disclaimer5: '法律声明',
    Disclaimer6: '一、权利归属',
    Disclaimer7:
      '除非NTL另行声明，NTL内的所有产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括版权、商标权、专利权、商业秘密及其他相关权利）均归NTL服务提供者及/或其关联公司所有。未经NTL服务提供者及/或其关联公司许可，任何人不得以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载等方式擅自使用NTL内的任何内容。NTL文字及标识，以及NTL的其他标识、徽记、产品和服务名称均为NTL服务提供者及/或其关联公司商标，如有宣传、展示等任何使用需要，您必须取得NTL服务提供者及/或其关联公司事先书面授权。',
    Disclaimer8: '二、责任限制',
    Disclaimer9:
      '鉴于NTL系提供一个包含数字资产的在线交易平台，用于交易数字资产和衍生品。NTL作为“网络服务提供者”的第三方平台，不担保网站平台上的信息及服务能充分满足用户的需求。NTL转载的作品（包括用户评论）出于传递更多信息之目的，并不意味我们赞同其观点或已经证实其内容的真实性。',
    Disclaimer10:
      '1、用户理解并同意，NTL的服务是按照现有技术和条件所能达到的现状提供的。NTL会尽最大努力向用户提供服务，确保服务的连贯性和安全性；但NTL不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。NTL因下列情形无法正常运作，致使用户无法使用各项服务时，NTL将免于承担任何损害赔偿责任，该情形包括但不限于：',
    Disclaimer11: '1）．NTL平台公告之系统停机维护期间；',
    Disclaimer12: '2）．电信设备出现故障不能进行数据传输的；',
    Disclaimer13:
      '3）．因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成NTL平台系统障碍不能正常运作的；',
    Disclaimer14:
      '4）．由于黑客攻击、计算机病毒侵入或发作、电信部门技术调整或故障、网站升级、银行方面的问题、因政府管制而造成的暂时性关闭等影响网络正常经营的原因而造成的服务中断或者延迟；',
    Disclaimer15: '5）．因为行业现有技术力量无法预测或无法解决的技术问题而造成的损失；',
    Disclaimer16: '6）．因第三方的过错或者延误而给用户或者其他第三方造成的损失。',
    Disclaimer17:
      '由于系统故障，网络原因，DDos等黑客攻击等意外因素可能导致的异常成交，行情中断，以及其他可能的异常情况，NTL有权根据实际情况取消异常成交结果，以及回滚某一段时间的所有成交。',
    Disclaimer18:
      '2.用户在使用NTL服务时已充分了解交易风险并同意在任何情况下，NTL就以下各事项不承担任何赔偿责任：',
    Disclaimer19: '1)收入的损失；',
    Disclaimer20: '2)交易利润或合同损失；',
    Disclaimer21: '3)业务中断；',
    Disclaimer22: '4)预期可节省的货币的损失；',
    Disclaimer23: '5)信息的损失；',
    Disclaimer24: '6)机会、商誉或声誉的损失；',
    Disclaimer25: '7)数据的损坏或损失',
    Disclaimer26: '8)购买替代产品或服务的成本；',
    Disclaimer27: '9)数字资产消亡或退出市场。',
  },
  // 交易费率
  TransactionRate: {
    TransactionRate: '交易费率',
    VIPgrade: 'VIP等级:',
    holdingAmount: '昨日NTL持有均量',
    turnover: '30日内成交量',
    info: 'NTL根据NTL持有量、交易量划分不同的等级。不同的等级决定了接下来一天的交易手续费。',
    transaction: '交易',
    withdrawal: '充值提现',
    title1: '等级',
    title2: 'NTL持有均量',
    title3: '30日成交量',
    title4: 'Maker/Taker',
    title5: '手续费折扣',
    title6: '全称',
    title7: '主网类型',
    title8: '最小提现数量',
    title9: '充值手续费',
    title10: '提币手续费',
    searchCoin: '搜索币种',
    searchLevel: '搜索等级',
    free: '免费',
    noFind: '未找到',
  },
  // 模拟交易
  mockTrading: {
    demoTrading: '模拟交易',
    demoTradText: '安全交易，零风险',
    realTransaction: '实盘交易',
    exitSimulation: '您已退出模拟交易',
  },
  // uzxLab
  uzxLab: {
    topText:
      'NTL LAB 致力于挖掘和投资潜力巨大的区块链项目，首期基金规模达500万美元，专注于发现并扶持区块链领域的优质创新。',
    aboutUs: '关于我们',
    meText1:
      '基金将重点投资于区块链领域，专注于底层基础设施、Layer2解决方案、去中心化金融（DeFi）、Web 3.0、非同质化代币（NFT）以及元宇宙等领域的项目发掘和资助。',
    meText2:
      'NTL LAB 致力于探索和投资区块链领域最尖端的产品技术创新项目，旨在推动全球区块链基础技术的发展。我们渴望与怀揣梦想、充满执行力的区块链创业者携手成长，共同探索未来的可能性。同时，我们将毫不保留地分享我们的全球资源和丰富的行业经验，助力创业者在这个充满活力的领域中取得成功。',
    direction: '投资方向',
    dcTitle1: '基础设施类',
    dcText1: 'Layer2，解决技术性能，提升效率和扩容问题',
    dcTitle2: '交易及金融项目',
    dcText2: '衍生品/Dex/借贷/合成资产/预言机/保险',
    dcTitle3: '热门公链生态类项目',
    dcText3: 'Polkadot生态/Solana生态/Near生态/Polygon生态/Avalanche生态',
    dcTitle4: '应用类',
    dcText4: '区块链浏览器/钱包/NFT/GameFi/SocialFi',
    dcTitle5: 'Web3.0 工具类',
    dcText5: '数据协议/技术服务商/插件/隐私保护',
    support: '对项目的支持',
    haiwai: '海外资源',
    haiwaiText:
      '我们为创业者提供广泛的全球资源接入，覆盖中国本土、硅谷、日本、韩国、东南亚等关键市场。我们与这些地区的媒体和KOL建立了紧密的全球资源网络，致力于为创业者提供多角度的支持。无论是在产品和服务的确认或完善，还是在建立有价值的战略伙伴关系、连接潜在客户群、吸引投资者、寻找潜在收购方或商业顾问方面，我们都能提供关键的帮助和指导。我们的目标是帮助创业者在全球范围内扩展视野，实现他们的商业目标。',
    shengtai: '生态资源',
    shengtaiText:
      '我们致力于促进交易平台对我们深度参与和投资的项目提供生态资源支持。这些项目将被优先推荐至相关平台，一旦通过审核，便有机会免费享受交易所提供的各类产品服务、宣传资源、用户流量和品牌支持等。我们将针对每个项目的独特特性，从平台上线的初期开始，制定详细的推广方案，以全面展示项目优势，不仅吸引平台用户，还将面向全球区块链行业的广泛用户群体。',
    fazhan: '发展建议',
    fazhanText:
      '我们的使命单一而明确：协助创业者打造一家真正强大的企业。对我们而言，创业者的成功便是我们的成功。我们深刻理解，相互之间的信任是成功的基石。因此，我们承诺在工作中始终秉持诚信和透明的原则，与创业者坦诚相待，共同迈向成功。',
    lianxi: '联系我们',
  },
  official: {
    officialChannel: '官方验证渠道',
    officialChannel2: '非官方验证渠道',
    ocDoc:
      '为避免钓鱼及网络诈骗给您带来资产损失，您可在此页面验证邮箱地址，或X、Telegram等社交账号是否为NTL官方认证渠道。',
    ocPlaceholder: '请输入正确的信息和格式进行验证',
    ocCheck: '验证',
    notPass: '您查询的【{0}】不是NTL官方渠道',
    tips: '请核实您输入的信息和格式是否正确：',
    tips2: 'Telegram/X：@xxxxx（有@符号）',
    tips3: 'Telegram群：https://xxxxx（网站链接）',
    doc: '*请不要将您的账号信息和密码透露给任何人',
    doc2: '*谨防诈骗，谨慎操作',
  },
  country: {
    cou1: `美国`,
    cou2: `日本`,
    cou3: `德国`,
    cou4: `英国`,
    cou5: `印度`,
    cou6: `法国`,
    cou7: `意大利`,
    cou8: `加拿大`,
    cou9: `韩国`,
    cou10: `俄罗斯`,
    cou11: `澳大利亚`,
    cou12: `巴西`,
    cou13: `中国香港`,
    cou14: `中国澳门`,
    cou15: `中国台湾`,
    cou16: `西班牙`,
    cou17: `墨西哥`,
    cou18: `印度尼西亚`,
    cou19: `荷兰`,
    cou20: `瑞士`,
    cou21: `沙特阿拉伯`,
    cou22: `土耳其`,
    cou23: `伊朗`,
    cou24: `波兰`,
    cou25: `瑞典`,
    cou26: `比利时`,
    cou27: `泰国`,
    cou28: `尼日利亚`,
    cou29: `奥地利`,
    cou30: `爱尔兰`,
    cou31: `以色列`,
    cou32: `挪威`,
    cou33: `阿根廷`,
    cou34: `菲律宾`,
    cou35: `阿拉伯`,
    cou36: `埃及`,
    cou37: `丹麦`,
    cou38: `马来西亚`,
    cou39: `新加坡`,
    cou40: `越南`,
    cou41: `孟加拉国`,
    cou42: `南非`,
    cou43: `智利`,
    cou44: `芬兰`,
    cou45: `哥伦比亚`,
    cou46: `罗马尼亚`,
    cou47: `葡萄牙`,
    cou48: `新西兰`,
    cou49: `希腊`,
    cou50: `卡塔尔`,
    cou51: `白俄罗斯`,
  },

  authentication_title: '身份认证',
  authentication_tip: '请认证身份信息',
  authentication_bind: '认证',
  authentication_has: '已认证',
  authentication_no: '未认证',
  authentication_real_name: '真实姓名',
  authentication_real_name_placeholder: '请输入真实姓名',
  authentication_real_name_error: '请填写正确的真实姓名',
  authentication_real_type: '证件类型',
  authentication_real_card: '证件号码',
  authentication_real_card_placeholder: '请输入证件号码',
  authentication_real_card_error: '请填写正确的证件号码',
  authentication_card_image: '证件照片',
  authentication_front_image: '证件正面照',
  authentication_reverse_image: '证件反面照',
  authentication_hand_image: '手持证件证照',
  authentication_card_image_error: '请上传正确的证件照片',
  authentication_submit: '提交',
  authentication_real_type_0: '身份证',
  authentication_real_type_1: '护照',
  authentication_real_type_2: '驾驶证',

  bind_phone: '绑定手机',
  bind_phone_tip: '请绑定手机号码',
  bind_phone_no: '未绑定',
  bind_phone_yes: '已绑定',
  bind_phone_number_error: '请输入手机号',
  bind_phone_code_error: '请输入手机验证码',
  bind_phone_pwd: '登录密码',
  bind_phone_pwd_tip: '请输入登录密码',
};
