module.exports = {
  index: {
    circulation: 'Safety and development fund circulation',
    hourEth: 'Cumulative revenue to be distributed in today',
    yesterdayEth: 'Cumulative revenue to be distributed in yesterday',
    yesterday: 'Mining output of the previous day:',
    bhbTotal: 'BHB Total flux',
    bhbSecond: 'BHB Two level market circulation',
    checkMineprinciple: 'View the principle of mining',
    checkFlowVolum: 'Inspection of circulation',
    checkLockPosition: 'Check the lock situation',
    BHBregister: 'NTL account registration',
    tibi: 'The time and limit of the payment of the money to the bill',
    line_plane: "Announcement on BHB's on-line plan, circulation and service fee return",
    fenpeijizhi: 'A description of the NTL income distribution mechanism',
    jiangli_jihua: 'Inviting the mining Award Scheme',
    friend_fanhuan: 'Invite friends, earn extra fee and return',
    recommendInfo: 'Recommend using application access for a better experience',
    download: 'Get it now',
  },
  transpage: {
    gw: 'Official website',
    h5: 'Web3 Exchange',
    down: 'Direct download',
    down1: 'Download for',
    file: 'File',
  },
  myfriends: {
    myhy: 'My friends',
    firsthy: 'First class',
    active: 'Activated',
    people: 'people',
    unactive: 'Not active',
    sehy: 'Second class',
    tx: 'Head picture',
    nickname: 'Nick name',
    createtime: 'createtime',
    status: 'Status',
    action: 'Action',
    charTime: 'Charge time',
  },
  Launchpad: {
    focuses:
      'It focuses on selecting high-quality blockchain emerging projects with the goal of assisting users to become early digital currency investors',
    ckwdzc: 'Look at my assets',
    yssxm: 'On-line project',
    ggb: 'Dogecoin means different things to different people. In essence, Dogecoin is an accidental cryptocurrency movement that brings happiness to people! Dogecoin is an open-source distributed digital currency that uses blockchain technology to achieve a highly secure decentralized system by using a network of computer nodes for bookkeeping.',
    LINK: 'ChainLink is the new hope for Smart contracts. ChainLink was designed in 2017 and its creators are Steve Ellis, Ari Juels and Sergey Nazarov. ChainLink is a decentralized transaction network for Prophecy machines. ChainLink connects blockchain smart contracts to real-world data, activity, and payment behavior.',
    AVAX: 'Avalanche is committed to increasing scalability without compromising speed or decentralization. Its core platform has three blockchains: transaction chain (X chain), contract chain (C chain) and platform chain (P chain). The X chain is used to create and trade assets, the C chain is used to create smart contracts, and the P chain is used to coordinate validators and subnets.',
    SOL: "SOL is Solana's native utility token, which the Solana network destroyed as part of a deflationary model. When transferring money or interacting with a smart contract, users need to pay a fee in SOL. SOL holders can also become network validators. Like Ethereum, Solana enables developers to build smart contracts and create projects based on the blockchain.",
    DOT: 'Polkadot has been described as an "open source protocol for all mankind" and describes itself as "the next stop in the development of blockchain technology." The Polkadot concept was originally proposed by Ethereum co-founder Dr. Gavin Wood. The development team wants to focus on the security, scalability and innovation of blockchain. To do this, you must create an infrastructure that supports new ideas and concepts while ensuring interoperability.',
    ATMO: 'Cosmos is a platform dedicated to connecting independent blockchain networks together. The platform facilitates the transfer of data between different blockchains to facilitate the so-called "blockchain Internet." ATOM is the native token of the Cosmos network and is used for transactional payments, governing voting, and collateral to protect the network.',
    jr: 'Join',
    jrnsdz:
      'If you are creating a blockchain project with innovative and unique potential, you can submit an application via the link below. NTL Launchpad, your blockchain asset distribution platform!',
    ljsq: 'Apply now',
    cjwt: 'Common problem',
    rhcy: 'How can I get involved ',
    text1:
      'First, you need to create a NTL personal account or log into an existing one; You need to hold a certain amount of NTL to pledge in order to participate in the event. After you log in to your account, you can find [Launchpad] in the platform and enter the activity homepage, view information and read details, just click the mouse/screen, you can complete the NTL pledge or release operation.',
    text2:
      'NTL Launchpad currently offers two types of activities: new coin mining and discount sales. The new coin mining mode allows users to earn tokens issued by the project by pledging NTL, each phase of the campaign has a different pledge cycle and total pledge limit, the more the number of pledges, the more tokens will be awarded. The discount sales model uses the "pre-subscription + lottery" method to distribute tokens. The whole process includes position statistics, subscription booking, reward calculation and reward distribution. It is worth noting that in this process, NTL was the only token officially accepted for participation in the event subscription. It should be emphasized that the new coin mining and discount sale activities are not applicable to users in some restricted countries/regions, and the specific users who can participate in the activities are subject to the event information published by NTL.',
    ynxhd: ' What types of activities are available?',
    gfwz: 'Official website',
    gw: 'Official website',
    bps: 'White paper',
    sjmt: 'Social media',
    jianjie: 'brief introduction',

    ggbxq:
      'Dogecoin is an open-source distributed digital currency that uses blockchain technology to achieve a highly secure decentralized system by using a network of computer nodes for bookkeeping.',
    ggbxq2:
      'Dogecoin is an open-source distributed digital currency that uses blockchain technology to achieve a highly secure decentralized system by using a network of computer nodes for bookkeeping.',
    ggbxq3:
      'More importantly, the spirit underlying Dogecoin, as stated in the Dogecoin manifesto, is an amazing, vibrant community of friendly people like you and me!',
    ggbxq4:
      "The Dogecoin community is dedicated to helping each other, being friendly, popularizing cryptocurrencies, finding fundraising support, being fun, forming beliefs, and turning the absurd into reality. Dogecoin's unofficial slogan sums it all up nicely: Daily Righteous Spirit.",
    ggbxq5:
      'The Dogecoin community is looking forward to the development of Dogecoin as a viable and legal alternative to traditional fiat currencies. To this end, the Dogecoin ecosystem will continue to expand in a dynamic and organic way, aiming to become a currency that is widely used by people, all for the people. At the same time, Dogecoin will also keep in mind its original intention to do the most interesting cryptocurrency!',
    ggbxq6:
      'The core spirit of Dogecoin is practical, elegant, warm and trustworthy, and I am very happy to meet you here.',

    LINKxq1:
      'ChainLink is the new hope for Smart contracts. ChainLink was designed in 2017 and its creators are Steve Ellis, Ari Juels and Sergey Nazarov. ChainLink is a decentralized transaction network for Prophecy machines. ChainLink connects blockchain smart contracts to real-world data, activity, and payment behavior.',
    LINKxq2:
      'In order to ensure the reliability and security of transactions, ChainLink provides reasonable solutions to improve the security of transactions.',
    LINKxq3:
      "ChainLink's platform allows its traders to leverage the full potential of smart contract algorithms and easily use them for a large number of multiple inputs and outputs.",
    LINKxq4:
      'However, the main feature of the system is the reliability of operation. Smart contracts require a connection to real-world information, so such contracts can establish protocols outside the blockchain system. In this process, smart contracts cannot avoid the use of prophetic machines (which act as intermediaries between on-chain and off-chain data).',
    LINKxq5:
      "When using centralized prophesies, users may encounter security risks because centralized prophesies lack a guarantee of reliability. This situation is ChainLink's best chance to make a difference.",
    LINKxq6:
      'ChainLink can validate and coordinate data from many sources to protect users from attacks by untrustworthy prophesies.',
    LINKxq7:
      "In addition to this, ChainLink has an embedded reputation system that provides the most appropriate predictor for the user's transaction.",
    LINKxq8:
      "ChainLink's native token name is'LINK'. 'LINK' is an ERC-20 token with additional ERC223 'transfer and call' features (address, uint256, bytes) that allow smart contracts to receive and process tokens in a single transaction.",
    LINKxq9:
      'LINK is used to pay the service fee of ChainLink Platform. ChainLink node operators can add their LINK tokens to the network, thus forming shares in the network. This approach has implications for reputation contracts: the larger the number of shares, the more requests the operator receives.',

    AVAX1:
      'Avalanche is committed to increasing scalability without compromising speed or decentralization. Its core platform has three blockchains: transaction chain (X chain), contract chain (C chain) and platform chain (P chain). The X chain is used to create and trade assets, the C chain is used to create smart contracts, and the P chain is used to coordinate validators and subnets.',
    AVAX2:
      'One of the most important breakthroughs in the protocol is Avalanche Consensus, where validators use repeated subsample voting to reach a quick and affordable consensus. Avalanche also uses subnets as a new way to scale out, allowing for the creation of customizable, interoperable blockchains. There is no limit to the number of possible subnets.',
    AVAX3:
      'Avalanche addresses three major issues: scalability, transaction fees, and interoperability.',
    AVAX4: 'Scalability versus decentralization level',
    AVAX5:
      'For a long time, traditional blockchain projects have struggled to find a balance between scalability and decentralization. If there is increasing activity on the network, it may soon become congested. Bitcoin (BTC) is a classic example: in times of network congestion, it can take hours or even weeks for users to complete a transaction.',
    AVAX6:
      'One way to solve this problem is to make the network more centralized, giving a subset of people more authority to verify network activity and achieve faster speeds. However, decentralization is essential for blockchain security. The new blockchain attempts to solve the problem through technological improvements, and Avalanche has also given a unique solution, which will be described below.',
    AVAX7: 'High cost',
    AVAX8:
      'Another common problem with large blockchains like Ethereum is the cost of fuel, which can increase as traffic pushes up. This will eventually discourage users from using these blockchains, but the competing ecosystems are also still immature. Ethereum, for example, continues to grow in popularity and strong competitors have not emerged, resulting in a congested network and high transaction fees. At one point, a typical single transfer on the Ethereum network cost more than $10. If complex smart contracts are involved, the cost can even exceed $100.',
    AVAX9: 'interoperability',
    AVAX10:
      "Different projects and businesses have different needs for blockchain platforms. Previously, blockchain projects could only choose between Ethereum, an independent blockchain that could not be adjusted to demand, or a private chain. Finding a balance between customizability and multi-chain collaboration has always been a difficult problem in the blockchain industry. Avalanche's solution is subnets, which offer the same security, speed and compatibility as mainnets.",

    SOL1: "SOL is Solana's native utility token, which the Solana network destroyed as part of a deflationary model. When transferring money or interacting with a smart contract, users need to pay a fee in SOL. SOL holders can also become network validators. Like Ethereum, Solana enables developers to build smart contracts and create projects based on the blockchain.",
    SOL2: 'SOL uses SPL protocol; SPL is the token standard for the Solana blockchain, similar to ERC20 in Ethereum. There are two main use cases for SOL tokens:',
    SOL3: '1. Pay the transaction fees incurred when using the network or smart contract.',
    SOL4: 'Act as a pledge token as part of the proof-of-stake consensus mechanism.',
    SOL5: 'Decentralized applications (DApps) built on Solana create new use cases for SOL and other tokens built using the SPL standard.',
    SOL6: 'However, the main feature of the system is the reliability of operation. Smart contracts require a connection to real-world information, so such contracts can establish protocols outside the blockchain system. In this process, smart contracts cannot avoid the use of prophetic machines (which act as intermediaries between on-chain and off-chain data).',
    SOL7: "When using centralized prophesies, users may encounter security risks because centralized prophesies lack a guarantee of reliability. This situation is ChainLink's best chance to make a difference.",
    SOL8: 'ChainLink can validate and coordinate data from many sources to protect users from attacks by untrustworthy prophesies.',
    SOL9: "In addition to this, ChainLink has an embedded reputation system that provides the most appropriate predictor for the user's transaction.",
    SOL10:
      'Since the launch of Mainnet Beta in 2020, the Solana ecosystem has grown tremendously. As of December 2022, there are 21,255 Github developer repositories using Solana, serving 8 million active users.',
    SOL11:
      "The giants of the traditional economy have announced their participation in Solana. These include Discord, where users' profiles link to the Solana Wallet, and ASICS, where users can purchase limited-edition shoe designs using the Solana Pay payment system. Solana has become the network of choice for multiple Web3 use cases with fast transactions and high throughput.",

    DOT1: "Polkadot has been described as an 'open source protocol for all mankind' and describes itself as 'the next stop in the development of blockchain technology.' The Polkadot concept was originally proposed by Ethereum co-founder Dr. Gavin Wood. The development team wants to focus on the security, scalability and innovation of blockchain. To do this, you must create an infrastructure that supports new ideas and concepts while ensuring interoperability.",
    DOT2: "The single blockchain within the Polkadot ecosystem is called a parallel chain (parallel blockchain) and the main chain is called a 'relay chain', and the two can always exchange information easily and quickly. Parallel chains are similar to independent shards in the Ethereum 2.0 project implementation.",
    DOT3: 'Developers, companies or individuals can customize parallel chains through Substrate, a framework for creating cryptocurrencies and decentralized systems. Once connected to the Polkadot network, the customized blockchain can interoperate with other parallel chains.',
    DOT4: 'With this design approach, building cross-chain applications, products and services becomes easier. Until now, transferring data or assets across chains on a large scale has been difficult.',
    DOT5: 'The data security and verification of parallel chains are completed by network validators, and a small number of validators can ensure the security of multiple parallel chains. The verifier is also responsible for ensuring that transaction information can be smoothly transmitted across multiple parallel chains, improving the scalability of the network.',
    DOT6: 'For developers, the Polkadot ecosystem has many advantages. The limitations of current blockchains create some core issues, including scalability, customization, interoperability, network governance, and upgradeability.',
    DOT7: 'In terms of scalability, Polkadot solves many problems. As a multi-chain network, it supports the parallel transmission of multiple independent blockchains, clearing a major obstacle in the field of blockchain technology. Parallel processing is a major breakthrough in the field of blockchain, which will pave the way for the global application of blockchain.',
    DOT8: "Users who need a custom blockchain can dig deeper into Polkadot's other features. Currently, a 'one-size-fits-all' blockchain infrastructure that meets all project needs does not exist. Each project has unique needs, and Polkadot is optimized to support individual blockchain functions. With Substrate support, developers can effectively adapt their individual blockchains to the needs of their projects.",
    DOT9: "Ensuring seamless connection between projects and applications and realizing data sharing is an important means to improve interoperability. While it's unclear what products and services will eventually emerge, there are plenty of potential use cases. Polkadot has the ability to create a whole new financial ecosystem where each parallel chain only needs to perform a specific function.",
    DOT10:
      'Specific parallel chains can be independently operated and managed by the relevant communities in a proprietary manner. In addition, each community is an integral part of the overall Polkadot network and has a significant impact on how Polkadot is governed in the future. The feedback gathered from the community will provide valuable input into the development of the Polkadot project.',
    DOT11:
      'In addition, Polkadot supports parallel chain escalation, which is extremely simple. The native chain eschews hard forks that can split communities, and instead upgrades smoothly in a frictionless manner.',

    ATMO1:
      "Cosmos is a platform dedicated to connecting independent blockchain networks together. The platform facilitates the transfer of data between different blockchains to facilitate the so-called 'blockchain Internet.' ATOM is the native token of the Cosmos network and is used for transactional payments, governing voting, and collateral to protect the network.",
    ATMO2:
      'Cosmos itself is not a blockchain, but rather an infrastructure for designing a dedicated blockchain (called a Zone) for applications and enterprises. It would be unrealistic to build a world of many blockchains if every blockchain had to implement all the networking and consensus code from scratch, so Cosmos offers a template software, the Cosmos SDK, that can handle these functions.',
    ATMO3:
      "Cosmos' years of development work on the SDK has made launching zones as easy as deploying smart contracts. But this approach is not unique to Cosmos, and other projects that combine application-specific blockchain ideas also offer developers 'blockchains in a box,' such as Polkadot's Substrate framework, which is similar to Cosmos's SDK.",
    ATMO4:
      'Tendermint, the consensus algorithm underlying the Cosmos network, is built as a high-performance BFT SMR (state machine replication) platform that replicates services, which can then be simulated as deterministic, non-random state machines. Tendermint Core was created to replicate specialized servers (or state). This is done through a special machine that replicates the servers and propagates them to the global Cosmos network. This process allows software engineers from Tendermint Core to replicate the blockchain on state machines around the world.',
    ATMO5:
      "Tendermint's significant technical capabilities allow developers to create their own blockchain platform without having to build everything from scratch. This is useful because it allows users to create any type of blockchain system they want, with almost everything already done for them except their application logic and tokens. Tokens hosted on the Cosmos Network include: Kava (KAVA), Terra (LUNA), Band Protocol (BAND), Aragon (ANT), and Akash Network (AKASH).",
    ATMO6:
      'ATOM tokens also play a key role in the functionality of the Tendermint Core BFT consensus mechanism, as Cosmos Hub is a proof-of-stake blockchain platform. Cosmos relies on a network of 100 verifier nodes to maintain consensus, security, and operational efficiency. In order for the system to work properly, users must pledge their ATOM coins.',
    ATMO7:
      'The role of the validator is to run a complete node, which validates the network rules and broadcasts votes to the network, and subsequently submits the new block to the blockchain. In turn, the verifier receives income in the form of ATOM based on the number of ATOM tokens pledged as collateral.',
  },
  AssetManagement: {
    Currency: 'Currency as deposit',
    AssetsAccount: 'AssetsAccount',
    analyze: {
      Date: 'Date',
      SelectDate: 'Select date',
      Currency: 'Currency',
      Balance: 'Balance',
      Today: 'Today’s PnL',
      CurrentAssets: 'Current assets',
      initialAssets: 'Todays initial assets',
      netInflowAssets: 'Todays net inflow assets',
      MonthlyProfit: 'Monthly profit/loss',
      Accumulated:
        'Accumulated profit/loss from the first natural day of the month 00:00 to today 00:00',
      HistoricalPL: 'Historical profit/loss',
      UpdatedAt: 'Updated at',
      returnRate: 'Cumulative return rate',
      CumulativeReturn: 'Cumulative return',
      InitialAssets: 'Initial assets',
      NetInflowAssets: 'Net inflow assets of the day',
      netTransferOfAssets: 'Net transfer of assets',
      CumulativeProfitlos: 'Cumulative profit/loss',
      Endingassets: 'Ending assets',
      Initialassets: 'Initial assets',
      DailyEarnings: 'Daily earnings',
      AssetsAt: 'Assets at the end of the day',
      oftheDay: 'Initial assets of the day',
      distribution: 'Asset distribution',
      AccountSumma:
        'Account summarizes the quantity and proportion of various assets, as well as their value converted into local currency.',
      lzkyl: 'Cumulative profit/loss rate',
      lzbtcqs: 'Cumulative BTC trend',
      lzky: 'Cumulative profit/loss',
      mryk: 'Daily profit/loss',
      zcfb: 'Asset distribution',
    },
    TotalAssets: {
      equity: 'Your equity currently is ',
      Deposit: 'Deposit',
      TotalAssets: 'Est total value',
      Today: 'Today’s PnL',
      Day: 'Day',
      Month: 'Month',
      HalfYear: 'Half year',
      classific: 'Asset classification',
      Spot: 'Funding',
      zd: 'History',
      Fund: 'Transaction history',
      ViewAll: 'View all',
      Recent: 'Recent records',
      Coin: 'Crypto',
      Depositamount: 'Deposit amount',
      Time: 'Time',
      NoData: 'No data available',
      SearchAssets: 'Search',
      hide0: 'Hide 0 balances',
    },
  },
  newmsg: {
    GeneralView: 'GeneralView',
    SafetyCenter: 'Safety',
    setting: 'Preferences',
    GeneralList: {
      nh: 'Hello',
      Hide: 'Hide Personal Information',
      Show: 'Show Personal Information',
      tradingFeeTier: 'Trading fee tier',
      Email: 'Email',
      YouCurrently: 'You currently have no assets, deposit to start your trading journey',
      Invite: 'Invite friends for a chance to enjoy lifelong commission rebates',
      Deposit: 'Deposit',
      Support: 'Support transfer between cryptocurrencies',
      transfer: 'Transfer',
      SupportsTransfers: 'Supports transfers between digital currencies',
      Market: 'Discover',
      Announ: 'Announcements',
      More: 'More',
      Invitation: 'Invitation Rewards',
      Partner: 'Affiliate',
      InviteFriends:
        ' Invite friends for a chance to enjoy the highest lifelong commission rebates',
      OpenTo: 'Open to KOLs, chance to become a platform partner',
      InviteNow: 'Invite Now',
      ApplyNow: 'Apply Now',
    },
    CenterList: {
      SecurityCenter: 'Security',
      SecuritySettings: 'Security Settings',
      Anonymous: 'Anonymous users can recover their account by binding an email',
      NotBound: 'Not bound',
      AlreadyBound: 'Already bound',
      LoginBassword: 'Login password',
      Device: 'Device management',
      Authenticated: 'Authenticated devices',
      Recent: 'Recent login time',
      LoginLocation: 'Login location',
      address: 'IP address',
      Actions: 'Actions',
      CurrentDevice: 'Current device',
      Recent10: 'Recent ten login records',
      Location: 'Location',
      AccountManagement: 'Account management',
      DeactivateAccount: 'Deactivate account',
      userTransaction:
        'user transaction, withdrawal, etc., for verification, please set and remember carefully',
      LogOut: 'Log out',
      DoYouWant: 'Do you want to delete the information of the current device?',
      AreYousure: 'Are you sure you want to deactivate this account?',
      AreYousure2: 'The operation will not be reversed!',
      his: 'Historical record',
      loc: 'Locations',
      IP: 'IP',
      datat: 'Date and time',
    },
    settingList: {
      PreferenceSettings: 'Preferences',
      Language: 'Language',
      QuoteCurrency: 'Quote Currency',
    },
  },
  common: {
    FindUs: 'Discover our products and services',
    registeredAccount: 'Register for an NTL account',
    unlock: 'Unlock NTL! Experience the power of anonymity',
    invitation: 'Invite your friends to join NTL',
    closeTheDeal: 'Complete the first transaction',
    sloganthree: 'Welcome to NTL!',
    slogantwo: 'Unleash the NTL Universe',
    slogantwo2: 'Transparent, worry-free security',
    currencySelection: 'form',
    chart: 'Chart',
    details: 'details',
    entrust: 'entrust',
    login: 'Log in',
    register: 'Register',
    loginregister: 'Login/Register',
    signupnow: 'Register',
    indexplaceholde: 'Email address',
    logout: 'Log Out',
    tip: 'Friendly reminder',
    logintip: 'please log in first',
    expect: 'Look forward to expectation!',
    delete: 'Are you confirm to delete?',
    nodata: 'No Records',
    set: 'Setting',
    update: 'Modify',
    slogan: 'The world’s leading cryptocurrency exchange',
    subslogan: 'State agency guarantees asset security',
    more: 'More',
    please: 'Please',
    pleaseselect: 'Select',
    searchplaceholder: 'Enter the coin symbol...',
    searchplaceholderswap: 'Enter the swap symbol...',
    loading: 'Loading...',
    close: 'Close',
    ok: 'OK',
    fillComplete: 'Please fill in the complete information',
    inputpleaseselect: 'Please enter',
    showDetail: 'View details',
    pleaseSelectTransferAccountFirst: 'Please select transfer account first',
  },
  financeNav: {
    cjspan1:
      'NTL DAO, by authorizing the Cayman Foundation to manage NTLEX.com, provides a governance structure that has advantages over traditional cryptocurrency exchanges, effectively ensuring the fairness of the exchange and the safety of user assets. The members of the management team come from many countries and regions around the world, and the core backbone has worked for the world famous financial and technology enterprises such as Coinbase, Binance, MorganStanley, Amazon, Google, etc., with strong global strategic operation capabilities and rich experience in financial risk control and management.',
    cjspan2:
      'NTLEX.com (Nano Token Liquidity) is an international digital asset derivatives exchange based on decentralized Autonomous Organization (DAO) governance.',
    cjspan3: 'NTL can do that for you',
    cjspan4:
      '· Multiple trading modes, such as coin trading, U-standard contract trading, coin standard contract trading, option contract trading, second contract trading.',
    cjspan5:
      '· Deposit coins introduced through the NTL platform yield interest, making idle assets in your hands become valuable.',
    cjspan6: '· Support anonymous user login to protect user privacy.',
    cjspan7:
      'NTLs technical solution is based on Amazon AWS and Google Cloud, which can effectively prevent DDOS attacks, support dynamic and static data separation, hotspot data caching, remote disaster recovery, parallel expansion and other functions. The worlds leading technology architecture guarantees users secure transactions, and has instant blockchain asset access, multi-currency address sharing technology, and multiple security verification protection. At the same time, NTLEX.com has the lowest transaction and withdrawal fees for blockchain assets in the industry.',
    Enhanced: 'Enhanced Trading Experience and User Asset Protection',
    WhatIs: 'What is NTL DAO?',
    WhatServices: 'What services does NTL provide?',
    IsNTL: 'Is NTL worthy of user trust?',
    WatchTutorials: 'Watch tutorials and master more knowledge about cryptocurrencies',
    BeginYour: 'Begin your trading journey',
    SignUp: 'Sign up now',
    ExploreThe: 'Explore the NTL Universe, trade anytime, anywhere',
    Deposit: 'Deposit',
    wdzc: 'My assets',
    zcgl: 'Assets',
    tzzx: 'Notification Center',
    cl: 'Examine',
    czszhb: 'Deposit',
    rmzc: 'Hot Assets',
    HotRank: 'HotRank',
    NewCoinRank: 'New',
    PriceGainRank: 'Gainers',
    CoinBase: 'CoinBase, U-Based Contracts, Ensuring Secure Trading',
    Simplified:
      'Simplified Options Contracts,Digital Asset Derivatives, Achieving Diversified Trading',
    SpotContract: 'Second future',
    Experience: 'Experience Thrilling Trading Moments',
    MakeMoney: 'Make money easily with idle assets',
  },
  header: {
    Exploration: 'Exploration',
    SecureAndStable: 'Secure and stable trading environment',
    HowToGovern: 'How to govern',
    Alldecisions: 'All decisions are made by token holders',
    UserCommunity: 'User community',
    SharedGrowth: 'Shared growth',
    CryptoConvert: 'Get More Digital Blind Boxes',
    InviteMates: 'Referral',
    SiteUpdates: 'Become a Platform Partner',
    PartnerProgram: 'Affiliate',
    NTL_SecureGuard: 'View All Rewards',
    RewardHub: 'Rewards center',
    potential: 'Potential development project',
    ExploreMore: 'More',
    NTL_Connect: 'NTL Connect',
    CoinCommunity: 'Service',
    CryptoTradeInsights: 'CryptoTradeInsights',
    HelpHub: 'Support',
    Aid: 'Help',
    RookieHub: 'Learn',
    ProjectProspect: 'ProjectProspect',
    AssetValor: 'AssetValor',
    EarningsEdge: 'Earn',
    cunru: 'deposit',
    SpotGoods: 'Funding',
    AssetsOverview: 'Assets overview',
    UCon: 'USDT-M',
    StableTrust: 'StableTrust SmartSelect',
    CoinCon: 'COIN-M',
    TrendMaster: 'TrendMaster SmartInvest',
    OptionC: 'Options',
    EasyOptions: 'EasyOptions, Trade in Few Steps',
    QuickOptions: 'Second future',
    ManageMoneyAffairs: 'Financial',
    AssetAnalysis: 'Analysis',
    InstaWave: 'QuickWave Market Trading',
    OrderCenter: 'Order center',
    gjhgpt1: 'Discover',
    gjhgpt2: 'Market',
    gjhgpt3: 'Latest cryptocurrency market trends',
    gjhgpt4: 'Opportunities',
    gjhgpt5: 'Currency rankings, discover opportunities',
    gjhgpt6: 'one-click transactions',
    gjhgpt7: 'Support for multiple currencies, easy transactions',
    index: 'Home',
    exchange: 'Spot',
    trade: 'Trade',
    sq: 'Community',
    otc: 'OTC',
    kefu: 'Support',
    xiaoxi: 'Message',
    login: 'Log in',
    register: 'Register',
    asset: 'ASSET',
    otcOrder: 'ORDER',
    ucenter: 'ACCOUNT',
    service: 'Announcement',
    downloadapp: 'Scan the code for download and installation',
    appdownlaod: 'Download APP',
    usercenter: 'User Center',
    helpcenter: 'Support',
    aboutus: 'About',
    partner: 'Partner',
    bzb: 'BZB ECO',
    assetmanage: 'Finance',
    trademanage: 'Exchange',
    activity: 'Lightning',
    lab: 'NTL Lab',
    ctc: 'C2C',
    labdetail: 'NTL Lightning Lab',
    invite: 'Promoting Partner',
    whitepaper: 'White Paper',
    scandownload: 'Download App',
    innovationmanage: 'Innovation',
    swap1: 'USDT-M',
    swap2: 'COIN-M',
    swap3: 'CFD',
    swap4: 'Second future',
    znum: 'AllNum',
    cexchange: 'Convert',
    option: 'Options',
    second: 'Second future',
    news: 'News',
    swapExchange: 'Futures',
    finance: 'Grow',
    fiatCurrency: 'Buy Crypto',
  },
  sectionPage: {
    global_leader: 'The world’s leading digital currency exchange',
    Professional:
      'Professional trading, easy to operate, let you easily understand digital trading',
    ptaqTitle: 'Platform Security',
    ptaqContent:
      "The hash algorithm is used to encrypt the world's leading security authentication",
    ptslTitle: 'Platform Strength',
    ptslContent: "The world's largest bitcoin trading platform",
    newsTitle: 'News Center',
    brandTitle: 'About NTL',
    brandTitle1: 'International digital asset derivatives exchange',
    brandTitle2: 'One stop encryption asset service',
    brandDetail: 'Honest  |  Fair  |  Enthusiasm  |  Open',
    brandDesc1:
      'NTL was founded by a group of early Bitcoin participants and geeks. The core members of the team are from well-known enterprises such as Google, Microsoft, Alibaba, Tencent and so on. It has deep research and development strength and rich experience in Internet product operation.',
    brandDesc2:
      'NTL is located in the basic service provider of block chain, dedicated to providing high-quality encrypted assets trading platform for global users, adhering to the basic principle of “DO NOT BE EVIL”, upholding honest, fair and enthusiastic service to customers, and welcoming all partners/projects that are beneficial to the fundamental interests of users with an open attitude.',
    brandDesc3:
      "NTL is committed to being a safe, stable, convenient, fair and reputable blockchain asset international exchange worthy of users' trust, and comprehensively guaranteeing the security of users' trading information and assets.",
    brandDesc4:
      'NTL currently has one-stop crypto asset services such as currency, perpetual contract, spot, option, financial lending, and supports multiple language versions such as Chinese/English/Japanese/Korean/.',
    brandDesc5:
      "NTL's technical solution is based on Google Cloud and Amazon AWS, which effectively prevents DDOS attacks and supports the separation of dynamic and static data; It supports hot data caching, remote disaster recovery, and parallel expansion. The world's leading technical architecture ensures users' safe transactions.",
    brandDesc6:
      'NTL has real-time blockchain asset access function, multi currency address sharing technology, multiple security verification protection, and low transaction and extraction costs of blockchain assets in the industry.',
    gettingstart: "HOT | Beginner's Guide",
    officialstart: 'NTL Official Tutorial',
    oneminutebuy: 'Spot',
    oneminutebuytips: 'Trade Crypto with advanced tools',
    baseknow: 'Perpetual contract',
    baseknowtips:
      'U-standard and dollar-based perpetual contracts support two-way positions, with up to 100 times leverage, allowing you to magnify gains by going long and short.',
    baseexchange: 'Options',
    baseexchangetips: 'Crypto Derivatives',
    usersocial: 'Convert',
    usersocialtips: 'The easiest way to trade',
    mainboard: 'Main Board',
    preview: 'Previous',
    nextpage: 'Next',
    downloadwhite: 'Download White Paper',
    showMore: 'More',
  },
  nav: {
    ptjy: 'Platform Trade',
    ptaq: 'Platform Security',
    ptsl: 'Platform Strength',
    xwzx: 'Multi-terminal APP',
    fabu: 'Place an ad',
    shouye: 'Home',
    jiaoyi: 'OTC Trade',
    dingdan: 'Order Management',
    zijin: 'Financial Center',
    yonghu: 'User Center',
    bibi: 'Spot',
  },
  progress: {
    already: 'Have to dig',
    total: 'Amount of excavable ore 6 billion BHB',
  },
  service: {
    USDT: 'USDT',
    BTC: 'BTC',
    ETH: 'ETH',
    CUSTOM: 'Favorites',
    COIN: 'Crypto',
    favor: 'Favorites',
    symbol: 'Pair',
    NewPrice: 'Price',
    ExchangeNum: '24h Volume',
    FeePercent: 'Funding rate',
    Change: '24h Change',
    OpenPrice: 'Open Price',
    PriceTrend: 'Price Trend',
    Operate: 'Operation',
    Exchange: 'Trade',
    trading: 'Trade',
    high: '24h High',
    low: '24h Low',
    Mainstream: 'Top',
    HotList: 'Hot List',
    PriceIncrease: 'Gainers',
    SGDLeaderboard: 'New',
    TurnoverList: 'Turnover',
  },
  exchange: {
    loginBuy: 'Buy After Login',
    loginOut: 'Sell after login',
    coin: 'Crypto',
    symbol: 'Pair',
    lastprice: 'Lastest',
    daychange: '24h Change',
    market: 'Market',
    favorite: 'Marked',
    do_favorite: 'Collected',
    cancel_favorite: 'Cancel Collected',
    type: 'Type',
    dealamount: 'Total',
    high: 'High',
    low: 'Low',
    vol: '24H Vol',
    buy: 'Buy',
    sell: 'Sell',
    limited_price: 'Limit order',
    market_price: 'Market order',
    fees_rate: 'fee rate',
    balance: 'Assets',
    or: 'or',
    starttrade: 'Trade Now!',
    canuse: 'Available',
    recharge: 'Deposit',
    buyprice: 'Buy Price',
    buynum: 'Amount',
    amount: 'Amount',
    totalAmount: 'Total amount entrusted',
    buyin: 'Buy',
    disablebuyin: 'Market Buy Disabled',
    buytip: 'Optimum Buy',
    sellprice: 'Sell Price',
    sellnum: 'Sell Amount',
    sellout: 'Sell',
    disablesellout: 'Market Sell Disabled',
    selltip: 'Optimum Sell',
    curdelegation: 'Orders',
    hisdelegation: 'History',
    realtransaction: 'Market Trades',
    num: 'Amount',
    price: 'Price',
    direction: 'Side',
    time: 'Time',
    stall: 'Stall',
    total: 'Total',
    traded: 'Filled',
    action: 'Action',
    undo: 'Cancel',
    delegationnum: 'Delegate Amount',
    done: 'Filled',
    status: 'Status',
    finished: 'Total',
    canceled: 'Canceled',
    parted: 'Parted',
    tip: 'Tip',
    buyamounttip: 'enter buy amount',
    buyamounttipwarning: 'buy amount not more',
    success: 'submit success!',
    pricetip: 'enter buy price',
    pricetipwarning: 'buy price not more',
    sellamounttip: 'enter sell amount',
    sellamounttipwarning: 'sell amount not more',
    sellpricetip: 'enter sell price',
    sellpricetipwarning: 'sell price not more',
    withdrawalPrompt: 'Withdrawal prompt',
    undotip: 'Confirm Undo?',
    cancelsuccess: 'Cancel order Successful',
    marketprice: 'Market Price',
    expand: {
      time: 'time',
      price: 'Price',
      amount: 'amount',
      fee: 'fee',
    },
    realtime: 'realtime',
    kline: 'KLine',
    depth: 'Depth',
    publishstate0: 'It is about to begin. Please wait....',
    publishstate1: 'It is about to end. Please wait...',
    publishstate2: 'Order consolidation, Please wait...',
    publishstate3: 'Consolidation is over, Please trade free....',
    publishdetail: 'More details',
    publishprice: 'Price',
    publishamount: 'Amount',
    publishcounttxt0: 'Start After',
    publishcounttxt1: 'End After',
    publishcounttxt2: 'Free Trade After',
    publishcounttxt3: 'Activity over!',
    dateTimeDay: 'D',
    dateTimeDay2: 'Day',
    dateTimeHour: 'H',
    dateTimeMinutes: 'M',
    dateTimeSeconds: 'S',
    moredetail: 'View More',
    coinSymbol: 'Trading crypto',
    cycle: 'Period',
    closeprice: 'Close Price',
    openprice: 'Entry Price',
    win: 'win',
    lose: 'lose',
    tied: 'tied',
    earn: 'Profit',
    orderType: 'Compensations',
    orderTypeNo: 'No',
    orderTypeYes: 'Yes',
    purchasable: 'Max',
    vendibility: 'Max',
    swapassets: 'Equity',
    Frozen: 'Frozen',
    toBeReleased: 'Pending',
    sellMost: 'We can sell {0} at most',
    lessThanMinimum: 'The available balance is less than the minimum transaction',
    beAboutToStart: 'Be about to start',
    listingTime: 'Listing time',
    countdown: 'Countdown',
    newCoin: 'New',
  },
  swap: {
    tradedTime: 'Traded Time',
    trade_tradedVolume: 'Traded Volume',
    trade_tradeFee: 'Fee',
    contract: 'Perpetual',
    swapindex: 'Perpetual',
    coin: 'Crypto',
    symbol: 'Coin Pair',
    volume: 'Volume',
    lastprice: 'Price',
    daychange: '24h Change',
    market: 'Market',
    favorite: 'Marked',
    do_favorite: 'Collected',
    cancel_favorite: 'Cancel Collected',
    type: 'Type',
    dealamount: 'Total',
    high: 'High',
    low: 'Low',
    vol: '24H Vol',
    buy: 'Buy',
    sell: 'Sell',
    limited_price: 'Limit order',
    market_price: 'Market order',
    spot_price: 'TP/SL',
    fees_rate: 'fee rate',
    balance: 'Assets',
    or: 'or',
    starttrade: 'Trade Now!',
    canuse: 'Available',
    recharge: 'Deposit',
    buyprice: 'Buy Price',
    buynum: 'Amount',
    amount: 'Amount',
    buyin: 'Buy',
    disablebuyin: 'Market Buy Disabled',
    buytip: 'Optimum Buy',
    sellprice: 'Sell Price',
    sellnum: 'Sell Amount',
    sellout: 'Sell',
    disablesellout: 'Market Sell Disabled',
    selltip: 'Optimum Sell',
    curdelegation: 'Orders',
    hisdelegation: 'History',
    TotalBill: 'Total',
    histrade: 'Details',
    realtransaction: 'Market Trades',
    num: 'Amount',
    price: 'Price',
    direction: 'Direction',
    time: 'Time',
    stall: 'Stall',
    total: 'Total',
    traded: 'Done',
    action: 'Action',
    undo: 'Cancel',
    delegationnum: 'Delegate Amount',
    done: 'Done',
    status: 'Status',
    finished: 'Finished',
    canceled: 'Canceled',
    parted: 'Parted',
    tip: 'Tip',
    buyamounttip: 'enter buy amount',
    buyamounttipwarning: 'buy amount not more',
    success: 'submit success!',
    pricetip: 'enter buy price',
    pricetipwarning: 'buy price not more',
    sellamounttip: 'enter sell amount',
    sellamounttipwarning: 'sell amount not more',
    sellpricetip: 'enter sell price',
    sellpricetipwarning: 'sell price not more',
    undotip: 'Confirm Undo?',
    cancelsuccess: 'Cancel order Successful',
    marketprice: 'Market Price',
    expand: {
      time: 'time',
      price: 'Price',
      amount: 'amount',
      fee: 'fee',
    },
    realtime: 'realtime',
    kline: 'KLine',
    depth: 'Depth',
    open: 'Open',
    close: 'Close',
    latestdeal: 'Latest deal',
    myswapaccount: 'My Account',
    accountmode: 'Pattren',
    accountmargin: 'Leverage',
    accountquanyi: 'Equity',
    profitandloss: 'Open P/L',
    principalAmount: 'Available Margin',
    positionAmount: 'Used Margin',
    frozenAmount: 'Frozen Margin',
    principalRate: 'Margin ratio',
    zijinhuazhuan: 'Transfer',
    zichanfenxi: 'Analysis',
    up: 'L',
    down: 'S',
    canup: 'Max',
    candown: 'Max',
    cancloseup: 'Max(S)',
    canclosedown: 'Max(L)',
    openup: 'Open Long',
    opendown: 'Open Short',
    closeup: 'Close Short',
    closedown: 'Close Long',
    currentposition: 'Positions',
    assetsdetail: 'Transactions',
    marginMode1: 'Isolated',
    marginMode2: 'Cross',
    modifyMarginModeTitle: 'Modify Account Margin Mode',
    modifyLeverage: 'Adjust Leverage',
    moredetail: 'View More',
    shareNumber: 'Cont',
    entrustprice: 'Price',
    triggleprice: 'Trigger',
    openvolume: 'Long Volume',
    closevolume: 'Short Volume',
    triggleplaceholder: 'The default price is market',
    marketpriceplaceholder: 'Will trade by the best price',
    levmaxtip: 'The leverage should not above the Max',
    levmintip: 'The leverage should not below the Min',
    marginModeSuccessTip: 'Modify success.',
    noenoughbalance: 'Insufficient balance',
    pleaseinputopenvolume: 'The volume should above 0',
    pleaseinputcorrectopenvolume: 'Please input correct volume',
    pleaseinputrightentrustprice: 'Plwase input correct price',
    noenoughposition: 'Insufficient position',
    pleaseinputclosevolume: 'The volume should above 0',
    pleaseinputcorrectclosevolume: 'Please input correct volume',
    pos_Pl: 'PnL',
    pos_Ratio: 'PnL%',
    pos_pos: 'Size',
    pos_canClose: 'Close size',
    pos_openPrice: 'Entry Price',
    pos_qp: 'Strong Parity',
    pos_leverage: 'Leverage',
    pos_pattern: 'Pattern',
    pos_margin: 'Margin',
    pos_mRatio: 'Margin level',
    pos_cmRatio: 'Mainten Ratio',
    pos_long: 'Long',
    pos_short: 'Short',
    ent_entrustType: 'Open/Close',
    ent_direction: 'Direction',
    ent_type: 'Order type',
    ent_trigglePrice: 'Trigger Price',
    ent_entrustPrice: 'Entrust Price',
    ent_tradedPrice: 'Transaction price',
    ent_margin: 'Margin',
    ent_volume: 'Amount',
    ent_status: 'Status',
    ent_status1: 'Entrusting',
    ent_status2: 'Canceled',
    ent_status3: 'Entrust Failed',
    ent_status4: 'Traded',
    ent_statusblast: 'Blast',
    ent_pl: 'P/L',
    ent_fee: 'Fee',
    triggerpriceshouldbigger: 'The trigger price should above 0',
    charts: 'Charts',
    currencyOverview: 'Currency Overview',
    orderBook: 'Order Book',
    latestTrades: 'Latest Trades',
    marketBuy: 'Market Buy',
    marketSell: 'Market Sell',
    quantityInLots: 'Quantity (Lots)',
    inputQuantity: 'Input Quantity',
    SpotBill: 'Spot',
    UBill: 'USDT-M',
    CoinBill: 'COIN-M',
    SecondBill: 'Second',
    FinancialBill: 'Financial',
    stopProfit: 'Stop profit',
    stopLoss: 'Stop loss',
    orderForm: {
      graphicDescription: 'Graphic description',
      case1: 'Case 1:',
      l_text1:
        "Limit order means that the user sets the number of orders and the acceptable maximum buy price or minimum sell price. When the market price meets the user's expectation, the system will deal with the best price within the limit price range.",
      l_text2:
        'The current BTC market price is 53,000 USDT, if you want to buy at the cheaper 52,900 USDT, you can choose a limit order, set the buy price of 52,900 USDT, and the order will be automatically closed when the price falls to 52,900 USDT or less.',
      m_text1:
        'Market order means that users can quickly trade at the best price in the current market, and close immediately.',
      m_text2:
        'The current BTC market price is 53,000 USDT, and buy and sell orders will be traded around 53,000 USDT.',
      ts_text1:
        'A stop-profit and stop-loss order is a trading strategy where you pre-set the trigger price and the order price, and the system will automatically trigger the trade when the market price reaches the trigger price.',
      ts_text2:
        'Suppose you have an open order with an average price of 53,000 USDT and want to sell a flat long to stop your loss when the market falls to 51,000 USDT, you can fill in the following parameters:',
      ts_text3:
        'When selling, it is recommended to sell below the trigger price in order to close immediately, or not fill in the default market order.',
      ts_text4:
        'When the price falls to 51,000 USDT, a stop loss is triggered and a flat order is placed at 50,000 USDT. If the order price is the market price, the market price is quickly sold to close the position.',
      ts_text5:
        'If you want to break the profit, you should sell the break order at the trigger price above 53,000 USDT.',
      cfjg: 'Trigger price:',
      wtjg: 'Commission price:',
      wtjg2: 'Commission price',
      wtjg3: 'Price',
      // 全|逐仓弹窗
      cp_text1: 'Margin adjustment mode is only effective for the current trading subject',
      cp_text2: 'What is the full and open margin model?',
      cp_text3: 'Margin mode by position:',
      cp_text4:
        "Margin refers to a position that is separate from the trader's account balance. In this margin model, traders are free to decide how much leverage to use. If a position is forced to close, the maximum loss a trader has to bear is the position margin.",
      cp_text5: 'Full margin mode:',
      cp_text6:
        "Is NTL's default margin mode. The full-position margin model refers to the use of all available balances in the corresponding currency as position margin to maintain the position and avoid strong flat. Under this margin model, forced liquidation is triggered when the net asset value is insufficient to meet margin maintenance needs. If the position is forced to close, the trader will lose all the assets in the corresponding currency.",
      warmReminder: 'Warm reminder',
      wr_text1:
        'The profit stop loss is not an actual profit stop loss order, which can be initiated only after the transaction is commissioned. After placing an order to trade, the default will be according to your actual transaction amount of all suspension profit stop loss order. If you cancel the pending order, the default profit stop loss order will be void at the same time.',
      wr_text2:
        'If the market is volatile and the stop profit/stop loss is triggered, the actual transaction price may differ from the price at the moment. There is also the possibility that a profit/stop loss order may not be closed or fully closed.',
    },
    orderConfirmation: 'Order confirmation',
    tradeAfterLogin: 'Login',
    experienceBonus: 'Experience bonus',
    selection: 'Selection',
    trialWenxin_text1:
      '1.Experience bonus is only applicable to perpetual contracts - market orders and must be used within the validity period.',
    trialWenxin_text2:
      '2.Experience bonus is prioritized during trading. Profits can be withdrawn, and the experience bonus will be automatically destroyed. Losses will be deducted from the experience bonus first, and if it exceeds the experience bonus, it will be deducted from the balance.',
    trialWenxin_text3: '3.When closing a position, the experience bonus will be prioritized.',
    zyj: 'TP',
    zsj: 'SL',
    leverage: 'Leverage',
    marketIsFlat: 'Close All',
    marketIsFlatTips: 'Are you sure you want to close all positions at market price?',
  },
  second: {
    balance: 'Balance',
    profitability: 'Profitability',
    opentime: 'Open Period',
    transactionMode: 'Transaction mode',
    currentPosition: 'Orders',
    tiaojie: 'Market adjustment',
    countdown: 'Countdown',
    purchaseQuantity: 'Quantity',
    quantity: 'quantity',
    SpotAccount: 'Funding',
    ULocalCurrencyAccount: 'USDT-M',
    CurrencylocalCurrency: 'COIN-M',
    secondContractAccount: 'Second future',
    actualPurchase: 'Actual purchase',
    fee: 'Fee',
  },
  ctc: {
    title: 'One-click Sale ● Platform Guarantee ● Security',
    desc: 'Crypto assets are managed by NTL, and the acceptor provides exchange services for exchange.',
    buyin: 'Buy',
    sell: 'Sell',
    buyprice: 'Buy Price',
    sellprice: 'Sell Price',
    buynum: 'Amount',
    sellnum: 'Sell Amount',
    payamount: 'Payment',
    getamount: 'Payment',
    avabalance: 'Available',
    time: 'Trade Date',
    tip: 'Notice',
    notice: 'Friendly reminder',
    notice1:
      '1. The legal currency trading area is an asset transaction between users and acceptors. The funds are not transferred to a platform, and the platform does not accept RMB recharge/remittance；',
    notice2:
      '2. The acceptors of legal currency transactions have passed real-name certification, offering transaction margins, and the tokens are hosted by the platform, so you can be assured that they can be converted；',
    notice3:
      '3. The service time of the acceptor is 09:00-21:00 per day. The acceptance is completed within 30 minutes after the acceptance of the order, and the two-hour uncompleted transaction is cancelled；',
    notice4:
      '4. In order to support bank cards in the legal currency trading area, it is necessary to transfer funds using an account that you have authenticated by your real name；',
    notice5:
      '5. In order to ensure the security of transaction funds, the legal currency transaction requires users to pass real-name authentication；',
    payType: 'Pay Account',
    receiveType: 'Receive Account',
    moneyTips:
      'The above price is for reference only. Please refer to the actual settlement amount after the following order.',
    verifyset: 'RealName/Asset Password',
    payset: 'Payment Settings',
    trade: 'Trade',
    input50tips: 'Please enter a number between 50 and 50,000',
    bank: 'Bank Card',
    alipay: 'Alipay',
    wechatpay: 'Wechat',
    payaccountset: 'Pay Account Settings',
    useselfaccount: 'Please be sure to use your real-name authenticated account.',
    orderSn: 'Order No.',
    tradetime: 'Exchange Date',
    direction: 'Direction',
    amount: 'Amount(USDT)',
    money: 'Total(CNY)',
    price: 'Price(CNY)',
    status: 'Status',
    operate: 'Operate',
    moredetail: 'More details',
  },
  cexchange: {
    use: 'From',
    consume: 'To',
    title: 'Quick Convert',
    desc: 'One-click,Profit everyday',
    quantity: 'ActivityPurchase Quantity',
    inputtips1: 'The number of custom',
    maxtip: 'Maximum redemption ',
    mintip: 'minimum exchange ',
    choosecurrency: 'Choose a currency',
    all: 'All',
    totalpay: 'Currency Amount paid',
    restricted: 'The total restricted today',
    currentprice: 'Exchange price',
    nextprice: 'Next issue price',
    confirmpay: 'Convert',
    assets: 'Balance：',
    loginFirst: 'Please login',
    success: 'Congratulations, Exchange successful!',
    tip: 'Tips',
    confirmmsg: 'Are you sure to exchange BZB?',
    quickExchange: 'Convert',
    zeroCommission: 'No slippage丨One-click transactions',
    records: 'Records',
    max: 'Max',
    fee: 'Fee',
  },
  option: {
    currentorder: 'Order',
    historyorder: 'History',
    title: 'Options',
    buyupreward: 'Total price up',
    buydownreward: 'Total price down',
    quantity: 'Bet Amount',
    myamount: 'Position',
    buyup: 'Price Up',
    buydown: 'Price Down',
    history: 'Past Results',
    seriers: 'No.',
    period: 'phase',
    currentoption: 'Time Range',
    openprice: 'Open Price',
    currentprice: 'Curent Price',
    openprice1: 'Open Price',
    closeprice: 'Close Price',
    opentime: 'Open Time',
    closetime: 'Close Time',
    progress: 'Progress',
    loginFirst: 'Please login first!',
    selectAmount: 'Please select your amount',
    balancenotenough: 'Banlance is not enough!',
    betsuccess: 'Congratulations！Bet successfull！',
    noopening: 'No opening now!',
    col_createTime: 'Create Time',
    col_direction: 'Direction',
    col_result: 'Result',
    col_optionNo: 'No.',
    col_betAmount: 'Bet Amount',
    col_rewardAmount: 'Reward Amount',
    col_fee: 'Open Fee',
    col_winfee: 'Market adjustment',
    win: 'Win',
    lose: 'Lose',
    wait: 'Opening',
    purchaseQuantity: 'Quantity',
    avaliablebalance: 'Avaliable Balance',
    login: 'Please Login',
    kline: 'Market',
    countdown: 'Countdown',
    currentPeriod: 'Current',
    trade: ' Exchange',
    currentPrice: 'The current price',
  },
  otc: {
    payment_detail: 'Payment method details',
    bind_payment: 'Bind the payment method',
    payment_placeholder: 'Please select a payment method',
    payment: 'Payment method',
    ad: 'Advertising',
    buyin: 'Buy',
    sellout: 'Sell',
    merchant: 'Merchant',
    applymerchant: 'Apply Merchant',
    volume: 'Volume',
    paymethod: 'Payment Method',
    amount: 'Amount',
    limit: 'Limit',
    price: 'Price',
    price_coin: 'Price/Coin',
    operate: 'Trade',
    validate: 'Please first Verified',
    sell: 'sell',
    buy: 'buy',
    transaction: 'Trading transactions',
    buttontxt: 'Become Merchant',
    title: 'OTC Exchange',
    desc: 'Convenient, safe and fast sale of digital currency',
    title1: 'Dynamic Tuning',
    desc1: 'Real time fluctuation according to the market price',
    title2: 'No Fee',
    desc2:
      'What you see is what you get. Besides the selling price, there is no service charge for the platform',
    title3: 'Instant Transaction',
    desc3:
      'Introduction of the platform services for merchants, Intelligent matching, no need to wait',
    title4: 'Platform Guarantee',
    desc4: 'Platform verified merchants to guarantee the security. 24h customer services',
    myad: {
      title: 'My Ad',
      post: 'Post An Ad',
      alert:
        '【Tip】：When the minimum amount of advertising purchases plus the fee is greater than the remaining number of advertisements, the ad is automatically taken off the shelf.',
      no: 'NO.',
      type: 'Type',
      sell: 'Sell',
      buy: 'Buy',
      limit: 'Limit',
      remain: 'Remaining Amount',
      coin: 'Crypto',
      created: 'Create Date',
      operate: 'Action',
      errmsg: 'Can be edited and modified after the ad is down',
      update: 'Edit',
      shelf: 'Shelf',
      dropoff: 'Dropoff',
      delete: 'Delete',
    },
    myorder: 'My Order',
    chatline: {
      status_1: 'The buyer did not pay and waited for the buyer to pay！',
      status_2: 'The buyer has paid and waits for the seller to release！',
      status_3: 'Order completed transaction！',
      status_4: 'Order is being appealed！',
      status_5: 'Order cancelled！',
      loadmore: 'Load more',
      warning:
        'Anti-fraud alerts: In the recent past, fraudsters have repeatedly used bank transfer remittance information and fake remittance credentials for fraud, so please be sure to check your own payment account number. Ensure the safety of remittance funds and avoid the risk of bank cards being frozen！',
      contenttip: 'Please enter chat content Enter key to send',
      contentmsg: 'Message cannot be empty',
    },
    chat: {
      seller: 'Seller',
      buyer: 'Buyer',
      exchangeamount: 'Exchange amount',
      operatetip: 'Operate tip',
      operatetip_1:
        'Please complete the payment in accordance with the account given by the other party within the time limit and click on this page.',
      finishpayment: 'payment completed',
      operatetip_1_1:
        'Please note in the comments when you transfer the good payment reference number',
      operatetip_1_2:
        'After receiving the payment, the seller will confirm the payment on the website. The system will automatically send the digital assets you have purchased to your account. Please note that',
      note: 'Note',
      notetip:
        'Please do not use other chat software to communicate with each other, and do not accept any documents, email attachments, etc. sent to you by the direction. All communication links are completed in the chat window on this page.',
      operatetip_2_1:
        'The digital assets that you have sold have been submitted to the platform for hosting and freezing. ou have confirmed your payment, click',
      operatetip_2_2:
        'Please do not believe any reason for urging the currency to be released, confirm the receipt of the money and release the digital assets to avoid loss！',
      operatetip_2_3:
        'After receiving the account short message, please be sure to log in to online banking or mobile banking to confirm whether the payment is accounted for, to avoid the false release of digital assets due to receiving fraudulent messages！',
      confirmrelease: 'Confirm release',
      paydigital: 'Pay digital assets',
      orderstatus: 'Status',
      orderstatus_1: 'payment completed',
      orderstatus_2: 'Order appeal',
      orderstatus_3: 'Confirm release',
      orderstatus_4: 'cancel the deal',
      orderstatus_5: 'Order appeal',
      order: 'Order',
      and: 'and',
      transition: 'exchange',
      transprice: 'Trading price',
      transnum: 'Trading num',
      transmoney: 'Trading amount',
      tip1: 'The user has not added the bank card number yet',
      tip2: 'The user has not added the Alipay account for the time being',
      tip3: 'The user has not added the WeChat account yet',
      zfb: 'Alipay',
      wx: 'WeChat',
      qrcode: 'QRCode',
      msg1: 'Are you sure you have paid?',
      msg2: 'Payments are not refundable! Are you sure to cancel your order?',
      msg3: '【Repeat】：Payments are not refundable!Are you sure to cancel your order?',
      msg4: 'Paid, not received',
      msg5: 'Already paid, not received',
      tip: 'Tip',
      comptype: 'Complaint type',
      compremark: 'Complaint note',
      willcomp: 'I want to complain',
      msg6: 'Do you confirm currency release?',
      result_1: 'waiting for payment',
      result_2: 'Waiting for release',
      result_3: 'Completed',
      result_4: 'Appealing',
      result_5: 'Canceled',
      msg7: 'Fund Password',
      msg7tip: 'please enter fund password',
    },
    checkuser: {
      emaildone: 'Email certified',
      emailundo: 'Email unauthorized',
      teldone: 'Telno certified',
      telundo: 'Telno unauthorized',
      idcarddone: 'IDCard certified',
      idcardundo: 'IDCard unauthorized',
      language: 'Language',
      languagetext: 'Chinese',
      languagetext1: 'English',
      registtime: 'Registration time',
      exchangetimes: 'Accumulated transactions',
      exchangeinfo: 'Trading Information',
      tablabel1: 'Sell online',
      tablabel2: 'Buy online',
      col_symbol: 'Crypto',
      col_paymode: 'Payment method',
      col_num: 'Amount',
      col_price: 'Price',
      col_created: 'Release time',
      col_operate: 'Operate',
      operatemsg: 'Please perform real name authentication first',
      buyin: 'Buy in',
      sellout: 'Sell out',
    },
    tradecenter: {
      postad: 'Post an ad',
      exchange: 'GCC transaction',
      buyin: 'I want buy in',
      sellout: 'I want sell out',
    },
    tradeinfo: {
      emaildone: 'Email certified',
      emailundo: 'Email unauthorized',
      teldone: 'Telno certified',
      telundo: 'Telno unauthorized',
      idcarddone: 'IDCard certified',
      idcardundo: 'IDCard unauthorized',
      exchangetimes: 'Exchange Times',
      price: 'Price',
      num: 'Amount',
      paymethod: 'PayMethod',
      exchangelimitamount: 'Exchange limit',
      location: 'Location',
      location_text: 'China',
      exchangeperiod: 'Exchange period',
      minute: 'min',
      amounttip: 'Please enter the amount',
      numtip: 'Please enter the num',
      remarktip: 'Tell him your request',
      remarktitle: 'Horizon Remarks',
      exchangetitle: 'Trading Information',
      exchange_tip1:
        'After you initiate the transaction request, the digital currency is locked in the hosting and protected by the platform. If you are a seller, after you initiate a transaction request, you can top up and wait for the buyer to pay. Buyers pay within the payment deadline. After you receive the payment, you should release the digital currency that is under your custody.',
      exchange_tip2:
        'Read before trading《Platform Network Terms of Service》 and FAQs, trading guides and other help documentation.',
      exchange_tip3:
        'Beware of liar!Before the transaction, please check the rating received by the user and pay more attention to the newly created account.',
      exchange_tip4:
        'Please note，Rounding and price fluctuations may affect the amount of digital currency that is eventually traded.The fixed amount you enter determines the final amount, and the digital currency amount will be calculated from the instant exchange rate at the same time that the request is issued.',
      exchange_tip5:
        'Hosting services protect both buyers and sellers of online transactions.In the event of a dispute, we will evaluate all the information provided and release the hosted digital currency to its legal owner.',
      warning1: 'Enter up to 2 decimal places',
      warning2: 'Order amount is',
      warning3: 'Enter up to 8 decimal places',
      warning4: 'The order quantity is',
      confirmbuyin: 'Confirm buy',
      confirmsellout: 'Confirm sell',
      buyin: 'Buy in',
      sellout: 'Sell out',
      warning5: 'Please fill in the order as required',
    },
    publishad: {
      createad: 'Create an ad deal',
      msg1: 'If you often trade, you can create your own trading ad.If you only trade occasionally, we recommend that you search directly',
      msg2: 'Create a trading ad is',
      msg3: 'Free of charge',
      msg4: 'If you want to directly edit the created ads, please check',
      tradead: 'Trading Advertising',
      myad: 'My advertisement',
      iwant: 'I want',
      sellonline: 'sell online',
      buyonline: 'buy online',
      exchangecoin: 'Trading crypto',
      country: 'Country',
      currency: 'Coin',
      openfixedprice: 'Open fixed price',
      open: 'Open',
      close: 'Close',
      usetip:
        'When enabled, your currency price will not fluctuate with the market and the price will not change.',
      premiseprice: 'Premium',
      premisepricetip: 'Please set your premium',
      fixedprice: 'fixed price',
      fixedpricetip: 'Please enter your exchange price',
      marketprice: 'Market reference price',
      marketpricetip: 'Premium refers to what percentage is higher than the current market price',
      exchangeprice: 'Exchange price',
      formual: 'Pricing formula',
      num: 'Amount',
      num_text1: 'Please enter what you want',
      num_text2: 'num',
      exchangeperiod: 'Transaction period',
      exchangeperiod_text1: 'Please enter your trading deadline',
      minute: 'min',
      tip1: 'How much time the buyer can accept transactions, please enter an integer',
      tip2: '【Tip】Can be bound to personal center/Add payment method',
      tip3: 'Please enter your minimum transaction amount',
      tip4: 'Please enter your maximum transaction amount',
      tip5: "You can fill in your special requirements in the remarks information, such as: the buyer's requirements, online time and so on.",
      paymode: 'Pay method',
      minlimit: 'Minimum transaction amount',
      maxlimit: 'Maximum transaction amount',
      remark: 'Remarks',
      openautoreply: 'Open autoreply',
      msg5: 'When enabled, when the user initiates a transaction to you through this ad, the system automatically sends the auto reply phrase you selected to the other party.',
      autoreply: 'AutoReply',
      autoreplytip:
        'After receiving the order, the information is automatically returned to the buyer, for example: payment method, collection account number, etc.',
      fundpwd: 'Fund password',
      fundpwdtip: 'Please enter your funds password',
      submit: 'Submit',
      warning1: 'Please enter the correct number',
      warning2: 'The overflow value is 0-20',
      warning3: 'Please enter your maximum transaction amount!',
      warning4: 'Please enter an integer',
      warning5:
        'The maximum transaction amount must be greater than the minimum transaction amount!',
      warning6: 'The maximum transaction amount cannot exceed your total sale amount',
      warning7: 'Please enter your minimum transaction amount!',
      warning8: 'The minimum transaction amount must be greater than or equal to 100!',
      warning9: 'The minimum transaction amount must be less than the maximum transaction amount',
      sellout: 'Sell',
      buyin: 'Buy',
      inputtip1: 'Please enter the currency',
      inputtip2: 'Please enter the correct option',
      inputtip3: 'The overflow value is 0-20 and cannot be 0',
      inputtip4: 'Please enter the correct fixed price',
      inputtip5:
        'Please enter the correct number, and the maximum number of transactions does not exceed 100',
      inputtip6: 'Please enter the correct trading deadline',
      inputtip7: 'Please select transaction method',
      inputtip8: 'Please enter funds password',
      zfb: 'Alipay',
      wx: 'Wechat',
      unionpay: 'BankCard',
      submit_failure: 'Failure!',
      submit_success: '',
      submittip1: 'Please proceed with a series of certifications such as real names',
      submittip2: 'Please conduct a series of certifications such as mobile phones',
      submittip3: 'Please proceed with a series of certifications such as capital passwords',
      submittip4: 'Please bind at least one payment method',
    },
    index: {
      title: 'Good advertising recommendation',
      exchangetimes: 'Transaction times',
      exchangeprice: 'Transaction price',
      exchangelimit: 'Transaction limit',
      paymode: 'Pay method',
      buy: 'buy',
      sell: 'sell',
      viewmore: 'More',
      bot1: 'Safe and reliable',
      bot1_tip:
        'More than 10 years of experience in the financial risk control team\nCustomizable security policy system',
      bot2: 'Fast and easy',
      bot2_tip: 'Peer-to-peer user free transaction mode support\nKinds of money exchange channels',
      bot3: 'Full currency',
      bot3_tip:
        'Selection of mainstream digital asset transaction currencies\nMulti-asset trading experience',
      ibuy: 'I want buy',
      isell: 'I want sell',
    },
  },
  activity: {
    all: 'All',
    prepare: 'Coming',
    ongoing: 'Ongoing',
    distributing: 'Distributing',
    completed: 'Completed',
    progress: 'Progress',
    totalsupply: 'Total Supply',
    starttime: 'Start Date',
    endtime: 'End Date',
    viewdetail: 'Detail',
    attend: 'Attend',
    tokendistribute: 'Token Distributing',
    baseinfo: 'Activity Info',
    status: 'Status',
    activitytype: 'Activity Type',
    activitytype0: 'Unkonwn',
    activitytype1: 'List(FOF)',
    activitytype2: 'List(AVA)',
    activitytype3: 'HolderDivide',
    activitytype4: 'FreeBuy',
    activitytype5: 'MiningBuy',
    activitytype6: 'Locked',
    ruledetail: 'About Rule',
    publishprice: 'Publish Price',
    activitycoin: 'Activity Symbol',
    acceptcoin: '接受币种',
    holdcoin: 'Hold Symbol',
    limittimes: 'Personal Limit Times',
    limitamount: 'Personal Limit Amount',
    unlimited: 'Unlimited',
    alreadyamount: 'Already Sell Amount',
    alreadyholdamount: '活动参与总持仓',
    myalreadyholdamount: '我的参与持仓',
    leftamount: 'Left Amount',
    attention: 'Attention',
    attentiontxt1:
      '1、Assets will be frozen after submitting for participation in [warehouse partitioning] and [free subscription] type activities until thawing is completed.',
    attentiontxt2:
      "2、Users may not withdraw their application for participation after participating in the activities of the type of 'Holder Divide' and 'Fress Buy'",
    attentiontxt3:
      '3、If the activity is cancelled due to the project party and other force majeure factors, the original route shall be returned to the frozen assets.',
    attentiontxt4:
      '* The right of final interpretation of this activity belongs to the official ownership of NTL.',
    inputamount: 'Input Amount',
    inputholdamount: 'Input Lock Amount',
    mybalance: 'Available Balance',
    holdtips: '[HolderDivide] requires lockout until the end of the activity.',
    pleaseinputamount: 'Please input amount!',
    pleaseinputholdamount: 'Please input hold amount!',
    commitfailed: 'Commit Failed！',
    minlimitamountfailed:
      'The quantity of exchange must not be less than the minimum minimum amount of exchange.！',
    maxlimitamountfailed:
      'The amount of exchange shall not be greater than the maximum purchase amount.！',
    limittimesfailed:
      'You have participated in more than the maximum number of individual purchases.！',
    balancenotenough: 'Balance is not enough！',
    headertitledesc:
      'Open Laboratories for Win-win Cooperation among Investors, Projectors and Exchanges',
    currentdivided: 'My current position will get',
    leveloneCount: 'Limit Level One Count',
    submit: 'Submit',
    tipsmobile: 'Please use PC web（www.NTLEX.COM）',
    tipsmobile1: 'You can enter PC web to attend this activity.',
    intvalue: 'Please input int value',
    inputminingamount: 'Input Buy Count',
    moreactivity: 'More Activities',
    releaseType: 'Release Type',
    releaseType0: 'Equal release',
    releaseType1: 'Isometric release',
    lock: 'Lock ',
    releaseDetail: 'Release Detail',
    lockday: ' Days',
    lockweek: ' Weeks',
    lockmonth: ' Months',
    lockyear: ' Years',
    releasePercent: 'Release Percent',
    lockFee: 'Lock Fee',
    releaseTimes: 'Release Times',
    times: 'times',
  },
  invite: {
    inviteLoginName: 'Login as Partner',
    chengwei: 'Receive the highest opportunity to return to domestic service for life',
    pthhr: 'Become a platform partner',
    partnerText:
      'Invite friends to register and authenticate with their real names to enjoy platform dividends',
    myinvitelink: 'Promotion Link',
    copy: 'Copy',
    copyed: 'Copied',
    mylevelone: 'Level one friends',
    myleveltwo: 'Level two friends',
    mycommission: 'Commission income(USDT)',
    extrareward: 'Additional rewards',
    partnerlevel: 'Partnership level',
    ruledetail: 'Rule details',
    ruleprofile1:
      '[Promotion Partner] is the highest proportion of online Commission return and the longest time of commission return (maximum lifetime commission). Promoters who make corresponding efforts can become real platform ‘partners’ and enjoy the dividend of growing together with NTL trading platform. Detailed rules are as follows:',
    ruleprofile2: '返佣比例最高、',
    ruleprofile3: '返佣时长最长(最高终身返佣)',
    ruleprofile4:
      '的推广活动，让付出相应努力的推广者能够成为真正意义上的平台“合伙人”，享受与NTL(WWW.NTLEX.COM)交易平台共同成长的红利！具体细则如下：',
    ruletext1:
      '1、In order to preach the concept of digital assets and expand the scale of users, NTL launched the “Promotion Partner” program, which is effective for a long time.',
    ruletext2:
      "2、Inviting friends can be divided into two levels. If A invites B, then B is A's first-level friend. If B invites C, then C belongs to B's first-level friend and also to A's second-level friend.",
    ruletext3:
      '3、When promoting the registration of friends, the first-level friends of inviters can only be the first-level friends of inviters by links provided by inviters or by manually entering invitation codes of inviters.',
    ruletext4:
      '4、After the invitee completes the registration through the invitor invitation code, the rebate incentive will take effect.',
    ruletext5:
      '5、The rebate period is N months from the completion of registration of the invitee. The proportion of rebate will vary according to the level of the invitee.',
    ruletext6:
      '6、Promotion of commission assets is the transaction fee of lower-level Friend Currency, which is the three market base currencies, namely USDT, BTC and ETH.',
    ruletext7:
      '7、The proportion and duration of promotion commission are positively correlated with the number of first-level friends. The more the number of promotion, the higher the proportion of enjoyable commission. The specific promotion of commission rebate ratio is as follows：',
    ruletext8:
      '8、TOP10 or TOP100 will be awarded additional incentives by MKWORLDS from time to time.',
    ruletext9:
      '9、If other activities are in conflict with the activities of the Promotion Partner, the Partners will be expected to understand whether to change temporarily after consultation.',
    ruletext10:
      '10、L4, L5 and L6 envoy-level partners are entitled to 5%, 10% and 15% bonuses of the total annual Commission returns, respectively.',
    ruletext11: '11、The right of final interpretation of this activity belongs to NTLEX.COM',
    ruleexampletitle: 'For Example：',
    ruleexamplecontent1:
      "If the user Xiaoyan invited 100 first-class friends, TA's first-class friends invited five second-class friends, then Xiaoyan had 100 first-class friends and 500 second-class friends. If the average person trades 3000 yuan per day, Xiaoyan's monthly income is about (100 * 3000 * 0.001 * 30% + 500 * 3000 * 0.001 * 10%)* 30 = 7200 / month.",
    ruleexamplecontent2:
      "If user Xiaoyan invited 1000 first-class friends, TA first-class friends invited 5 second-class friends, Xiaoyan had 1000 first-class friends and 5000 second-class friends. If the average person trades 3000 yuan per day, Xiaoyan's monthly income is about (1000*3000*0.001*50%+5000*3000*0.001*20%)*30=135000/month.",
    lastupdate: 'Last updated in',
    laststastic: 'Last stastic in',
    level: 'Level',
    lv1: '',
    lv2: '',
    lv3: '',
    lv4: '',
    lv5: '',
    lv6: '',
    levelonecount: 'Number of Levlel one',
    levelonecommission: 'Level-One Return Ratio / Return Time',
    leveltwocommission: 'Level-Two Return Ratio / Return Time',
    partnerdivide: 'Partner Dividend',
    day: 'day',
    alllife: 'Lifetime',
    totalcommissionrank: 'Promotion of total Commission',
    rank: 'No.',
    userid: 'User ID',
    rankday: '(Day)',
    rankweek: '(Week)',
    rankmonth: '(Month)',
    rankall: '(All)',
    promotioncount: 'Number of Promotions',
    commissionusdt: 'Commission(USDT)',
    ranktip1:
      'The number of invitations is the number of first-class friends. This list ranks the total amount of commissions from large to small.',
    ranktip2:
      'The number of invitations is the number of first-class friends. The list is ranked by the number of first-class friends invited.',
    thanks: 'Thank you for supporting NTL！',
    headertip:
      'The above data is not updated in real time. The system statistics and updates every 24H.',
    ptools: 'Promotion Tools',
    pt_title: 'Cryptocurrency Gift Card',
    pt_desc: 'This is a surprise from the future, to send friends, relatives, customers, partners.',
    pt_more: 'More tools is coming',
    pt_tips:
      'If you have a good idea, please send an email to promotion@NTLEX.COM, with a reward after adoption.！',
    pt_card_amount: 'Card Amount',
    pt_card_deadline: 'DeadLine',
    pt_card_noend: 'Indefinite',
    pt_card_btn: 'Get 30 cards for free',
    pt_card_btn1: 'Promote free partnership',
    pt_card_btn2: '张',
    pt_card_btn_login: 'Please login first',
    pt_card_rule: 'Rule Details',
    pt_card_summary:
      'Digital currency gift card is a promotional tool developed to enable promotional partners to better invite offline friends. When users exchange gift cards, they will automatically become offline friends of promotional partners.',
    pt_card_rule1:
      '1. Free gift cards are limited to 30 cards per user. If more gift cards need to be issued, users need to pay for customization, which can customize the amount of cards, LOGO, instructions, etc. For customized requirements, please send an email to',
    pt_card_rule2:
      '2. Free gift cards are limited to one copy per user. Free gift cards issued by the NTL government are limited to one for each user. Even if different users receive free gift cards with different exchange codes, only one can be exchanged. Custom gift cards are not subject to this restriction.',
    pt_card_rule3:
      "3. Before the user exchanges the gift card, if there is no 'inviter' (i.e. the invitation code has not been entered at the time of registering the account), it will be automatically associated with the first-level friend of the card issuer. If A does not enter an invitation code when registering an account, then A does not belong to any one's first or second-level friends, but when A is converted into B's first-level friends through the gift card issued by B, A automatically becomes B's first-level friends.",
    pt_card_rule4:
      '4. In order to prevent brushing, the book value of the gift card is not redeemed in real time, and the activation conditions must be met after receipt (the recharge virtual digital currency value reaches 300 USDT) before it can be operated.',
    pt_card_rule5:
      '5. You need to meet the activation conditions when you receive a free gift card, and you can receive it without completing the activation conditions when redeeming. This is to facilitate promoters to get lower-level friends faster.',
    pt_card_rule6: '6. The right of final interpretation of free gift cards belongs to NTLEX.COM.',
    pt_card_day: 'Days',
    pt_card_title_tips: 'Free promotion grant of 2000 CNY',
    pt_card_title_tips1: 'led',
    pt_card_title_tips2: 'Promotion fund',
    pt_card_receivew_success:
      'Congratulations! Successful collection of partner promotion gift cards! Please go to the Personal Center - > Card and Voucher Center.',
    usepromotion: 'Use Promotional Materials',
    saveimage: 'Save Image',
    imagetips:
      "If you can't download pictures properly, please download them using the screenshot tool!",
    pt_invite_title: 'Invitation registration leaflet',
    pt_invite_desc:
      'Automatically become subordinate friends through two-dimensional code registration, suitable for forwarding in various groups or forums.',
    colum_text0: 'Rank',
    colum_text1: 'Member',
    colum_text2: 'Promotion',
    colum_text3: 'Commission(USDT)',
    colum_text4: 'Extra Reward',
    context_title: 'Exclusive Redemption Code for Promotion Partners',
    context_title1: 'Scan to learn more',
    context_title2: 'My Invitation Code',
    context_title3: 'Invitation Image',
  },
  uc: {
    verify: 'Finish KYC First',
    retrieveprivate: {
      findText: 'Retrieve the private key of the account',
      findText2: 'The account private key has been recreated',
      inputFindtext: 'Enter the email bound to this account',
      continue: 'continue',
    },
    login: {
      welcomelogintxt: 'Welcome to NTL',
      welcomelogintxt2: "If you don't have an account",
      welcomelogintxt3: 'You can click here',
      welcomelogintxt4: 'Registered account',
      welcomelogintxt5: 'Login account',
      welcomelogintxt6: 'If you have an account',
      welcomelogintxt7: 'Embark on a new trading journey',
      phoneEmail: 'Email',
      phoneLogin: 'Phone',
      anonymousLogin: 'Anonymous',
      inputPrivate: 'Please enter the private key of the account...',
      forgetPrivateTxt: 'Forgot the private key of the account?',
      noaccount: 'No Account？',
      register: 'Register',
      login: 'Log in',
      welcomelogin: 'Welcome',
      usertip: 'Enter email',
      pwdtip: 'Enter password',
      validatecodeload: 'Loading verification code',
      validatemsg: 'Please finish verification first',
      forget: 'Forget password?',
      loginvalidate: 'Please input email or phone number',
      pwdvalidate1: 'Enter password',
      pwdvalidate2: 'Password length not less than six',
      success: 'Success',
      goregister: 'Sign up',
      getlostpwd: 'Forget Password',
      noid: 'Don’t have an account?',
      keyErr: 'The entered private key is incorrect',
      verify: 'Please complete the verification',
      loginFailure: 'Login failure',
      loginFailureDoc: 'Login status is invalid, please log in again.',
    },
    forget: {
      title: 'Find Password',
      hasaccount: 'Have a Account？To Log In',
      login: 'Log in',
      sendcode: 'Send',
      newpwd: 'Enter new password',
      confirmpwd: 'Enter new password again',
      save: 'Submit',
      pwdvalidate1: 'Enter confirm password',
      pwdvalidate2: 'Password not same!',
      resettelpwd: 'Reset tel password',
      resetemailpwd: 'Reset email password',
      newpwdtip: 'Enter new password',
      pwdvalidate3: 'Password length not less than six',
      telno: 'Tel number',
      smscode: 'Sms verification code',
      teltip: 'Enter tel',
      smscodetip: 'Enter sms verification code',
      email: 'Email',
      password: 'Password',
      NoId: 'No account yet',
      emailcode: 'Email verification code',
      captchaPrompt:
        'If you do not receive the verification code, you can check it in your mailbox - spam.  ',
      emailtip: 'Enter email',
      emailcodetip: 'Enter email verification code',
      resetpwdsuccess: 'Reset password success',
      smswarn: 'Please check for SMS',
      bangding: 'Scan code binding',
      googleAuth: 'GoogleAuth',
      pleaseGoogleAuth: 'Please bind GoogleAuth',
      bindGoogleAuth: 'Bound GoogleAuth',
      jcbind: 'Remove GoogleAuth',
      GoogleCode: 'Google verification code',
    },
    finance: {
      center: 'Finance center',
      personalassets: 'Currency Assets',
      swapassets: 'USDT-M Perpetual Assets',
      swapassets2: 'COIN-M Perpetual Assets',
      billdetail: 'Bill detail',
      tradetail: 'Trade Mining',
      paydividends: 'Pay dividends',
      invitingmining: 'Inviting mining Awards',
      charge: 'Deposit',
      pickup: 'Withdraw',
      money: {
        cointype: 'Coin',
        balance: 'Balance',
        frozen: 'Frozen Balance',
        operate: 'Action',
        charge: 'Deposit',
        pickup: 'Withdraw',
        onkeytrans: 'Trans',
        getaddress: 'Get Address',
        resetsuccess: 'Reset Success',
        match: 'Match',
        matchtip1: 'Available Match GCX Amount',
        matchtip2: 'Enter Match Amount',
        matcherr1: 'Please enter a valid quantity!',
        matcherr2: 'Exceeds the maximum number of matches!',
        matchsuccess: 'Match Success!',
        needreleased: 'Assets to be released',
        totalassets: 'Estimated Total Value of Assets：',
      },
      swap: {
        day1: 'Daily rate',
        day2: 'nnual interest rate',
        totalassets: 'Estimated Total Value of Assets：',
        swap: ' Perpetual',
        swaptype: 'Options',
        swapassets: 'Equity',
        avabalance: 'Available Margin',
        usebalance: 'Used Margin',
        experienceBonus: 'Experience bonus',
        frozenbalance: 'Frozen Margin',
        profitandloss: 'Open P/L',
        transfor: 'Transfer',
        inputtransferamount: 'Transfer Amount',
        all: 'All',
        currencyaccount: 'Spot Account',
        swapaccount: 'Perpetual Swap Account',
        clickchange: 'Click to change direction',
        avaamount: 'Avaliable',
        pleaseselectwallet: 'Please choose a transfer wallet fist.',
        pleaseinputamount: 'Please input transfer amount',
        oktransfer: 'Transfer Now',
        from: 'From',
        to: 'To',
        available: 'Available',
        successfulTransfer: 'Successful transfer',
      },
      second: {
        secondaccount: 'Second future',
        swap: ' Options',
      },
      finance: {
        transfer: 'Financial Transfer',
        account: 'Financial',
        balance: 'Balance',
        earn: 'Total Earnings',
        stlTotalEarn: 'Unsettled Interest',
        rate: 'Rate',
        confirm: 'Confirm Transfer',
        transferAmount: 'Transfer-in Quantity',
        all: 'Transfer all in',
        coin: 'Crypto',
        open: 'Position',
        close: 'Closing',
        fail: 'operation failed',
        success: 'success',
        updateTime: 'Update Time',
        financialManagement: 'FMC',
        financialOrder: 'Financial order',
      },
      record: {
        start_end: 'Start-End',
        to: 'To',
        code: 'coding',
        remark: 'remarks',
        operatetype: 'Action Type',
        search: 'Search',
        charge: 'Charge',
        pickup: 'Pick up',
        pickupfee: 'Withdrawal Fee',
        pickupCode: 'Cash Withdrawal Code',
        pickupCodePay: 'Deposit Code',
        transaccount: 'Transfer',
        exchange: 'Spot',
        exchangefee: 'Coin Fee',
        otcbuy: 'OTC-Buy',
        otcsell: 'OTC-Sell',
        activityaward: 'Activity Award',
        promotionaward: 'Promotion Award',
        promotionawardRebates: 'Promotion rebate reward',
        istantRedemptionRevenue: 'Flash Income',
        flashPayouts: 'Flash Payment',
        instantRedemptionFees: 'Flash Exchange Fee',
        dividend: 'Dividend',
        rebateincome: 'Rebate income',
        vote: 'Vote',
        handrecharge: 'Artificial recharge',
        match: 'Match',
        activitybuy: 'Activity Buy',
        ctcbuy: 'C2C BUY',
        ctcsell: 'C2C SELL',
        redout: 'Crypto gift',
        redin: 'RED-IN',
        chargetime: 'Time',
        type: 'Type',
        fee: 'Fee',
        shouldfee: 'Estimated gas fee',
        discountfee: 'Deduction',
        realfee: 'Actual Fee',
        symbol: 'Crypto',
        num: 'Amount',
        status: 'Status',
        finish: 'Finished',
        quickExchange: 'Convert',
        currentRate: 'Current Exchange Rate',
        from: 'From',
        inputexchangeamount: 'Input Quick Convert Amount',
        inputexchangepasswd: 'Input Exchange Password',
        chooseTransCoinUnit: 'Choose Trans Coin',
        inputTransAmount: 'Choose Trans Amount',
        predictAmount: 'Will Exchange',
        qamount: 'Amount',
        qrate: 'Rate',
        qexamount: 'Exchange Amount',
        withdrawcodein: 'WithdrawCode Recharge',
        withdrawcodeout: 'WithdrawCode Withdraw',
        contractfee: 'Swap Fee',
        contractprofit: 'Swap Profit',
        expenditureofcapitalexpenses: 'Expenditure of funds',
        accesstofundingfees: 'Cost of obtaining funds',
        optionscontractfails: 'Option Fail',
        optionscontractfee: 'Option Fee',
        optionscontractbonuses: 'Option Reward commission',
        optionscontractcom: 'Options Market adjustment',
        contractloss: 'Loss on perpetual contracts',
        activityrevenue: 'Activity income',
        activityexpenditure: 'Activity expenditure',
        redenvelopecollection: 'Receiving red envelope',
        optionfail: 'Option Fail',
        optionfee: 'Option Fee',
        optionreward: 'Option Reward',
        secondfee: 'Second Futures Fee',
        secondcom: 'Second futures Market adjustment',
        secondfail: 'Second Futures Failed',
        secondreward: 'Second Futures Reward',
        financereward: 'Financial Interest',
        other: 'Other',
        adjust: 'Adjust',
        tradingRewards: 'Trading rewards',
        financialDefaultHandlingFee: 'Financial default handling fee',
        coinTradeFeeDiscount: 'Coin trade fee discount',
        contracttradeFeeDiscount: 'Contracttrade fee discount',
        redEnvelopeReturn: 'Red envelope return',
        experienceUse: 'Contract experience bonus usage',
        deduction: 'Deduction of contract experience bonus',
        transfer: 'Transfer',
        kolCommission: 'KOL commission',
      },
      paydividende: {
        money_holding: 'Money sharing has been returned and accumulated(ETH) : ',
        money_hold: 'Pay dividends to return(ETH) : ',
        paydividends: 'Pay dividends(ETH)',
        account_date: 'Time for receiving the capital',
        datehodld: 'Date of holding money',
        start_end: 'Start-End',
        to: 'To',
        operatetype: 'Action Type',
        search: 'Search',
        charge: 'Charge',
        pickup: 'Pick up',
        transaccount: 'Transfer',
        exchange: 'Spot',
        otcbuy: 'OTC-Buy',
        otcsell: 'OTC-Sell',
        activityaward: 'Activity Award',
        promotionaward: 'Promotion Award',
        dividend: 'Dividend',
        vote: 'Vote',
        handrecharge: 'Artificial recharge',
        match: 'Match',
        chargetime: 'Time',
        type: 'Type',
        fee: 'Fee',
        symbol: 'Crypto',
        num: 'Amount',
        status: 'Status',
        finish: 'Finished',
      },
      trade: {
        accumulative_return: 'Mining fees have been repaid (BHB) : ',
        accumulat_return: 'Mining fees should be repaid(BHB) : ',
        start_end: 'Start-End',
        account_date: 'Time for receiving the capital',
        to: 'To',
        operatetype: 'Action Type',
        search: 'Search',
        charge: 'Charge',
        pickup: 'Pick up',
        transaccount: 'Transfer',
        exchange: 'Spot',
        otcbuy: 'OTC-Buy',
        otcsell: 'OTC-Sell',
        activityaward: 'Activity Award',
        promotionaward: 'Promotion Award',
        dividend: 'Dividend',
        vote: 'Vote',
        handrecharge: 'Artificial recharge',
        match: 'Match',
        chargetime: 'order generation time',
        type: 'Type',
        fee: 'Return of mining fees',
        symbol: 'Coin Pair',
        num: 'Amount',
        status: 'Status',
        finish: 'Finished',
        transactionTime: 'Trading time',
        direction: 'Direction',
        price: 'Price',
        entrustment: 'Entrustment',
        havedeal: 'Have done a deal',
        poundageAmount: 'Service Charge ',
        exchangeOrderId: 'The order ID',
        mineAmount: 'Dig a currency (BHB)',
        buy: 'BUY',
        sell: 'SELL',
        limit: 'LIMIT',
        market: 'MARKET',
        clear: 'Clear',
        turnover: 'Turnover',
      },
      inviting: {
        accumulative_return: 'Inviting mining rewards has been returned and accumulated(BHB) : ',
        accumulat_return: 'Invite mining awards to be returned(BHB) : ',
        start_end: 'Start-End',
        account_date: 'Time for receiving the capital',
        to: 'To',
        operatetype: 'Action Type',
        search: 'Search',
        charge: 'Charge',
        pickup: 'Pick up',
        transaccount: 'Transfer',
        exchange: 'Spot',
        otcbuy: 'OTC-Buy',
        otcsell: 'OTC-Sell',
        activityaward: 'Activity Award',
        promotionaward: 'Promotion Award',
        dividend: 'Dividend',
        vote: 'Vote',
        handrecharge: 'Artificial recharge',
        match: 'Match',
        chargetime: ' order generation time',
        type: 'Type',
        fee: 'Fee',
        symbol: 'Crypto',
        num: 'Amount',
        status: 'Status',
        finish: 'Finished',
        refereename: 'Name of the referee',
        referetel: "Referee's cell phone number",
        invitingawards: 'Inviting mining Awards(BHB)',
        refere: 'Referee',
        refereinput: 'Please enter the name / cell phone number',
      },
      recharge: {
        recharge: 'Deposit',
        symbol: 'Crypto',
        address: 'Deposit Address',
        copy: 'Copy',
        qrcode: 'QRCode',
        qrcodeaddress: 'QRCode For Deposit',
        msg1: 'Do not recharge any non-currency assets to the above address, otherwise the assets will not be recovered.',
        msg2: 'After you recharge to the above address, you need to confirm the entire network node, after 2 network confirmations arrive, after 6 network confirmation can be extracted.',
        msg3: 'Minimum recharge amount: ',
        msg3_1: ', recharge less than the minimum amount will not be accounted.',
        msg4: 'Your top-up address will not change frequently and you can repeat the recharge; if there is any change, we will try to notify you via website announcement or email.',
        msg5: 'Please be sure to confirm the security of your computer and browser to prevent information from being tampered with or leaked.',
        record: 'Recording',
        copysuccess: 'Success！',
        copyerr: 'Failure！Please copy it manually',
        time: 'Arrival time',
        amount: 'Amount',
        gettingaddress: 'Getting deposit address，Please wait...',
        getaddress: 'Get Address',
        memotips: 'Please enter this Memo when recharging, otherwise you will lose assets.',
        withdrawrecharge: 'Recharge by code',
        coderechargetip: 'Please enter withdraw code',
        rechargesuccess: 'Congratulations, Recharge success!',
        rechargeconfirm: 'Recharge Confirm',
        status: 'Status',
        protocol: 'Network protocol',
        status_0: 'fail',
        status_1: 'Non arrival',
        status_2: 'Received account',
        protocolerr: 'Please select an agreement',
        network: 'network',
        notaddress:
          'You have not applied for a recharge address，Please click the button to get the recharge address',
        rechargeDisable: 'Recharge function is temporarily unavailable',
        nickname: 'Please enter a nickname',
        phoneOrEmail: 'Please fill in the correct mobile phone number or email number',
      },
      withdraw: {
        pickup: 'Pick up',
        network: 'Transfer Network',
        withdrawDisable: 'Suspend withdrawals',
        addressmanager: 'Address management',
        withdrawbycode: 'Withdraw by code',
        withdrawcode: 'Withdraw Code',
        symbol: 'Crypto',
        address: 'Address',
        num: 'Withdrawal amount',
        avabalance: 'Available Balance',
        msg1: 'The amount of coins is lower than',
        msg2: 'Automatically arrives at account, otherwise manual review is required',
        increase: 'Increase credit',
        tip1: 'Enter up to ',
        tip11: 'decimal places and the minimum value is',
        tip2: 'The maximum value is',
        numtip1: 'Enter the number of coins',
        fee: 'Estimated transaction fee',
        feeIns: 'The fee is subject to changes based on market fluctuations.',
        range: 'Range',
        arriamount: 'Total',
        msg3: 'The minimum number of coins is',
        msg4: 'currency',
        msg5: 'In order to ensure the security of funds, when your account security policy changes, passwords are changed, and you use the new address to withdraw coins, we will conduct a manual audit of the coins. Please be patient and wait for the staff to call or email.',
        msg6: 'Please be sure to confirm the security of your computer and browser to prevent information from being tampered with or leaked.',
        record: 'Withdrawals record',
        symboltip: 'Please select a currency',
        addresstip: 'Please fill in the address',
        amounttip: 'Please enter amount',
        newAmounttip: 'Please fill in the correct withdrawal amount',
        numtip2_max: 'Please fill in the correct withdrawal amount, the maximum is',
        numtip2: 'Please fill in the correct number of coins, the minimum value is',
        numtip3: 'Coinage amount must not be less than processing fee',
        feetip1: 'The minimum fee is',
        feetip2: 'The maximum value is',
        time: 'Withdrawal time',
        status: 'Status',
        turnDown: 'Turn down',
        inProcess: 'Processing',
        inTransfer: 'Transfering',
        successful: 'Success',
        failure: 'Failure',
        reason: 'Reason',
        remark: 'Remark',
        add: 'Add',
        addresslist: 'Address List',
        safevalidate: 'Safety verification',
        telno: 'Tel',
        smscode: 'Phone verification code',
        second: 'second',
        clickget: 'Get',
        email: 'Email',
        emailcode: 'Email  verification code',
        save: 'Save',
        delete: 'Delete',
        telerr: 'Incorrect phone number',
        emailerr: 'Incorrect email',
        codeerr: 'Incorrect verification code',
        remarktip: 'Enter remark',
        savemsg1: 'Failed to save!',
        savemsg2: 'Saved successfully!',
        operate: 'Operate',
        fundpwdtip: 'Please enter funds password',
        click: 'click',
        filtrate: 'can filtrate',
        txid: 'TxId',
        generateCode: 'Generate WithdrawCode',
        withdrawcodelist: 'WithdrawCode List',
        withdrawcodestatus: 'Status',
        submit: 'Submit',
        generatecodesuccess: 'WithdrawCode generated!',
        code_status0: 'Unused',
        code_status3: 'Success',
        protocol: 'Network Potocol',
        shenqing: 'Withdrawal request success',
        fundsVerified: 'You have not set the fund password!',
      },
      securityModal: {
        securityReminder: 'Security Reminder',
        toEAuthentication: 'To ensure the security of your funds, please proceed to ',
        toEAuthentication2: ' to bind your email and enable Google authentication.',
        setFundPassword: 'Set Fund Password',
        goToSettings: 'Go to Settings',
        alreadySet: 'Already Set',
        alreadyBound: 'Already Bound',
        bindEmail: 'Bind Email',
        goToBind: 'Go to Bind',
        forTPassword:
          'For the security of your account, withdrawal function will be disabled for 24 hours after you change your password.',
        youHHours:
          'You have changed your login/funds password, the withdrawal function has been disabled and will be available after {0}.',
      },
    },
    member: {
      usercenter: 'User Profile',
      securitycenter: 'Security center',
      securitysetting: 'Security setting',
      accountsetting: 'Account setting',
      account: 'Account',
      assets: 'Finance',
      exchange: 'Exchange',
      curdelegate: 'Orders',
      hisdelegate: 'History',
      otc: 'OTC',
    },
    order: {
      ordercenter: 'Order center',
      myorder: 'My order',
      myad: 'My ad',
    },
    regist: {
      anonymousLogin: 'Anonymous',
      fillInvitationCode: 'Fill in the invitation code',
      go_login: 'Existing account',
      go_login2: 'Sign in',
      gologin: '已有账号，立即登录',
      SaveKey: 'Please save the key',
      zhanghaosiyao: 'Account private key',
      nimingtishiTxt:
        'Enter the account private key to log in to the account, the account private key is randomly created by the local, you need to keep it properly, if lost, you can retrieve through the mailbox bound to the account, otherwise it will not be recovered!',
      shuruningcheng: 'Enter nickname ',
      logintishi:
        'When logging in, you only need the account private key to log in to the account. The account private key is randomly created by the local, and you need to keep it properly. Click OK to automatically copy it',
      hasaccount: 'Have a Account？To Log In',
      login: 'Log in',
      username: 'Username',
      country: 'Country',
      smscode: 'Sms verification code',
      sendcode: 'Send',
      resendcode: 'Resend(',
      pwd: 'Password',
      repwd: 'Confirm password',
      confrimpwd: 'Confrim password',
      agreement: 'Accept',
      userprotocol: 'User Agreement',
      privacypolicy: 'Privacy Policy',
      regist: 'Register',
      teltip: 'Enter tel',
      telerr: 'Tel format error,enter again',
      emailtip: 'Enter email',
      emailerr: 'Email format error,enter again',
      confirmpwdtip: 'Enter confirm password',
      confirmpwderr: 'Two passwords not same!',
      telregist: 'Tel Regist',
      emailregist: 'Email',
      usernametip: 'Enter username',
      usernamemsg: 'Username length not less than 3,not more than15',
      countrytip: 'Select country',
      smscodetip: 'Enter sms verification code',
      pwdtip: 'Enter login password',
      pwdmsg: 'Password length not less than 6',
      telno: 'Tel',
      email: 'Email',
      agreementtip: 'Click to agree',
      reloadChoice: 'Please re-register',
      modaltitle: 'Please verification',
      promotion: 'Invite Code Optional',
      china: 'china',
      us: 'United States',
      japan: 'japan',
      germany: 'germany',
      uk: 'uk',
      india: 'india',
      france: 'france',
      italy: 'italy',
      canada: 'Canada',
      korea: 'korea',
      russia: 'russia',
      australia: '澳大利亚',
      brazil: '巴西',
      hk: 'hk',
      macao: '中国澳门',
      taiwan: 'taiwan',
      spain: 'spain',
      mexico: '墨西哥',
      indonesia: '印度尼西亚',
      netherlands: '荷兰',
      switzerland: '瑞士',
      saudiarabia: '沙特阿拉伯',
      turkey: 'turkey',
      iran: '伊朗',
      poland: '波兰',
      sweden: '瑞典',
      belgium: '比利时',
      thailand: 'thailand',
      nigeria: '尼日利亚',
      austria: '奥地利',
      ireland: '爱尔兰',
      israel: '以色列',
      norway: '挪威',
      argentina: '阿根廷',
      philippines: '菲律宾',
      arab: '阿拉伯',
      egypt: '埃及',
      denmark: '丹麦',
      malaysia: 'malaysia',
      singapore: 'singapore',
      vietnam: 'vietnam',
      bangladesh: '孟加拉国',
      southafrica: '南非',
      chile: '智利',
      finland: '芬兰',
      colombia: '哥伦比亚',
      romania: '罗马尼亚',
      portugal: '葡萄牙',
      newzealand: '新西兰',
      greece: '希腊',
      qatar: '卡塔尔',
      belarus: '白俄罗斯',
    },
    safe: {
      safelevel_low: 'Security Level: Low',
      safelevel_high: 'Security Level: High',
      safelevel_medium: 'Security Level: Medium',
      nickname: 'Nickname',
      bind: 'Bind',
      binded: 'Binded',
      binding: 'Bindind',
      binderr: 'Failure',
      bindretry: 'Retry',
      verifypass: 'Verified',
      verified: 'Verified',
      notverified: 'Not verified',
      verifiedtip:
        'To protect your account security, please complete real-name certification before you can trade operations!',
      realname: 'Actual name',
      idcard: 'ID Card',
      upload: 'Upload',
      upload_positive: 'Upload photo ID front',
      upload_negative: 'Upload ID card reverse photo',
      upload_hand: 'Upload handheld ID photos',
      save: 'Save',
      reset: 'Reset',
      email: 'Email',
      bindemail: 'Bind email',
      loginpwd: 'Login Password',
      emailcode: 'Email verification code',
      clickget: 'Click to get',
      second: 's',
      phone: 'Telphone',
      bindphone: 'Bind telphone',
      phonecode: 'Telphone verification code',
      logintip: 'Use when logging in to the platform',
      edit: 'Modify',
      oldprivateKey: 'Private Key',
      oldpwd: 'Old login password',
      newpwd: 'New login password',
      confirmnewpwd: 'Confirm new password',
      fundpwd: 'Fund password',
      fundtip: 'When account funds change, you need to verify the funds password first',
      set: 'Setting',
      confirmpwd: 'confirm password',
      oldfundpwd: 'Old Fund password',
      newfundpwd: 'New Fund password',
      newpwdmsg1: 'Please enter no less than 6 new login passwords',
      newpwdmsg2: 'New login password is inconsistent',
      pwdmsg1: 'Please enter no less than 6 passwords',
      pwdmsg2: 'Inconsistent password',
      emailtip: 'Please enter email',
      privateKeytip: 'Please enter the private key',
      codetip: 'Please enter verification code',
      telnotip: 'Please enter phone number',
      oldpwdtip: 'Please enter the original password',
      realnametip: 'Please enter your real name',
      idcardtip: 'Please enter the ID number',
      bindphonetip: 'Please bind your phone first!',
      resetfundpwd: 'Reset funds password',
      upload_positivetip: 'Please upload the photo ID card front',
      upload_negativetip: 'Please upload the photo ID card reverse',
      upload_handtip: 'Please upload handheld ID photos',
      save_success: 'Success!',
      save_failure: 'Failure!',
      idcard_verifymsg1:
        '1. The file is less than 8M.The information on the document should be clearly visible, no modifications in the document or cover by the foreign objects is allowed, and the document number and name should be visible.',
      idcard_verifymsg2:
        '2. The photograph should show you without a hat on. It is recommended that there is no make-up. The facial features of the hand-held document holders should be clear, and the arms should be completely exposed.',
      idcard_verifymsg3:
        "3. Need to hold both hand-held documents and handwritten copy (name + application date), 'All my actions on this site are my own operations, and I understand the risks involved and willing to bear all the legal consequences of this account.'",
    },
    account: {
      successDelete: 'Deletion succeeded',
      pagetitle: 'Bind real-name account',
      pagetip: 'Please set your payment method, be sure to use real-name account',
      backcardno: 'Bank account',
      backcardtip: 'Personal bank card information is not bound',
      modify: 'Modify',
      bind: 'Bind',
      name: 'Name',
      bankaccount: 'Bank account',
      bankbranch: 'Bank branch',
      bankno: 'Bank number',
      confirmbankno: 'Confirm card number',
      fundpwd: 'Fund password',
      save: 'Save',
      zfbaccount: 'Alipay account',
      zfbaccounttip: 'Personal Alipay account is not bound',
      wxaccount: 'Wechat account',
      wxaccounttip: 'Personal Wechat account is not bound',
      banknomsg1: 'Please enter the correct bank card number',
      banknomsg2: 'The bank card number entered twice is inconsistent!',
      verifiedmsg: 'Please perform real name authentication first',
      bankaccountmsg: 'Please select your bank',
      bankbranchmsg: 'Please enter account branch',
      banknomsg: 'Please enter the correct bank card number',
      fundpwdmsg1: 'Please enter the correct fund password',
      fundpwdmsg2: 'Password cannot be less than 6 characters',
      zfbaccountmsg: 'Please enter Alipay account',
      wxaccountmsg: 'Please enter WeChat account',
      save_success: 'Success!',
      save_failure: 'Failure!',
      imgtip: '请上传您的收款码',
    },
    otcorder: {
      unpaid: 'Unpaid',
      paided: 'Paided',
      finished: 'Finished',
      canceled: 'Canceled',
      appealing: 'Appealing',
      searchtip: 'Enter the order number',
      orderno: 'OrderNo',
      created: 'Time',
      symbol: 'Trading crypto',
      type: 'Type',
      type_sell: 'sell',
      type_buy: 'buy',
      tradename: 'TradeName',
      amount: 'Amount',
      money: 'Money',
      fee: 'Fee',
    },
    identity: {
      certified: 'already certified',
      placeholder: 'Please fill in the reason for cancellation',
      apply: 'Apply to become business',
      become: 'Become NTL Certified Business and enjoy more transaction privileges',
      zhusnhu: 'Businesses enjoy exclusive advertising booths to increase transaction success',
      tijiaoziliao: 'Submit merchant authentication information',
      place:
        'Please upload the prepared merchant authentication information to the platform and submit it',
      tijiao: 'Your merchant certification audit has been submitted',
      tijiaosuc: 'congratulations Your merchant certification has passed',
      tijiaofail: "I'm sorry! Your merchant's certification has not passed",
      zhuxiaotijiao: 'Your business cancellation application has been submitted',
      shenhefail: 'Your business cancellation application has not been approved',
      shenhesuc: 'Your business cancellation application has been approved',
      shangjiazhuxiao: 'business cancellation',
      shenheshibai: 'Audit Failed',
      shenagain: 'review',
      sheqinggain: 'To apply for',
      reason: 'reason',
      shenqingtuibao: 'Apply for loan',
      getquan: 'You have the following permissions',
      read: 'I read and agree',
      lijishenqing: 'Apply immediately',
      tips: 'prompt',
      wufachexiao:
        'You are in the business cancellation operation, after confirming the submission of the application operation, it will not be able to be revoked.',
      suredo: 'Are you sure this is done?',
      shuzizichan: 'Digital asset trading certificate',
      gerenzichan: 'Personal digital asset certificate',
      second: {
        line: 'How to apply for business?',
        step1: 'Step 1: prepare business application materials as required',
        step1c1: 'Prepare the following application:',
        step1c2:
          'Mobile phone number, WeChat ID, QQ number, personal digital asset certificate (picture), digital asset transaction certificate (picture)',
        step2: 'Step 2: submit the application',
        step2c:
          'To complete the business certification audit materials that need to be filled in and uploaded, click submit for approval.',
        step3: 'Step 3: data audit',
        stepc:
          "We'll be reviewing your business applications for the next three to five days, please keep your eyes open, but you can submit a page. After the approval, you can publish the advertisement in the French currency exchange area.",
        agree: 'Agreed to freeze',
        agreec: 'As a merchant deposit',
        shenqingchngweishangjia: 'Apply for business',
      },
      yuanyin: 'reason',
      tijiaoshenqing: '提交申请',
      bizhong: 'Deposit currency',
      shuliang: 'Margin amount',
      chosen: 'Select Crypto',
      seat: 'Exclusive booth',
      service: 'One-to-one service',
      lowfee: 'Lower fees',
      phone: 'Phone',
      balance: 'Personal assets',
      cardphoto: 'Positive and negative ID photos',
      wx: 'Wechat',
      exchange: 'Whether to engage in off-exchange trading of digital assets',
      handphoto: 'User holding ID photo',
      qq: 'QQ',
      ploy: 'Whether there is a corresponding risk control strategy',
      agreement: '《Certified Merchant Agreement》',
      he: 'and',
      applyfor: 'Apply',
      sendcode: 'Send',
      confirm: 'OK',
      prepare: 'Prepare materials',
      review: 'Submit review',
      result: 'Wait for the result',
      passed: 'Review passed',
      approve: 'Please agree to the certification merchant agreement',
      emailtip1: 'Please email the following materials to',
      emailtip2: 'We will review your application as soon as possible.',
    },
    extension: {
      title1: 'Promotion links',
      title2: 'Promotion friends',
      title3: 'My commission',
      linkdesc:
        'The following URL is your address for promotion to the outside world. You can promote it through friends, QQ, Wechat, Weibo, blogs, forums or your own website. All people who have accessed through this address will be your users after registration., And when these users submit strategies in this site, you can earn commissions, and detailed promotion can be viewed in the access record.',
      linktitle: 'Your romotion links',
      copy: 'Copy',
      copy_msg1: 'Copy Success！',
      copy_msg2: 'Failure！Please copy it manually',
      username: 'Name',
      currrecharge: 'Transaction',
      userlevel: 'User Level',
      createdtime: 'Registration time',
      currcommission: 'Current commission',
      managerfee: 'management fee',
      yuan: 'yuan(RMB)',
      symbol: 'Crypto',
      amount: 'Amount',
      remark: 'Remark',
      amounttime: 'Deliver Time',
    },
    promotion: {
      title: 'Promotion Center',
      subtitle1: 'My Cards',
      subtitle2: 'Exchange Records',
      subtitle3: 'My Promotion',
      card_column0: 'Exchange Code',
      card_column1: 'Name',
      card_column2: 'Symbol',
      card_column3: 'Amount',
      card_column4: 'Dead Line',
      card_column5: 'Total',
      card_column6: 'Exchanged',
      card_column7: 'Create Time',
      card_column8: '过期时间',
      my_column0: 'Invite User',
      my_column1: 'Registion Date',
      my_column2: 'Real Name',
      gopromotion: 'Promotion',
      inputcardno: 'Please input the card code',
      exchange: 'Convert',
      exchangesuccess: 'Congratulations！You can check the asset in finance center！',
      exchangewithcode: 'Exchange With Code',
    },
    activity: {
      all: '全部',
      navTitle: 'Innovation ',
      subNavTitle1: 'My Orders',
      subNavTitle2: 'My Minings',
      subNavTitle3: 'My Locked',
      column1: 'Name',
      column2: 'Type',
      column3: 'Amount',
      column4: 'Unit(IN)',
      column5: 'Unit(OUT)',
      column6: 'Status',
      column7: 'Turnover',
      column8: 'Create Time',
      mColumn1: 'Image',
      mColumn2: 'Name',
      mColumn3: 'Crypto',
      mColumn4: 'Period',
      mColumn5: 'MiningDays',
      mColumn6: 'MiningedDays',
      mColumn7: 'Status',
      mColumn8: 'Base Capacity',
      mColumn9: 'Current Capacity',
      mColumn10: 'Total Mininged',
      mColumn11: 'End Date',
      mColumn12: 'Create Date',
      lColumn1: 'Image',
      lColumn2: 'Name',
      lColumn3: 'Crypto',
      lColumn4: 'Period',
      lColumn5: 'LockedDays',
      lColumn6: 'ReleasedDays',
      lColumn7: 'Status',
      lColumn8: 'Base Capacity',
      lColumn9: 'Current Capacity',
      lColumn10: 'Total Released',
      lColumn11: 'End Date',
      lColumn12: 'Create Date',
    },
    mining: {
      title: 'My Mining List',
      empty: 'No Records',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      invitetip1:
        'The capacity of the mine machine can be improved by inviting friends. Each person invited to exchange the mine machine will increase the capacity ',
      invitetip2: 'Upper limit of capacity increase ',
      miningUnit: 'Mining Unit',
      miningPeriod: 'Mining Period',
      miningDays: 'Mining Times',
      miningedDays: 'Mininged Times',
      miningDaysProfit: 'Base Output',
      miningCurrentDaysProfit: 'Current Output',
      status0: 'To be deployed',
      status1: 'Deployed',
      status2: 'Over',
    },
    locked: {
      title: 'My Locked List',
      empty: 'No Records',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      invitetip1:
        'The capacity of the locked can be improved by inviting friends. Each person invited to exchange the locked will increase the capacity ',
      invitetip2: 'Upper limit of capacity increase ',
      miningUnit: 'Release Unit',
      miningPeriod: 'Release Period',
      miningDays: 'Release Times',
      miningedDays: 'Released Times',
      miningDaysProfit: 'Base Output',
      miningCurrentDaysProfit: 'Current Output',
      status0: 'Pending',
      status1: 'Releasing',
      status2: 'Over',
      totallocked: 'Total Locked',
    },
    api: {
      apiAdmin: 'API Management',
      createApi: 'Create a new API Key ',
      mark: 'Remark',
      bindAddress: 'The unbound IP address key is valid for 90 days',
      hint: 'hint',
      part1:
        'We provides you with a powerful API, you can use market query, automatic transactions and other services',
      apiDoc: 'by API documentation.',
      howUse: 'See about how to use',
      part2: 'Create up to 5 sets of API Keys per user',
      part3:
        'Please do not disclose your API Key to avoid asset loss. For security reasons, it is recommended to bind IP for API Key, with a maximum of 5 IP for each API Key. Fill in a single address directly, multiple IP addresses half Angle commas, such as 192.168.1.1, 192.168.1.2 instead, 192.168.1.3.',
      myApiKey: 'My API Key',
      ipAddress: 'IP address',
      createTime: 'Create Time',
      endTime: 'Last settlement time',
      accessKey: 'Access Key',
      bindIpAddress: 'Bind IP address',
      ioDays: 'The remaining days',
      operation: 'operation',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
      sureDelete: 'Are you sure to delete?',
      tips: 'The key is only displayed when it is added, please save it in time.',
      copy: 'copy',
      access: 'key',
      okText: 'OK',
      cancel: 'Cancel',
    },
  },
  cms: {
    NewExclusive: 'New users only',
    Newtip:
      'Sign up for an NTL account, invite your friends and get a chance to enjoy lifetime rebate',
    noticecenter: 'Announcements',
    newshelp: 'New user helping',
    appdownload: 'Download App',
    onlineservice: 'online service',
    faq: 'FAQ',
    notice: 'Announcements',
    NTLnotice: 'NTL Announcement',
    info: 'Get NTL first-hand news anytime, anywhere',
    servicecenter: 'Customer Center',
    about: 'About',
    joinus: 'Join us',
    aboutus: 'About us',
    exchangerule: 'Trading Rules',
    useprotocol: 'Use Agreement',
    feenote: 'Charge description',
    merchantprocotol: 'Merchant Agreement',
    features: 'Features',
    contactus: 'Contact us',
    noticelist: 'All Announcement',
    nextnotice: 'NEXT',
    prevnotice: 'PREV',
    notexist: 'The page you visited does not exist or has been delete',
    otherhelp: 'Other In This Category',
    scanforshare: 'Scan for share',
    download: 'Download',
    downloadslogan: 'NTL APP - Trade everywhere.',
    pinned: 'Pinned',
    searchArticle: 'Search article',
  },
  description: {
    title1: 'Security',
    title2: '24h Service',
    title3: 'Global Exchange',
    title4: 'Superior Project',
    message1:
      'All-round financial risk control system and security system, hot and cold wallet, multi-signature system to ensure the safety of funds',
    message2:
      '24h online audit can prevent customers from missing out the best investment opportunities.',
    message3:
      'Global business service network helps you invest in global crypto and trade with global users',
    message4:
      'We strictly select the high-quality blockchain projects to eliminate 80% high-risk projects for our customers.',
    message5: 'NTL(WWW.NTLEX.COM)国际数字加密资产交易平台手机客户端，专注掌上交易体验',
    scanqrcode: 'Download APP',
    aboutdesc1:
      'NTLEX.COM is a leading digital asset trading platform in the world, registered in the Cayman Islands, with a core operating team in Hong Kong. The core members of NTLEX.COM come from top Internet and financial companies. Most of them are deep believers in Bitcoin and block chains. We are convinced that block chains will change the traditional monopolistic financial system and build a more democratic and autonomous social structure.',
    aboutdesc2:
      'NTLEX.COM platform has a professional financial-level trading architecture, a self-developed high concurrent memory matching trading engine. The platform uses a full-cold rechargeable wallet system + multiple signatures + high-protection DDOS attack system and other trading architecture to ensure customer asset security.',
    aboutdesc3:
      'In order to let customers better grasp the investment opportunities, NTLEX.COM adopts flat management in customer service department, and establishes an extremely fast service response mechanism. The asset customer service manager is on-line 7*24H all the year round, providing customers with services such as recharge and cash of assets, and ensuring that customers complete recharge in 25 minutes.',
    aboutdesc4:
      'NTLEX.COM strictly screens high-quality projects and provides secure and stable asset hosting services. NTLEX.COM upholds the concept of “honesty, justice, enthusiasm and openness”, and strives to create a safe, reliable, efficient and friendly ultimate exchange for users.',
    aboutdesc5:
      "Although digital assets are only in a small circle now, we believe that this kind of assets that everyone can fully grasp will be popular in the future. Let's work together and look forward to it!",
    aboutdesc6:
      'NTLEX.com (Nano Token Liquidity) is an international digital asset derivatives exchange based on a decentralized autonomous organization (DAO) governance, aiming to provide secure, stable, convenient, fair, and reputable blockchain asset trading services to global users.',
    aboutdesc7:
      'Managed by the authorized Cayman Foundation, the NTL DAO governance structure is more advantageous than traditional cryptocurrency exchanges, effectively ensuring the fairness, justice, and safety of user assets. The management team members come from various countries and regions worldwide, and the core members have worked for globally renowned financial and technology enterprises such as Coinbase, Binance, Morgan Stanley, Amazon, and Google, with strong global strategic operation capabilities and rich financial risk control and management experience.',
    aboutdesc8:
      'NTLEX.com provides one-stop encrypted asset services such as coin-to-coin, perpetual contract, spot, options, NFT, financial borrowing and lending, and supports multiple language versions including Chinese, English, Japanese, and Korean. Its technical solution is based on Amazon AWS and Google Cloud, which can effectively prevent DDOS attacks, support dynamic and static data separation, hot data caching, off-site disaster recovery, parallel expansion, and other functions. The world-leading technical architecture ensures secure user transactions and has real-time blockchain asset access capabilities, multi-currency address sharing technology, and multiple security verification protections. Additionally, NTLEX.com has relatively low blockchain asset trading fees and withdrawal fees in the industry.',
    aboutdesc9:
      'As a trustworthy DAO governance exchange, NTLEX.com is committed to fully safeguarding user transaction information security and asset security and providing users with an excellent trading experience.',
    support: 'Support',
    report: 'Suggestion',
    service: 'Customer Service',
    apply: 'Board Apply',
    hhjh: 'Affiliate',
    coop: 'Cooperation',
    community: 'NTL Community',
    wechat: 'Wechat',
    addwechat: "Add 'NTL01' as Wechat friends and then enter Wechat community",
    weibo: 'Weibo',
    twitter: 'Twitter',
    biyong: 'Biyong',
    telegram: 'Telegram',
    medium: 'Medium',
    reddit: 'Reddit',
  },
  feereturn: {
    ruletitle: 'Note',
    rulecontent:
      'Our White Paper has a detailed explanation of Reimbursement of Transaction Fees. Overall, 51% of the platforms total BHB base (referred to as "community reward portion" from now on) will be reimbursed to users over time via our "Trans-Fee Mining" mechanism. However once this 51% of the total BHBs has been fully released via reimbursement, the "Trans-Fee Mining" mechanism will automatically end. ',
    recordtitle: 'List',
    recordcontent:
      'Everyday (UTC+8, same as below) BHB will reimburse 100% of the userstransaction fees incurred from the previous day in the form of BHB. This will be done by using the average BHB price of the previous day (this average price is calculated as total value transacted/total volume transacted). We will complete this reimbursement of transaction fees from the previous day in the form of BHBs, at 11am everyday.',
  },
  finance: {
    saveCoins: 'Earn',
    info: 'Grow your assets easily on Earn',
    whySaveCoins: 'What is Earn?',
    order: 'My Order',
    current: 'Flexible',
    periodical: 'Fixed',
    save: 'Deposit',
    introduce:
      'Depositing coins and generating interest is a financial product created by NTL. It can help users make money by utilizing idle assets. Deposit interest is divided into current and fixed periods. Current users can withdraw principal and interest at any time, and regular users will settle according to the time selected during deposit. If they withdraw in advance, a certain handling fee will be charged.',
    ok: 'OK',
    curDayRate: 'Est DPR',
    curYearRate: 'Est APR',
    term: 'APR and term',
    day: 'days',
    quantity: 'Amount',
    inputQuantity: 'Please enter the quantity...',
    all: 'all',
    available: 'Available',
    reminder: 'Friendly reminder：',
    reminderCont1: 'If the regular order is terminated early, payment is required ',
    reminderCont2:
      ' handling fees. After the expiration, the system will automatically close the position, and the principal and interest will be deposited into your coin account.',
    cancle: 'Cancel',
    PositionRecords: 'Position',
    ClosingRecord: 'Closing',
    ClosingPosition: 'Close',
    title1: 'Crypto',
    title2: 'Amount',
    title3: 'Total Earnings',
    title4: 'Creation Time',
    title5: 'Term',
    title6: 'Last settlement time',
    title7: 'Settlement Time',
    title8: 'Operate',
    title9: 'Est APR',
    title10: 'Est DPR',
    title11: 'Invested',
    title12: 'Expected Interest',
    title13: 'Expiration Time',
    title14: 'Interest Rate',
    title15: 'Actual Interest',
    title16: 'Term',
    title17: 'Closing Time',
    title18: 'Types',
    title19: 'Commission',
    tip1: 'Are you sure to end this order?',
    tip2: 'Your regular order has not yet reached the end time, and a {0}% handling fee is required for early termination. Are you sure to close this order?',
    depositQuantity: 'Please enter the amount to be deposited',
    cannot: 'The number of deposits cannot be greater than the available amount',
    aheadOfSchedule: 'Early closing',
    expire: 'Maturity closing',
  },
  Partnership: {
    PartnershipPlan: 'Affiliate',
    becomePartner: 'Becoming a platform partner',
    info: 'Promote NTL, receive the highest lifetime commission return opportunity, invite friends to register, and enjoy platform dividends',
    moreService: 'Enjoy more exclusive services',
    title1: 'One on one exclusive service',
    title2: 'Real time data tracking',
    title3: 'Cooperation and win-win situation',
    introduce1:
      'Exclusive operation and customer service, available 24 hours a day to solve any issues you encounter during use or promotion.',
    introduce2:
      'Real time display of revenue and visualization of data dashboards. Let you have a clear understanding of the promotion situation.',
    introduce3: 'Invite friends together with fans, develop and manage your own team.',
    invite: 'Invite users and each user trades 50 USDT (per person).',
    tip: 'Commission available/month',
    NTL: 'NTL Advantages',
    NTLTitle1: 'Up to lifetime commission rebate',
    NTLTitle2: 'Global leading trading platform',
    NTLIntroduce1:
      'You can promote the NTL platform in any form of media such as social media and communities. Every active trader you recommend brings us income, and you will have the opportunity to receive a lifetime commission!',
    NTLIntroduce2:
      'NTL is an international digital asset derivatives exchange based on decentralized autonomous organization (DAO) governance, providing global users with secure, stable, convenient, fair, and reputable blockchain asset trading services.',
    methods: 'How to earn commission on NTL',
    method1:
      'Method 1: Fill out the form and after the official review by NTL, a professional team will contact you.',
    method2: 'Method 2: Invite 10 valid new users.',
    stepBtn1: 'Form Application',
    stepBtn2: 'Telegram application',
    stepBtn3: 'Invite Now',
    rule: 'View Rule Details>',
    KOL: 'KOL Joins NTL to Open a New Universe',
    KOLText1: 'Global Partner Network',
    KOLText2: 'Distributed in various countries',
    KOLText3: 'Monthly Maximum Return (USDT)',
    view: 'View commission income list',
    join: 'Join Now',
    earn: 'How much can I earn',
    promoteNTL: 'Promote NTL',
    becomingPartner: 'Becoming a NTL Partner',
    earnRebates: 'Earn high commission rebates',
    application: 'Application',
    submit: 'Submit',
    name: 'name',
    email: 'email',
    emailCode: 'emailCode',
    contact: 'Your contact information (social account or email)',
    fans: 'How many fans/members/users are there in your community/social media/community',
    community:
      'Your community/social media/community (you can view the communities/social media/communities you maintain, if multiple are available, separate them)',
    inputName: 'Please enter your name....',
    inputemail: 'Please enter your email address...',
    inputemailCode: 'Please enter the email verification code...',
    inputcontact: 'Please enter a social account or email address...',
    inputfans: 'Please enter the number of fans...',
    inputcommunity: 'Please enter the community/social media/community...',
    getCode: 'Obtain verification code',
    share:
      'Share the invitation link or invitation code with your friends, or promote it through other channels such as social media',
    trades:
      'After the user you invite trades, you can obtain commission returns from the handling fee',

    congratulations: 'Congratulations',
    becomingAPlatformPartner: 'Becoming a Platform Partner',
    myRebates: 'My Rebates',
    spotRebatePercentage: 'Spot Rebate Percentage',
    futuresRebatePercentage: 'Futures Rebate Percentage',
    accumulatedRebates: 'Accumulated Rebates (USDT)',
    yesterdaysRebates: "Yesterday's Rebates",
    kolAndMemberInformation: 'KOL and Member Information',
    directKol: 'Direct KOL',
    subordinateKol: 'Subordinate KOL',
    directMember: 'Direct Member',
    subordinateKolMember: 'Subordinate KOL Member',
    directRechargeMember: 'Direct Recharge Member',
    subordinateKolRechargeMember: 'Subordinate KOL Recharge Member',
    totalMembers: 'Total Members',
    totalRechargeMembers: 'Total Recharge Members',
    newDirectMembersToday: 'New Direct Members Today',
    newSubordinateKolToday: 'New Subordinate KOL Today',
    forMoreInformationPleaseLogInToTheBackendToView:
      'For more information, please log in to the backend to view.',
  },
  rule: {
    Breadcrumb1: 'Support',
    Breadcrumb2: 'FAQ',
    Breadcrumb3: 'Affiliate',
    Breadcrumb4: 'article',
    rule: 'Partnership Plan Rules',
    time: 'Published on February 19, 2023 | Updated on July 19, 2023',
  },
  helpCenter: {
    uzxsq: 'NTL Community',
    yhzc: 'User support',
    help: 'Support',
    helpCenter: 'NTL Support',
    card1: 'Change login password',
    card2: 'Change fund password',
    card3: 'Email verify',
    hotProblem: 'Hot Issues',
    novice: 'Required for Beginners',
    more: 'More',
    commonProblem: 'FAQ',
    safe: 'Security',
    article: 'article',
    searchResults: 'search result',
    toSubmite: 'Submit',
    tip: 'Welcome to submit product comments and bugs',
    search: 'search',
    published: 'published on',
    updated: 'updated on',
    inputProblem: 'Please enter a question',
    account: 'Account Management',
    community: 'community',
    articleTime: 'Published on {0} | Updated on {1}',
    toMyworkorder: 'My Work Order',
    googleVerification: 'Google verification',
    setAFundPassword: 'Set a fund password',
    forgotTheFundPassword: 'Forgot the fund password',
    nonsupport: 'Anonymous accounts cannot be modified',
  },
  feedBack: {
    topic: 'Everyone is a product manager',
    info: 'Can provide product feedback and bugs during your use process',
    feedBack: 'Feedback',
    email: 'Email (optional)',
    submit: 'submit',
    error: 'Submission failed',
    photo: 'Picture or video',
    title: 'Title',
    inputTitle: 'Please enter a title...',
    inputContent: 'Please enter content...',
    look: 'view',
  },
  detail: {
    createTime: 'Creation Time',
  },
  MyWorkOrder: {
    customerService: 'Customer service work order',
    info: 'View unresolved and past submitted issues',
    toBeProcessed: 'To be processed',
    processing: 'In process',
    completed: 'Already ended',
  },
  community: {
    joinCommunity: 'Join the NTL community',
    info: 'Understand the latest information on digital asset investment strategies, blockchain knowledge, and industry trends, and communicate online with NTL customer service.',
    english: 'English',
    chinese: 'Chinese',
  },
  noviceCommunity: {
    noviceCommunity: 'Learn',
    topic: 'Playing with digital currency',
    info: 'For beginner strategies, advanced trading, and operation guidelines, please refer to the NTL Beginner Academy',
    recharge: 'Recharge USDT',
    secondUnderstanding: 'Spot Trading',
    UContract: 'USDT-M',
    toRecharge: 'Proceed to Deposit',
    toTransaction: 'Trade',
    topicClass: 'Theme Classroom',
    more: 'Show more',
    blockchain: 'Blockchain Glossary',
    unresolved: 'Your issue has not been resolved yet?',
    toHelpCenter: 'To Support',
    contactService: 'Contact NTL customer service',
    noviceStrategy: 'Novice Strategy',
    advancedTrading: 'Advanced Trading',
    unresolved1: 'Not resolved yet?',
    join: 'Get Started Quickly',
  },
  bzb_description: {
    title1: '分红权益',
    title2: '手续费折扣',
    title3: '优先认购',
    title4: '投票权',
    title5: '第一知情权',
    title6: '优先上币',
    title7: '共商决策',
    title8: 'More',
    desc1: '手续费分红、收益分红、上币分红、股权分红等',
    desc2: '交易手续费最高80%折扣返还奖励，细则待定',
    desc3: 'IEO首发及Boot促销Token优先认购权利',
    desc4: '参与NTL社区投票活动，包括但不限于上币、回购等',
    desc5: '持仓量达一定数量进SVIP群，跟大V零距离接触',
    desc6: '仅限超级节点，拥有推荐上币资格',
    desc7: '仅限超级节点，拥有共商重大决策权利',
    desc8: '更多权益，敬请期待',
  },
  footer: {
    wechatkefu: 'wechat service',
    notice: 'Announcement',
    changjian: 'FAQ',
    shiyong: '使用协议',
    feilv: '费率说明',
    lianxi: '联系邮箱',
    biyong: 'BiYong',
    gsmc: 'Trade the best Cryptocurrency',
    bah: 'No. 13026899 -3, Shanghai ICP',
    gsjj: 'Profile',
    gywm: 'About Us',
    jrwm: 'Join Us',
    lxwm: 'Contact Us',
    yqlj: 'Links',
    bzzx: 'Support',
    xsrm: 'Average price',
    czzn: 'Recharge Guide',
    jyzn: 'Trade Guide',
    ptsm: 'Explain',
    fysm: 'Fees',
    fltk: 'Fees',
    wechat: 'Wechat Customer Service',
    xszn: "Beginner's Guide",
    cjwt: 'FAQ',
    mztk: 'Disclaimer',
    ystk: 'Privacy Policy',
    tkxy: 'Conditions',
    gybzb: 'About BZB',
    kfyx: 'Service Mailbox',
    swhz: 'Cooperation',
    sbsq: 'Market App',
    bzzl: 'Coin’s Info',
    tsjb: 'Report',
    apidoc: 'Api Doc',
    zwkf: 'come soon',
    fwtk: 'Terms of Service',
    yszc: 'Privacy Policy',
    mzsm: 'Disclaimer',
    lzjb: 'Anti-Corruption Report',
    zfqq: 'Law Enforcement Request',
    cp: 'Product',
    fw: 'Service',
    secondFuture: 'Second Future',
  },
  plate: {
    title: "Platform income distribution scheme（BHB Holder's equity）",
    content1:
      'As described in the white paper, NTL will take up 80% of the platform (after deduct Taxes and fees )The income is allocated to BHB holders, and 20% of revenue is used to support R & D and operation of the platform.',
    content2:
      'The distribution of income takes days as a distribution cycle, and June 6, 2018 as the first distribution day. Every day after that, the total income to be distributed on the previous day will be allocated to BHB holders in proportion.',
    content3:
      '（notice：1. the BHB here only refers to the BHB that has been released / circulated. See also【',
    content3_1: 'Note on the ratio of BHB circulation / participation in income distribution',
    content3_2:
      '2. the hourly (whole point) snapshot is calculated once and the income distribution is executed once a day.',
    hourFenpei: 'Distribution of income today',
    hourTotal: "Total income of today's platform",
    xiangqing: "Details of today's income distribution",
    yesterdaytit: 'Distribution of income yesterday',
    yesterdaytotal: "Total income of yesterday's platform",
    yesterdayxiangqing: "Details of yesterday's income distribution",
  },
  rewardCenter: {
    rewardCenter: 'Rewards center',
    aChanceToWinMorePrizes: 'A chance to win more prizes',
    activity: 'Activity',
    singleDeposit: 'Single Deposit',
    singleDepositDescription1: 'Make a first single deposit of ',
    singleDepositDescription2: '$, and get a digital blind box',
    myRewards: 'My Rewards',
    learnMoreAboutRewards: 'Learn More About Reward Rules',
    digitalCurrency: 'Digital Currency',
    digitalCurrencyType: 'Digital Currency',
    financialExperienceGold: 'Trade with experience bonus',
    notIssued: 'Not Issued',
    issued: 'Issued',
    noAvailableRewards:
      'You currently have no available rewards; invite friends to get a blind box',
    topUpNow: 'Proceed to Deposit',
    commissionCard: 'Commission Card',
    dikouquan1: 'Coin Commission Card',
    dikouquan2: 'Contract Commission Card',
    perpetualContractExperience: 'Perpetual',
    leverageLimit: 'Leverage {0}{1}x available',
    unlimitedLeverage: 'No limit',
    deductible: 'Deductible',
    useNow: 'Use Now',
    expired: 'Expired',
    haveBeenUsed: 'Have been used',
    noDataAvailable: 'No Data Available',
    inviteFriends: 'Referral',
    commonProblems: 'FAQ',
    whatIsRebateCardAndHowToUseIt: 'What is a Rebate Card? How to Use It?',
    rebateCardUsageDescription:
      'The rebate card can be used to offset transaction fees within its validity period. It takes effect immediately after you acquire the rebate card. On the day following your incurring of transaction fees, the rebate card will deduct the corresponding amount and return the remaining USDT to your funding account until it is exhausted.',
    whereToViewDigitalCurrencies: 'Where to View Digital Currencies?',
    digitalCurrenciesViewingDescription:
      "Digital currencies received will be directly deposited into your spot account, and you can view them in 'Assets - Spot Account.' Digital currencies have no expiration date.",
    rewardRules: 'Reward Rules',
    text1: 'NTL User First Deposit Reward Explanation',
    text2: 'Published on February 19, 2023 | Updated on July 19, 2023',
    text3: '100% New User Reward:',
    text4:
      '1. New users who complete their first deposit are eligible for rewards (the form and amount of rewards are subject to the page display; including blind box rewards in BTC, ETH, NTL, or commission cards);',
    text5: '2. Deposit methods include:',
    text6:
      '(1) On-chain transfer: Transfer coins from other exchanges or personal wallets to your personal NTL account, with a minimum single order amount of $35;',
    text7: '3. Rewards in NTL platform tokens are frozen',
    text8:
      'Unlocking rules: Recommend friends to engage in contract or spot trading, and when the invited friends complete contract trading, the trading user and the inviting person can unlock NTL platform tokens. Unlocking quantity = trading fees generated / NTL platform token price;',
    text9: 'About Blind Boxes',
    text10: '1. Will the blind box expire?',
    text11:
      'Yes, users must claim the blind box within 30 days of receipt, otherwise it will expire. For example, if you receive a blind box on March 1st at 00:00, it will expire on March 30th at 23:59. After expiration, you will not be able to claim it.',
    text12: '2. How many blind boxes can I get?',
    text13: 'You can get 1 blind box when you first deposit $50 or more.',
    text14: 'For more ways to get blind boxes, please follow our activity announcements.',
    text15: '3. What types of rewards are in the blind box?',
    text16:
      'The rewards in the blind box can go up to the equivalent of $XXXX in digital currency, BTC, ETH, NTL, or fee deduction vouchers. The types of rewards may change from time to time.',
    text17: '4. How can I check the digital currency rewards in the blind box?',
    text18:
      'After claiming the blind box, you can go to the NTL App > User Center > Reward Center to check the digital currency rewards.',
    text19: 'Terms and Conditions of the Promotion',
    text20: '1. Each eligible user is limited to one reward.',
    text21:
      '2. Sub-accounts are not eligible to participate in the blind box promotion or receive any rewards.',
    text22:
      '3. Any and all applicable taxes, as well as all fees and additional expenses (including connection, installation, and/or service fees) associated with accepting and using rewards, will be borne by the user who accepts the reward.',
    text23:
      '4. When you meet all the requirements and log in to the NTL App within 30 days of registering your NTL account, the reward will be issued immediately.',
    text24:
      '5. If the NTL risk control system detects any cheating behavior in your account, any actions you take to complete the first deposit will be considered invalid. NTL will block, suspend, or terminate any accounts engaged in cheating behavior.',
    text25:
      '6. NTL reserves the right to cancel the participation of any participant in this promotion and/or to cancel the promotion at any time, without further notice.',
    text26:
      '7. If there is any discrepancy between the translated version of this document and the English version, the English version shall prevail.',
    text27:
      '8. NTL reserves the right to interpret the terms and conditions of this promotion in a manner it deems appropriate and to adjust or modify the terms and conditions of this promotion at any time.',
  },
  inviteNew: {
    winUpTo: 'Win up to ',
    OfCryptoBlindBox: ' worth of crypto blind box',
    inviteFriendsAndGetCryptoBlindBox:
      'Invite friends and both you and your friends can get crypto blind boxes',
    inviteLink: 'Invite Link',
    link: 'Link',
    inviteCode: 'Invite Code',
    howToGetCryptoBlindBox: 'How to Receive Crypto Blind Box',
    inviteFriends: 'Referral',
    friendsNeedToDownloadAppAndRegisterWithInviterCode:
      "Friends need to download the app and register with the inviter's code to qualify",
    friendsLoginToApp: 'Friends log in to the App',
    bothPartiesUnlockBlindBoxUponFriendsLogin:
      "Both parties can unlock a blind box upon the friend's login",
    receiveBlindBoxRewards: 'Receive Blind Box Rewards',
    openNTLomAppInviteFriendsCheckReceivedBlindBoxes:
      'Open the NTLEX.com App, invite friends, and check received blind boxes in the records',
    invitationRules: 'Invitation Rules',
    inviteYourFriendsToJoinNTLomAndGetUpTo10000USDOfCryptoBlindBox:
      '1. Invite your friends to join NTLEX.com and get up to $10,000 worth of crypto blind box.',
    step1EnterInviteFriendsPageShareInviteLinkOrCode:
      "Step 1: Enter the 'Invite Friends' page and share your invite link or code with your friends.",
    step2FriendSuccessfullyRegistersAndBothGetBlindBoxWithin30Days:
      'Step 2: If your friend successfully registers and logs in to the NTLEX.com App within 30 days, both you and your friend will receive a blind box.',
    step3FriendDeposits200USDInCryptoWithin30DaysBothGetBlindBox:
      'Step 3: Friends within 30 days of registration, a single recharge of digital currency greater than or equal to $35, you and friends can get a blind box.',
    eachBlindBoxContainsUpTo10000USDOfCryptoAndMore:
      "2. Each blind box can contain up to $10,000 worth of cryptocurrency, including BTC, ETH, NTL, and more. Cryptocurrency rewards will be credited to your account within 1 hour of opening the blind box. You can check your rewards in the 'Assets' section.",
    noLimitOnBlindBoxQuantityInviteMoreFriendsForMoreBlindBoxes:
      '3. There is no limit to the number of blind boxes you can receive. Invite more friends to get more blind boxes!',
    subAccountsCannotParticipateInThisPromotion:
      '4. Sub-accounts cannot participate in this promotion.',
    moreDetailedRulesAvailableAt: 'For more detailed rules, please check',
    uzxInviteFriendsRulesExplanation: "the 'NTL Invite Friends Rules Explanation'.",
    inviteFriendsAndGetCommission: 'Invite Friends and Get Commission',
    recommend1NewUserToRegisterAndGetCommissionRewards:
      'For every 1 new user you recommend who successfully registers on NTL, you will receive commission rewards when they engage in spot or contract trading on NTL. The recommended user will also receive cashback rewards for their own trading.',
    setFriendCommissionPercentageInApp:
      'You can set the commission percentage for friends in the app.',
    uzxCommissionRulesExplanation: "the 'NTL Commission Rules Explanation'.",
    promotionTools: 'Promotion Tools',
    invitePromotionalGraphics: 'Invite Promotional Graphics',
    promotionalMaterials: 'Promotional Materials',
    exclusiveQRCodeForAutomaticRegistration:
      'Exclusive QR code that automatically registers friends as subordinates when scanned. Suitable for sharing in various groups or forums.',
    inviteFriendsRecord: 'Invite Friends Record',
    copy: 'Copy',
    acquired: 'Acquired',
    toBeAcquired: 'To be acquired',
    invitePromoImage: 'Invite Promo Image',
    scanToLearnMore: 'Scan to Learn More',
    myInvitationCode: 'My Invitation Code: ',
    downloadImageUsingScreenshotTool:
      'If Unable to Download Images Normally, Use Screenshot Tool to Save',
    save: 'Save',
    helpCenter: 'Support',
    faq: 'FAQ',
    inviteFriendsAndPartnershipProgram: 'Invite Friends and Partnership Program',
    articles: 'Articles',
    text_1: 'NTL Invite Friends Rules',
    text_2: 'Published on February 19, 2023 | Updated on July 19, 2023',
    text_3: 'I. Activity Rules',
    text_4:
      '1. Invite your friends to join NTLEX.com, and you can receive a digital currency blind box worth up to 10,000 USD.',
    text_5:
      "Step 1: Go to the 'Invite Friends' page and share your invitation link or code with your friends.",
    text_6:
      'Step 2: After successful registration, your friend must log in to the NTLEX.com APP within 30 days for both you and your friend to receive a blind box.',
    text_7:
      'Step 3: If your friend deposits a single transaction of 35 USD or more in digital currency within 30 days of registration, both you and your friend will receive another blind box.',
    text_8:
      'Digital currency deposits: Deposits of 35 USD or more from other exchanges or personal wallets to the NTLEX.com account. Digital currency obtained through internal transfers, red envelopes, activity rewards, fiat purchases, and other methods do not count as deposits. Note: Your invited friend must complete the deposit and log in to the NTLEX.com APP to receive the blind box. If your invited friend does not log in to the APP within 30 days of registration, even if they complete a deposit of 35 USD or more, neither you nor your friend will receive the blind box.',
    text_9:
      "2. Each blind box contains a maximum of 10,000 USD worth of digital currency, including BTC, ETH, LTC, DOT, BNB, and commission cards. Digital currency rewards will be credited to your wallet within 1 hour of opening the blind box. You can check your rewards in the 'Assets' section.",
    text_10:
      '3. There is no limit to the number of blind boxes you can receive. The more friends you invite, the more blind boxes you can earn. Invite more friends now!',
    text_11: '4. Sub-accounts cannot participate in this activity',
    text_12: 'II. Frequently Asked Questions',
    text_13: '1. Will unopened blind boxes expire?',
    text_14:
      'Blind boxes are valid for 30 days from the moment you receive them. For example, if you receive a blind box at 00:00 on January 1st, it will expire at 23:59 on January 30th. Expired blind boxes cannot be opened, so be sure to open them within the validity period.',
    text_15: '2. What is a surprise blind box?',
    text_16:
      'When your invited friend meets the deposit requirements, you will receive a surprise blind box with doubled reward amounts.',
    text_17: '3. Where can I see the total number of my blind boxes?',
    text_18:
      "You can check the total number of blind boxes you have received, including unexpired and openable blind boxes, by going to 'APP - Invite Friends - My Invitation Records'.",
    text_19: '4. How many blind boxes can I receive at most?',
    text_20:
      'There is no limit to the number of blind boxes you can receive. The more friends you invite, the more blind boxes you can earn. Invite more friends now!',
    text_21: '5. How can I view the rewards I have received?',
    text_22:
      "After unlocking the blind box, you can view your rewards on the 'Invite Friends Home Page - My Rewards' page.",
    text_23: '6. What rewards can I get after opening a blind box?',
    text_24:
      'Each blind box contains a maximum of $10,000 worth of digital currency, including BTC, ETH, NTL, and commission cards. NTLEX.com will update rewards periodically, so the actual rewards you receive when opening a blind box may vary.',
    text_25: '7. What are commission cards, and how do I use them?',
    text_26:
      'Commission cards can be used to offset transaction fees within their validity period. After obtaining a commission card, it will take effect immediately. On the day following the generation of your transaction fees, the commission card will deduct the corresponding amount and return the remaining USDT to your wallet until it is used up.',
    text_27: 'III. Special Notes',
    text_28:
      "1. You can only receive blind box rewards through the 'Invite Friends' activity. Inviting friends through other activities will not earn you blind box rewards.",
    text_29: '2. Sub-accounts cannot participate in invitations or receive rewards.',
    text_30:
      "3. NTLEX.com will rigorously review users' invitation behaviors. If there is suspected cheating behavior related to accounts or devices, it may trigger risk control measures and result in the loss of reward eligibility. Severe cheating behavior may lead to the suspension of your account.",
    text_31: '4. NTLEX.com reserves the right to adjust the format and rewards of the activity.',
    text2_0: 'NTL Commission Rules',
    text2_1: 'Published on February 19, 2023, Updated on July 19, 2023',
    text2_2: '1. What rewards do referrers get?',
    text2_3:
      'For every successfully referred new user who registers with NTL, the referrer can enjoy commission rewards when the referred user engages in spot or contract trading on NTL. The referred user can also enjoy cashback rewards on their own trades.',
    text2_4: '2. Referral Rules Details:',
    text2_5:
      'By inviting friends to register through referral links, you can offer them a discount on trading fees.',

    text2_6: 'Spot Trading:',
    text2_7:
      "1.If the inviter's locked $NTL holding quantity is less than 100, the base commission rebate rate is 50%. The inviter can choose to share 0-50% with the invited friends.",
    text2_8:
      "2.If the inviter's locked $NTL holding quantity is between 100 and 500, the base commission rebate rate increases to 70%. The inviter can choose to share 0-70% with the invited friends.",
    text2_8_new:
      "3.If the inviter's locked $NTL holding quantity is greater than 500, the base commission rebate rate further increases to 80%. The inviter can choose to share 0-80% with the invited friends.",
    text2_9: 'Futures Trading:',
    text2_10:
      "1.If the inviter's locked $NTL holding quantity is less than 100, the base commission rebate rate is 50%. The inviter can choose to share 0-50% with the invited friends.",
    text2_11:
      "2.If the inviter's locked $NTL holding quantity is between 100 and 500, the base commission rebate rate increases to 70%. The inviter can choose to share 0-70% with the invited friends.",
    text2_11_new1:
      "3.If the inviter's locked $NTL holding quantity is greater than 500, the base commission rebate rate further increases to 80%. The inviter can choose to share 0-80% with the invited friends.",
    text2_11_new2:
      'If users wish to receive a higher commission standard, they can apply to become a NTL platform partner.\n',

    text2_12: '3. Commission Rules Explanation:',
    text2_13:
      "1. The referred person must successfully register using the referrer's exclusive referral link (QR code, referral ID). After registration, when the referred person engages in trading on NTL's spot or contract markets, the referrer can earn referral commissions from their trading fees. If the referrer's account ID is entered directly during registration, they will not be eligible for referral commissions or cashbacks.",
    text2_14:
      '2. Commissions and cashbacks will be settled and distributed daily (GMT+8) based on the actual transaction fees collected. The total commission will be displayed in equivalent U. After contract trading, rewards will be returned to the spot account.',
    text2_15:
      '3. The total holding quantity will be randomly snapshot within 24 hours, and the NTL quantity will be based on the daily average (average of the end of the previous day and the end of the current day).',
    text2_16:
      '4. When the base commission rate is adjusted up or down, the new rate will take effect the next day, and the base rate will be determined based on NTL holdings.',
    text2_17:
      '5. The final interpretation rights belong to the platform. Violations of rules due to the exploitation of unforeseen bugs or rule vulnerabilities in the system will be considered invalid, and the platform reserves the right to pursue accountability.',
    text2_18: '6. Referral Percentage',
    text2_19:
      'Risk Warning: Malicious volume manipulation and actions such as attracting large customers to the platform will trigger platform risk control, and the platform has the right to cancel commissions and cashbacks for violating users.',
    transactionType: 'Trading Type',
    inviterUzxcAmount: "Inviter's $NTL Holding Quantity",
    inviterBaseCommissionRate: "Inviter's Base Commission Rebate Percentage",
    inviterReceivedCommissionRate: "Inviter's Commission Rebate Percentage",
    inviteeCashbackRate: "Invitee's Commission Rebate Percentage",
    spot: 'Funding',
    contract: 'Contract',
    accountNumber: 'Account number',
    registrationBonus: 'Registration bonus',
    depositBonus: 'Deposit bonus',
    custom: 'Custom',
    notLoggedIn: 'Not logged in',
    tipText: 'You have become a platform partner and can view more content in the “Affiliate”.',
    view: 'View',
  },
  summarize: {
    coinSummary: 'Coin Summary',
    collapse: 'Show less',
    expand: 'More',
    officialLinks: 'Learn more',
    officialWebsite: 'Official website',
    github: 'Github',
    blockExplorer: 'Block explorer',
    socialMedia: 'Social media',
    popularityRank: 'Popularity Rank',
    marketValueRank: 'Market Value Rank',
    marketValue: 'Market cap',
    circulatingSupply: 'Circulating Supply',
    addToWatchlistRatio: 'Favorited rate',
    historicalHighPrice: 'All-time high',
    historicalHighDate: 'Historical High Date',
    historicalLowPrice: 'Historical Low Price',
    historicalLowDate: 'Historical Low Date',
    firstIssuanceDate: 'ICO date',
    ownedPublicChain: 'Owned public chain',
    initialIssuePrice: 'Initial issue price',
    maximumSupplyMarketValue: 'Maximum supply market value',
    flowRate: 'Flow rate',
    tokenDistribution: 'Token Distribution',
    maxSupply: 'Max supply',
    powMining: 'PoW Mining',
  },
  NTLDao: {
    welcomeNTL: 'Welcome to NTL OpenGov',
    info: 'NTLEX.com is a globally recognized cryptocurrency exchange driven by DAO, committed to providing a secure and stable trading environment.Through DAO, our goal is to eliminate potential risks of centralized exchanges, enhance user trust and transparency.',
    joinWhitelist: 'Join',
    limitedTimeFree: 'Claim governance tokens for free, for a limited time.',
    equity: 'NTL Partial rights of NTL Governance Token Holders',
    proposalRights: 'Right to Proposals',
    proposalRightsInfo:
      'Governance token holders will have the right to propose proposals related to the development of the exchange and the ecosystem, which may include suggestions for new features, improvements to user experience, expansion into new markets, etc.',
    VotingRights: 'Voting Rights',
    VotingRightsInfo:
      'Governance token holders will have the right to participate in voting. They can vote on important matters related to the operation of the exchange and the development of its ecosystem, such as accepting or rejecting proposals, electing super representatives, etc.',
    VIP: 'VIP Trading Rights',
    VIPInfo:
      'Users who hold a certain number of governance tokens will be entitled to VIP trading rights, enjoying a range of trading benefits such as low transaction fees, high commission rewards, etc.',
    DividendRights: 'Profit Sharing Rights from Exchange',
    DividendRightsInfo:
      'Governance token holders will have profit-sharing rights for the profits generated by exchange, allowing them to share the success of exchange and encouraging them to actively support its development.',
    circuitDiagram: 'The NTL Governance Token Roadmap',
    GovernanceTokens:
      "The governance token is expected to have a lock-up rate of over 95% in the first year and an estimated lock-up rate of over 90% in the second year, ensuring a steady increase in the governance token's price as the exchange develops.",
    SecurityEmpowerment: 'Security Empowerment',
    smartContracts: 'Smart Contracts',
    smartContractsInfo:
      'Members are not bound by formal contracts but are connected through common goals and network incentives.',
    Timelocks: 'Timelocks',
    TimelocksInfo:
      'Funds can be set to be untransferable before a certain point in time, providing users with additional security to respond to suspicious activities.',
    MultiSig: 'Multi-Sig Wallet',
    MultiSigInfo:
      "Users' assets will be fully locked in audited exchange smart contracts and multi-sig wallets, and exchange administrators cannot misappropriate user funds.",
    ProjectRoadmap: 'Project Roadmap',
    NTLExchange: 'Who owns the NTLEX.com exchange?',
    SharedOwnership: 'NTL DAO members collectively own it!',
    NTLExchangeInfo:
      'NTL token holders (NTL DAO members) have voting, proposal, and dividend rights. The NTL DAO decision-making committee can vote to accept or review proposals. Each member has one voting qualification, and significant decisions require a two-thirds majority vote, while routine governance decisions require a simple majority vote.',
    NTLOpenGov: 'NTL OpenGov',
    introduce: 'Introduction',
    introduceInfo: 'Decentralization and Community Governance',
    vote: 'Voting and Election',
    voteTitle: 'All decisions are made by token holders',
    voteInfo:
      ' We will adopt DAO (Decentralized Autonomous Organization) as the exchange’s governance mechanism. This means that all token holders will have equal power to participate in exchange’s operations and decisions, ensuring fairness, openness, and transparent governance.',
    decisionSystem: 'Decision-making System',
    decisionSystemInfo:
      'To effectively manage and guide exchange’s operations and development, we will establish a DAO decision-making committee, consisting of 20 super representatives and 7 genesis members.',
    RepresentativeTerm: 'Representative Tenure',
    RepresentativeTermInfo:
      'The tenure for each super representative will be set as one year. Once the tenure of a super representative ends, NTL token holders in community will have the opportunity to vote for new super representatives. This mechanism ensures the continuous fluidity and democracy of the decision-making committee members.',
    CandidateRequirements: 'Eligibility for Candidate Selection',
    CandidateRequirementsInfo:
      'A candidate eligible for becoming a super representative must hold at least 5 million NTL tokens as collateral. This requirement aims to ensure that super representatives possess sufficient rights and share a common belief in the long-term success of the exchange.',
    ProposalSystem: 'Proposal System',
    ProposalSystemInfo:
      'We encourage DAO members to actively propose ideas related to exchange development. These proposals can cover various aspects, including but not limited to adding new features, improving user experience, expanding the market, enhancing security, etc.',
    construct: 'Start Building',
    becomeMember: 'Become a part of our community',
    becomeMemberInfo: 'Join our ever-growing community of developers and end-users',
    whitePaper: 'NTL DAO WhitePaper',
    whitePaperInfo: 'Co-creating NTL Decentralized Governance',
    download: 'Download',
    modalTitle:
      'To fill out information, participate in an airdrop, and become a member of the DAO.',
    name: 'Name (Optional)',
    email: 'Email',
    wallet: 'Wallet (Please provide NTL recharge address within the NTL platform)',
    inputname: 'Your name...',
    inputemail: 'Your email...',
    inputwallet: 'Please provide the NTL recharge address in the NTL platform...',
    days: 'd',
    hours: 'h',
    minutes: 'm',
    seconds: 's',
    // "tip1": "Only active addresses are supported (addresses that have had transactions on the Polygon chain or Ethereum chain, such as transfers or having received USDT, ETH, MATIC, etc.)",
    tip: "NTL NTL is committed to protecting and respecting your privacy. We will only use your personal information to manage your account and provide the products and services you request from us. By clicking 'Submit' below, you consent to NTL storing and processing the personal information submitted above to provide you with the requested content.",
    stepTitle1: 'Issuing 500 million governance tokens',
    stepTitle2: 'Allocating 140 million tokens to Genesis members',
    stepTitle3: 'Releasing 210 million tokens to super representatives',
    stepTitle4: '100 million tokens as trading rewards',
    stepTitle5: "The exchange's operational account holds 30 million tokens.",
    stepTitle6: 'The NTL development fund holds 20 million tokens.',
    stepInfo1:
      'NTL DAO will issue a governance token named NTL with a total supply of 500 million, priced at 0.0416 USDT per token.',
    stepInfo2:
      '140 million NTL tokens will be allocated to 7 genesis members, each with no more than 20 million tokens.Locking tokens for 2 years after issuance.',
    stepInfo3:
      '210 million NTL tokens will be released to 2 super representatives every three months until the release is completed.',
    stepInfo4:
      'The total network reward is 100 million NTL governance tokens, released in sync with a 1% proportion of daily trading fees generated by users on the exchange. Users can instantly buy and sell the reward tokens they receive.',
    stepInfo5:
      'The exchange operational fund account will hold 30 million NTL tokens, allocated for the early operational expenses of the exchange, including marketing activities and rewarding exceptional talent. Rewards for marketing personnel come with no lock-up requirements and can be bought or sold at any time.',
    stepInfo6:
      "The NTL DAO development fund will hold 20 million NTL tokens, dedicated to NTL.network's technical research and development as well as community development.",
    NTLToken: 'NTL Token',
    NTLTokenInfo:
      'The NTL governance token is not only the digital currency of the platform, but also the core token of governance. It will determine the development direction and future of the exchange, and is the ultimate weapon to gather global wisdom and enhance the core competitiveness of the exchange.',
    objectTitle1: 'Building Infrastructure',
    objectTitle2: 'Platform Launch and Expansion',
    objectTitle3: 'Continuous Improvement and Development',
    objectTime1: '(Early 2023 - End of 2023)',
    objectTime2: '(2024 - 2025)',
    objectTime3: '(After 2026)',
    objectInfo1: 'Platform Construction',
    objectInfo2: 'Token Issuance',
    objectInfo3: 'Security Audit',
    objectInfo4: 'Community Interaction and Transparency',
    objectInfo5: 'Exchange Launch',
    objectInfo6: 'Cross-chain Bridge',
    objectInfo7: 'Community Building',
    objectInfo8: 'Technological Upgrades',
    objectInfo9: 'Global Expansion',
    objectInfo10: 'Building the NTL.network Public Blockchain',
    objectInfo11: 'Community Governance',
    emailCode: 'Email verification code',
    inputemailCode: 'Please enter the email verification code',
    getCode: 'Get code',
    reGetCode: 'Resend in seconds',
    certificate1: ' Certificate of Incorporation',
    certificate2: ' NTL DAO FOUNDATION',
  },
  tradingDrawer: {
    tradeMarketInfo: 'Trading/Market Information',
    orderCenter: 'Order Center',
    tutorials: 'Tutorials',
    beginnerTutorials: 'Average price',
    layout: 'Layout',
    optionContractBilling: 'Option Contract Billing',
    coinBasedContractBilling: 'Coin-Based Contract Billing',
    perpetualContractBilling: 'Perpetual Contract Billing',
    usdtBasedContractBilling: 'USDT-Based Contract Billing',
    spotBilling: 'Spot Billing',
    openOrders: 'Orders',
    historicalOrders: 'History',
    chart: 'Chart',
    orderTableLatestTrades: 'Order Table, Latest Trades',
    market: 'Market',
    orderAssets: 'Order, Assets',
    positionOrdersAssets: 'Position, Orders, Assets',
    quickBuySell: 'Quick Buy/Sell',
    pastResultsRealTimeQuotes: 'Past Results, Real-time Quotes',
    current: 'Current',
    trade: 'Trade',
    history: 'History',
  },
  price: {
    USDT_M: 'USDT-M',
    COIN_M: 'COIN-M',
    secondFuture: 'Second future',
    options: 'Options',
    '24hVolume': '24h volume',
    '24hTurnover': '24h turnover',
    MarketCap: 'Market cap',
    NTLRanking: 'NTL Ranking',
    Overview: 'Overview',
    Details: 'Details',
    MainstreamCoins: 'Top',
    PopularList: 'Popular List',
    ChangeRanking: 'Gainers',
    NewCoinsList: 'New',
    TradingVolumeRanking: 'Turnover',
    More: 'More',
    addOptional: 'Add favorites',
    NTLurrencyQuotation: 'NTL currency quotation',
    understand:
      'Understand the latest price, rise and fall, market value, trading volume and other information of digital currencies.',
  },
  transitionpage: {
    transtr: 'Mobile version is under upgrade, please use PC to access the desktop version.',
    open: 'Open',
    cancel: 'Cancel',
  },
  aboutUs: {
    paragraph1:
      'NTLEX.com (Nano Token Liquidity) is an international digital asset derivatives exchange based on decentralized autonomous organization (DAO) governance. It aims to provide secure, stable, convenient, fair,and reputable blockchain asset trading services to users worldwide.',
    paragraph2:
      'NTL DAO authorizes the Cayman Foundation to manage NTLEX.com, a governance structure that offers advantages over traditional cryptocurrency exchanges, effectively ensuring fairness and the security of user assets.The management team consists of members from various countries and regions, with core members having prior experience at globally renowned financial and technology companies such as Coinbase, Binance, Morgan Stanley, Amazon, Google, and possesses strong global strategic operational capabilities and extensive experience in financial risk control and management.',
    paragraph3:
      'NTLEX.com offers a one-stop solution for cryptocurrency services, including spot trading, perpetual contracts,options, NFTs, financial lending, and borrowing. It supports multiple language versions, including Chinese,English, Japanese, and Korean. Its technical solutions are based on platforms like Amazon AWS and Google Cloud, providing effective protection against attacks such as DDoS, support for dynamic and static data separation, hot data caching, off-site disaster recovery, parallel expansion, and more. Its globally leading technical architecture ensures secure trading for users, with features like instant blockchain asset access,multi-currency address sharing technology, and multi-factor security verification protection. Additionally,NTLEX.com boasts lower blockchain asset trading fees and withdrawal fees compared to industry standards.',
    paragraph4:
      'As a trusted DAO governance exchange, NTLEX.com is committed to safeguarding user trading and asset information security, providing users with an exceptional trading experience.',
    paragraph5:
      '1. Digital assets involve significant risks, and market trading is subject to various uncertainties. Due to the relatively low total market capitalization of digital currencies, their prices are susceptible to significant fluctuations influenced by market manipulators and government policies. Price fluctuations may lead to substantial profits or losses. Any digital asset can experience substantial volatility, and it may even become worthless.',
    paragraph6:
      '2. Digital asset trading may be suspended or prohibited at any time due to the formulation or modification of laws, regulations, and normative documents in various countries. Users should assess the risks based on local laws, regulations, and policy documents when using NTL services.',
    paragraph7:
      '3. Users participating in digital asset trading should carefully consider and evaluate the investment value and risks of digital assets based on their financial condition. They should manage their own trading risks and be prepared to bear the economic risk of losing their entire investment.',
    email: 'E-mail Address',
    service: 'Customer Service',
    support: 'Technical Support',
    application: 'Coin Application',
    suggestions: 'Complaint Suggestions',
    Cooperation: 'Cooperation',
    media: 'Social Media',
    aboutUs: 'About Us',
    ContactUs: 'Contact Us',
  },
  aboutNTL: {
    service: 'Terms of Service',
    Privacy: 'Privacy Policy',
    Disclaimer: 'Disclaimer',
    report: 'Anti-corruption Reporting Instructions',
    Guidelines: 'Law Enforcement Request Guidelines',
    report1:
      'The group of companies is committed to conducting business with integrity and based on ethical best practices and principles (including anti-bribery and anti-corruption compliance standards). The group has zero tolerance for bribery and corruption.',
    report2:
      'If you are aware of or suspect any employee, advisor, or related individuals of the group of companies, or anyone claiming to be an employee or advisor of the group, who has engaged in bribery or requested bribes from you, please notify the group of companies immediately and provide all relevant details to the email address CS@NTLEX.com.',
    report3:
      'The group of companies will treat all information providers and the information they provide as strictly confidential and take the following measures to provide multiple safeguards:',
    report4:
      '1. Whether you voluntarily or involuntarily provide improper benefits or advantages to any employee of the group of companies or related individuals, or to individuals associated with them, before the group of companies become aware of such information through other means, if you proactively provide valid information and explain the situation to the group of companies, the group of companies may, at its own discretion, consider continuing to do business with you based on the actual circumstances. The group of companies may not pursue any violations of the above provisions by you.',
    report5:
      "2. Once information related to acts of commercial bribery provided to the group of companies is verified, the group of companies shall provide remuneration to the information provider based on the impact of the commercial bribery conduct.Please note that this text emphasizes the group's commitment to anti-bribery and anti-corruption principles and encourages individuals to report any such activities while providing confidentiality and potential rewards for doing so.",
    Guidelines1: 'Last Updated: September 01, 2023',
    Guidelines2: 'Applicable to Government and Law Enforcement Agencies Only:',
    Guidelines3: '1. Introduction',
    Guidelines4:
      'As disclosed in our Terms of Service, the services of NTLEX.com are provided by the following entity:',
    Guidelines5:
      "As disclosed in our Terms of Service, the services of NTLEX.com are provided by the following entity:NTLEX.COM. (hereinafter referred to as the 'Company') is a company registered in the Cayman Islands under Cayman Islands law, and it operates the website http://ntlex.com (hereinafter referred to as the 'Website' or 'Site'). The Website is a platform specifically designed for users to engage in digital asset trading and related services (hereinafter referred to as the 'Service' or 'Services'). For the sake of convenience in this agreement, the Company and the Website are collectively referred to as 'we' or 'our'.    ",
    Guidelines6:
      "When necessary, we will respond to requests from authorized law enforcement officers. We will evaluate each case and cooperate in accordance with relevant terms of service, our privacy policy statement, and any applicable laws and regulations. This Law Enforcement Request Guide provides information on how authorized law enforcement officers can contact us and request customer information and/or freeze users' NTL accounts.Please note that we will only respond to requests sent by authorized law enforcement officers. If international law enforcement is involved (where applicable), international law enforcement agencies must provide formal requests for judicial assistance to facilitate our provision of disclosure.",
    Guidelines7: '2. Law Enforcement Requests',
    Guidelines8:
      'We are responsible for handling law enforcement requests from all jurisdictions in accordance with our terms of service, our privacy policy statement, and any applicable laws and regulations.If you are an authorized law enforcement officer, for all law enforcement-related requests, please contact the following email address: CS@NTLEX.com',
    Guidelines9: '3. Required Information',
    Guidelines10:
      'To enable us to process your request, law enforcement officers should send emails to us using an official email address with a government domain and provide at least the following information in the request:',
    Guidelines11: 'Full name of the law enforcement agency.',
    Guidelines12: 'A sealed written law enforcement request, court order, or subpoena.',
    Guidelines13: 'Official contact information (email address and phone number) for you.',
    Guidelines14:
      "If you can provide the following documents simultaneously, your request may be expedited: (i) proof that the law enforcement officer is authorized to request information (such as an authorization certificate) and their proof of employment at the law enforcement agency, (ii) proof of the law enforcement agency officer's identity (such as a photo ID with an identification number and a photo of the internal ID with an internal identification number).Law enforcement officers should clearly state the nature of the assistance they are requesting from us and provide sufficient detailed information for us to properly process the request.",
    Guidelines15: '4. Urgent Law Enforcement Requests',
    Guidelines16:
      "For highly urgent requests, such as when someone's life is at risk or there is a serious threat to personal safety, please indicate the urgent nature of the request in the subject line of the email.",
    Disclaimer1: 'Risk Warning',
    Disclaimer2:
      '1. Digital assets involve significant risks, and there are many uncertainties in market trading. Additionally, due to the generally small market capitalization of digital currencies, prices are susceptible to substantial fluctuations influenced by market manipulation and government policies worldwide. Due to price volatility, you may experience significant gains or losses. Any digital asset can experience substantial fluctuations and may even become worthless.',
    Disclaimer3:
      '2. Due to the formulation or modification of laws, regulations, and normative documents in various countries, digital asset trading may be suspended or prohibited at any time. Users are advised to evaluate the risks based on local laws, regulations, and policy documents when using NTL services.',
    Disclaimer4:
      '3. Users participating in digital asset trading should carefully consider and evaluate the investment value and investment risks of digital assets based on their financial situation. Users should independently control trading risks and bear the economic risk of losing their entire investment.',
    Disclaimer5: 'Legal Disclaimer',
    Disclaimer6: '1. Ownership of Rights',
    Disclaimer7:
      'Unless otherwise stated by NTL, all rights (including copyrights, trademarks, patent rights, trade secrets, and other related rights) to all products, technologies, software, programs, data, and other information (including text, icons, images, photos, audio, video, graphics, color combinations, layout design, etc.) within NTL are owned by the service providers of NTL and/or their affiliated companies. Without the permission of NTL service providers and/or their affiliated companies, no one may use any content within NTL in any way, including monitoring, copying, distributing, displaying, mirroring, uploading, downloading, etc., through robots, spiders, or other programs or devices.The text and logos of NTL, as well as other logos, emblems, product and service names of NTL, are trademarks of NTL service providers and/or their affiliated companies. For any promotional or display use, you must obtain prior written authorization from NTL service providers and/or their affiliated companies.',
    Disclaimer8: '2. Limitation of Liability',
    Disclaimer9:
      "Given that NTL provides an online trading platform that includes digital assets for trading, NTL, as a 'network service provider' third-party platform, does not guarantee that the information and services on the website platform fully meet the user's needs. Works reproduced by NTL (including user comments) are for the purpose of conveying more information and do not imply our endorsement of their views or the verification of their content's authenticity.",
    Disclaimer10:
      "2.1. Users understand and agree that NTL's services are provided as is according to existing technology and conditions. NTL will make its best efforts to provide users with services, ensuring the continuity and security of the services. However, NTL cannot foresee and prevent legal, technical, and other risks, including but not limited to force majeure, viruses, Trojans, hacker attacks, system instability, defects in third-party services, government actions, etc., which may result in service interruptions, data loss, and other losses and risks. NTL shall be exempt from any damage compensation liability under the following circumstances, including but not limited to:",
    Disclaimer11: 'System downtime for maintenance as announced by the NTL platform;',
    Disclaimer12: 'Telecommunication equipment failure that prevents data transmission;',
    Disclaimer13:
      'Service interruption due to force majeure events such as typhoons, earthquakes, tsunamis, floods, power outages, wars, terrorist attacks, etc.;',
    Disclaimer14:
      'Service interruptions or delays caused by reasons such as hacker attacks, computer virus intrusion or outbreak, technical adjustments or failures of the telecommunications department, website upgrades, problems with banks, temporary shutdowns due to government regulations, etc.;',
    Disclaimer15:
      'Losses caused by technical issues that cannot be predicted or resolved due to the current technology capabilities in the industry;',
    Disclaimer16:
      'Losses incurred by users or other third parties due to the fault or delay of third parties;',
    Disclaimer17:
      'In case of abnormal transactions, market interruptions, and other possible abnormal situations caused by system failures, network issues, DDos, hacker attacks, and other unexpected factors, NTL has the right to cancel abnormal transaction results and roll back all transactions during a certain period.',
    Disclaimer18:
      '2.2 Users, when using NTL services, have fully understood the trading risks and agreed that NTL shall not be liable for any compensation for the following matters under any circumstances:',
    Disclaimer19: 'a.Loss of income;',
    Disclaimer20: 'b.Trading profits or contract losses;',
    Disclaimer21: 'c.Business interruption;',
    Disclaimer22: 'd.Loss of expected savings;',
    Disclaimer23: 'e.Loss of information;',
    Disclaimer24: 'f.Loss of opportunities, reputation, or reputation;',
    Disclaimer25: 'g.Data damage or loss;',
    Disclaimer26: 'h.Costs of purchasing alternative products or services;',
    Disclaimer27: 'i.Disappearance or withdrawal of digital assets from the market.',
  },
  // 交易费率
  TransactionRate: {
    TransactionRate: 'Transaction Fees',
    VIPgrade: 'VIP Levels:',
    holdingAmount: 'Average NTL Holdings',
    turnover: 'Yesterday 30-day Trading Volume',
    info: 'NTL is divided into different levels based on the amount held and traded. Different levels determine the transaction fees for the next day.',
    transaction: 'Transactions',
    withdrawal: 'Deposit and Withdrawal',
    title1: 'Level',
    title2: 'Average NTL Holdings',
    title3: '30-day Trading Volume',
    title4: 'Maker/Taker',
    title5: 'Fee Discount',
    title6: 'Full Name',
    title7: 'Mainnet',
    title8: 'Minimum Withdrawal',
    title9: 'Deposit Fee',
    title10: 'Withdrawal Fee',
    searchCoin: 'Search Currency',
    searchLevel: 'search Level',
    free: 'Free',
    noFind: 'Not Found',
  },
  // 模拟交易
  mockTrading: {
    demoTrading: 'Demo trading',
    demoTradText: 'Safe trading, zero risk',
    realTransaction: 'Real transaction',
    exitSimulation: 'You have quit the demo trading',
  },
  // uzxLab
  uzxLab: {
    topText:
      'NTL LAB is committed to exploring and investing in blockchain projects with enormous potential. The initial fund size is $5 million, focusing on discovering and supporting high-quality innovations in the blockchain sector.',
    aboutUs: 'About us',
    meText1:
      'The fund will primarily invest in projects within the blockchain space, with a focus on underlying infrastructure, Layer2 solutions, decentralized finance (DeFi), Web 3.0, non-fungible tokens (NFTs), and the metaverse. NTL LAB aims to identify and fund projects in these areas.',
    meText2:
      'NTL LAB is dedicated to exploring and investing in the most cutting-edge technological innovation projects in the blockchain sector, with the goal of advancing global blockchain infrastructure technology. We aspire to grow alongside blockchain entrepreneurs who are driven by dreams and strong execution, collectively exploring the possibilities of the future. Simultaneously, we will openly share our global resources and extensive industry experience to support entrepreneurs in succeeding in this dynamic field.',
    direction: 'Investment Direction',
    dcTitle1: 'Infrastructure Category',
    dcText1:
      'Layer2 Solutions, Addressing Technical Performance, Improving Efficiency, and Scaling Issues',
    dcTitle2: 'Trading and Financial Projects',
    dcText2: 'Derivatives/Dex/Lending/Synthetic Assets/Oracle/Insurance',
    dcTitle3: 'Popular Public Blockchain Ecosystem Projects',
    dcText3:
      'Polkadot Ecosystem/Solana Ecosystem/Near Ecosystem/Polygon Ecosystem/Avalanche Ecosystem',
    dcTitle4: 'Application Category',
    dcText4: 'Blockchain Browser/Wallet/NFT /GameFi /SocialFi ',
    dcTitle5: 'Web3.0 Tools Category',
    dcText5: 'Data Protocols/Technology Service Providers/Plugins/Privacy Protection',
    support: 'Support for Projects',
    haiwai: 'Overseas Resources',
    haiwaiText:
      "We provide entrepreneurs with access to a wide range of global resources, covering key markets such as Mainland China, Silicon Valley, Japan, Korea, and Southeast Asia. We have established a close-knit global resource network with media and Key Opinion Leaders (KOLs) in these regions, dedicated to offering multi-dimensional support for entrepreneurs. Whether it's confirming or refining products and services, establishing valuable strategic partnerships, connecting with potential customer groups, attracting investors, finding potential acquirers, or engaging business advisors, we can provide crucial assistance and guidance. Our goal is to help entrepreneurs broaden their perspectives globally and achieve their business objectives.",
    shengtai: 'Ecological Resources',
    shengtaiText:
      'We are committed to facilitating transaction platforms in providing ecological resource support for projects in which we are deeply involved and have invested. These projects will receive preferential recommendations to relevant platforms. Once approved, they will have the opportunity to enjoy various product services, promotional resources, user traffic, and brand support provided by the exchange for free. Tailoring detailed promotion plans for each project based on its unique characteristics, we start from the initial launch on the platform to comprehensively showcase project advantages. This not only attracts platform users but also targets a broad global audience within the blockchain industry.',
    fazhan: 'Development Advice',
    fazhanText:
      'Our mission is clear and singular: to assist entrepreneurs in building a truly powerful enterprise. For us, the success of entrepreneurs is our success. We deeply understand that mutual trust is the cornerstone of success. Therefore, we pledge to uphold principles of honesty and transparency in our work, treating entrepreneurs with candor as we stride together towards success.',
    lianxi: 'Contact us',
  },
  official: {
    officialChannel: 'Official verification channels',
    officialChannel2: 'Unofficial authentication channels',
    ocDoc:
      'To avoid phishing and online fraud causing asset losses, you can verify whether email addresses, or X, Telegram, and other social accounts are official NTL authentication channels on this page.',
    ocPlaceholder: 'Please enter the correct information and format for verification.',
    ocCheck: 'Check',
    notPass: 'The [{0}] you queried is not an official NTL channel.',
    tips: 'Please verify if the information and format you entered are correct:',
    tips2: 'Telegram/X: @xxxxx (with @ symbol)',
    tips3: 'Telegram group: https://xxxxx (website link)',
    doc: '*Please do not disclose your account information and password to anyone.',
    doc2: '*Beware of fraud and operate cautiously.',
  },
  country: {
    cou1: `USA`,
    cou2: `Japan`,
    cou3: `Germany`,
    cou4: `Britain`,
    cou5: `India`,
    cou6: `France`,
    cou7: `Italy`,
    cou8: `Canada`,
    cou9: `South Korea`,
    cou10: `Russia`,
    cou11: `Australia`,
    cou12: `Brazil`,
    cou13: `Hong Kong, China`,
    cou14: `Macau, China`,
    cou15: `Taiwan of China`,
    cou16: `Spain`,
    cou17: `Mexico`,
    cou18: `Indonesia`,
    cou19: `Holland`,
    cou20: `Switzerland`,
    cou21: `Saudi Arabia`,
    cou22: `Turkey`,
    cou23: `Iran`,
    cou24: `Poland`,
    cou25: `Sweden`,
    cou26: `Belgium`,
    cou27: `Thailand`,
    cou28: `Nigeria`,
    cou29: `Austria`,
    cou30: `Ireland`,
    cou31: `Israel`,
    cou32: `Norway`,
    cou33: `Argentina`,
    cou34: `Philippines`,
    cou35: `Arab`,
    cou36: `Egypt`,
    cou37: `Denmark`,
    cou38: `Malaysia`,
    cou39: `Singapore`,
    cou40: `Vietnam`,
    cou41: `Bangladesh`,
    cou42: `South Africa`,
    cou43: `Chile`,
    cou44: `Finland`,
    cou45: `Colombia`,
    cou46: `Romania`,
    cou47: `Portugal`,
    cou48: `New Zealand`,
    cou49: `Greece`,
    cou50: `Qatar`,
    cou51: `Belarus`,
  },

  authentication_title: 'Authentication',
  authentication_has: 'Authenticated',
  authentication_no: 'Not Authenticated',
  authentication_real_name: 'Real Name',
  authentication_real_name_placeholder: 'Please enter your real name',
  authentication_real_name_error: 'Please enter a valid real name',
  authentication_real_type: 'Document Type',
  authentication_real_card: 'Document Number',
  authentication_real_card_placeholder: 'Please enter your document number',
  authentication_real_card_error: 'Please enter a valid document number',
  authentication_card_image: 'Document Photo',
  authentication_front_image: 'Front Side of Document',
  authentication_reverse_image: 'Back Side of Document',
  authentication_hand_image: 'Hand-held Document Photo',
  authentication_card_image_error: 'Please upload a valid document photo',
  authentication_submit: 'Submit',
  authentication_real_type_0: 'Identity Card',
  authentication_real_type_1: 'Passport',
  authentication_real_type_2: "Driver's License",

  bind_phone: 'Phone number',
  bind_phone_tip: 'Your Phone number',
  bind_phone_no: 'Unbound',
  bind_phone_yes: 'Bound',
  bind_phone_number_error: 'Your Phone number',
  bind_phone_code_error: 'Verification code',
  bind_phone_pwd: 'Password',
  bind_phone_pwd_tip: 'Please enter your login password',
};
